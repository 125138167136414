.gallery-wrapp {
	overflow: hidden;
	position: relative;
	margin-bottom: 20px;

	@include media ('>=tablet') {
		margin-bottom: 30px;
	}

	.custom-pagination {
		height: 12px;
		position: absolute;
		right: 0;
		left: 0;
		bottom: 10px;
		z-index: 10;
		text-align: center;

		span {
			border-radius: 50%;
			margin: 0 3px;
			display: inline-block;
			vertical-align: top;
			cursor: pointer;
			background: $white;
			opacity: 1;
			width: 12px;
			height: 12px;
			border: 1px solid $brand-warning;
			transition: background .2s;
			&:hover,
			&.active {
				background: $brand-warning;
			}
		}
	}
}

.carousel-holder {
	overflow: hidden;
	padding: 10px 0 6px;

	.gallery-wrapp {
		margin: 0;
	}

	@include media ('>=tablet') {
		margin: 0 -16px;
		padding: 40px 0 20px;
	}
	@include media ('>=desktop') {
		margin: 0;
		padding: 40px 5px 36px;
	}
}

.swiper-container {
	.swiper-pagination-progress {
		height: 3px;
		background: none;
		position: absolute;
		top: auto;
		bottom: 0;

		.swiper-pagination-progressbar {
			background: $brand-warning;
		}
	}

	.swiper-pagination-fraction {
		color: $white;
		font-weight: 500;
		font-size: 12px;
		bottom: 100px;
		text-align: left;
		padding: 0 26px;
	}

	.swiper-pagination-bullets {
		height: 12px !important;

		.swiper-pagination-bullet {
			margin: 0 3px;
			vertical-align: top;
			background: $white;
			opacity: 1;
			width: 12px;
			height: 12px;
			border: 1px solid $brand-warning;
			transition: background .2s;
			&:hover,
			&.swiper-pagination-bullet-active {
				background: $brand-warning;
			}
		}
	}
}

.counter {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	bottom: 100%;
	width: auto;
	color: $white;
	font-weight: 500;
	font-size: 11px;
	padding: 0 23px 7px;
	z-index: 10;
	@include media ('>=tablet') {
		font-size: 12px;
		padding: 0 26px 8px;
	}
}

.swiper-button-prev,
.swiper-button-next {
	z-index: 20;
	margin-top: -19px;
	left: 0;
	width: 38px;
	height: 38px;


	@include media ('>=tablet') {
		width: 44px;
		height: 44px;
		margin-top: -22px;
	}
}

.swiper-button-next {
	left: auto;
	right: 0;
}

.swiper-control-panel {
	position: absolute;
	bottom: 150px;
	left: 0;
	padding: 0 18px 22px;
	z-index: 7;

	@include media ('>=tablet') {
		padding: 0 20px 53px;
	}

	@include media ('>=desktop') {
		padding: 0 20px 21px;
	}

	.swiper-control-btn {
		padding: 0;
		float: left;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		color: $white;
		background: rgba($gray-base, .4);
		text-align: center;
		text-decoration: none;
		transition: background .2s;

		&.active {
			.icon-play {
				display: none;
			}
			.icon-pause {
				display: block;
			}
		}

		@include media ('>=tablet') {
			width: 44px;
			height: 44px;
		}
		&:only-child {
			margin: 0;
		}
		&:hover {
			text-decoration: none;
			background: $brand-warning;
		}

		.icon-play {
			display: block;
		}
		.icon-pause {
			display: none;
		}

		i {
			line-height: 40px;
			display: inline-block;
			@include media ('>=tablet') {
				line-height: 44px;
			}
			&.icon-stop {
				font-size: 9px;
				@include media ('>=tablet') {
					font-size: 10px;
				}
			}
			&.icon-play {
				margin-left: 3px;
				font-size: 9px;
				@include media ('>=tablet') {
					font-size: 10px;
				}
			}
			&.icon-pause {
				font-size: 9px;
				@include media ('>=tablet') {
					font-size: 10px;
				}
			}
		}
	}
}


.has-full-mode-gallery {
	overflow: hidden;
}


.visual-gallery {
	background: $white;
	&.visual-without-thumbs {
		padding-right: 0;
		.swiper-wrapper {
			@include media ('>=desktop') {
				height: 556px;
			}
			.caption {
				p {
					@include media ('>=tablet') {
						max-width: 87%;
					}
				}
			}
		}
	}

	&.has-video-active {
		.swiper-pagination,
		.screen-controll-btn,
		.gallery-top .swiper-button-prev,
		.gallery-top .swiper-button-next,
		.caption,
		.swiper-control-panel,
		.btn-play {
			display: none;
		}
	}

	&.full-mode-gallery {
		position: fixed;
		z-index: 100;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;

		.swiper-container {
			height: 100%;
		}

		.swiper-wrapper {
			height: 100%;
		}

		.screen-controll-btn {
			.icon-reduce {
				opacity: 1;
				visibility: visible;
			}
			.icon-full {
				opacity: 0;
				visibility: hidden;
			}
		}

		&.visual-without-thumbs {
			.swiper-container {
				height: 100%;
			}
			.swiper-wrapper {
				height: 100%;
			}
			.caption {
				p {
					max-width: none;
				}
			}
		}

		.caption {
			p {
				max-width: none;
			}
		}
	}
	@include media ('>=desktop') {
		padding-right: 240px;
	}
	.swiper-wrapper {
		height: 274px;
		@include media ('>=tablet') {
			height: 512px;
		}
		@include media ('>=desktop') {
			height: 675px;
		}
	}

	.swiper-slide {
		background-size: cover;
		background-position: center;
		a {
			display: block;
			width: 100%;
			height: 100%;

			&:hover {
				.btn-play {
					color: $brand-warning;
				}
			}
		}

		&.video-active {
			background-image: none !important;
			background: $gray-base;
			.video {
				border: none;
				width: 100%;
				height: 100%;
			}
		}
	}

	.gallery-thumbs {
		height: 100%;
		width: 240px;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		overflow: hidden;
		display: none;
		box-sizing: border-box;
		background: $white;

		@include media ('>=desktop') {
			display: block;
		}

		.swiper-slide {
			width: 100%;
			height: 25%;

			&.swiper-slide-active {
				opacity: 1;
				a {
					&:before {
						display: block;
					}
				}
			}

			a {
				display: block;
				height: 100%;
				&:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: rgba($white, .6);
					display: none;
				}
			}
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		text-align: center;
		width: 44px;
		border-radius: 50%;
		background: rgba($gray-base, .3);
		transition: background .2s;
		left: 20px;
		display: none;
		margin-top: -22px;

		@include media ('>=tablet') {
			display: block;
		}

		i {
			color: $white;
			line-height: 44px;
		}

		&:hover {
			background: $brand-warning;
		}
	}
	.swiper-button-next {
		left: auto;
		right: 20px;
	}
	.swiper-container-vertical {
		.swiper-button-prev,
		.swiper-button-next {
			transform: rotate(90deg);
			bottom: 10px;
			left: 50%;
			top: auto;
			margin-top: 0;
			margin-left: -22px;
			i {
				margin-top: -1px;
				margin-right: -2px;
				display: inline-block;
			}
		}

		.swiper-button-prev {
			top: 10px;
			bottom: auto;
			i {
				margin-right: 0;
				margin-top: -1px;
				margin-left: -2px;
			}
		}
	}
	.caption {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba($gray-base, .4);
		padding: 14px 17px 19px;
		color: $white;
		font-size: 12px;
		line-height: 1.27;
		font-weight: 500;
		@include media ('>=tablet') {
			font-size: 14px;
			line-height: 1.285;
			padding: 14px 20px 19px;
		}

		p {
			margin: 0;
			@include media ('>=tablet') {
				max-width: 72%;
			}
		}
	}

	.screen-controll-btn {
		position: absolute;
		right: 20px;
		top: 20px;
		z-index: 8;
		width: 32px;
		height: 32px;
		color: rgba($gray-base, .5);
		text-decoration: none;
		display: none;
		&:hover {
			color: $brand-warning;
			text-decoration: none;
		}

		@include media ('>=tablet') {
			display: block;
		}
		[class^="icon-"], [class*=" icon-"]{
			transition: opacity .2s, visibility .2s;
		}
		.icon-full {
			opacity: 1;
			visibility: visible;
		}
		.icon-reduce {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
		}
	}

	.btn-play {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 82px;
		height: 82px;
		color: $white;
		text-decoration: none;
		margin: -41px 0 0 -41px;
		display: none;
		transition: color .2s;
		@include media ('>=tablet') {
			display: block;
		}
	}

	.close-video {
		z-index: 1000;
		position: absolute;
		top: 10px;
		left: 10px;
		color: $gray-base;
		cursor: pointer;
		transition: background .2s;
		box-shadow: 1px 1px 1px rgba($gray-base, .5);
		width: 30px;
		height: 30px;
		background: $white;
		border-radius: 50%;
		text-align: center;


		&:hover {
			background: $brand-warning;
		}
		&:before {
			content: "\e916";
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 30px;
		}
	}
}

.carousel-with-nav {
	padding: 0 32px;

	@include media ('>=tablet') {
		padding: 0 20px;
	}

	@include media ('>=1280px') {
		margin: 0 -32px 24px;
	}
}

.post-gallery {
	padding: 0 0 26px;
	@include media ('>=tablet') {
		padding: 0 32px;
	}

	.swiper-wrapper {
		height: 368px;
		@include media ('>=tablet') {
			height: 410px;
		}
		@include media ('>=desktop') {
			height: 676px;
		}
	}

	.swiper-slide {
		background-size: cover;
		background-position: center;
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
		@include media ('>=tablet') {
			display: block;
		}
		@include media ('>=desktop') {
			margin-top: -32px;
		}
	}

	.swiper-pagination {
		height: 4px;
	}
	.custom-pagination {
		bottom: 0;
		@include media ('>=tablet') {
			display: none;
		}
	}
}

.gallery-top-post {
	&:after {
		@include media ('>=desktop') {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			background: rgba($gray-base, .4);
			z-index: 1;
			width: 392px;
		}
	}

	.text-block {
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-right: 450px;
		padding: 20px 15px 32px;
		text-align: center;
		@include media ('>=tablet') {
			justify-content: flex-end;
			text-align: left;
			padding: 50px 200px 50px 50px;
		}

		@include media ('>=desktop') {
			padding: 50px 450px 50px 50px;
		}
	}

	.gallery-post {
		color: $white;
		font-size: 13px;
		line-height: 1.27;
		font-weight: 500;
		@include media ('>=tablet') {
			max-width: 430px;
			font-size: 14px;
			line-height: 1.285;
		}

		.title {
			display: inline-block;
			vertical-align: top;
			border-bottom: 1px solid $white;
			border-top: 1px solid $white;
			padding: 13px 0 12px;
			letter-spacing: 1.2px;
			text-transform: uppercase;
			font-size: 11px;
			line-height: 1;

			@include media ('>=tablet') {
				font-size: 12px;
				padding: 14px 0 13px;
			}
		}

		p {
			margin-bottom: 20px;
			@include media ('>=tablet') {
				max-width: 80%;
				margin-bottom: 24px;
			}
		}

		h2 {
			font-size: 36px;
			line-height: 1;
			max-width: 86%;
			margin: 17px auto 10px;

			@include media ('>=tablet') {
				font-size: 56px;
				max-width: 91%;
				margin-top: 17px;
				margin: 17px 0 9px;
			}
		}

		.btn-link {
			position: relative;
			border: 1px solid $white;
			color: $white;
			font-size: 11px;
			line-height: 1;
			min-width: 134px;
			padding: 15px 12px;
			text-align: center;
			transition: background .2s, border-color .2s;
			@include media ('>=tablet') {
				min-width: 148px;
				font-size: 12px;
				padding: 17px 12px;
			}
			&:hover {
				background: $brand-warning;
				border-color: $brand-warning;
			}
			&:focus,
			&.active:focus,
			&:active:focus,
			&.active,
			&:active {
				background: none;
				border-color: $white;
			}
		}

		.post-holder {
			width: 100%;
			@include media ('>=tablet') {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.info-box {
			padding: 0 13px 1px 20px;
			font-weight: 900;
			display: none;
			@include media ('>=tablet') {
				display: block;
			}
			span {
				display: block;
				font-weight: 500;
			}
		}
	}
}

.gallery-thumbs-post {
	padding: 0 20px 0 48px;
	position: absolute;
	right: 32px;
	top: 34px;
	bottom: 34px;
	width: 392px;
	z-index: 1;
	height: auto;
	display: none;
	@include media ('>=desktop') {
		display: block;
	}

	.thumbs-item {
		color: $white;
		cursor: pointer;
		padding: 14px 0;

		&.thumbs-active,
		&:hover {
			.img-box {
				&:before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	.thumbs-post {
		@extend %clearfix;
		transform: translate3d(0,0,0);
		.holder {
			overflow: hidden;
			font-size: 12px;
			line-height: 1.5;
			font-weight: 900;
			color: rgba($white, .7);
		}

		.sub-title {
			display: block;
			text-transform: uppercase;
			line-height: 1;
			padding: 0 0 11px;
			font-weight: 700;
			color: $white;
		}

		.img-box {
			margin-right: 24px;
			float: left;
			width: 153px;
			position: relative;

			&:before {
				content: '';
				opacity: 0;
				visibility: hidden;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border: 2px solid $brand-warning;
				transition: opacity .2s, visibility .2s;
			}

			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}

		h3 {
			color: $white;
			margin-top: 0;
			font-size: 30px;
			font-weight: 700;
			line-height: 1;
			margin-bottom: 1px;
		}

		p {
			margin: 0;
		}
	}
}

.carousel-wrapper {
	margin: 0 0 24px;
	padding: 0 2px;
	@include media ('>=tablet') {
		padding: 0;
	}
	.swiper-container {
		padding-bottom: 14px;
		@include media ('>=tablet') {
			padding-bottom: 0;
		}
	}
	.swiper-pagination {
		position: relative;
		bottom: -13px;
		@include media ('>=tablet') {
			display: none;
		}
	}
}

.service-carousel {
	padding: 0;

	&:after,
	&:before {
		content: '';
		position: absolute;
		width: 32px;
		top: 0;
		bottom: 0;
		left: 0;
		height: auto;
		background: $white;
		z-index: 10;
	}

	&:after {
		left: auto;
		right: 0;
	}

	@include media ('>=640px') {
		margin: 0 -12px;
	}

	@include media ('>=tablet') {
		padding: 0 20px;
		margin: 0 0 24px;
	}

	@include media ('>=1280px') {
		margin: 0 -32px 24px;
	}

	.swiper-container {
		padding-bottom: 16px;
		@include media ('>=tablet') {
			padding-bottom: 0;
		}
	}

	.swiper-slide {
		@include media ('>=640px') {
			padding: 0 12px;
		}
	}

	.swiper-pagination {
		position: relative;
		bottom: -15px;
		@include media ('>=tablet') {
			display: none;
		}
	}

	.product-block {
		margin-bottom: 0;
		padding: 0 0 7px;
		@include media ('>=tablet') {
			padding: 0;
		}

		.holder-box {
			padding-bottom: 13px;
			@include media ('>=tablet') {
				padding-bottom: 25px;
			}
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
		@include media ('>=tablet') {
			display: block;
		}
	}
}

.product-carousel {
	padding: 0;
	@include media ('>=tablet') {
		padding: 0 20px;
	}

	&:after,
	&:before {
		content: '';
		position: absolute;
		width: 32px;
		top: 0;
		bottom: 0;
		left: 0;
		height: auto;
		background: $white;
		z-index: 10;
	}

	&:after {
		left: auto;
		right: 0;
	}

	.product-block {
		margin-bottom: 0;
		h3 {
			min-height: 0;
			@include media ('>=640px') {
				min-height: 60px;
			}
		}
	}
	.swiper-pagination {
		display: none;
	}
	.swiper-slide {
		overflow: hidden;
		@include media ('>=640px') {
			padding: 0 12px;
		}
	}
}

.partner-section {
	.gallery-wrapp {
		padding: 0 25px;
		margin-bottom: 33px;
		@include media ('>=tablet') {
			padding: 0 80px;
			margin-bottom: 12px;
		}
	}
	.swiper-container {
		padding-bottom: 25px;
		@include media ('>=tablet') {
			padding-bottom: 0;
		}
	}
	.swiper-pagination {
		bottom: 3px;
		@include media ('>=tablet') {
			display: none;
		}
	}
	.swiper-slide {
		@include media ('>=640px') {
			padding: 0 15px;
		}
	}
	.swiper-button-prev,
	.swiper-button-next {
		display: none;
		@include media ('>=tablet') {
			display: block;
		}
	}
}

.info-section {
	&.item {
		@include media ('>=desktop') {
			padding-bottom: 40px;
		}
		.swiper-button-prev,
		.swiper-button-next {
			@include media ('>=tablet') {
				display: none;
			}
		}

		.heading-title {
			padding: 0;
		}

		.swiper-container {
			padding: 0;
		}
	}
	.swiper-container {
		@include media ('>=tablet') {
			padding-top: 58px;
		}
		@include media ('>=desktop') {
			padding-top: 47px;
		}
	}

	.gallery-wrapp {
		margin-bottom: 0;
		@include media ('>=tablet') {
			margin: 0 -14px;
		}
		@include media ('>=desktop') {
			margin: 0 -15px;
		}
	}

	.swiper-slide {
		padding: 0 28px;
		@include media ('>=tablet') {
			padding: 0 14px;
		}
		@include media ('>=desktop') {
			padding: 0 15px;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		z-index: 20;
		@include media ('>=tablet') {
			left: auto;
			right: 11px;
			padding: 3px 2px;
			height: 24px;
			width: 20px;
			border: none;
			top: 0 !important;
			margin-top: 0;
		}

		&:hover {
			@include media ('>=tablet') {
				color: $brand-warning;
				background: none;
			}
		}
	}

	.swiper-button-prev {
		@include media ('>=tablet') {
			right: 37px;
		}
	}
}

.holder-section {
	.swiper-button-prev,
	.swiper-button-next {
		@include media ('>=tablet') {
			display: none;
		}
	}
}

.carousel-history {
	.swiper-slide {
		padding: 0 8px 1px;
		@include media ('>=tablet') {
			padding: 0 13px 1px;
		}
	}
}

.gallery-box {
	.swiper-slide {
		padding: 0 20px;
	}
}

.default-carousel {
	margin: 0 -10px;
	border-bottom: 1px solid lighten($gray-base, 91.37%);

	&.item-default {
		margin: 0 -6px;
		@include media ('>=tablet') {
			margin: 0 -10px;
		}
		@include media ('>=desktop') {
			margin: 0 -12px;
		}

		.swiper-slide {
			padding: 0 9px;

			@include media ('>=tablet') {
				padding: 0 10px;
			}

			@include media ('>=desktop') {
				padding: 0 12px;
			}
		}
	}

	@include media ('>=tablet') {
		margin: 0 -12px;
	}
	@include media ('>=desktop') {
		margin: 0 -15px;
	}

	.swiper-slide {
		padding: 0 9px;

		@include media ('>=tablet') {
			padding: 0 12px;
		}

		@include media ('>=desktop') {
			padding: 0 15px;
		}
	}

	.item-box {
		h3 {
			font-size: 22px;
			line-height: 1;
			margin-top: 8px;
			margin-bottom: 6px;

			@include media ('>=tablet') {
				font-size: 24px;
				margin-bottom: 16px;
			}
			@include media ('>=desktop') {
				font-size: 28px;
				margin-bottom: 19px;
			}
		}
	}

	.detail-post {
		margin-bottom: 0;
		width: 100%;

		@include media ('>=tablet') {
			padding-bottom: 8px;
		}
		@include media ('>=desktop') {
			padding-bottom: 16px;
		}

		.img-box{
			@include media ('>=tablet') {
				margin-bottom: 18px;
			}
			@include media ('>=desktop') {
				margin-bottom: 15px;
			}
			@include media ('<tablet') {
				margin-bottom: 16px;
			}
		}

		h3 {
			font-weight: 500;
			@include media ('<tablet') {
				font-size: 14px;
				line-height: 1;
				margin: 0 0 2px;
			}
		}

		.details {
			@include media ('<tablet') {
				font-size: 9px;
				margin-bottom: 6px;
			}
		}

		.price {
			@include media ('<tablet') {
				border-top: 1px solid lighten($gray-base, 91.37%);
				font-size: 21px;
				width: 100%;
				padding: 11px 0 0;
				margin-top: 10px;
			}

			.sup {
				@include media ('<tablet') {
					font-size: 12px;
					padding-top: 2px;
				}
			}
		}

		.builder {
			margin: 0;
			padding: 0 0 1px;
			display: block;
			@include media ('<tablet') {
				display: none;
			}
			span {
				@include media ('>=tablet') {
					display: block;
				}
				@include media ('>=desktop') {
					display: inline;
				}
			}
		}

		.link-box {
			@include media ('<tablet') {
				border: none;
				flex-direction: column-reverse;
				align-items: flex-start;
				font-size: 9px;
				padding-bottom: 0;
				margin-bottom: 2px;
			}
			@include media ('>=tablet') {
				padding-bottom: 13px;
				margin-bottom: 8px;
			}
			@include media ('>=desktop') {
				margin-bottom: 15px;
			}

			a {
				color: rgba($gray-base, .5);
				&:hover {
					color: $gray-base;
					@include media ('<tablet') {
						color: $link-color;
					}
				}
				@include media ('<tablet') {
					color: $gray-base;
				}
			}
		}
	}
}

.customer-product {
	padding: 6px 0 0;
	@include media ('>=600px') {
		padding: 6px 30px 0;
	}
	@include media ('>=tablet') {
		padding: 0 10px;
	}
	@include media ('>=desktop') {
		padding: 0;
	}
}

.customer-carousel {
	@include media ('>=desktop') {
		margin: 0 -24px;
	}
	.swiper-slide {
		padding: 0 20px;
		@include media ('>=tablet') {
			padding: 0 5px;
		}
		@include media ('>=desktop') {
			padding: 0 25px;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		margin-top: -34px;
		top: 27%;
	}

	.product-block {
		margin-bottom: 0;
		h3 {
			font-size: 24px;
			margin-bottom: 4px;
			@include media ('>=tablet') {
				font-size: 26px;
				line-height: 1.076;
				margin-bottom: 0;
			}
		}

		.note-box {
			padding: 0;
		}
	}
}