#wrapper {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.container {
	width: 100%;
	max-width: 1224px;
	@include media ('<=desktop') {
		padding: 0 10px;
	}
}

.bg-default {
	background: lighten($gray-base, 94.9%);
}

.bg-warning {
	background: $brand-warning;
}

.bg-success {
	background: $brand-success;
}

.bg-danger {
	background: $brand-danger;
}

.bg-primary {
	background: $brand-primary;
}

.bg-info {
	background: $brand-info;
}

.panel {
	box-shadow: none;
	&.fancy-panel {
		.panel-list {
			margin: 0 0 -17px;
			li {
				border-width: 1px 0 0;
				&.active {
					a {
						color: $brand-warning;
					}
				}
				&:first-child {
					border: none;
				}
				a {
					&:hover {
						color: $brand-warning;
					}
					&:active {
						color: $gray-base;
					}
				}
			}
		}
	}

	.btn-block {
		margin-bottom: 0;
	}

	.panel-title {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 17px;
	}

	.panel-list {
		margin: 0 0 15px;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.6px;

		li {
			border-radius: 0;
			border: 1px solid #e3e3e3;
			border-width: 0 0 1px;
			padding-left: 0;

			&.active {
				a {
					color: $brand-primary;
					font-weight: 800;
				}
			}

			a {
				display: block;
				padding: 12px 10px 11px 0;
				position: relative;
				color: $gray-base;
				text-decoration: none;
				transition: color .2s;
				min-height: 41px;
				display: block;
				width: 100%;
				height: auto;

				&:before {
					content: '›';
					position: absolute;
					top: 50%;
					margin-top: -7px;
					right: 0;
					line-height: 1;
				}
				&:hover {
					font-weight: 800;
					color: $brand-primary;
				}
				&:active {
					color: $brand-primary;
				}
			}

			strong {
				font-weight: 800;
			}
		}
	}
}

.text-warning {
	color: $brand-warning;
}
.text-primary {
	color: $brand-primary;
}
.text-success {
	color: $brand-success;
}
.text-danger {
	color: $brand-danger;
}

.font-serif {
	font-family: $font-family-serif;
}

.font-sans-serif {
	font-family: $font-family-sans-serif;
}

.example-block {
	margin-left: 0;
	margin-right: 0;
}

.example-head {
	border-bottom: 1px solid lighten($gray-base, 74.9%);
	margin-bottom: 80px;
	.example-title {
		color: $brand-primary;
		margin-bottom: 26px;
	}
}

.example-title-sm,
.example-title {
	font-size: 24px;
	color: $gray-light;
	font-family: $font-family-sans-serif;
	font-weight: 700;
	margin-bottom: 40px;
}

.example-title-sm {
	display: block;
	margin-bottom: 15px;
	font-size: 14px;
	text-transform: uppercase;
}

.example-wrapper {
	@extend %clearfix;
	border-top: 1px solid lighten($gray-base, 91.37%);
	margin-bottom: 30px;
	@include media ('>=tablet') {
		margin-bottom: 40px;
	}
}

.example-row {
	overflow: hidden;
	width: 100%;
	margin-bottom: 50px;
	border-bottom: 1px solid $gray-lighter;
	padding-bottom: 20px;

	.btn-block {
		margin-bottom: 20px;
	}

	.panel {
		.btn-block {
			margin-bottom: 0;
		}
	}

	.lead {
		font-size: 44px;
		line-height: 0.909;
		font-weight: normal;
		margin: 0 0 48px;

		&.font-sans-serif {
			font-weight: 100;
		}
	}

	.validation-row {
		margin-bottom: 34px;
	}
}

.btn-row {
	.example-title-sm {
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 20px;
		width: 150px;
		text-align: right;
	}

	nav {
		display: inline-block;
		vertical-align: middle;
	}

	.pager {
		margin: 0 0 20px 40px;
	}

	.btn-top,
	.btn {
		margin-left: 40px;
		margin-bottom: 20px;
	}

	.btn-arrow  + .btn-arrow {
		margin-left: 1px;
	}
}

.example-list {
	font-size: 44px;
	line-height: 1.136;
	padding-top: 5px;
	margin-bottom: 24px;
}

.list-fancybox {
	li {
		margin-bottom: 10px;
	}
}

.fw-100 {
	font-weight: 100;
}
.fw-300 {
	font-weight: 300;
}
.fw-400 {
	font-weight: 400;
}
.fw-500 {
	font-weight: 500;
}
.fw-600 {
	font-weight: 600;
}
.fw-700 {
	font-weight: 700;
}
.fw-900 {
	font-weight: 900;
}

.step-list {
	text-align: center;
	counter-reset: newCounter;
	font-size: 13px;
	padding: 0 20px;
	margin-bottom: 35px;
	letter-spacing: .6px;
	li {
		padding: 0 0 20px;
		@include media ('>=tablet') {
			padding: 0;
		}
		&.active {
			a {
				&:before {
					background: $brand-warning;
				}
			}
		}
		a {
			display: block;
			position: relative;
			padding: 37px 20px 0;
			color: $gray-base;
			&:before {
				font: 700 20px/32px "Times New Roman", Times, serif;
				counter-increment: newCounter;
				content: counters(newCounter,".") " ";
				background: lighten($gray-base, 74.9%);
				color: $white;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -16px;
				transition: background .2s;
			}
			&:focus,
			&:active:focus,
			&:active,
			&:hover {
				background: none;
				&:before {
					background: $brand-warning;
				}
			}
		}
	}
}

.public-steps {
	text-align: center;
	counter-reset: newCounter;
	font-size: 14px;
	padding: 0 20px;
	margin-bottom: 35px;
	letter-spacing: 0;
	font-weight: 700;
	li {
		position: relative;
		padding: 60px 20px 0;
		margin: 0 0 20px;
		color: lighten($gray-base, 74.9%);
		transition: color .2s;

		@include media ('>=tablet') {
			padding: 78px 20px 0;
			margin: 0;
		}
		&:before {
			z-index: 2;
			font: 700 28px/50px "Times New Roman", Times, serif;
			counter-increment: newCounter;
			content: counters(newCounter,".") " ";
			background: lighten($gray-base, 74.9%);
			color: $white;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -25px;
			transition: background .2s, color .2s;

			@include media ('>=tablet') {
				width: 64px;
				height: 64px;
				margin-left: -32px;
				font-size: 42px;
				line-height: 64px;
			}
		}
		&.active {
			color: $gray-base;
			&:before {
				background: $brand-warning;
				color: $gray-base;
			}
			&:after {
				background: $brand-warning;
			}
		}
		&:after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 32px;
			left: 0;
			height: 2px;
			width: 100%;
			background: lighten($gray-base, 91.3725%);
			display: none;

			@include media ('>=tablet') {
				display: block;
			}
		}
		&:first-child {
			&:after {
				width: 52%;
				left: 50%;
			}
		}
		&:last-child {
			&:after {
				width: 52%;
				right: 50%;
				left: auto;
			}
		}
	}
}

.example-loader {
	.example-title-sm {
		margin-bottom: 10px;
	}
	.loader {
		margin-bottom: 30px;
	}
}

.loader {
	display: inline-block;
	vertical-align: top;
	width: 150px;
	height: 150px;
	position: relative;
	margin-top: 30px;

	@include media ('>=tablet') {
		width: 210px;
		height: 210px;
		margin-top: 40px;
	}

	.circle {
		canvas {
			width: 150px !important;
			height: 150px !important;

			@include media ('>=tablet') {
				width: 210px !important;
				height: 210px !important;
			}
		}
	}

	.logo-img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(0.7);

		@include media ('>=tablet') {
			transform: translate(-50%, -50%) scale(1);
		}

		&.item {
			top: 46%;
		}
	}

	.progress {
		position: absolute;
		bottom: 100%;
		margin: 0 0 5px;
		font-weight: 700;
		left: 0;
		right: 0;
		width: auto;
		text-align: center;
		color: $gray-light;
		font-size: 14px;

		@include media ('>=tablet') {
			font-size: 16px;
		}

		em {
			font-style: normal;
		}
	}
}

.example-icons-list {
	&.align-middle {
		li {
			vertical-align: middle;
		}
	}
	li {
		display: inline-block;
		vertical-align: top;
		padding: 0 5px 15px;

		img {
			vertical-align: top;
		}
	}
}

.banner-box {
	max-width: 140px;
	background: lighten($gray-base, 94.9%);
	margin: 0 auto 24px;
	@include media ('>=tablet') {
		max-width: 384px;
		margin: 0 0 24px;
	}

	@include media ('>=desktop') {
		padding: 42px;
	}

	&.primary-box {
		a {
			border-color: #113b70;
		}
	}

	&.horizontal-box {
		max-width: 812px;
	}

	a {
		display: block;
		border: 1px solid $gray-base;
	}

	img {
		width: 100%;
		display: block;
		height: auto;
	}
}

.product-block {
	width: 100%;
	position: relative;
	font-size: 14px;
	line-height: 1.285;
	margin-bottom: 24px;
	max-width: 384px;
	margin: 0 auto;

	img {
		height: auto;
		max-width: 100%;
	}

	.holder-box {
		color: $gray-light;
		display: block;
		padding-bottom: 24px;
		@include media ('>=tablet') {
			padding-bottom: 25px;
		}

		&.hover {
			h3 {
				color: #2a3768;
			}

			.slide-box {
				height: 93px;
			}
		}

		img {
			display: block;
			width: 100%;
		}
	}

	.text-box {
		background: $white;
		padding: 25px 25px 0;
		position: absolute;
		bottom: 0;
		left: 14px;
		right: 14px;
		@include media ('>=tablet') {
			left: 27px;
			right: 27px;
			padding: 28px 25px 0;
		}
		@include media ('>=desktop') {
			left: 42px;
			right: 42px;
		}
	}

	.category-title {
		font-size: 11px;
		line-height: 1;
		letter-spacing: 1.2px;
		@include text-overflow;
		@include media ('>=tablet') {
			font-size: 12px;
		}
	}

	h3 {
		font-size: 25px;
		line-height: 1;
		letter-spacing: -0.7px;
		color: $gray-base;
		margin-top: 10px;
		margin-bottom: 6px;
		font-weight: 700;
		transition: color .2s;

		@include media ('>=tablet') {
			font-size: 28px;
			margin-top: 12px;
			margin-bottom: 0;
			letter-spacing: -0.7px;
			min-height: 60px;
		}

		a {
			color: $gray-base;
			&:hover {
				text-decoration: none;
				color: #2a3768;
			}
		}
	}

	p {
		margin-bottom: 13px;
	}

	.detail {
		display: block;
		font-weight: 700;
		font-size: 12px;
		line-height: 1.2;
	}

	.slide-box {
		overflow: hidden;
		height: 0;
		transition: height .3s;

		p {
			margin-top: 12px;
		}
	}

	.icon-lock-circle {
		position: absolute;
		top: -16px;
		left: 50%;
		transform: translateX(-50%);
		color: $brand-warning;

		&:after {
			z-index: 0;
			content: '';
			position: absolute;
			top: 2px;
			left: 2px;
			right: 2px;
			bottom: 2px;
			background: $white;
			border-radius: 50%;
		}

		&:before {
			position: relative;
			z-index: 2;
		}
	}

	.text-wrapp {
		padding: 18px 10px 6px;

		.sub-title {
			display: block;
			font-size: 11px;
			line-height: 1.1;
			font-weight: 500;
			padding: 7px 0 14px;
			@include media ('>=tablet') {
				font-size: 12px;
				padding: 7px 0 17px;
			}
		}
	}

	.price {
		font: 700 22px/1 $font-family-serif;
		display: block;
		text-transform: uppercase;
		padding-bottom: 8px;
		white-space: nowrap;
		@include media ('>=tablet') {
			font-size: 24px;
			padding-bottom: 11px;
		}

		.sup {
			font-size: 12px;
			line-height: 1;
			padding-top: 0px;
			display: inline-block;
			vertical-align: top;

			@include media ('>=tablet') {
				font-size: 14px;
				padding-top: 2px;
			}
		}
	}

	.note-box {
		display: block;
		padding: 0 0 12px;
		@include media ('>=tablet') {
			padding: 0 0 15px;
		}
	}
	.rating {
		width: 60px;
		display: inline-block;
		vertical-align: middle;
		padding: 5px 1px;
		img {
			display: block;
			width: 100%;
		}
	}
	.note {
		padding: 5px 4px 4px;
		display: inline-block;
		vertical-align: middle;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 10px;
		color: $gray-light;
	}

	.link-holder {
		padding: 14px 0 0;
	}

	.like {
		display: inline-block;
		vertical-align: top;
		color: $gray-base;
		position: relative;
		padding-left: 17px;
		font-size: 11px;

		@include media ('>=tablet') {
			font-size: 12px;
			padding-left: 19px;
		}
		
		i {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-52%);
			color: $brand-primary;

			&:before {
				font-size: 9px;
				@include media ('>=tablet') {
					font-size: 10px;
				}
			}
		}
	}
}

.partner-section {
	border-top: 1px solid lighten($gray-base, 91.37%);
	border-bottom: 1px solid lighten($gray-base, 91.37%);
	margin-bottom: 30px;
	padding: 18px 15px 0;

	@include media ('>=tablet') {
		margin-bottom: 40px;
		padding: 22px 0 0;
	}

	.heading-title {
		margin-bottom: 12px;
		@include media ('>=tablet') {
			text-align: center;
			margin-bottom: 31px;
		}
	}
}

.item-box,
.partner-box {
	.img-box {
		height: 95px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;

		@include media ('>=tablet') {
			height: 120px;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
			max-height: 120px;
			width: auto;
		}
	}

	.category-title {
		font-size: 10px;
		line-height: 1;
		letter-spacing: 1.2px;
		@include text-overflow;
		@include media ('>=tablet') {
			font-size: 12px;
		}
	}

	h3 {
		font-size: 22px;
		line-height: 1.1;
		margin-top: 10px;
		letter-spacing: -0.1px;
		font-weight: 700;

		@include media ('>=tablet') {
			font-size: 25px;
		}

		a {
			color: $gray-base;
			&:hover {
				text-decoration: none;
				color: #2a3768;
			}
		}
	}
}

.item-box {
	padding-bottom: 5px;
	@include media ('>=tablet') {
		padding-bottom: 0;
	}

	.img-box {
		height: auto !important;
		display: block;
		margin-bottom: 16px;

		@include media ('>=tablet') {
			margin-bottom: 18px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			max-height: none;
		}
	}

	h3 {
		font-size: 27px;
		margin-top: 6px;
		margin-bottom: 10px;
		letter-spacing: -0.7px;
		@include media ('>=tablet') {
			font-size: 30px;
			margin-top: 5px;
		}
	}

	.sub-text {
		text-transform: uppercase;
		color: #bfbfbf;
		font-size: 9px;
		line-height: 1;
		display: block;
		letter-spacing: 1px;
		@include media ('>=tablet') {
			font-size: 10px;
		}
	}
}

.holder-section {
	padding: 18px 0 0;
	margin-bottom: 24px;
	border-top: 1px solid lighten($gray-base, 91.37%);

	@include media ('>=tablet') {
		padding: 43px 0 0;
		margin-bottom: 30px;
	}

	@include media ('>=desktop') {
		padding: 32px 0 0;
	}

	.gallery-wrapp {
		margin: 0;
	}
}

.heading-title {
	font: 700 22px/1.14 $font-family-sans-serif;
	letter-spacing: -0.7px;
	margin: 0 0 20px;
	text-align: center;

	@include media ('>=tablet') {
		letter-spacing: -0.6px;
		font-size: 24px;
		line-height: 1.1;
		text-align: left;
		margin: 0 0 24px;
	}

	mark {
		background: none;
		font-weight: 300;
		padding: 0;
	}
}

.info-section {
	border-bottom: 1px solid $gray-lighter;
	border-top: 1px solid $gray-lighter;
	padding: 12px 20px 20px;
	margin: 0 0 24px;
	overflow: hidden;
	text-align: center;

	@include media ('>=tablet') {
		padding: 21px 0 30px;
		margin: 0 0 30px;
		text-align: left;
	}

	@include media ('>=desktop') {
		text-align: left;
		padding: 21px 0 30px;
		margin: 0 0 40px;
	}

	.heading-title {
		position: relative;
		z-index: 10;
		margin: 0 0 12px;
		padding: 0 25px;
		line-height: 1.1;
		@include media ('>=tablet') {
			padding-right: 60px;
			margin-bottom: -22px;
			padding-left: 0;
		}
	}
}

.heading-box {
	@extend %clearfix;
	padding: 6px 0 15px;
	@include media ('>=tablet') {
		padding: 11px 0 22px;
	}

	.heading-title {
		margin: 0;
		@include media ('>=tablet') {
			float: left;
		}
	}

	.btn-link {
		margin-top: 3px;
		&.pull-right {
			@include media ('<tablet') {
				float: none !important;
				display: inline-block;
				vertical-align: top;
			}
		}
	}
}

.popup-holder {
	position: relative;
	overflow: hidden;
	height: 0;
}

div.lightbox {
	padding: 43px 13px 13px;
	width: 420px;
	background: $white;
	position: relative;
	max-width: 100%;
	margin-bottom: 20px;

	@include media ('>=tablet') {
		max-width: none;
		width: 708px;
		padding: 48px 40px 40px;
	}

	@include media ('>=desktop') {
		width: 738px;
	}

	&.bg-info,
	&.bg-primary,
	&.bg-danger,
	&.bg-success,
	&.bg-warning {
		background: $brand-warning;

		.close {
			color: $white;
			&:hover {
				color: $gray-base;
			}
			&:active {
				color: $white;
			}
		}

		.form-control {
			border-color: $white;
		}
	}
	&.bg-success {
		background: $brand-success;
	}
	&.bg-danger {
		background: $brand-danger;
	}
	&.bg-primary {
		background: $brand-primary;
	}
	&.bg-info {
		background: $brand-info;
	}

	&.fancy-lg {
		@include media ('>=desktop') {
			width: 907px;
		}
	}

	&.fancy-sm {
		padding: 47px 13px 13px;
		@include media ('>=tablet') {
			padding: 48px 40px 40px;
		}
		@include media ('>=desktop') {
			width: 708px;
		}
	}

	.close {
		color: $gray-light;
		text-decoration: none;
		position: absolute;
		top: 17px;
		right: 18px;
		width: 14px;
		height: 14px;
		cursor: pointer;
		z-index: 8040;

		@include media ('>=tablet') {
			width: 16px;
			height: 16px;
			top: 20px;
			right: 20px;
		}

		i {
			display: inline-block;
			vertical-align: top;

			&:before {
				font-size: 14px;

				@include media ('>=tablet') {
					font-size: 16px;
				}
			}
		}

		&:hover {
			text-decoration: none;
			color: $brand-warning;
		}
		&:active {
			color: $gray-light;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		margin-top: 0;
		mark {
			font-weight: 400;
		}
	}

	h3 {
		font-size: 30px;
		line-height: 29px;
		margin-bottom: 16px;
		letter-spacing: -0.5px;
		margin-left: -6px;
		margin-right: -6px;

		@include media ('>=tablet') {
			font-size: 44px;
			line-height: 1;
			letter-spacing: 0;
			margin-bottom: 12px;
			margin-left: 0;
			margin-right: 0;
		}
	}

	h4 {
		font-size: 27px;
		line-height: 1;
		font-weight: 700;
		margin-bottom: 17px;

		@include media ('>=tablet') {
			font-size: 30px;
			margin-bottom: 15px;
		}
	}

	.fancy-body {
		font-size: 14px;
		line-height: 1.571;
		@include media ('>=tablet') {
			font-size: 16px;
			line-height: 1.5;
			padding: 0 40px 21px;
		}

		h3 {
			margin-bottom: 4px;
			@include media ('>=tablet') {
				margin-bottom: 13px;
			}
		}

		p {
			margin-bottom: 7px;
			@include media ('>=tablet') {
				margin-bottom: 24px;
			}
		}

		.btn-holder {
			padding-top: 8px;
			padding-bottom: 41px;

			@include media ('>=tablet') {
				padding-top: 20px;
				padding-bottom: 0;
			}
			.btn {
				display: block;
				@include media ('>=tablet') {
					padding: 14px 20px;
					display: inline-block;
				}
			}
		}
	}

	.col-holder {
		padding: 0 5px 13px;
		overflow: hidden;
		position: relative;

		@include media ('>=tablet') {
			padding: 42px 0 20px;
			margin: 0 -20px;
		}

		@include media ('>=desktop') {
			margin: 0 -40px;
		}
		
		&:before {
			content: '';
			background: lighten($gray-base, 91.37%);
			width: 1px;
			height: auto;
			position: absolute;
			left: 50%;
			top: 20px;
			bottom: 0;
			margin-left: -1px;
			display: none;
			@include media ('>=tablet') {
				display: block;
			}
		}

		.col {
			padding: 17px 0 0;
			@include media ('>=tablet') {
				float: left;
				width: 50%;
				padding: 0 0 10px 24px;
			}

			@include media ('>=desktop') {
				padding: 0 80px 10px 40px;
			}

			&:first-child {
				padding: 0 0 27px;
				border-bottom: 1px solid lighten($gray-base, 91.37%);
				@include media ('>=tablet') {
					padding: 0 30px 0 4px;
					border: none;
				}
				@include media ('>=desktop') {
					padding: 0 16px 0 10px;
				}

				h4 {
					@include media ('>=tablet', '<desktop') {
						max-width: 90%;
						margin: 0 auto 15px;
					}
				}

				p {
					margin: 0 auto 24px;
					@include media ('>=tablet') {
						
					}
					@include media ('>=desktop') {
						max-width: 70%;
					}
				}
			}

			&:last-child {
				h4 {
					margin-bottom: 13px;
					@include media ('>=desktop') {
						margin-bottom: 15px;
					}
				}
			}
		}
	}

	.form {
		@extend %clearfix;
		width: 100%;
		margin: 0 auto;

		.input-group {
			width: 100%;
			display: block;

			@include media ('>=tablet') {
				display: table;
			}

			.form-control {
				border-radius: 3px;
			}

			.btn {
				width: 100%;
				border-radius: 3px;
				@include media ('>=tablet') {
					min-width: 174px;
				}
			}

			.input-group-btn {
				width: 100%;
				float: left;
				padding-top: 9px;
				@include media ('>=tablet') {
					padding-left: 10px;
					padding-top: 0;
					float: none;
					width: 1%;
				}
			}
		}
	}

	.form-group {
		margin-bottom: 9px;
		@include media ('>=tablet') {
			margin-bottom: 10px;
		}
	}

	.form-control {
		font-size: 11px;
		line-height: 1;
		padding: 14px 16px;
		height: 44px;
		@include media ('>=tablet') {
			height: 48px;
			font-size: 12px;
			padding: 14px 18px;
		}
	}

	.link {
		display: inline-block;
		vertical-align: top;
		color: $gray-base;
		font-size: 14px;
		font-weight: 500;
		margin-top: 18px;
		text-decoration: underline;
		@include media ('>=tablet') {
			margin-top: 22px;
			letter-spacing: 0.7px;
		}
		&:hover {
			color: $brand-info;
		}
	}

	.btn {
		@include media ('>=tablet') {
			min-width: 174px;
		}
		&.btn-lg {
			padding: 13px 12px 12px;
			@include media ('>=tablet') {
				padding: 14px 12px;
			}
		}
		&.btn-small {
			min-width: 110px;
			@include media ('>=tablet') {
				min-width: 124px;
			}
		}
	}

	.reg-form {
		@include media ('>=tablet') {
			padding: 11px 19px 0 17px;
			text-align: left;
		}
		@include media ('>=desktop') {
			padding: 9px 0 0;
		}
	}

	.form-subscribe {
		margin: 0 auto;
		max-width: 468px;
		@include media ('>=tablet') {
			margin-bottom: 22px;
		}

		.input-group-btn {
			.btn {
				min-width: 124px;
			}
		}
	}

	.form-email {
		margin: 0 auto 3px;
		max-width: 518px;
		@include media ('>=tablet') {
			padding-top: 10px;
			margin-bottom: 0;
		}

		.link {
			margin-top: 11px;
			@include media ('>=tablet') {
				margin-top: 28px;
				margin-bottom: 2px;
			}
		}
	}

	.form-alert {
		margin: 0 auto 3px;
		max-width: 518px;
		text-align: left;
		@include media ('>=tablet') {
			margin-bottom: 25px;
		}

		&.validation-success {
			margin-bottom: 0;
			.hidden-box {
				display: none;
			}
		}

		.input-group {
			margin-bottom: 18px;
			@include media ('<tablet') {
				display: table;
				margin-bottom: 9px;
			}

			.form-control {
				@include media ('<tablet') {
					border-radius: 2px 0 0 2px;
					border-right: 0;
					height: 38px;
					font-size: 10px;
					padding: 10px 15px;
				}
			}

			.input-group-btn {
				@include media ('<tablet') {
					float: none;
					padding-left: 0;
					padding-top: 0;
					width: 1%;
				}
				.btn {
					@include media ('<tablet') {
						border-radius: 0 2px 2px 0;
						margin-left: 0;
						height: 38px;
						min-width: 88px;
						font-size: 10px;
						padding: 5px 12px;
					}
				}
			}
		}

		.success-message {
			padding-top: 8px;
			padding-bottom: 10px;
			margin-top: -5px;
			border-top: 1px solid lighten($gray-base, 91.37%);
			@include media ('>=tablet') {
				margin-top: 0;
				padding-top: 20px;
				padding-bottom: 0;
			}

			.email-title {
				font-weight: 900;
				display: block;
				letter-spacing: 0.2px;
			}

			p {
				margin-bottom: 13px;
				@include media ('>=tablet') {
					margin-bottom: 20px;
				}
			}

			.btn {
				@include media ('<tablet') {
					min-width: 140px;
					font-size: 10px;
					padding: 11px 12px;
				}
			}
		}
	}

	.card-box {
		font-size: 13px;
		line-height: 1.615;

		@include media ('>=tablet') {
			font-size: 14px;
			line-height: 1.714;
			padding: 10px 13px 0;
		}

		@include media ('>=desktop') {
			overflow: hidden;
			padding: 0 0 20px 14px;
		}

		h3 {
			padding: 0 5px;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 17px;
			@include media ('>=tablet') {
				line-height: 44px;
				padding: 0 70px;
			}
			@include media ('>=desktop') {
				padding: 0;
				margin-bottom: 25px;
			}
		}

		.img-box {
			padding: 0 21px 0 16px;
			@include media ('>=tablet') {
				padding: 15px 101px 22px 40px;
			}
			@include media ('>=desktop') {
				float: left;
				width: 57%;
				padding: 6px 4px 0 0;
			}
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		.sub-text {
			display: block;
			padding-right: 6px;
			@include media ('>=tablet') {
				padding-right: 0;
			}
		}

		.text-box {
			padding: 10px 0 0 0;
			overflow: hidden;
			font-weight: 500;

			@include media ('>=tablet') {
				padding: 8px 0 0 35px;
			}

			@include media ('>=desktop') {
				padding-top: 0;
			}

			ul {
				line-height: 1.67;
				margin-bottom: 11px;
				@include media ('>=tablet') {
					margin-bottom: 29px;
					line-height: 1.714;
					margin-bottom: 14px;
					float: left;
					width: 72%;
				}
				@include media ('>=desktop') {
					width: 100%;
					float: none;
					margin-bottom: 30px;
				}

				li {
					&:before {
						margin-right: 4px;
						@include media ('>=tablet') {
							margin-right: 6px;
						}
					}
				}
			}
		}

		.btn {
			display: block;

			@include media ('>=tablet') {
				display: inline-block;
				margin-top: 14px;
			}

			@include media ('>=desktop') {
				margin-top: 0;
			}
		}
	}

	.product-body {
		@include media ('>=tablet') {
			padding-top: 25px;
			padding-bottom: 37px;
			overflow: hidden;
		}
		h3 {
			margin-bottom: 9px;
			@include media ('>=tablet') {
				margin-bottom: 50px;
			}
		}
		.img-box {
			width: 78px;
			margin: 0 auto 20px;

			@include media ('>=tablet') {
				width: 144px;
				margin: 0;
				display: inline-block;
			}

			img {
				display: block;
				width: 100%;
			}
		}

		.product-item {
			@include media ('>=tablet') {
				display: flex;
				justify-content: center;
			}
		}

		.text-box {
			@include media ('>=tablet') {
				display: inline-block;
				text-align: left;
				padding-top: 13px;
				padding-left: 22px;
			}
		}

		h4 {
			font-size: 21px;
			line-height: 1;
			margin-bottom: 19px;

			@include media ('>=tablet') {
				font-size: 32px;
				line-height: 0.97;
				margin-bottom: 14px;
			}

			.price,
			mark {
				@include media ('>=tablet') {
					display: block;
				}
			}

			.price {
				@include media ('>=tablet') {
					font-size: 24px;
					line-height: 1.5;
				}
			}
		}

		.price {
			font-weight: 400;
		}

		.btn {
			display: block;
			@include media ('>=tablet') {
				display: inline-block;
			}
		}
	}

	.alert-body {
		font-size: 13px;
		padding: 0 0 7px;
		@include media ('>=tablet') {
			padding: 8px 0 14px;
			font-size: 16px;
		}

		h3 {
			font-size: 33px;
			margin-bottom: 11px;
			@include media ('>=tablet') {
				margin-bottom: 19px;
				font-size: 44px;
			}
		}

		p {
			margin-bottom: 20px;
			@include media ('>=tablet') {
				margin-bottom: 24px;
			}
		}

		.checkbox-inline {
			margin-bottom: 0;
		}
	}

	.label-list {
		font-size: 0;
		line-height: 0;
		letter-spacing: -4px;
		margin: 0 -7px 16px;
		@include media ('>=tablet') {
			margin: 0 0 10px;
		}
		li {
			display: inline-block;
			vertical-align: middle;
			padding: 0 5px 10px;
			@include media ('>=tablet') {
				padding: 0 10px 20px;
			}
		}
		.label {
			font-weight: 700;
			letter-spacing: 0.4px;
			font-size: 10px;
			line-height: 1;
			padding: 12px 14px;
			display: inline-block;
			vertical-align: middle;
			border-radius: $border-radius-small;
			@include media ('>=tablet') {
				font-size: 12px;
				padding: 16px 19px;
				letter-spacing: 0.4px;
			}
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		min-width: 0 !important;
	}

	.product-box {
		.btn {
			min-width: 0;
			letter-spacing: 1px;
			&.btn-sm {
				padding: 7px 15px;
				line-height: 11px;
				font-size: 10px;
				@include media ('>=tablet') {
					padding: 8px 17px 7px;
					font-size: 11px;
				}
			}
		}
	}

	.product-wrapper {
		margin-top: -5px;
		@include media ('>=tablet') {
			margin-top: 0;
			padding: 8px 7px 0;
		}

		.footer-box {
			text-align: left;
			@include media ('>=desktop') {
				margin-bottom: -32px;
			}
		}

		.gallery-wrapp {
			padding: 0;
			margin: 0 -13px 16px;
			@include media ('>=tablet') {
				margin: 0 -17px 30px;
				padding: 0 87px;
			}
			@include media ('>=desktop') {
				margin: 0 -30px 30px;
				padding: 0 45px;
			}
		}

		h3 {
			margin-bottom: 9px;
			@include media ('>=tablet') {
				margin-bottom: 33px;
			}
		}

		.cart-total {
			font-size: 14px;
			display: block;
			font-weight: 300;
			padding: 0 0 8px;

			@include media ('>=tablet') {
				font-size: 16px;
				padding: 0 0 15px;
			}

			span {
				text-transform: uppercase;
				font: 700 29px/1.1 $font-family-serif;
				padding-top: 2px;
				display: block;

				@include media ('>=tablet') {
					font-size: 45px;
					line-height: 1.1;
					padding-top: 0;
					display: inline-block;
					padding-left: 12px;
				}

				i {
					font-style: normal;
					font-size: 17px;
					line-height: 1;
					display: inline-block;
					vertical-align: top;
					@include media ('>=tablet') {
						font-size: 27px;
					}
				}
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			display: none;
			@include media ('>=tablet') {
				display: block;
				margin-top: -17px;
			}
		}

		.btn-box {
			@include media ('>=tablet') {
				padding-bottom: 30px;
			}
			.btn {
				display: block;
				@include media ('>=tablet') {
					display: inline-block;
					min-width: 184px;
				}
			}
		}
	}

	.footer-box {
		padding: 17px 0 0;
		border-top: 1px solid lighten($gray-base, 91.37%);
		@include media ('>=tablet') {
			padding: 20px 26px 0;
			margin: 0 -15px -13px;
		}

		@include media ('>=desktop') {
			padding: 20px;
			margin: 0 32px -20px;
		}

		.btn {
			min-width: 0;
		}

		.subscribe-box {
			display: flex;
			justify-content: space-around;
			align-items: center;
			.img-box {
				width: 113px;
				margin-right: 26px;
			}

			h4 {
				font-size: 24px;
				line-height: 1.06;
				letter-spacing: 0.7px;
				margin-bottom: 18px;
				width: 64%;

				@include media ('>=desktop') {
					margin-bottom: 15px;
				}

				mark {
					letter-spacing: 0;
					display: block;
					@include media ('>=desktop') {
						display: inline-block;
					}
				}

				.price {
					display: block;
					font-size: 18px;
					font-weight: 400;
					padding-top: 2px;
					letter-spacing: 0;
					@include media ('>=desktop') {
						padding-top: 3px;
					}
				}
			}

			.btn {
				@include media ('>=tablet') {
					width: 170px;
				}
				@include media ('>=desktop') {
					width: 150px;
				}
			}
		}

		.btn-block {
			.btn {
				display: block;
				& + .btn {
					margin-top: 10px;
					@include media ('>=tablet') {
						margin-top: 0;
					}
				}
				&.btn-default {
					background: none;
					color: $gray-base;
					border-color: lighten($gray-base, 91.37%);

					&:hover {
						background: lighten($gray-base, 91.37%);
					}
					&:focus,
					&.active:focus,
					&:active:focus,
					&.active,
					&:active {
						background: none;
					}
				}

				@include media ('>=tablet') {
					width: 274px;
					margin: 0 22px;
					display: inline-block;
				}
			}
		}
	}

	.product-detail {
		@include media ('>=tablet') {
			padding-top: 10px;
		}
		h3 {
			font-size: 26px;
			line-height: 24px;
			letter-spacing: -0.3px;
			margin-bottom: 21px;
			@include media ('>=tablet') {
				font-size: 32px;
				line-height: 1;
				letter-spacing: 0;
				margin-bottom: 34px;
			}
		}

		h4 {
			font-size: 18px;
			line-height: 1;
			letter-spacing: -0.4px;
			margin-bottom: 5px;
			@include media ('>=tablet') {
				font-size: 26px;
				letter-spacing: -0.6px;
				margin-bottom: 8px;
			}
		}

		.img-box {
			width: 88px;
			@include media ('>=tablet') {
				width: 126px;
				margin-right: 20px;
			}
		}

		.text-box {
			overflow: hidden;
			font-size: 9px;

			@include media ('>=tablet') {
				font-size: 12px;
			}

			p {
				margin: 0 0 4px;
				@include media ('>=tablet') {
					margin-bottom: 14px;
				}
			}
		}

		.holder {
			padding: 0 0 12px;
			margin-right: -5px;
			@include media ('>=tablet') {
				margin: 0;
				width: 100%;
				display: flex;
				justify-content: center;
				padding-bottom: 40px;
			}
		}

		.footer-box {
			@include media ('>=tablet') {
				margin: 0 -15px;
				padding: 20px 0 0;
			}
		}
	}
}

.support-box {
	display: flex;
	justify-content: center;

	@include media ('>=tablet') {
		justify-content: flex-start;
		padding-top: 2px;
	}
	@include media ('>=desktop') {
		padding-top: 3px;
	}
	img {
		width: 100%;
		height: auto;
	}

	.img-holder {
		float: left;
		width: 50%;
		padding: 1px 6px 0 1px;
		margin-right: 4px;
		max-width: 185px;

		@include media ('>=tablet') {
			width: 54%;
			max-width: 210px;
			padding-left: 3px;
			margin-right: 6px;
			padding-top: 0;
		}

		@include media ('>=desktop') {
			padding: 5px 0 0 2px;
			margin-right: 23px;
		}
	}

	.text-wrapp {
		overflow: hidden;
	}

	.available-list {
		margin: 0;
		line-height: 1;
		li {
			vertical-align: middle;
			padding: 0 7px 5px 0;
		}
		a {
			color: $brand-primary;

			&:hover {
				color: $link-color;
				text-decoration: none;
			}

			i {
				display: inline-block;

				&:before {
					font-size: 16px;

					@include media ('>=tablet') {
						font-size: 19px;
					}
				}
				&.icon-android {
					margin-top: 2px;
				}
				&.icon-amazon {
					margin-top: 4px;
					&:before {
						font-size: 18px;
						@include media ('>=tablet') {
							font-size: 20px;
						}
					}
				}
			}
		}
	}

	.sub-title {
		color: $brand-primary;
		font-size: 9px;
		display: block;
		letter-spacing: 0.3px;

		@include media ('>=tablet') {
			font-size: 10px;
		}
	}

	h4 {
		line-height: 1;
		font-size: 18px;
		margin: 0 0 9px;
		font-weight: 700;

		@include media ('>=tablet') {
			font-size: 20px;
			margin-bottom: 11px;
		}

		@include media ('>=desktop') {
			font-size: 26px;
			margin-bottom: 10px;
		}
		mark {
			display:block;
			font-weight:400;
		}
	}

	.btn {
		&.btn-default {
			font-size: 10px;
			padding: 12px 12px 10px;
			max-width: 146px;
			letter-spacing: 1.7px;
			border: 1px solid $gray-base;
			background: none;
			display: block;
			color: $gray-base;
			margin-bottom: 9px;
			transition: background .2s, color .2s, border-color .2s;

			@include media ('>=tablet') {
				max--width: 162px;
				padding: 13px 12px 11px;
				font-size: 12px;
				letter-spacing: 1.2px;
				margin-bottom: 10px;
			}

			&:hover {
				border-color: $gray-base;
				background: $gray-base;
				color: $white;
			}

			&:focus,
			&:active:focus,
			&:active {
				background: none;
				color: $gray-base;
				border-color: $gray-base;
			}
		}
	}
}

.support-box-sm {
	border-bottom: 1px solid lighten($gray-base, 91.37%);
	border-top: 1px solid lighten($gray-base, 91.37%);
	padding: 20px 10px 18px;
	text-align: center;
	max-width: 280px;
	margin: 0 auto 24px;

	@include media ('>=tablet') {
		margin: 0 0 24px;
	}

	@include media ('>=desktop') {
		text-align: left;
		max-width: 384px;
		padding: 20px 0 23px;
	}

	.img-holder {
		margin: 0 auto 3px;

		@include media ('>=desktop') {
			margin: 3px 10px 0 0;
			max-width: none;
			float: left;
			width: 44.6%;
			text-align: center;
		}

		.img-box {
			max-width: 134px;
			margin: 0 auto -4px;

			@include media ('>=desktop') {
				margin: 0 0 -10px;
				max-width: none;
			}

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}

	.text-wrapp {
		overflow: hidden;
	}

	h4 {
		line-height: 1.06;
		font-size: 24px;
		margin: 0 0 9px;
		font-weight: 700;
		letter-spacing: 0.8px;

		@include media ('>=desktop') {
			margin-bottom: 20px;
			line-height: 1.03;
		}

		mark {
			display: block;
			font-weight: 400;
			letter-spacing: 0;
		}
	}

	.price {
		display: block;
		font-size: 18px;
		font-weight: 400;
		letter-spacing: 0;
		padding: 4px 0 0;
	}

	.available-list {
		margin: 0;
		line-height: 1;
		li {
			vertical-align: middle;
			padding: 0 3px 5px;
		}
		a {
			color: $brand-primary;

			&:hover {
				color: $link-color;
				text-decoration: none;
			}

			i {
				display: inline-block;

				&:before {
					font-size: 18px;
				}
				&.icon-android {
					margin-top: 2px;
				}
				&.icon-amazon {
					margin-top: 4px;
					&:before {
						font-size: 18px;
					}
				}
			}
		}
	}

	.btn {
		@include media ('>=desktop') {
			width: 90%;
		}
	}
}

.product-detail {
	.img-box {
		width: 88px;
		margin-right: 13px;
		float: left;
		@include media ('>=tablet') {
			width: 126px;
			margin-right: 20px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	h4 {
		font-size: 18px;
		line-height: 1;
		letter-spacing: -0.4px;
		margin-bottom: 5px;

		@include media ('>=tablet') {
			font-size: 30px;
		}

		a {
			color: inherit;
			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.category-title {
		font-size: 8px;
		line-height: 1;
		letter-spacing: 1px;
		padding-bottom: 7px;
		@include media ('>=tablet') {
			font-size: 12px;
			padding-bottom: 10px;
		}
	}

	.text-box {
		overflow: hidden;
		font-size: 9px;
		font-weight: 500;

		@include media ('>=tablet') {
			font-size: 12px;
		}

		p {
			margin: 0 0 4px;
			@include media ('>=tablet') {
				margin-bottom: 14px;
			}
		}
	}

	.rating-box {
		padding-bottom: 3px;
		@include media ('>=tablet') {
			padding-bottom: 8px;
		}

		.rating-img {
			width: 63px;
			height: 11px;
			display: block;
			@include media ('>=tablet') {
				width: 91px;
				height: 16px;
			}
		}

		.note {
			display: block;
			color: $gray-light;
			padding-top: 4px;
			@include media ('>=tablet') {
				font-size: 10px;
				padding-top: 4px;
			}
		}
	}

	.price {
		font: 700 14px/1.1 $font-family-serif;
		display: block;
		padding-bottom: 2px;

		@include media ('>=tablet') {
			font-size: 20px;
			padding-bottom: 5px;
		}

		span {
			padding-right: 4px;
			@include media ('>=tablet') {
				padding-right: 10px;
			}
			i {
				font-size: 9px;
				font-style: normal;
				display: inline-block;
				vertical-align: top;

				@include media ('>=tablet') {
					font-size: 12px;
				}
			}
		}

		.old-price {
			font-size: 13px;
			display: inline-block;
			color: #bfbfbf;
			position: relative;

			&:before {
				content: '';
				width: 46%;
				border-top: 2px solid $gray-base;
				position: absolute;
				top: 6px;
				left: 43%;
				height: 2px;
				transform: translateX(-50%) rotate(-45deg);
				@include media ('>=tablet') {
					width: 46%;
					top: 9px;
				}
			}

			@include media ('>=tablet') {
				font-size: 17px;
			}

			i {
				font-size: 7px;
				@include media ('>=tablet') {
					font-size: 11px;
				}
			}
		}
	}
}

.product-box {
	@extend %clearfix;
	width: 100%;
	padding-top: 3px;

	&.product-active {
		.img-box {
			border-color: $brand-warning;
			border-width: 3px;
			border-radius: 3px;
			box-shadow: none;
			margin: -2px -2px 15px;

			@include media ('>=tablet') {
				margin: -2px -2px 17px;
			}
		}

		.btn {
			display: none;
		}

		.check {
			display: inline-block;
		}
	}

	.img-box {
		background: #fff;
		border: 1px solid lighten($gray-base, 74.9%);
		box-shadow: -4px 4px 0 rgba($gray-base, .1);
		transform: translate3d(0,0,0);
		margin-bottom: 17px;
		overflow: hidden;
		@include media ('>=tablet') {
			margin-bottom: 19px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			position: relative;
		}
	}

	.btn {
		letter-spacing: 1px;
		&.btn-sm {
			padding: 7px 15px;
			font-size: 10px;
			@include media ('>=tablet') {
				padding: 8px 17px 7px;
				font-size: 11px;
			}
		}
	}

	.check {
		display: none;
		vertical-align: top;
		color: $white;
		background: $brand-warning;
		border-radius: 50%;
		width: 26px;
		height: 26px;
		text-align: center;

		@include media ('>=tablet') {
			width: 28px;
			height: 28px;
		}

		i {
			display: inline-block;
			line-height: 21px;
			vertical-align: top;

			@include media ('>=tablet') {
				line-height: 25px;
			}

			&:before {
				font-size: 10px;
				display: inline-block;
				@include media ('>=tablet') {
					font-size: 12px;
					margin-left: 1px;
				}
			}
		}
	}

	.number {
		display: block;
		font-size: 11px;
		line-height: 1;
		font-weight: 700;
		padding-bottom: 6px;
		@include media ('>=tablet') {
			font-size: 12px;
			padding-bottom: 8px;
		}
	}
}

.card-block {
	border-bottom: 1px solid lighten($gray-base, 91.37%);
	border-top: 1px solid lighten($gray-base, 91.37%);
	padding: 15px 0;
	text-align: center;
	margin-bottom: 20px;
	@include media ('>=tablet') {
		padding: 30px 0;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	@include media ('>=desktop') {
		padding: 36px 30px 22px;
	}

	.img-box {
		padding: 0 27px 11px 21px;
		max-width: 300px;
		display: inline-block;
		vertical-align: top;

		@include media ('>=tablet') {
			padding: 17px 20px 0 5px;
			width: 53%;
			max-width: 400px;
		}

		@include media ('>=desktop') {
			width: 45%;
			max-width: none;
			padding: 2px 50px 0 3px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.text-box {
		@include media ('>=tablet') {
			text-align: left;
			padding: 0 0 0 2px;
			font-size: 14px;
			line-height: 1.714;
			font-weight: 500;
			width: 47%;
		}
		@include media ('>=desktop') {
			width: 48%;
			padding: 12px 3px 0 0;
		}

		.text-wrapp {
			@include media ('>=desktop') {
				float: left;
				width: 72%;
			}
		}
	}

	h3 {
		font-size: 22px;
		margin: 0 0 10px;
		line-height: 0.96;
		letter-spacing: 0.2px;

		@include media ('>=tablet') {
			font-size: 28px;
			line-height: 0.98;
			letter-spacing: -0.4px;
			margin: 0 0 12px;
		}

		@include media ('>=desktop') {
			font-size: 34px;
			margin-bottom: 6px;
		}

		mark {
			display: block;
			@include media ('>=tablet') {
				letter-spacing: 0.4px;
			}
		}

		.price {
			font-size: 16px;
			font-weight: 400;
			display: block;
			letter-spacing: 0;
			padding-top: 5px;
		}
	}

	.sub-text {
		display: none;
		@include media ('>=tablet') {
			display: block;
		}
	}

	ul {
		display: none;
		@include media ('>=tablet') {
			display: block;
		}
	}

	.btn {
		@include media ('>=desktop') {
			float: right;
			margin-top: 2px;
		}
		&.btn-lg {
			padding: 4px 9px 3px;
			@include media ('>=tablet') {
				padding: 14px 12px;
				min-width: 124px;
			}
		}
	}
}

.item-product-box {
	font-weight: 500;

	.img-box {
		margin:0 auto 12px;
		max-width: 187px;
		img {
			display: block;
			width: 100%;
			height: auto;
		}

		@include media ('>=tablet') {
			max-width: 206px;
			margin-bottom: 16px;
		}
	}

	.category-title {
		font-size: 11px;
		letter-spacing: 1px;
		padding-bottom: 8px;
		@include media ('>=tablet') {
			font-size: 12px;
		}
	}

	h4 {
		font-size: 26px;
		margin: 0 0 7px;
		letter-spacing: -1px;
		font-weight: 700;

		@include media ('>=tablet') {
			letter-spacing: 0.2px;
			margin-bottom: 9px;
		}

		a {
			color: inherit;

			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}

		span {
			display: block;
		}
	}

	.month {
		display: block;
		font-size: 11px;
		padding-bottom: 22px;

		@include media ('>=tablet') {
			font-size: 12px;
			padding-bottom: 25px;
		}
	}

	.btn {
		min-width: 110px;

		@include media ('>=tablet') {
			min-width: 122px;
		}
	}
}

.postcard-block {
	padding: 20px 0 30px;
	margin-bottom: 20px;
	font-weight: 500;
	border-top: 1px solid lighten($gray-base, 92.54%);
	border-bottom: 1px solid lighten($gray-base, 92.54%);

	@include media ('>=tablet') {
		margin-bottom: 30px;
		padding: 52px 0 60px;
	}

	h2 {
		font-size: 36px;
		margin: 0 0 18px;

		@include media ('>=tablet') {
			font-size: 56px;
			margin-bottom: 13px;
		}
	}

	h5 {
		margin: 0 0 23px;
		letter-spacing: -0.4px;

		@include media ('>=tablet') {
			font-size: 32px;
			letter-spacing: -0.5px;
			margin-bottom: 24px;
		}

		@include media ('>=desktop') {
			margin-bottom: 35px;
			letter-spacing: -0.9px;
		}

		br {
			@include media ('>=480px') {
				display: none;
			}
		}

		mark {
			letter-spacing: 0;
			font-weight: 400;
			@include media ('>=640px') {
				display: block;
			}

			@include media ('>=desktop') {
				display: inline;
				letter-spacing: -0.4px;
			}
		}
	}

	.sub-title {
		font-size: 13px;
		line-height: 1.2;
		font-weight: 500;
		letter-spacing: 0.6px;
		display: block;
		text-transform: uppercase;
		padding: 0 20px 28px;

		@include media ('>=tablet') {
			font-size: 15px;
			letter-spacing: 2.2px;
			padding-bottom: 44px;
		}
	}

	.btn.btn-lg {
		padding: 12px;

		@include media ('>=tablet') {
			padding: 14px 15px;
		}
	}
}

.postcard-list {
	white-space: nowrap;
	font-size: 0;
	line-height: 0;
	letter-spacing: -4px;
	overflow: hidden;
	padding: 42px 0 0;
	margin: 0 -12px 24px;
	padding: 95px 0 0;

	@include media ('>=tablet') {
		margin: 0 0 53px;
	}

	@include media ('>=desktop') {
		margin: 0 -26px 53px;
	}
	li {
		display: inline-block;
		vertical-align: top;
		width: 33.332%;
		max-width: 150px;

		@include media ('>=tablet') {
			max-width: 250px;

		}

		@include media ('>=desktop') {
			width: 20%;
		}

		&:nth-child(even) {
			background: #f00;
			margin: -42px -12px 0;
			position: relative;
			@include media ('>=tablet') {
				margin: -95px -30px 0;
			}
		}
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.subscribe-block {
	position: relative;
	text-align: center;
	padding: 14px 20px 12px;
	font-size: 14px;
	line-height: 1.2855;
	letter-spacing: 0.2px;
	font-weight: 500;
	margin-bottom: 30px;
	overflow: hidden;

	@include media ('>=tablet') {
		text-align: left;
		font-size: 16px;
		line-height: 1.25;
		padding: 28px 36px 21px;
	}

	@include media ('>=desktop') {
		padding-left: 115px;
		margin-bottom: 40px;
	}

	* {
		position: relative;
	}
	
	.decor-box {
		display: none;
		@include media ('>=tablet') {
			width: 770px;
			height: 1200px;
			border-radius: 50%;
			overflow: hidden;
			display: block;
			position: absolute;
			left: 100%;
			top: 100%;
			margin-top: -315px;
			transform: translateX(-48.5%);
			border: solid $white;
			border-width: 40px 3px;

			@include media ('>=desktop') {
				width: 995px;
				height: 950px;
				transform: translateX(-46.1%);
				margin-top: -346px;
				border-width: 40px 7px;
				border-radius: 48%;
			}
		}

		img {
			position: absolute;
			width: 650px;
			height: auto;
			top: -97px;
			left: 33px;

			@include media ('>=desktop') {
				left: -5px;
				top: -67px;
			}
		}
	}

	h3 {
		font-size: 28px;
		margin: 0 0 5px;
		letter-spacing: -0.5px;

		@include media ('>=tablet') {
			font-size: 48px;
			letter-spacing: 0;
			margin-bottom: 12px;
		}
	}

	p {
		margin: 0 0 14px;

		@include media ('>=tablet') {
			margin: 0 0 29px;
			max-width: 60%;
		}

		@include media ('>=desktop') {
			max-width: 40%;
		}
	}

	.link {
		color: inherit;
		font-size: 10px;
		line-height: 16px;
		display: inline-block;
		vertical-align: top;
		max-width: 68%;
		letter-spacing: 0.4px;
		@include media ('>=tablet') {
			font-size: 12px;
			letter-spacing: 0;
		}

		span {
			padding-left: 2px;
			display: inline-block;
		}
	}
}

.subscribe-block-md {
	position: relative;
	text-align: center;
	padding: 14px 20px 12px;
	font-size: 14px;
	line-height: 1.2855;
	letter-spacing: 0.2px;
	font-weight: 500;
	margin-bottom: 30px;
	overflow: hidden;

	@include media ('>=tablet') {
		text-align: left;
		font-size: 16px;
		line-height: 1.25;
		padding: 15px 19px 20px;
		letter-spacing: 0;
	}

	@include media ('>=desktop') {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 40px;
		padding: 23px 30px 10px 19px;
	}

	* {
		position: relative;
	}
	
	.decor-box {
		display: none;
		@include media ('>=tablet') {
			width: 540px;
			height: 450px;
			border-radius: 50%;
			overflow: hidden;
			display: block;
			position: absolute;
			left: 100%;
			top: 100%;
			margin-top: -155px;
			transform: translateX(-43.5%);
			border: solid $white;
			border-width: 15px 4px;

			@include media ('>=desktop') {
				transform: translateX(-61.5%);
				border-width: 17px 3px;
				border-radius: 55% 50%;
			}
		}

		img {
			position: absolute;
			width: 496px;
			height: auto;
			top: -97px;
			left: -28px;

			@include media ('>=desktop') {
				width: 494px;
				left: -21px;
				top: -98px;
			}
		}
	}

	h3 {
		font-size: 28px;
		margin: 0 0 5px;
		letter-spacing: -0.5px;

		@include media ('>=tablet') {
			font-size: 30px;
			letter-spacing: -0.1px;
			margin-bottom: 6px;
		}
	}

	p {
		margin: 0 0 14px;

		@include media ('>=tablet') {
			margin: 0 0 16px;
			max-width: 60%;
		}

		@include media ('>=desktop') {
			max-width: none;
		}
	}

	.link {
		color: inherit;
		font-size: 10px;
		line-height: 16px;
		display: inline-block;
		vertical-align: top;
		max-width: 68%;
		letter-spacing: 0.4px;
		@include media ('>=tablet') {
			display: none;
		}

		span {
			padding-left: 2px;
			display: inline-block;
		}
	}

	.holder-box {
		@include media ('>=desktop') {
			width: 45%;
			padding-right: 20px;
			display: inline-block;
		}
	}

	.form-subscribe-1 {
		@include media ('>=tablet') {
			margin-bottom: 0;
		}

		@include media ('>=desktop') {
			width: 55%;
			padding-left: 4px;
			margin-bottom: 12px;
		}

		.form-control {
			@include media ('>=tablet') {
				border: none;
				box-shadow: none;
				border-radius: 3px 0 0 3px !important;
			}
			@include media ('>=desktop') {
				height: 48px;
				font-size: 12px;
				padding: 14px 18px;
				letter-spacing: 0.3px;
				border-radius: 3px !important;
			}
		}

		.input-group-btn {
			@include media ('>=tablet') {
				padding-left: 0;
			}
			@include media ('>=desktop') {
				padding-left: 12px;
			}
		}

		.btn {
			&.btn-lg {
				@include media ('>=tablet') {
					padding: 10px 15px;
					border-radius: 0 3px 3px 0 !important;
				}
				@include media ('>=desktop') {
					height: 48px;
					padding: 14px 16px;
					border-radius: 2px !important;
					min-width: 123px;
				}
			}
		}
	}
}

.subscribe-block-sm {
	overflow: hidden;
	max-width: 280px;
	margin: 0 auto 24px;
	font-weight: 500;
	position: relative;
	padding: 23px 20px 20px;
	line-height: 1.25;

	@include media ('>=tablet') {
		margin: 0 0 24px;
	}

	@include media ('>=desktop') {
		max-width: 384px;
		background: none;
		padding: 16px 30px 52px;
	}

	&.bg-warning {
		.decor {
			background: $brand-warning;
		}
	}

	&.bg-primary {
		.decor {
			background: $brand-primary;
		}
	}

	* {
		position: relative;
		z-index: 5;
	}

	p {
		margin: 0 0 16px;
		@include media ('>=desktop') {
			margin: 0 0 14px;
		}
	}

	h4 {
		font-size: 24px;
		margin: 0 0 9px;

		@include media ('>=desktop') {
			font-size: 32px;
			margin: 0 0 4px;
		}
	}

	.decor {
		width: 651px;
		height: 400px;
		border: solid $white;
		border-width: 5px 69px 3px 3px;
		border-radius: 50%;
		position: absolute;
		bottom: 67px;
		left: 46%;
		transform: translateX(-50%);
		z-index: 2 !important;
		display: none;

		@include media ('>=desktop') {
			display: block;
		}
	}

	img {
		bottom: -152px;
		height: auto;
		left: -64px;
		position: absolute !important;
		width: 128%;
		z-index: 0 !important;
		display: none;

		@include media ('>=desktop') {
			display: block;
		}
	}
}

.product-banner {
	margin-bottom: 30px;
	padding: 18px 20px 15px;
	font-weight: 500;
	@include media ('>=tablet') {
		margin-bottom: 40px;
		padding: 10px 20px 16px;
	}

	@include media ('>=desktop') {
		padding: 10px 15px 11px 20px;
	}

	.holder {
		@include media ('>=desktop') {
			overflow: hidden;
		}
	}

	.img-box {
		float: left;
		width: 69px;
		margin: 0 12px 0 9px;
		@include media ('>=tablet') {
			width: 78px;
			margin: 0 9px 0 -4px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	h3 {
		font-size: 28px;
		margin: 4px 0 10px;
		letter-spacing: -0.5px;

		@include media ('>=tablet') {
			font-size: 32px;
			margin: 6px 0 4px;
		}

		mark {
			letter-spacing: 0.5px;
			font-weight: 400;
			@include media ('>=tablet') {
				letter-spacing: 0;
				padding-right: 5px;
			}
		}

		a {
			color: inherit;
			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.text-wrapp {
		font-size: 14px;
		line-height: 1.15;
		text-align: center;
		padding: 12px 15px 3px;
		width: 100%;
		clear: both;

		@include media ('>=tablet') {
			padding: 7px 0 0;
			font-size: 16px;
			line-height: 1.5;
			text-align: left;
		}

		@include media ('>=desktop') {
			clear: none;
			padding: 0 15px 0 0;
			width: 57%;
			display: inline-block;
			vertical-align: top;
		}

		p {
			margin-bottom: 10px;
			@include media ('>=tablet') {
				margin-bottom: 8px;
			}
		}

		.link {
			font-size: 9px;
			color: $brand-primary;
			font-weight: 700;
			display: inline-block;
			vertical-align: top;
			text-decoration: underline;
			@include media ('>=tablet') {
				font-size: 10px;
				letter-spacing: 0.1px;

			}

			&:hover {
				text-decoration: none;
			}
		}
	}

	.holder-box {
		text-align: center;
		@include media ('>=tablet') {
			text-align: left;
		}
		@include media ('>=desktop') {
			display: inline-block;
			vertical-align: top;
			width: 42%;
			padding-top: 8px;
		}
	}

	.price {
		font-size: 11px;
		display: inline-block;
		vertical-align: top;
		color: rgba($gray-base, .5);
		padding: 0 22px 0 3px;
		max-width: 50%;

		@include media ('>=tablet') {
			font-size: 12px;
			line-height: 1.1;
			padding: 0 27px 0 0;
		}

		@include media ('>=desktop') {
			max-width: none;
		}

		strong {
			display: block;
			color: $brand-primary;
			font: 700 30px/0.9 $font-family-serif;
			letter-spacing: 0.5px;
			@include media ('>=tablet') {
				font-size: 34px;
				font-weight: 900;
				padding: 2px 0 0 0;
				letter-spacing: 0;
			}
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-style: normal;
			font-size: 20px;

			@include media ('>=tablet') {
				font-size: 19px;
				padding-top: 2px;
			}
		}
	}

	.btn {
		margin-top: 4px;
	}
}

.product-banner-md {
	padding: 13px 0 6px;
	font-weight: 500;
	max-width: 280px;
	margin: 0 auto 30px;
	border-bottom: 1px solid lighten($gray-base, 91.37%);
	border-top: 1px solid lighten($gray-base, 91.37%);
	@include media ('>=tablet') {
		margin-bottom: 40px;
		padding: 13px 14px 7px 0;
	}

	@include media ('>=desktop') {
		padding: 14px 0 11px;
		max-width: 392px;
	}

	.holder {
		@include media ('>=desktop') {
			overflow: hidden;
		}
	}

	.img-box {
		float: left;
		width: 90px;
		margin: 0 10px 0 0;
		@include media ('>=tablet') {
			width: 93px;
			margin: 0 6px 0 -2px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	h3 {
		font-size: 28px;
		margin: 2px 0 10px;
		letter-spacing: -0.5px;

		@include media ('>=tablet') {
			font-size: 32px;
			margin: 2px 0 4px;
		}

		@include media ('>=desktop') {
			margin: 1px 0 -4px;
		}

		mark {
			letter-spacing: 0.5px;
			font-weight: 400;
			@include media ('>=tablet') {
				letter-spacing: 0;
				padding-right: 5px;
			}
			@include media ('>=desktop') {
				padding: 0;
			}
		}

		a {
			color: inherit;
			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.text-wrapp {
		font-size: 14px;
		line-height: 1.15;
		padding: 8px 0 0;
		width: 100%;
		clear: both;

		@include media ('>=tablet') {
			padding: 8px 0 0;
			font-size: 16px;
			line-height: 1.3;
			text-align: left;
		}

		@include media ('>=desktop') {
			padding: 8px 24px 0 0;
		}

		p {
			margin-bottom: 10px;
			@include media ('>=tablet') {
				margin-bottom: 5px;
			}
			@include media ('>=desktop') {
				margin-bottom: 6px;
			}
		}
	}

	.holder-box {
		padding: 0 0 5px;
	}

	.link {
		font-size: 9px;
		color: $brand-primary;
		font-weight: 700;
		display: inline-block;
		vertical-align: top;
		text-decoration: underline;
		@include media ('>=tablet') {
			font-size: 10px;
			letter-spacing: 0.1px;

		}

		&:hover {
			text-decoration: none;
		}
	}

	.price {
		font-size: 11px;
		display: inline-block;
		vertical-align: top;
		color: rgba($gray-base, .5);
		padding: 0 22px 0 0;
		max-width: 50%;

		@include media ('>=tablet') {
			font-size: 12px;
			line-height: 1.1;
			padding: 0 11px 0 0;
		}

		@include media ('>=desktop') {
			padding: 0 14px 0 0;
		}

		strong {
			display: block;
			color: $brand-primary;
			font: 700 30px/0.9 $font-family-serif;
			letter-spacing: 0.5px;
			@include media ('>=tablet') {
				font-size: 34px;
				font-weight: 900;
				padding: 2px 0 0 0;
				letter-spacing: 0;
			}
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-style: normal;
			font-size: 20px;

			@include media ('>=tablet') {
				font-size: 19px;
				padding-top: 2px;
			}
		}
	}

	.btn {
		margin-top: 4px;
	}
}

.product-banner-sm {
	padding: 14px 18px 11px;
	font-weight: 500;
	max-width: 280px;
	margin: 0 auto 30px;
	font-size: 14px;
	line-height: 1.4285;

	@include media ('>=tablet') {
		margin-bottom: 40px;
	}

	@include media ('>=desktop') {
		max-width: 392px;
		padding: 13px 18px 2px;
	}

	h3 {
		font-size: 25px;
		margin: 0 -2px 8px;

		@include media ('>=desktop') {
			margin: 1px 0 5px;
		}

		mark {
			letter-spacing: 0.5px;
			font-weight: 400;
			padding-right: 4px;
		}

		a {
			color: inherit;
			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	p {
		margin-bottom: 11px;
		max-width: 90%;
		@include media ('>=desktop') {
			margin-bottom: 2px;
			max-width: none;
		}
	}

	.holder-box {
		padding: 0 0 6px;
	}

	.link {
		font-size: 10px;
		color: $brand-primary;
		font-weight: 700;
		display: inline-block;
		vertical-align: top;
		text-decoration: underline;
		letter-spacing: 0.2px;

		&:hover {
			text-decoration: none;
		}
	}

	.price {
		font-size: 12px;
		line-height: 1.1;
		display: inline-block;
		vertical-align: top;
		color: rgba($gray-base, .5);
		padding: 2px 10px 0 0;
		max-width: 50%;

		@include media ('>=desktop') {
			padding: 2px 9px 0 0;
		}

		strong {
			display: block;
			color: $brand-primary;
			font: 700 27px/0.9 $font-family-serif;
			padding: 3px 0 0;
			letter-spacing: 0.7px;
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-style: normal;
			font-size: 18px;
			padding-top: 2px;
		}
	}

	.btn {
		font-size: 10px;
		padding: 13px 12px;
		margin-top: 4px;
	}
}

.partner-banner {
	padding: 21px 15px 30px;
	max-width: 280px;
	margin: 0 auto 24px;
	text-align: center;

	@include media ('>=tablet') {
		padding: 21px 30px 20px;
		margin: 0 0 24px;
	}

	@include media ('>=desktop') {
		text-align: left;
		padding: 21px 20px 30px 30px;
		max-width: 384px;
	}

	h4 {
		font-weight: 400;
		font-size: 22px;
		margin: 0 0 15px;

		@include media ('>=desktop') {
			margin: 0 0 13px;
		}
	}

	h5 {
		font-size: 25px;
		margin: 1px 0 6px;
		letter-spacing: 0.5px;
	}

	.img-box {
		width: 100px;
		margin: 0 auto 12px;

		@include media ('>=desktop') {
			float: left;
			width: 31.2%;
			margin: 0 20px 10px 0;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.text-box {
		overflow: hidden;
	}

	.btn {
		min-width: 144px;
	}
}

.postcard-box-sm {
	max-width: 280px;
	margin: 0 auto 24px;
	padding: 0 0 30px;
	border-bottom: 1px solid lighten($gray-base, 91.37%);

	@include media ('>=tablet') {
		margin: 0 0 24px;
	}

	@include media ('>=desktop') {
		max-width: 384px;
		padding: 0 0 25px;
	}

	h4 {
		font-size: 28px;
		margin: 0 0 14px;
		@include media ('>=desktop') {
			margin: 0 0 11px;
		}
	}

	h5 {
		margin: 0;
		font-size: 18px;
		letter-spacing: -0.2px;
		max-width: 65%;

		@include media ('>=desktop') {
			font-size: 22px;
		}

		@include media ('>=desktop') {
			max-width: none;
		}

		mark {
			letter-spacing: 0.3px;
			display: block;

			@include media ('>=desktop') {
				display: inline;
			}
		}
	}

	.img-list {
		overflow: hidden;
		margin: 0 -4px 17px;
		font-size: 0;
		line-height: 0;
		letter-spacing: -4px;
		@include media ('>=desktop') {
			margin: 0 -5px 16px;
		}

		li {
			display: inline-block;
			vertical-align: top;
			padding: 4px;
			width: 50%;

			@include media ('>=desktop') {
				padding: 5px;
			}

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}

	.holder {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0 0 0 4px;

		@include media ('>=desktop') {
			padding: 0 0 0 20px;
		}
	}

	.img-holder {
		position: relative;
		margin-right: 13px;
		@include media ('>=desktop') {
			margin-right: 20px;
		}
	}

	.img-box {
		width: 68px;
		height: 68px;
		overflow: hidden;
		border: 2px solid $white;
		background: $brand-warning;
		box-shadow:0 0 3px rgba($gray-base, .1);
		position: relative;
		z-index: 2;

		&.item {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			transform: rotate(-10deg);
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

.contact-banner {
	overflow: hidden;
	position: relative;
	padding: 20px 15px 18px;
	margin-bottom: 30px;
	text-align: center;
	font-size: 14px;
	line-height: 1.25;
	font-weight: 500;
	@include media ('>=tablet') {
		margin-bottom: 40px;
		text-align: left;
		font-size: 16px;
		padding: 21px 18px 19px;
	}
	@include media ('>=desktop') {
		padding: 21px 300px 14px 39px;
	}

	* {
		position: relative;
	}

	h3 {
		font-size: 27px;
		margin: 0 0 4px;

		@include media ('>=tablet') {
			font-size: 30px;
		}

		mark {
			font-size: 20px;
			display: block;
			margin-bottom: -2px;
			@include media ('>=tablet') {
				font-size: 22px;
				letter-spacing: 0.2px;
			}
		}
	}

	p {
		margin: 0 0 14px;
		@include media ('>=tablet') {
			margin: 0 0 16px;
		}
	}

	.decor-box {
		display: none;
		@include media ('>=tablet') {
			width: 540px;
			height: 450px;
			border-radius: 50%;
			overflow: hidden;
			display: block;
			position: absolute;
			left: 100%;
			top: 100%;
			margin-top: -158px;
			transform: translateX(-46.9%);
			border: solid $white;
			border-width: 21px 9px;

			@include media ('>=desktop') {
				transform: translateX(-57.2%);
				border-width: 23px 16px;
				border-radius: 51% 50%;
				margin-top: -153px;
			}
		}

		img {
			position: absolute;
			width: 380px;
			height: auto;
			top: -84px;
			left: -7px;

			@include media ('>=desktop') {
				width: 380px;
				left: -14px;
				top: -84px;
			}
		}
	}

	.wrapp-box {
		@include media ('>=desktop') {
			display: inline-block;
			vertical-align: middle;
			width: 54.4%;
		}
	}

	.btn {
		min-width: 164px;
		@include media ('>=tablet') {
			min-width: 184px;
			padding: 13px;
		}
		@include media ('>=desktop') {
			margin-bottom: 7px;
		}
	}
}

.subscription-banner {
	position: relative;
	overflow: hidden;
	padding: 15px;
	margin-bottom: 30px;
	text-align: center;
	font-size: 14px;
	line-height: 1.5714;
	font-weight: 500;
	@include media ('>=tablet') {
		margin-bottom: 40px;
		font-size: 16px;
		line-height: 1.5;
		padding: 16px 16px 20px;
	}

	@include media ('>=desktop') {
		padding: 23px 30px 20px 18px;
		text-align: left;
	}

	h3 {
		font-size: 28px;
		line-height: 0.98;
		margin: 0 0 3px;
		letter-spacing: -0.5px;
		@include media ('>=tablet') {
			font-size: 30px;
			line-height: 1;
			letter-spacing: 0;
			margin-bottom: 5px;
		}
		@include media ('>=desktop') {
			margin: 0 0 4px;
		}
		mark {
			font-weight: 400;
			letter-spacing: -0.3px;
			display: block;
			@include media ('>=tablet') {
				letter-spacing: 0;
			}
			@include media ('>=desktop') {
				display: inline;
			}
		}
	}

	p {
		margin-bottom: 9px;
		@include media ('>=tablet') {
			margin-bottom: 14px;
		}
		@include media ('>=desktop') {
			margin: 0;
			max-width: 92%;
		}
	}

	.btn {
		&.btn-lg {
			padding: 12px 18px;
			@include media ('>=tablet') {
				padding: 14px 22px;
			}
			@include media ('>=desktop') {
				white-space: nowrap;
			}
		}
	}

	.wrapp-box {
		@include media ('>=desktop') {
			width: 64%;
		}
	}

	.holder-box {
		width: 100%;
		position: relative;
		@include media ('>=desktop') {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.decor-box {
		background: $white;
		display: none;
		@include media ('>=desktop') {
			width: 600px;
			height: 506px;
			border-radius: 50%;
			overflow: hidden;
			display: inline-block;
			position: absolute;
			left: 100%;
			top: 100%;
			margin-top: -160px;
			transform: translateX(-56%);
			border: solid $white;
			border-width: 20px 3px;
		}

		&:before {

		}

		img {
			position: absolute;
			width: 311px;
			height: auto;
			top: 15px;
			left: 22px;
		}
	}
}

.publish-banner {
	overflow: hidden;
	position: relative;
	padding: 15px 15px 18px;
	margin-bottom: 30px;
	text-align: center;
	font-size: 14px;
	line-height: 1.25;
	font-weight: 500;
	@include media ('>=tablet') {
		margin-bottom: 40px;
		text-align: left;
		font-size: 16px;
		padding: 15px 18px 20px;
	}
	@include media ('>=desktop') {
		padding: 20px 40px 25px 46px;
	}

	* {
		position: relative;
	}

	.holder-box {
		@include media ('>=desktop') {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.text-wrapp {
		@include media ('>=desktop') {
			display: inline-block;
			padding-right: 36px;
			max-width: 52%;
		}
	}

	h3 {
		font-size: 27px;
		margin: 0 0 4px;

		@include media ('>=tablet') {
			font-size: 32px;
			letter-spacing: 0.3px;
			margin-bottom: 5px;
		}
		@include media ('>=desktop') {
			font-size: 30px;
			line-height: 1;
			margin: 0;
			letter-spacing: 0.1px;
		}

		mark {
			font-size: 26px;
			display: block;
			margin-bottom: -3px;
			@include media ('>=tablet') {
				font-size: 30px;
				letter-spacing: 0.7px;
				margin-bottom: 2px;
			}
			@include media ('>=desktop') {
				margin-bottom: 0;
			}
		}
	}

	p {
		margin: 0 0 14px;
		@include media ('>=tablet') {
			margin: 0 0 16px;
		}
		@include media ('>=desktop') {
			margin: 0;
		}
	}

	.icon {
		display: none;
		@include media ('>=desktop') {
			display: inline-block;
			margin: 2px 21px 0 0;
		}
	}

	.btn {
		padding: 17px 27px;
		@include media ('>=desktop') {
			margin-top: 5px;
		}
	}

	.decor-box {
		display: none;
		@include media ('>=tablet') {
			width: 540px;
			height: 450px;
			border-radius: 52% 50%;
			overflow: hidden;
			display: block;
			position: absolute;
			left: 100%;
			top: 100%;
			margin-top: -154px;
			transform: translateX(-43.9%);
			border: solid $white;
			border-width: 19px 3px;

			@include media ('>=desktop') {
				transform: translateX(-61.3%);
				border-width: 18px 4px;
				border-radius: 52% 50%;
				margin-top: -152px;
			}
		}

		img {
			position: absolute;
			width: 366px;
			height: auto;
			top: -49px;
			left: -85px;

			@include media ('>=desktop') {
				left: -123px;
				top: -69px;
				width: 494px;
			}
		}
	}
}

.contact-banner-sm,
.publish-banner-sm {
	line-height: 1.25;
	font-weight: 500;
	padding: 14px 20px 20px;
	overflow: hidden;
	margin: 0 auto 24px;
	max-width: 280px;
	position: relative;

	@include media ('>=tablet') {
		margin: 0 0 24px;
	}

	@include media ('>=desktop') {
		max-width: 384px;
		padding: 14px 20px 10px;
	}

	* {
		position: relative;
	}

	h4 {
		font-size: 30px;
		line-height: 25px;
		margin: 0 0 6px;

		mark {
			display: block;
			font-size: 22px;
			letter-spacing: 0.4px;
		}
	}

	p {
		max-width: 50%;
		margin: 0 0 15px;

		@include media ('>=desktop') {
			max-width: 40%;
			margin: 0 0 5px;
		}
	}

	.decor {
		display: none;
		@include media ('>=desktop') {
			background: $white;
			overflow: hidden;
			display: block;
			position: absolute;
			width: 258px;
			height: 400px;
			left: 100%;
			top: 100%;
			margin: -147px 0 0 -140px;
			border-radius: 50%;
			border: solid $white;
			border-width: 20px 4px 4px;
		}

		img {
			width: 274px;
			height: auto;
			position: absolute;
			left: -115px;
			top: -31px;
		}
	}

	.text-wrapp {
		@include media ('>=desktop') {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}
	}

	.btn {
		font-size: 12px;
		@include media ('>=desktop') {
			display: inline-block;
			width: auto;
			margin-top: 7px;
			min-width: 184px;
		}
	}
}

.contact-banner-sm {
	p {
		max-width: none;
		@include media ('>=desktop') {
			max-width: 40%;
		}
	}

	.decor {
		img {
			width: 204px;
			left: -2px;
			top: -2px;
		}
	}
}

.bio-block {
	@extend %clearfix;
	margin: 0 auto 24px;
	padding: 13px 19px 15px;
	font-size: 14px;
	line-height: 1.4285;
	max-width: 280px;

	@include media ('>=tablet') {
		margin: 0 0 24px;
	}

	.img-box {
		width: 64px;
		float: left;
		overflow: hidden;
		border-radius: 2px;
		margin: 4px 15px 11px 0;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.text-box {
		overflow: hidden;
	}

	h5 {
		margin: 0 0 1px;
		font-size: 28px;
	}

	p {
		margin: 0 0 10px;
	}

	.btn {
		clear: both;
		padding: 13px 10px 12px;
		letter-spacing: 2px;
	}
}

.process-box {
	border: 2px solid lighten($gray-base, 91.37%);
	padding: 27px 20px 30px;
	max-width: 280px;
	margin: 0 auto 24px;
	@include media ('>=tablet') {
		margin: 0 0 24px;
	}
	@include media ('>=desktop') {
		max-width: 384px;
		padding: 27px 32px 30px;
	}

	.step-title {
		display: block;
		text-transform: uppercase;
		color: lighten($gray-base, 74.9%);
		font-size: 14px;
		font-weight: 900;
		margin: 0 0 -2px;
	}

	h5 {
		@include text-overflow;
		font-weight: 700;
		margin: 0 0 11px;
		font-size: 22px;
		line-height: 1.3;
		letter-spacing: -0.6px;
	}

	.process-list {
		color: $gray-light;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.4285;
		margin: 0 0 15px;
		border-bottom: 1px solid lighten($gray-base, 91.37%);

		li {
			padding: 0 0 0 32px;
			position: relative;
			margin: 0 0 16px;

			&.available {
				color: $gray-base;
				&:before {
					color: $brand-success;
				}
			}

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				font-size: 18px;
				color: lighten($gray-base, 74.9%);
				content: "\e912";
				font-family: 'icomoon' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
			}
		}
	}

	.total-box {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
		padding: 0 0 12px;
		margin: -4px 0 20px;
		border-bottom: 1px solid lighten($gray-base, 91.37%);

		h5 {
			text-overflow: auto;
			overflow: visible;
			white-space: normal;
			margin: 0;
		}
	}

	.price {
		white-space: nowrap;
		display: inline-block;
		font: 700 22px/1.3 $font-family-serif;
		letter-spacing: -0.3px;

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 13px;
		}
	}

	.btn {
		font-size: 12px;
	}
}

.cart-box-1,
.cart-box {
	font-weight: 500;
	padding: 21px 10px 10px;
	font-size: 11px;
	max-width: 170px;
	margin: 0 auto 24px;

	@include media ('>=tablet') {
		margin: 0 0 24px;
		max-width: 280px;
		padding: 34px 20px 30px;
		font-size: 12px;
	}

	@include media ('>=desktop') {
		padding: 38px 42px 26px;
		max-width: 384px;
	}

	.old-price {
		font: 700 22px/1 $font-family-serif;
		display: inline-block;
		color: #737373;
		position: relative;
		margin-bottom: 3px;

		@include media ('>=tablet') {
			font-size: 24px;
			margin-bottom: 12px;
		}

		&:before {
			content: '';
			width: 50%;
			border-top: 2px solid $gray-base;
			position: absolute;
			top: 10px;
			left: 52%;
			height: 3px;
			transform: translateX(-50%) rotate(-45deg);

			@include media ('>=tablet') {
				top: 12px;
			}
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 13px;

			@include media ('>=tablet') {
				font-size: 14px;
			}
		}
	}

	.price {
		font: 700 32px/1.2 $font-family-serif;
		display: block;
		padding: 0 0 1px;

		@include media ('>=tablet') {
			font-size: 45px;
			line-height: 1;
			padding: 0 0 3px;
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 18px;
			padding-top: 2px;

			@include media ('>=tablet') {
				font-size: 26px;
				padding-top: 0;
			}
		}
	}

	.download {
		display: inline-block;
		vertical-align: top;
		position: relative;
		padding-left: 23px;
		color: $gray-base;
		font-size: 10px;
		margin: 5px 0 13px;
		letter-spacing: 0.1px;
		font-weight: 700;

		@include media ('>=tablet') {
			font-size: 12px;
			margin: 6px 0 20px;
			padding-left: 26px;
		}

		&:hover {
			text-decoration: none;
			color: $brand-warning;
		}

		i {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.cart-row {
		padding: 7px 0 0;
		border-top: 1px solid $gray-light;

		@include media ('>=tablet') {
			padding: 13px 0 0;
		}
	}

	p {
		margin-bottom: 10px;

		@include media ('>=tablet') {
			margin-bottom: 15px;
		}
	}

	.count-box {
		border-top: 1px solid $gray-light;
		padding: 8px 0 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 10px;
		line-height: 1.4;

		@include media ('>=tablet') {
			font-size: 14px;
			line-height: 1.2857;
			padding: 8px 0 11px;
		}

		.number {
			font: 700 32px/1 $font-family-serif;
			display: inline-block;
			padding: 0 5px 3px 0;

			@include media ('>=tablet') {
				font-size: 45px;
				padding: 0 7px 0 0;
			}
		}

		p {
			margin: 0;

			@include media ('>=desktop') {
				max-width: 50%;
			}
		}
	}

	.btn {
		&.alt-btn {
			margin-top: -3px;
			padding: 22px 5px;

			@include media ('>=tablet') {
				margin-top: 31px;
				padding: 25px 5px 24px;
			}
		}
	}

	.category-title {
		color: $gray-base;
		font-size: 11px;
		letter-spacing: 0.4px;
		padding: 0 0 6px;
		font-weight: 500;
		@include media ('>=tablet') {
			font-size: 12px;
			letter-spacing: 0.5px;
			padding: 0 0 5px;
		}
	}
}

.cart-box-1 {
	padding: 1px 10px;

	@include media ('>=tablet') {
		padding: 30px 20px 32px;
	}

	@include media ('>=desktop') {
		padding: 22px 42px 40px;
	}

	.cart-row {
		padding: 18px 0 11px;

		@include media ('>=tablet') {
			padding: 19px 0 11px;
		}

		&:first-child {
			border: none;
		}
	}

	.price {
		padding: 0 0 5px;
		@include media ('>=tablet') {
			padding: 0 0 11px;
		}
	}

	.download {
		margin: 4px 0 14px;
		@include media ('>=tablet') {
			margin: 0 0 15px;
		}
	}

	.btn {
		&.btn-lg {
			padding: 13px 1px 12px;
			@include media ('>=tablet') {
				padding: 15px 1px 13px;
			}
		}
		&.btn-warning {
			margin-bottom: 5px;
			@include media ('>=tablet') {
				margin-bottom: 7px;
			}
		}
		&.btn-primary {
			margin-bottom: 12px;
			@include media ('>=tablet') {
				margin-bottom: 13px;
			}
		}
	}
	p {
		margin: 0;
	}
}

.banner-box-sm {
	max-width: 280px;
	margin: 0 auto 24px;
	font-weight: 500;
	line-height: 1.25;

	&.alt-banner {

		h4 {
			margin-bottom: 6px;
		}
		p {
			max-width: 80%;
			margin: 0 auto 25px;
		}

		.btn {
			&.btn-lg {
				padding: 15px 10px 14px;
				@include media ('>=desktop') {
					padding: 14px 10px;
				}
			}
		}
	}

	@include media ('>=tablet') {
		margin: 0 0 24px;
	}

	@include media ('>=desktop') {
		padding: 42px;
		max-width: 384px;
	}

	.holder {
		position: relative;
		background: none;
		overflow: hidden;
		padding: 21px 15px 35px;

		&.bg-warning {
			.decor {
				background: $brand-warning;
			}
		}

		&.bg-primary {
			.decor {
				background: $brand-primary;
			}
		}

		* {
			position: relative;
			z-index: 5;
		}
	}

	.decor {
		width: 475px;
		height: 400px;
		border: solid $white;
		border-width: 5px 60px 3px 3px;
		border-radius: 50%;
		position: absolute;
		left: 49%;
		bottom: 56px;
		transform: translateX(-50%);
		z-index: 2 !important;

		@include media ('>=desktop') {
			border-width: 5px 40px 4px 4px;
			bottom: 58px;
			left: 45%;
			width: 505px;
			height: 421px;
		}
	}

	img {
		bottom: -5px;
		height: auto;
		left: -3px;
		position: absolute !important;
		width: 110%;
		z-index: 0 !important;

		@include media ('>=desktop') {
			left: -2px;
			width: 102%;
		}
	}

	h4 {
		font-size: 28px;
		margin: 0 0 5px;
		padding: 0 7px;

		@include media ('>=desktop') {
			line-height: 1;
			padding: 0 17px;
		}
	}

	p {
		margin: 0 0 18px;
	}

	.btn {
		letter-spacing: 1.8px;

		@include media ('>=desktop') {
			letter-spacing: 1.2px;
		}
		&.btn-lg {
			padding: 15px 14px 14px;

			@include media ('>=desktop') {
				padding: 14px;
			}
		}
	}
}

.category-title {
	display: block;
	color: lighten($gray-base, 74.9%);
	font-size: 11px;
	line-height: 1;
	letter-spacing: 1px;
	font-weight: 700;
	text-transform: uppercase;
	@include media ('>=tablet') {
		font-size: 12px;
	}
}

.item-post {
	@extend %clearfix;
	padding: 15px 0 11px;
	color: $gray-light;
	font-size: 13px;
	line-height: 1.23;
	font-weight: 500;
	border-bottom: 1px solid lighten($gray-base, 91.37%);

	&.alt-post {
		@include media ('>=desktop') {
			padding: 24px 0 20px;
		}
	}
	@include media ('>=tablet') {
		padding: 20px 0 16px;
		font-size: 14px;
		line-height: 1.2875;
	}
	@include media ('>=desktop') {
		padding: 30px 0 26px;
		display: flex;
	}

	.holder-box {
		@extend %clearfix;
		width: 100%;
	}

	.text-box {
		@include media ('>=desktop') {
			width: 57.8%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	.wrapp-box {
		overflow: hidden;
		width: 100%;
		@include media ('>=tablet') {
			padding-top: 3px;
		}
		@include media ('>=desktop') {
			padding-top: 0;
			margin-top: -3px;
		}
	}

	.img-box {
		margin-bottom: 14px;
		@include media ('>=tablet') {
			float: left;
			width: 200px;
			margin: 0 9px 13px 0;
		}

		@include media ('>=desktop') {
			width: 41.8%;
			float: none;
			display: inline-block;
			margin: 0 30px 4px 0;
		}
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.category-title {
		padding: 0 0 6px;
	}

	h3 {
		font-size: 27px;
		margin: 0 0 7px;
		font-weight: 700;
		max-height: 60px;
		letter-spacing: -0.7px;
		color: $gray-base;

		@include media ('>=tablet') {
			font-size: 30px;
		}
		@include media ('>=desktop') {
			margin: 0 0 6px;
		}

		a {
			color: inherit;

			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.info-tag {
		color: lighten($gray-base, 74.9%);
		font-size: 10px;
		padding-top: 4px;
		letter-spacing: 0.3px;
		overflow: hidden;
		margin: 0 0 5px;
		font-weight: 700;
		@include media ('>=tablet') {
			width: 100%;
			letter-spacing: 0.9px;
			margin: 0 0 10px;
		}

		@include media ('>=desktop') {
			padding-top: 0;
		}

		li {
			padding: 0 9px 0 0;
			position: relative;
			@include media ('>=tablet') {
				padding: 0 11px 0 0;
			}
			&:before {
				content: '';
				width: 3px;
				height: 3px;
				background: lighten($gray-base, 74.9%);
				border-radius: 50%;
				position: absolute;
				top: 42%;
				left: -8px;
				transform: translateY(-50%);
			}
		}
	}

	.members {
		display: block;
		font-size: 11px;
		line-height: 1.45;
		padding: 0 5px 0 0;
		font-weight: 900;
		@include media ('>=tablet') {
			font-size: 12px;
		}
		span {
			display: block;
			font-weight: 500;
			letter-spacing: 0;
		}
	}

	.price {
		display: block;
		color: $gray-base;
		font: 700 21px/1 $font-family-serif;
		padding: 2px 0 5px;
		@include media ('>=tablet') {
			font-size: 24px;
			padding: 3px 0 5px;
		}
		@include media ('>=desktop') {
			padding-top: 10px;
		}
		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 12px;
			@include media ('>=desktop') {
				font-size: 14px;
			}
		}
	}

	.price-item {
		float: right;
		width: 54%;
		color: $brand-primary;
		font: 700 26px/1 $font-family-serif;
		padding: 0 0 5px;
		text-align: right;
		@include media ('>=tablet') {
			font-size: 28px;
		}
		@include media ('>=desktop') {
			width: 100%;
			float: none;
			text-align: left;
		}
		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 18px;
			padding-top: 2px;
			@include media ('>=tablet') {
				font-size: 20px;
				padding-top: 0;
			}
		}
	}

	.buy-link {
		display: inline-block;
		vertical-align: top;
		color: $gray-base;
		font-size: 10px;
		line-height: 0.96;
		letter-spacing: 0.3px;
		font-weight: 700;
		@include media ('>=tablet') {
			letter-spacing: 0.9px;
			margin: 0 0 13px;
		}
	}

	.members-lock {
		padding: 12px 0 11px 38px;
		position: relative;
		color: $brand-warning;
		font-size: 10px;
		line-height: 1;
		display: block;
		margin: 8px 0 2px;
		font-weight: 900;
		width: 100%;
		overflow: hidden;
		@include media ('>=tablet') {
			margin: 14px 0 4px;
			letter-spacing: 0.6px;
			padding-left: 40px;
		}

		.icon {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);

			&:before {
				font-size: 28px;
				@include media ('>=tablet') {
					font-size: 32px;
				}
			}
		}
	}

	.details {
		float: left;
		width: 45%;
		@include media ('>=tablet') {
			margin-bottom: -2px;
		}
		@include media ('>=desktop') {
			float: none;
			width: 100%;
			margin-bottom: 21px;
		}
	}

	p {
		margin: 0 0 11px;
		@include media ('>=tablet') {
			margin: 0;
		}
		@include media ('>=desktop') {
			margin: 0 0 14px;
			max-width: 80%;
		}
	}
}

.details {
	overflow: hidden;
	margin: 0;
	font-size: 11px;
	color: $gray-light;
	@include media ('>=tablet') {
		letter-spacing: 0.5px;
	}
	@include media ('>=desktop') {
		margin-bottom: 20px;
	}
	dt {
		float: left;
		clear: both;
		max-width: 50%;
		padding: 0 2px 0 0;
		line-height: 1.66;
		max-width: 50%;
		font-weight: 500;
	}

	dd {
		max-width: 50%;
		float: left;
		font-weight: 700;
		line-height: 1.66;
	}
}

.product-post {
	@extend %clearfix;
	padding: 15px 0 12px;
	font-size: 13px;
	line-height: 1.23;
	font-weight: 500;
	border-bottom: 1px solid lighten($gray-base, 91.37%);

	&.alt-post {
		@include media ('>=desktop') {
			padding: 24px 0 19px;
		}
	}
	@include media ('>=tablet') {
		padding: 23px 0 20px;
		font-size: 14px;
		line-height: 1.2875;
	}
	@include media ('>=desktop') {
		padding: 30px 0 25px;
	}

	.product-block {
		margin: 0;
		max-width: none;
	}

	.img-box {
		float: left;
		width: 107px;
		text-align: center;
		margin: 0 10px 0 0;
		@include media ('>=tablet', '<desktop') {
			text-align: left;
			width: 178px;
			margin: 0 19px 0 0;
		}
		@include media ('>=desktop') {
			width: 40.3%;
			margin: 0 30px 4px 0;
		}

		img {
			max-width: 100%;
			height: auto;
			width: auto;
			display: inline-block;
			vertical-align: top;

			@include media ('>=tablet', '<desktop') {
				max-width: 120px;
			}
			@include media ('>=desktop') {
				max-width: 100%;
			}
		}
	}

	h3 {
		margin-bottom: 13px;
		min-height: 0;
		max-height: 56px;
		@include media ('>=tablet') {
			margin-bottom: 15px;
		}
		@include media ('>=desktop') {
			margin-bottom: 13px;
		}
	}

	.price {
		padding-bottom: 0;
	}

	.rating {
		width: 54px;
		padding: 5px 0;

		@include media ('>=tablet') {
			width: 60px;
		}
	}

	.note {
		@include media ('>=tablet') {
			padding-top: 6px;
		}
	}

	.wrapp {
		overflow: hidden;
		@include media ('>=tablet', '<desktop') {
			overflow: visible;
		}
	}


	.footer-holder {
		@include media ('>=tablet', '<desktop') {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
		}
	}

	.link-holder {
		@include media ('>=tablet') {
			padding: 8px 10px 5px 0;
			width: 200px;
		}
		@include media ('>=desktop') {
			padding: 18px 0 0;
		}
	}
}

.selling-post {
	@extend %clearfix;
	padding: 15px 0 9px;
	color: $gray-light;
	font-size: 12px;
	font-weight: 500;
	border-bottom: 1px solid lighten($gray-base, 91.37%);

	@include media ('>=tablet') {
		padding: 18px 0 12px;
		font-size: 14px;
		line-height: 1.2875;
	}

	@include media ('>=desktop') {
		padding: 18px 0 16px;
	}

	.img-box {
		margin-bottom: 8px;

		@include media ('>=tablet') {
			float: left;
			width: 200px;
			margin: 2px 21px 0 0;
		}

		@include media ('>=desktop') {
			width: 248px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.text-box {
		@extend %clearfix;
		@include media ('>=desktop') {
			overflow: hidden;
			position: relative;
		}
	}

	h3 {
		font: 700 16px/1.2 $font-family-sans-serif;
		margin: 0;
		color: $gray-base;

		@include media ('>=tablet') {
			font-size: 18px;
			line-height: 1;
			margin: 0 0 2px;
		}

		@include media ('>=desktop') {
			max-width: 65%;
		}

		a {
			color: inherit;

			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.details {
		font-size: 9px;
		margin: 0 0 8px;
		@include media ('>=tablet') {
			margin: 0 0 10px;
		}
		@include media ('>=desktop') {
			margin-bottom: 16px;
			max-width: 65%;
		}
		dt, dd {
			line-height: 1.8;
			@include media ('>=tablet') {
				line-height: 2;
			}
		}
	}

	.sub-title {
		display: block;
		text-transform: uppercase;
		font-size: 9px;
		@include media ('>=tablet') {
			font-size: 10px;
			padding: 0 0 3px;
		}
	}

	.name {
		color: $gray-base;
		display: block;
		padding: 0 0 3px;

		@include media ('>=tablet') {
			font-weight: 500;
			letter-spacing: -0.3px;
			padding: 0 0 7px;
		}
	}

	.link-box {
		font-size: 9px;
		border-bottom: 1px solid lighten($gray-base, 91.37%);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 0 11px;
		margin: 0 0 7px;
		@include media ('>=tablet') {
			font-size: 10px;
			padding: 0 0 18px;
			margin: 0 0 12px;
		}

		a {
			display: inline-block;
			color: $gray-base;
			&:hover {
				text-decoration: none;
				color: $link-color;
			}
		}

		.location-link {
			font-weight: 700;
			position: relative;
			display: inline-block;
			padding-left: 12px;
			@include media ('>=tablet') {
				padding-left: 14px;
			}
			i {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-52%);
				&:before {

				}
			}
		}
	}

	.builder {
		font-size: 11px;
		line-height: 1.4;
		float: left;
		max-width: 50%;
		padding-right: 10px;
		font-weight: 900;

		@include media ('>=tablet') {
			font-size: 12px;
			float: none;
			padding-top: 4px;
			display: inline-block;
			vertical-align: middle;
			width: 56%;
			max-width: none;
			margin-right: -4px;
		}
		@include media ('>=desktop') {
			padding: 2px 0 0 2px;
			margin: 0;
			display: block;
			width: 100%;
		}

		span {
			font-weight: 500;
			padding: 0 2px 0 0;
		}
	}

	.price {
		float: right;
		max-width: 50%;
		text-align: right;
		font: 700 22px/1.2 $font-family-serif;
		padding: 4px 2px 0 0;
		color: $brand-primary;

		@include media ('>=tablet') {
			padding: 0;
			float: none;
			display: inline-block;
			vertical-align: middle;
			width: 44%;
			max-width: none;
			margin-right: -4px;
			font-size: 24px;
		}

		@include media ('>=desktop') {
			position: absolute;
			top: -4px;
			right: 0;
			margin: 0;
			max-width: 38%;
			padding-left: 10px;
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 13px;
			padding-top: 2px;
			@include media ('>=tablet') {
				font-size: 14px;
				padding-top: 3px;
			}
		}
	}
}

.contact-block {
	padding: 20px;
	overflow: hidden;
	position: relative;
	margin-bottom: 20px;

	@include media ('>=tablet') {
		padding: 36px 34px 29px 36px;
		margin-bottom: 40px;
	}

	&:before {
		position: absolute;
		top: 2px;
		left: 2px;
		right: 2px;
		bottom: 2px;
		content: '';
		height: auto;
		width: auto;
		border: 2px solid $white;
	}

	.holder {
		@extend %clearfix;
		position: relative;
	}

	h4 {
		font-size: 20px;
		font: 700 20px/1.1 $font-family-sans-serif;
		margin: 0 0 15px;
		letter-spacing: -0.7px;

		@include media ('>=tablet') {
			font-size: 24px;
			margin: 0 0 24px;
		}
	}

	.footer-holder {
		@include media ('>=desktop') {
			float: right;
		}
	}

	.form-col {
		@include media ('>=desktop') {
			float: left;
			width: 54%;
		}

		&:first-child {
			@include media ('>=desktop') {
				width: 46%;
				padding-right: 10px;
			}
		}
	}

	.contact-box {
		background: darken(saturate(adjust-hue($brand-primary, 1.5602), 8.0497), 9.6078);
		position: relative;
		padding: 28px 29px 16px;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			height: 0;
			width: 0;
			border: solid 17px;
			border-color: rgba($white, .5) $brand-primary $brand-primary rgba($white, .5);
		}

		.title {
			display: block;
			font: 700 18px/1.2 $font-family-serif;
			color: rgba($white, .5);
			letter-spacing: -0.5px;
			padding: 0 0 7px;
		}

		.advertiser {
			display: block;
			font-size: 24px;
			line-height: 1.1;
			margin-left: -2px;
			letter-spacing: -0.6px;
			padding: 0 0 7px;
		}
	}

	.contact-list {
		font-weight: 700;
		font-size: 12px;
		letter-spacing: 0.3px;
		padding-left: 1px;
		margin: 0 0 15px;

		li {
			margin-bottom: 7px;
		}
		a {
			color: $white;
			position: relative;
			display: inline-block;
			vertical-align: top;
			padding-left: 20px;
			&:hover {
				text-decoration: none;
				color: $brand-warning;
			}

			i {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				&.icon-phone {
					margin-top: -2px;
					&:before {
						font-size: 14px;
					}
				}
				&:before {
					font-size: 15px;
				}
			}
		}
	}
}

.contact-block-sm {
	padding: 7px 14px 5px;
	overflow: hidden;
	position: relative;
	margin: 0 auto 24px;
	max-width: 170px;

	@include media ('>=tablet') {
		max-width: 280px;
		margin: 0 0 24px;
		padding: 21px 24px 36px;
	}

	@include media ('>=desktop') {
		max-width: 384px;
		padding: 21px 34px 30px;
	}

	&:before {
		position: absolute;
		top: 2px;
		left: 2px;
		right: 2px;
		bottom: 2px;
		content: '';
		height: auto;
		width: auto;
		border: 2px solid $white;
	}

	.box {
		text-align: center;
		padding: 10px 0;
		position: relative;
		border-top: 1px solid rgba($white, .2);
		@include media ('>=tablet') {
			text-align: left;
			padding: 20px 0 12px;
		}

		&.alt {
			@include media ('>=tablet') {
				text-align: center;
			}

			@include media ('>=desktop') {
				padding-bottom: 7px;
			}
		}

		&:first-child {
			border: none;
		}
	}

	.title {
		display: block;
		font: 700 18px/1 $font-family-serif;
		color: rgba($white, .5);
		letter-spacing: -0.5px;
		padding: 0 0 12px;

		@include media ('>=tablet') {
			font-size: 20px;
			padding: 0 0 21px;
		}
	}

	.advertiser {
		display: block;
		font-size: 18px;
		line-height: 1;
		letter-spacing: -0.4px;
		padding: 0 0 13px;

		@include media ('>=tablet') {
			letter-spacing: -0.6px;
			font-size: 22px;
			padding: 0 0 9px;
		}

		@include media ('>=desktop') {
			letter-spacing: 0.4px;
		}
	}

	.contact-list {
		font-weight: 700;
		font-size: 11px;
		letter-spacing: 0.3px;
		margin: 0 0 -4px;
		text-align: left;
		@include media ('>=tablet') {
			font-size: 12px;
			margin: 0 0 18px;
		}

		li {
			margin-bottom: 6px;
			@include media ('>=tablet') {
				margin-bottom: 7px;
			}
		}
		a {
			@include text-overflow;
			color: $white;
			position: relative;
			display: inline-block;
			vertical-align: top;
			padding-left: 17px;
			max-width: 100%;
			@include media ('>=tablet') {
				padding-left: 20px;
			}
			&:hover {
				text-decoration: none;
				color: $brand-warning;
			}

			i {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				&.icon-phone {
					margin-top: -2px;
					&:before {
						font-size: 12px;
						@include media ('>=tablet') {
							font-size: 14px;
						}
					}
				}
				&:before {
					font-size: 13px;

					@include media ('>=tablet') {
						font-size: 15px;
					}
				}
			}
		}
	}

	.price-title {
		display: block;
		font: 700 20px/1.2 $font-family-serif;
		color: rgba($white, .5);
		padding: 1px 0 2px;

		@include media ('>=tablet') {
			font-size: 24px;
			letter-spacing: -0.5px;
			padding: 3px 0 7px;
		}

		@include media ('>=desktop') {
			padding: 3px 0 5px;
		}
	}

	.price {
		font: 700 24px/1 $font-family-serif;
		display: block;
		padding: 0 0 4px;

		@include media ('>=tablet') {
			font-size: 40px;
			padding: 0 0 11px;
		}

		@include media ('>=desktop') {
			font-size: 48px;
			padding: 0 0 10px;
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			padding-top: 4px;

			@include media ('>=tablet') {
				font-size: 23px;
				padding-top: 3px;
			}

			@include media ('>=desktop') {
				font-size: 28px;
				padding-top: 5px;
			}
		}
	}

	.link {
		color: $white;
		font-weight: 700;
		display: inline-block;
		vertical-align: top;
		font-size: 11px;
		line-height: 1.1;
		margin-bottom: 2px;

		@include media ('>=tablet') {
			font-size: 12px;
			line-height: 1.14;
			letter-spacing: 0.2px;
			margin-bottom: 0;
		}
		&:hover {
			text-decoration: none;
			color: $brand-warning;
		}
	}

	.btn {
		&.btn-contact {
			line-height: 1;
			padding: 9px 12px;
			margin-bottom: 20px;
			@include media ('>=tablet') {
				padding: 14px 12px;
				line-height: 1.5;
				margin-bottom: 9px;
			}
			@include media ('>=desktop') {
				margin-bottom: 18px;
			}
		}
		&.btn-add {
			background: #1f2a55;
			border-color: #1f2a55;
			letter-spacing: -0.1px;
			margin: 5px 0 0;
			padding: 8px 20px 7px;

			@include media ('>=tablet') {
				margin: -3px 0 0;
				padding: 11px 5px 10px;
				letter-spacing: 0;
			}

			&:focus,
			&:active:focus,
			&:active {
				background: #1f2a55;
				border-color: #1f2a55;
			}
			i {
				display: none;
				@include media ('>=tablet') {
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
				}
			}
		}
	}
}

.plans-block {
	padding: 20px 51px 0;
	margin-bottom: 30px;

	@include media ('>=tablet') {
		background: lighten($gray-base, 98.43%);
		border: 1px solid lighten($gray-base, 91.37%);
		padding: 25px 17px 24px;
		margin-bottom: 59px;
	}

	@include media ('>=desktop') {
		padding: 16px 18px 24px;
		margin-bottom: 40px;
	}

	.swiper-pagination {
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);
		@include media ('>=tablet') {
			display: none;
		}
	}

	.swiper-container {
		padding-bottom: 46px;
		max-width: 250px;
		@include media ('>=tablet') {
			padding: 0;
			max-width: none;
		}
	}
}

.plans-list {
	margin: 0;
	padding: 0;
	@include media ('>=tablet') {
		max-width: none;
		padding: 0;
	}
	> li {
		overflow: hidden;
		position: relative;

		@include media ('>=tablet') {
			width: 33.332% !important;
			float: left;
			padding: 0 5px;
		}

		@include media ('>=desktop') {
			padding: 0 4px;
		}

		&.active{
			.plan-box {
				background: lighten($gray-base, 96.07%);
				border-color: lighten($gray-base, 74.9%);

				.holder-box {
					border: solid #b83e22;
					border-width: 5px 0 0;
					padding-top: 9px;
					padding-bottom: 8px;

					@include media ('>=tablet') {
						border-width: 6px 0 0;
					}


					&:before {
						position: absolute;
						top: 0;
						left: 50%;
						margin-left: -2px;
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 6px 2px 0;
						border-color: #b83e22 transparent transparent transparent;

						@include media ('>=tablet') {
							
						}
					}
				}
			}

			.img-box {
				margin: 0 -8px 6px;

				@include media ('>=tablet') {
					margin: 0 -10px 7px;
				}
				&:before {
					left: 50%;
					margin-left: -6px;
				}
			}

			h5 {
				text-align: center;
				margin: 0 0 10px;
			}

			.fake-radio {
				&:before {
					opacity: 1;
					visibility: visible;
				}
			}

			.price {
				text-align: center;
				font: 700 20px/1.2 $font-family-serif;
				letter-spacing: 0.2px;

				@include media ('>=tablet') {
					font-size: 22px;
					line-height: 1.2;
					letter-spacing: 0.2px;
				}

				.sup {
					font-size: 12px;
				}
			}

			.contact-list {
				font-weight: 400;
				text-align: center;
				background: lighten($gray-base, 96.07%);
				overflow: hidden;
				margin: 6px -11px;
				padding: 6px 10px;

				@include media ('>=tablet') {
					margin: 8px -12px 7px;
					padding: 6px 10px 8px;
				}
			}
		}
	}
}

.plan-box {
	border-radius: 3px;
	padding: 6px 4px 5px;
	font-size: 12px;
	line-height: 1.2;
	border: 1px solid lighten($gray-base, 91.37%);
	background: lighten($gray-base, 98.43%);
	transition: background .2s, border-color .2s;

	@include media ('>=tablet') {
		padding: 6px 4px 5px;
		font-size: 13px;
		line-height: 1.23;
	}

	@include media ('>=desktop') {
		padding: 6px;
	}

	.fake-radio {
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -12px;
		width: 23px;
		height: 23px;
		background: $white;
		border-radius: 50%;
		border: 1px solid lighten($gray-base, 91.37%);
		transition: background .2s;

		@include media ('>=tablet') {
			margin-top: -12px;
			width: 24px;
			height: 24px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 4px;
			left: 4px;
			right: 4px;
			bottom: 4px;
			background: $brand-success;
			border-radius: 50%;
			opacity: 0;
			visibility: hidden;
			transition: opacity .2s, visibility .2s;
		}
	}

	.img-box {
		position: relative;
		overflow: hidden;
		margin: -5px -11px 7px;
		&:before {
			position: absolute;
			top: 0;
			left: 10px;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 11px 5px 0;
			border-color: $white transparent transparent transparent;

			@include media ('>=tablet') {
				border-width: 12px 6px 0;
				left: 12px;
			}
		}
		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}

	.heading {
		white-space: nowrap;
		text-align: center;
		padding: 0 2px 3px 4px;
		&:after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 0;
			min-height: 46px;

			@include media ('>=tablet') {
				min-height: 52px;
			}
		}

		@include media ('>=tablet') {
			padding: 0 2px 4px 7px;
		}
	}

	.title {
		white-space: normal;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		padding-left: 26px;
		font-size: 14px;
		line-height: 1.2;
		font-weight: 900;
		text-align: left;

		@include media ('>=tablet') {
			font-size: 16px;
			padding-left: 29px;
		}
	}

	h5 {
		font:300 17px/1.2 $font-family-sans-serif;
		margin: 0 0 6px;
		letter-spacing: -0.5px;

		@include media ('>=tablet') {
			font-size: 20px;
			margin-bottom: 7px;
		}

	}

	.holder-box {
		background: $white;
		border: 1px solid $white;
		border-radius: 0 0 2px 2px;
		padding: 4px 9px 5px;
		position: relative;

		@include media ('>=tablet') {
			padding: 2px 11px 5px;
		}

		p {
			margin: 0;
		}
	}

	.price {
		display: block;
		font-weight: 300;
		padding: 0 0 2px;

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 8px;
		}
	}

	.contact-list {
		font-size: 11px;
		line-height: 1.5;
		font-weight: 700;
		letter-spacing: 0.2px;

		@include media ('>=tablet') {
			font-size: 12px;
			line-height: 1.5;
			letter-spacing: 0.4px;
		}
		li {
			&:first-child {
				letter-spacing: 0.6px;
			}
		}
		a {
			color: inherit;
			&:hover {
				text-decoration: none;
				color: $link-color;
			}
		}
	}
}

.detail-post {
	@extend %clearfix;
	padding: 0 0 9px;
	color: $gray-light;
	font-size: 12px;
	line-height: 1.4;
	font-weight: 500;
	max-width: 248px;
	width: 100%;
	margin: 0 auto 30px;

	@include media ('>=tablet') {
		margin-bottom: 40px;
		padding: 0 0 12px;
		font-size: 14px;
		line-height: 1.2875;
	}

	@include media ('>=desktop') {
		padding: 0 0 16px;
	}

	&.hover {
		.hover-box {
			opacity: 1;
			visibility: visible;
		}

		h3 {
			color: $brand-primary;
			a {
				color: $brand-primary;
			}
		}
	}

	.img-box {
		margin-bottom: 12px;
		position: relative;
		overflow: hidden;
		@include media ('>=tablet') {
			margin-bottom: 15px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.hover-box {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		background: rgba($brand-primary, .5);
		opacity: 0;
		visibility: hidden;
		transition: visibility .2s, opacity .2s;

		i {
			border-radius: 50%;
			width: 44px;
			height: 44px;
			margin: auto;
			text-align: center;
			background: rgba($white, .8);
			color: $brand-primary;
			&:before {
				line-height: 44px;
			}
		}
	}

	h3 {
		font: 700 18px/1.2 $font-family-sans-serif;
		margin: 0 0 1px;
		color: $gray-base;

		@include media ('>=tablet') {
			font-size: 18px;
			line-height: 1;
			margin: 0 0 2px;
		}

		a {
			color: inherit;

			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.details {
		font-size: 10px;
		margin: 0 0 4px;
		@include media ('>=tablet') {
			font-size: 10px;
			letter-spacing: 0;
		}
		dt, dd {
			line-height: 1.8;
		}
	}

	.link-box {
		font-size: 10px;
		border-bottom: 1px solid lighten($gray-base, 91.37%);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 0 14px;
		margin: 0 0 13px;
		@include media ('>=tablet') {
			margin: 0 0 12px;
		}

		a {
			display: inline-block;
			color: $gray-base;
			&:hover {
				text-decoration: none;
				color: $link-color;
			}
		}
	}

	.builder {
		padding-right: 10px;
		font-weight: 900;

		@include media ('>=tablet') {
			font-size: 12px;
			padding-top: 4px;
			margin-right: -4px;
		}
		@include media ('>=desktop') {
			padding: 2px 0 0 2px;
			margin: 0;
		}

		span {
			font-weight: 500;
			padding: 0 4px 0 0;
		}
	}

	.price {
		font: 700 24px/1.2 $font-family-serif;
		color: $brand-primary;

		@include media ('>=tablet') {
			padding: 0;
			font-size: 24px;
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			padding-top: 3px;
		}
	}
}

.document-post {
	@extend %clearfix;
	padding: 17px 0;
	color: $gray-light;
	font-size: 13px;
	line-height: 1.23;
	font-weight: 500;
	border-bottom: 1px solid lighten($gray-base, 91.37%);

	&.item {
		.img-box {
			max-width: 21%;
			margin-right: 10px;

			@include media ('>=tablet') {
				margin-right: 21px;
			}
		}

		h3 {
			max-width: 80%;
		}

		.btn-group {
			.btn-xs {
			padding: 9px 6px 7px;
			min-width: 110px;
			@include media ('>=tablet') {
					padding: 8px;
					min-width: 124px;
				}
			}
		}
	}

	@include media ('>=tablet') {
		padding: 20px 0;
		font-size: 14px;
		line-height: 1.2875;
	}

	.text-box {
		@include media ('>=tablet') {
			overflow: hidden;
		}
	}


	.holder-box {
		overflow: hidden;
		width: 100%;
		padding: 10px 0 0;

		@include media ('>=tablet') {
			padding: 0;
			overflow: visible;
		}
	}


	.img-box {
		float: left;
		margin-bottom: 14px;
		max-width: 28.2%;
		margin: 2px 15px 0 0;
		@include media ('>=tablet') {
			max-width: 40%;
			margin: 0 19px 0 0;
		}

		@include media ('>=desktop') {
			margin: 0 19px 0 0;
			max-width: 29%;
		}
		img {
			height: auto;
			max-width: 100%;
		}
	}

	.category-title {
		padding: 0 0 5px;
	}

	h3 {
		font-size: 27px;
		margin: 0 0 7px;
		font-weight: 700;
		letter-spacing: -0.7px;
		color: $gray-base;
		max-width: 90%;

		@include media ('>=tablet') {
			font-size: 30px;
			max-width: none;
		}
		@include media ('>=desktop') {
			margin: 0 0 6px;
		}

		a {
			color: inherit;

			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.info-tag {
		color: lighten($gray-base, 74.9%);
		font-size: 9px;
		letter-spacing: 0.8px;
		overflow: hidden;
		margin: 3px 0 14px;
		font-weight: 700;
		@include media ('>=tablet') {
			width: 100%;
			letter-spacing: 0.9px;
			font-size: 10px;
			margin: 0;
			padding-top: 4px;
		}

		li {
			display: inline-block;
			vertical-align: top;
			padding: 0 9px 0 0;
			position: relative;
			@include media ('>=tablet') {
				padding: 0 11px 0 0;
			}
			&:before {
				content: '';
				width: 3px;
				height: 3px;
				background: lighten($gray-base, 74.9%);
				border-radius: 50%;
				position: absolute;
				top: 42%;
				left: -8px;
				transform: translateY(-50%);
			}
		}
	}

	.btn-group {
		&.btn-group-alt {
			.btn {
				margin-bottom: 0;
				@include media ('>=tablet') {
					margin-top: 17px;
				}
			}
		}
		.btn-xs {
			padding: 9px 17px 7px;
			min-width: 102px;
			@include media ('>=tablet') {
				padding: 8px 19px;
				min-width: 114px;
			}
		}
	}

	p {
		margin: 0 0 11px;
		@include media ('>=tablet') {
			margin: 0 0 9px;
			max-width: 340px;
		}
		@include media ('>=desktop') {
			margin: 0 0 10px;
		}
	}
}

.table-wrapp {
	margin-bottom: 30px;
	width: 100%;
}

.table {
	td, th {
		text-align: center;
		border-top: 0 !important;
		border-bottom: 1px solid lighten($gray-base, 91.37%);
	}
	th {
		text-transform: uppercase;
		color: lighten($gray-base, 74.9%);
		font-size: 9px;
		padding-top: 8px !important;
		padding-bottom: 5px !important;
		@include media ('>=tablet') {
			font-size: 10px;
			padding-bottom: 6px !important;
		}
	}

	.price {
		display: inline-block;
		vertical-align: top;
		font: 700 18px/1 $font-family-serif;
		padding: 1px 0 0;

		@include media ('>=tablet') {
			font-size: 20px;
			padding: 2px 0 0;
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 10px;

			@include media ('>=tablet') {
				font-size: 12px;
			}
		}
	}

	.old-price {
		font: 700 16px/1 $font-family-serif;
		display: inline-block;
		color: #bfbfbf;
		position: relative;
		padding-bottom: 10px;
		margin-top: 3px;

		&:before {
			content: '';
			width: 50%;
			border-top: 2px solid $gray-base;
			position: absolute;
			top: 7px;
			left: 48%;
			height: 2px;
			transform: translateX(-50%) rotate(-45deg);
		}

		@include media ('>=tablet') {
			font-size: 18px;
			padding-bottom: 12px;
			margin-left: 4px;
		}

		@include media ('>=desktop') {
			margin-left: 3px;
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 9px;
			@include media ('>=tablet') {
				font-size: 10px;
			}
		}
	}

	time {
		font-weight: 700;
	}

	h5 {
		font-size: 18px;
		font-weight: 400;
		margin: 1px 0 0;

		@include media ('>=tablet') {
			font-size: 20px;
		}
	}

	.btn-del {
		display: inline-block;
		vertical-align: middle;
		color: $gray-light;
		text-transform: uppercase;
		position: relative;
		padding-left: 14px;
		font-size: 9px;
		line-height: 1.1;
		white-space: nowrap;
		margin-top: -2px;

		@include media ('>=tablet') {
			padding-left: 16px;
			font-size: 10px;
		}
		@include media ('>=desktop') {
			margin-top: 0;
		}

		&:hover {
			text-decoration: none;
			color: $brand-warning;
		}

		&:focus,
		&:active {
			color: $gray-light;
		}

		i {
			font-weight: 700;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			margin-top: -1px;
			&:before {
				font-size: 9px;
				@include media ('>=tablet') {
					font-size: 10px;
				}
			}
		}
	}

	.dash {
		display: inline-block;
		vertical-align: middle;
		background: rgba($gray-base, .5);
		width: 10px;
		height: 2px;
		border-radius: 2px;
	}
}

.table-1 {
	font-size: 12px;
	@include media ('>=tablet') {
		font-size: 14px;
	}
	td {
		padding-top: 14px !important;
		padding-bottom: 14px !important;

		@include media ('>=tablet') {
			padding-top: 15px !important;
			padding-bottom: 15px !important;
		}
	}

	tr {
		td, th {
			&:first-child {
				text-align: left;
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
				text-align: right;
			}
		}
	}
	.col-1 {
		width: 38%;
		@include media ('>=tablet') {
			width: 32%;
		}
		@include media ('>=desktop') {
			width: 37%;
		}
	}
	.col-2 {
		width: 34%;
		@include media ('>=tablet') {
			width: 25.5%;
		}
		@include media ('>=desktop') {
			width: 27.5%;
		}
	}
	.col-3 {
		width: 25.5%;
		@include media ('>=desktop') {
			width: 20.5%;
		}
	}
	.col-4 {
		width: 28%;
		@include media ('>=tablet') {
			width: 16%;
		}
		@include media ('>=desktop') {
			width: 15%;
		}
	}

	tr {
		td, th {
			&:nth-last-child(2) {
				@include media ('<tablet') {
					display: none;
				}
			}
		}
	}
}

.table-responsive {
	border: none;

	.table {
		width: 730px;
		max-width: none;
		@include media ('>=tablet') {
			margin-bottom: 0;
			width: 100%;
			max-width: 100%;
		}
	}
}

.table-2 {
	font-weight: 900;
	font-size: 11px;
	@include media ('>=tablet') {
		font-size: 12px;
	}

	tr {
		td {
			padding-top: 20px !important;
			padding-bottom: 21px !important;
			@include media ('>=tablet') {
				padding-top: 23px !important;
				padding-bottom: 23px !important;
			}
		}

		td, th {
			&:first-child {
				padding-left: 20px;
				text-align: left;
			}
			&:last-child {
				padding-left: 29px;
				@include media ('>=desktop') {
					padding-left: 26px;
				}
			}
			&:nth-last-child(2) {
				text-align: right;
			}
		}

	}
	.col-1 {
		width: 19.5%;
		@include media ('>=desktop') {
			width: 22.45%;
		}
	}
	.col-2 {
		width: 13%;
		@include media ('>=desktop') {
			width: 13%;
		}
	}
	.col-3 {
		width: 15.5%;
		@include media ('>=desktop') {
			width: 18%;
		}
	}
	.col-4 {
		width: 23.5%;
		@include media ('>=desktop') {
			width: 20.5%;
		}
	}
	.col-5 {
		width: 11.2%;
		@include media ('>=desktop') {
			width: 9.8%;
		}
	}
	.col-6 {
		width: 17.3%;
		@include media ('>=desktop') {
			width: 16.25%;
		}
	}
}

.table-striped {
	tr {
		&:first-child {
			background: none !important;
		}
		&:nth-child(odd) {
			background: #f00;
		}
	}
}

.article-box {
	@extend %clearfix;
	padding: 18px 0 1px;
	color: $gray-light;
	font-size: 12px;
	font-weight: 500;
	border-bottom: 1px solid lighten($gray-base, 91.37%);

	&.item-box {
		@include media ('>=tablet') {
			padding-bottom: 12px;
		}
		@include media ('>=desktop') {
			padding-bottom: 10px;
		}
		.img-box {
			@include media ('>=tablet') {
				margin-bottom: 8px;
			}
			@include media ('>=desktop') {
				margin-bottom: 10px;
			}
		}
		.holder-box {
			@include media ('>=desktop') {
				max-width: none;
			}
		}

		.footer-box {
			@include media ('>=tablet') {
				padding: 10px 0 0;
			}
		}

		.builder {
			@include media ('>=tablet') {
				padding-top: 4px;
			}

			@include media ('>=desktop') {
				padding-top: 0;
			}
		}
	}

	@include media ('>=tablet') {
		padding: 18px 0 15px;
		font-size: 14px;
		line-height: 1.2875;
	}

	@include media ('>=desktop') {
		padding: 18px 0 15px;
	}

	.img-box {
		float: left;
		width: 33.2%;
		margin: 0 10px 15px 0;

		@include media ('>=tablet') {
			float: left;
			width: 200px;
			margin: 2px 21px 5px 0;
		}

		@include media ('>=desktop') {
			width: 230px;
			margin: 2px 20px 5px 0;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.text-box {
		@include media ('>=tablet') {
			overflow: hidden;
			position: relative;
		}
	}

	.link-box {
		font-size: 9px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-bottom: 12px;
		@include media ('>=tablet') {
			font-size: 10px;
			padding-bottom: 16px;
		}
		@include media ('>=desktop') {
			padding-bottom: 9px;
		}

		a {
			display: none;
			color: rgba($gray-base, .5);
			@include media ('>=desktop') {
				display: inline-block;
			}
			&:hover {
				text-decoration: none;
				color: $gray-base;
			}
		}
	}

	.builder {
		font-size: 11px;
		line-height: 1.5;
		max-width: 44%;
		display: inline-block;
		padding-right: 10px;
		font-weight: 900;

		@include media ('>=tablet') {
			font-size: 12px;
			padding-top: 1px;
			width: 56%;
			max-width: none;
		}

		span {
			font-weight: 500;
			padding: 0 2px 0 0;
		}
	}

	.price {
		font: 700 22px/1.2 $font-family-serif;
		color: $brand-primary;
		display: block;
		width: 100%;

		@include media ('>=tablet') {
			font-size: 24px;
		}

		@include media ('>=desktop') {
			display: inline-block;
			width: auto;
		}

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 13px;
			padding-top: 2px;
			@include media ('>=tablet') {
				font-size: 14px;
				padding-top: 3px;
			}
		}
	}

	.category-title {
		padding-bottom: 5px;
		@include media ('>=tablet') {
			padding-bottom: 4px;
		}
	}

	h3 {
		font-size: 24px;
		line-height: 1.2;
		margin: 0 0 5px;
		letter-spacing: -0.8px;
		color: $gray-base;

		@include media ('>=tablet') {
			font-size: 30px;
			margin: 0 0 4px;
			letter-spacing: -1px;
		}

		a {
			color: inherit;

			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.details {
		font-size: 9px;
		margin: 0 0 4px;
		@include media ('>=tablet') {
			margin: 0 0 4px;
		}
		@include media ('>=desktop') {
			margin-bottom: 4px;
		}
		dt, dd {
			line-height: 1.8;
			@include media ('>=tablet') {
				line-height: 2;
			}
		}
	}

	.holder-box {
		overflow: hidden;
		@include media ('>=tablet') {
			max-width: 63%;
		}
	}

	.footer-box {
		border-top: 1px solid lighten($gray-base, 91.37%);
		overflow: hidden;
		width: 100%;
		clear: both;
		padding: 6px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media ('>=tablet') {
			padding: 16px 0 0;
		}

		@include media ('>=desktop') {
			padding: 13px 0 0;
		}

		.btn {
			min-width: 138px;
			@include media ('>=tablet') {
				min-width: 154px;
			}
		}
	}

	.detail-box {
		border-top: 1px solid lighten($gray-base, 91.37%);
		padding: 10px 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media ('>=tablet') {
			max-width: 36%;
			position: absolute;
			top: 0;
			right: 0;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			border: none;
			padding: 0;
		}

		@include media ('>=desktop') {
			max-width: 35%;
		}

		.btn {
			min-width: 100px;

			@include media ('>=tablet') {
				min-width: 113px;
			}
		}
	}

	.info-detail {
		display: inline-block;
		position: relative;
		padding: 0 15px 0 21px;
		font-size: 9px;
		line-height: 1;
		max-width: 63%;
		margin-top: 2px;

		@include media ('>=tablet') {
			max-width: none;
			display: block;
			font-size: 10px;
			padding-left: 24px;
			padding-right: 0;
			margin-bottom: 7px;
		}

		i {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-58%);
			color: 1;

			&:before {
				font-size: 14px;
				@include media ('>=tablet') {
					font-size: 16px;
				}
			}
		}
	}

	.btn {
		&.btn-default {
			border-color: lighten($gray-base, 74.9%);
			background: lighten($gray-base, 74.9%);

			&:hover {
				border-color: lighten($gray-base, 83.13%);
				background: lighten($gray-base, 83.13%);
			}

			&:focus,
			&:active:focus,
			&:active {
				border-color: lighten($gray-base, 74.9%);
				background: lighten($gray-base, 74.9%);
			}
		}
	}
}

.modifer-list {
	display: inline-block;
	vertical-align: middle;
	font-weight: 900;
	margin: 0;
	@include media ('>=desktop') {
		margin-top: 1px;
	}

	li {
		display: inline-block;
		vertical-align: middle;
		margin-left: 24px;
		white-space: nowrap;

		@include media ('>=tablet') {
			margin-left: 26px;
		}

		&:first-child {
			margin: 0;
		}
	}

	a {
		display: inline-block;
		vertical-align: middle;
		color: $gray-light;
		text-transform: uppercase;
		position: relative;
		padding-left: 14px;
		font-size: 9px;
		line-height: 1.1;
		white-space: nowrap;
		margin-top: -2px;

		@include media ('>=tablet') {
			padding-left: 16px;
			font-size: 10px;
		}
		@include media ('>=desktop') {
			margin-top: 0;
		}

		&:hover {
			text-decoration: none;
			color: $brand-warning;
		}
		
		&:focus,
		&:active {
			color: $gray-light;
		}

		i {
			font-weight: 700;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			margin-top: -1px;
			&:before {
				font-size: 9px;
				@include media ('>=tablet') {
					font-size: 10px;
				}
			}
		}
	}
}

.table-checkout {
	font-size: 11px;
	font-weight: 500;
	@include media ('>=tablet') {
		font-size: 12px;
	}
	&.cart-item {
		tr {
			td, th {
				&:first-child {
					@include media ('>=tablet') {
						width: 51.6%;
					}
					@include media ('>=desktop') {
						width: 55.5%;
					}
				}
				&:first-child + th,
				&:first-child + td {
					@include media ('>=tablet') {
						width: 21.4%;
						padding-top: 45px !important;
					}
					@include media ('>=desktop') {
						width: 19.5%;
					}
				}
				&:nth-last-child(2) {
					@include media ('>=tablet') {
						width: 13%;
						padding-top: 60px !important;
					}
					@include media ('>=desktop') {
						width: 12%;
					}
				}
				&:last-child {
					@include media ('>=tablet') {
						width: 14%;
						padding-top: 78px !important;
					}
					@include media ('>=desktop') {
						width: 13%;
					}
				}
			}
			th {
				&:nth-last-child(2),
				&:last-child,
				&:first-child + th,
				&:first-child, {
					padding-top: 8px !important;
				}
			}
		}
	}
	thead {
		@include media ('<tablet') {
			display: none;
		}
		tr {
			th {
				padding-top: 8px !important;
				border-bottom-width: 1px;

			}
		}
	}
	tr {
		float: left;
		width: 100%;
		border-bottom: 1px solid lighten($gray-base, 91.37%);
		padding: 18px 0 15px;
		@include media ('>=tablet') {
			border: none;
			float: none;
			padding: 0;
		}
		td {
			@include media ('<tablet') {
				padding: 0 2px 0 0 !important;
				border-width: 0;
			}
			@include media ('>=tablet') {
				padding-top: 20px !important;
				padding-bottom: 20px !important;
			}
		}
		td, th {
			float: right;
			width: 35%;
			text-align: right;
			@include media ('>=tablet') {
				float: none;
			}
			&:first-child {
				text-align: left;
				float: left;
				width: 65%;
				@include media ('>=tablet') {
					width: 51.8%;
					float: none;
					padding-left: 0 !important;
				}
				@include media ('>=desktop') {
					width: 56%;
				}
			}
			&:first-child + th,
			&:first-child + td {
				@include media ('>=tablet') {
					width: 16.2%;
					text-align: left;
					padding-top: 42px !important;
				}
				@include media ('>=desktop') {
					width: 18%;
				}
			}
			&:last-child {
				padding-right: 0;
				@include media ('>=tablet') {
					width: 21%;
					padding-top: 75px !important;
				}
				@include media ('>=desktop') {
					width: 16%;
				}

				.box {
					@include media ('>=tablet') {
						margin-top: -33px;
					}
				}
			}
			&:nth-last-child(2) {
				@include media ('>=tablet') {
					width: 11%;
					padding-top: 75px !important;
					text-align: center;
				}
				@include media ('>=desktop') {
					width: 10%;
				}
			}
		}

		th {
			&:nth-last-child(2),
			&:last-child,
			&:first-child + th,
			&:first-child, {
				padding-top: 8px !important;
			}
		}
	}

	.category-title {
		padding-bottom: 14px;
		@include media ('>=tablet') {
			padding-bottom: 15px;
		}
	}

	h4 {
		font: 700 18px/1 $font-family-serif;
		letter-spacing: -0.5px;
		margin: 0 0 7px;

		@include media ('>=tablet') {
			font-size: 20px;
			letter-spacing: -0.5px;
			margin: 0 0 9px;
		}

		br {
			display: none;
			@include media ('>=tablet') {
				display: inline;
			}
		}

		a {
			color: inherit;
			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.price {
		padding: 1px 0 8px;
		display: block;

		@include media ('>=tablet') {
			padding: 0 0 10px;
		}
	}

	.old-price {
		@include media ('>=tablet') {
			margin-top: 3px;
		}
	}

	.img-box {
		float: left;
		width: 28.4%;
		margin-right: 10px;

		@include media ('>=tablet') {
			width: 127px;
			margin-right: 20px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.text-box {
		overflow: hidden;

		p {
			margin: 0 0 12px;
			@include media ('>=tablet') {
				margin-bottom: 14px;
			}
		}
	}

	.rating-box {
		padding-bottom: 8px;
		@include media ('>=tablet') {
			padding-bottom: 19px;
		}

		.rating {
			width: 82px;
			height: 14px;
			display: block;
			@include media ('>=tablet') {
				width: 91px;
				height: 16px;
			}

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		.note {
			display: block;
			color: $gray-light;
			padding-top: 4px;
			font-size: 9px;
			@include media ('>=tablet') {
				padding-top: 4px;
				font-size: 10px;
			}
		}
	}

	.number {
		display: inline-block;
		vertical-align: top;
		text-align: center;
		font: 700 18px/1 $font-family-serif;
		padding: 7px 0 10px;
		width: 66px;

		@include media ('>=tablet') {
			font-size: 20px;
			padding: 0;
			width: 100%;
		}

		@include media ('>=desktop') {
			padding-left: 10px;
		}
	}

	.detail {
		display: block;
		color: #bfbfbf;
		font-size: 11px;
		letter-spacing: -0.2px;
		@include media ('>=tablet') {
			font-size: 12px;
			letter-spacing: 0;
		}
	}

	.btn {
		min-width: 128px;
		@include media ('>=tablet') {
			min-width: 153px;
		}
		&.btn-default {
			border-color: lighten($gray-base, 74.9%);
			background: lighten($gray-base, 74.9%);

			&:hover {
				border-color: lighten($gray-base, 83.13%);
				background: lighten($gray-base, 83.13%);
			}

			&:focus,
			&:active:focus,
			&:active {
				border-color: lighten($gray-base, 74.9%);
				background: lighten($gray-base, 74.9%);
			}
		}
	}

	.jcf-select {
		width: auto;
		min-width: 63px;
		height: 44px;
		margin: 4px 0 11px;

		@include media ('>=tablet') {
			height: 48px;
			width: 70px;
		}

		.jcf-select-opener {
			width: 26px;
			text-align: left;

			@include media ('>=tablet') {
				width: 28px;
			}

			&:before {
				left: 0;
				top: 19px;
				@include media ('>=tablet') {
					top: 22px;
				}
			}
		}

		.jcf-select-text {
			text-align: center;
			margin: 0 30px 0 5px;
			padding: 12px 0;
			font: 700 18px/1 $font-family-serif;

			@include media ('>=tablet') {
				padding: 14px 0;
				font-size: 20px;
			}
		}
	}

	.btn-del {
		font-weight: 500;
		font-size: 10px;
		margin-top: 0;
		padding-left: 18px;
		padding-top: 2px;
		@include media ('>=tablet') {
			padding-left: 22px;
			margin-top: 14px;
		}
		i {
			margin-top: 0;
			font-weight: inherit;
			&:before {
				font-size: 11px;
				@include media ('>=tablet') {
					font-size: 13px;
				}
			}
		}
	}
}

.info-table {
	tr {
		td, th {
			&:first-child {
				text-align: left;
				width: 70%;
				padding-left: 0;
				padding-top: 19px;
			}
			&:last-child {
				text-align: right;
				width: 30%;
				padding-right: 0;
				padding-top: 35px;
				@include media ('>=tablet') {
					padding-top: 76px;
				}
			}
		}
	}

	.detail-list {
		@extend %clearfix;
		width: 100%;
		margin-bottom: 7px;
		dt, dd {
			line-height: 1.4285;
			float: left;
		}
		dt {
			font-size: 12px;
			font-weight: 900;
			text-transform: uppercase;
			clear: both;
			padding-right: 4px;
			padding-top: 2px;
			padding: 2px 4px 5px 0;
			width: 100%;

			@include media ('>=tablet') {
				width: auto;
				padding: 2px 4px 10px 0;
				font-size: 12px;
			}
		}

		dd {
			width: 100%;
			@include media ('>=tablet') {
				width: auto;
			}
			p {
				margin: 0 0 9px;
				@include media ('>=tablet') {
					margin: 0 0 18px;
				}
			}
		}
	}

	.info-body {
		padding-top: 10px;
		padding-bottom: 2px;
		font-size: 12px;
		line-height: 1.4285;
		border-top: 1px solid lighten($gray-base, 91.37%);

		@include media ('>=tablet') {
			font-size: 14px;
			padding-top: 22px;
		}
	}

	.category-title {
		padding-bottom: 5px;
	}

	h4 {
		font-size: 24px;
		font-weight: 700;
		margin: 0 0 5px;
		letter-spacing: -0.7px;

		@include media ('>=tablet') {
			font-size: 30px;
		}
	}

	.details {
		color: $gray-light;
		font-size: 9px;
		margin: 0 0 7px;
		
		dt, dd {
			line-height: 2;
		}
	}

	.price-item {
		color: $gray-light;
		display: block;
		text-transform: uppercase;
		font: 700 20px/1 $font-family-serif;
		letter-spacing: 0.2px;
		padding: 0 0 12px;

		@include media ('>=tablet') {
			font-size: 23px;
		}

		.sup{
			display: inline-block;
			vertical-align: top;
			font-size: 12px;

			@include media ('>=tablet') {
				font-size: 14px;
			}
		}
	}

	.img-list {
		@extend %clearfix;
		margin: 0;
		font-size: 0;
		line-height: 0;
		letter-spacing: -4px;

		li {
			display: inline-block;
			vertical-align: top;
			padding: 0 5px 5px 0;
			max-width: 50%;
			@include media ('>=tablet') {
				padding: 0 20px 20px 0;
				max-width: none;
			}
			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
}

.aside-box {
	margin-bottom: 24px;
	.head-box {
		border-bottom: 1px solid lighten($gray-base, 91.37%);
		text-align: right;
		padding: 0 0 12px;

		&.item-head {
			@include media ('>=desktop') {
				padding-right: 15px;
			}

			h4,
			h3 {
				@include media ('>=desktop') {
					margin-right: -15px;
				}
			}
		}

		h4,
		h3 {
			text-align: left;
		}

		.btn-link {
			margin-right: -5px;
		}

		.category-title {
			letter-spacing: 0.6px;
			margin-bottom: -1px;
		}
	}
	h3 {
		line-height: 1;
		margin: 0 0 4px;
		@include media ('>=desktop') {
			font-size: 42px;
			margin: 0 0 2px;
		}
	}

	h4 {
		font-size: 28px;
		line-height: 1;
		margin: 0 0 2px;
	}
}

.ads-post {
	@extend %clearfix;
	font-size: 12px;
	width: 100%;
	font-weight: 500;
	padding: 12px 0 4px;
	color: lighten($gray-base, 74.9%);
	border-bottom: 1px solid lighten($gray-base, 91.37%);

	@include media ('>=desktop') {
		padding: 12px 0 6px;
	}

	&.dots-1 {
		h5 {
			@include text-overflow;
		}
	}

	&.related {
		h5 {
			margin: 1px 0 8px;
			@include media ('>=desktop') {
				margin: 5px 0 7px;
			}
		}
	}

	.img-holder,
	.img-box {
		float: left;
		position: relative;
		margin: 0 10px 8px 0;
		width: 100px;
		@include media ('>=desktop') {
			margin: 0 16px 6px 0;
			width: 135px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}

		&:hover {
			.icon {
				color: $brand-warning;
			}
		}

		.icon {
			color: $white;
			position: absolute;
			top: 50%;
			left: 50%;
			transition: color .2s;
			transform: translate(-50%, -50%);
		}
	}

	.img-holder {
		width: 84px !important;
		margin: 0 16px 8px 0;
		@include media ('>=desktop') {
			margin: 0 16px 6px 0;
		}

		&.bordered-box {
			border: 1px solid lighten($gray-base, 74.9%);
		}
	}

	.category-title {
		padding: 2px 0 5px;
		letter-spacing: 1.2px;

		@include media ('>=desktop') {
			padding: 1px 0 2px;
		}
	}

	.text-wrapp,
	.text-box {
		overflow: hidden;
		margin-top: -3px;

		@include media ('>=desktop') {
			margin-top: -2px;
		}

		p {
			margin: 0;
		}

		time {
			display: block;
			padding: 2px 0 1px;
			@include media ('>=desktop') {
				padding: 2px 0 0;
			}
		}
	}

	.text-wrapp {
		padding-right: 15px;
		@include media ('>=desktop') {
			padding-right: 50px;
		}
	}

	h5 {
		font: 500 16px/1.25 $font-family-sans-serif;
		margin: 0 0 4px;
		color: $gray-base;
		@include media ('>=desktop') {
			margin: 5px 0 4px;
		}
		a {
			color: inherit;
			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	.details {
		margin: 0 0 2px;
		font-size: 11px;
		padding-left: 1px;

		dt, dd {
			line-height: 1.66;
		}
	}

	.price {
		font: 700 20px/1.2 $font-family-serif;
		color: $brand-primary;
		display: block;

		.sup {
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			padding-top: 2px;
		}
	}

	.disclosed {
		display: block;
		text-transform: uppercase;
		font-weight: 900;
		color: $brand-primary;
		font-size: 10px;
		line-height: 1;
		padding: 4px 0;
	}

	.number {
		font-size: 16px;
		line-height: 1;
		font-weight: 900;
		text-transform: uppercase;
		color: $gray-base;
		display: block;
		margin-top: -2px;
		padding: 0 0 16px;
	}

	.date {
		display: block;
		color: $gray-light;
		text-transform: uppercase;
		font-size: 10px;
		line-height: 1;
		font-weight: 500;
	}

	.link-box {
		@include media ('>=desktop') {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		a {
			font-size: 10px;
			color: $gray-base;
			display: none;
			margin-top: 7px;

			&:hover {
				text-decoration: none;
				color: $link-color;
			}
			@include media ('>=desktop') {
				display: inline-block;
			}
		}
	}

	.info-tag {
		color: lighten($gray-base, 74.9%);
		font-size: 10px;
		padding-top: 2px;
		letter-spacing: 1px;
		overflow: hidden;
		margin: 0 0 3px;
		font-weight: 700;

		@include media ('>=desktop') {
			margin: 0 0 5px;
		}

		li {
			padding: 0 9px 0 0;
			position: relative;
			&:before {
				content: '';
				width: 3px;
				height: 3px;
				background: lighten($gray-base, 74.9%);
				border-radius: 50%;
				position: absolute;
				top: 42%;
				left: -8px;
				transform: translateY(-50%);
			}
		}
	}
}
