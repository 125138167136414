@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans-100-webfont.woff') format('woff'),
         url('../fonts/museosans-100-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;

}
@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans-100italic-webfont.woff') format('woff'),
         url('../fonts/museosans-100italic-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;

}
@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans-300-webfont.woff') format('woff'),
         url('../fonts/museosans-300-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;

}
@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans-300italic-webfont.woff') format('woff'),
         url('../fonts/museosans-300italic-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;

}
@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans_500-webfont.woff') format('woff'),
         url('../fonts/museosans_500-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;

}
@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans_500_italic-webfont.woff') format('woff'),
         url('../fonts/museosans_500_italic-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;

}
@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans_700-webfont.woff') format('woff'),
         url('../fonts/museosans_700-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;

}
@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans-700italic-webfont.woff') format('woff'),
         url('../fonts/museosans-700italic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;

}
@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans_900-webfont.woff') format('woff'),
         url('../fonts/museosans_900-webfont.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;

}
@font-face {
    font-family: 'Museo_sans';
    src: url('../fonts/museosans-900italic-webfont.woff') format('woff'),
         url('../fonts/museosans-900italic-webfont.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}



@font-face {
  font-family: 'Kepler';
  src: url('../fonts/KeplerStd-LightDisp.woff') format('woff'),
       url('../fonts/KeplerStd-LightDisp.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Kepler';
  src: url('../fonts/KeplerStd-Disp.woff') format('woff'),
       url('../fonts/KeplerStd-Disp.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Kepler';
  src: url('../fonts/KeplerStd-MediumDisp.woff') format('woff'),
       url('../fonts/KeplerStd-MediumDisp.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Kepler';
  src: url('../fonts/KeplerStd-SemiboldDisp.woff') format('woff'),
       url('../fonts/KeplerStd-SemiboldDisp.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Kepler';
  src: url('../fonts/KeplerStd-BoldDisp.woff') format('woff'),
       url('../fonts/KeplerStd-BoldDisp.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Kepler';
  src: url('../fonts/KeplerStd-BlackDisp.woff') format('woff'),
       url('../fonts/KeplerStd-BlackDisp.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
