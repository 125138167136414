.annonces-subfooter {
	margin: 0 12px;
	padding: 20px 20px 0;
	border-bottom: solid 1px $grey;
	@include media('>=desktop') {
		padding-left: 0;
		padding-right: 0; }
	.item {
		padding-top: 30px;
		@include media('>=desktop') {
			padding-top: 50px; } }
	.picto {
		text-align: center;
		@include media('>=desktop') {
			text-align: left;
			padding-left: 14px; }
		@include media('>=widescreen') {
			padding-left: 0; } }

	.details {
		text-align: center;
		@include media('>=desktop') {
			text-align: left;
			padding-left: 14px; }
		.h4 {
			font-size: rem(22);
			margin-top: 10px;
			margin-bottom: 15px;
			@include media('>=desktop') {
				margin-top: 5px;
				margin-bottom: 5px; } }
		p {
			font-size: rem(14);
			line-height: em(20, 14);
			color: $gray-light;
			padding: 0 5px;
			@include media('>=desktop') {
				padding: 0 10px 0 0; } } } }

.banner-box {
	margin: 20px 0;
	@include media('>=tablet') {
		margin: 28px auto 50px !important; }
	@include media('>=desktop') {
		margin: 28px auto 45px !important; } }

