.top-bar {
	padding: 14px 0;
	background: $brand-primary;
	margin-bottom: 80px;

	.header-text {
		display: inline-block;
		vertical-align: middle;
		padding-left: 20px;
		color: $white;
		font-size: 24px;
	}
}
.logo {
	width: 98px;
	display: inline-block;
	vertical-align: middle;

	img {
		display: block;
		width: 100%;
	}
}