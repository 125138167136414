.footer {
	font-weight: 500;

	&.item-footer {
		.top-panel {
			@include media ('>=tablet') {
				padding: 21px 0 20px;
			}
			@include media ('>=desktop') {
				padding: 22px 0 19px;
			}
			.holder-box {
				@include media ('>=tablet') {
					width: 20%;
				}
				@include media ('>=desktop') {
					width: 47%;
					padding-bottom: 4px;
				}
				.btn-top {
					@include media ('>=desktop') {
						margin-left: 0;
					}
				}
			}
		}

		.logo-box {
			margin: 6px 0 3px;
			@include media ('>=tablet') {
				margin: 0;
				max-width: none;
				width: 70%;
				max-width: 530px;
			}
			@include media ('>=desktop') {
				width: 53%;
				max-width: 540px;
			}
			.footer-logo {
				width: 230px;
				@include media ('>=tablet') {
					width: 250px;
					margin-right: 10px;
				}
			}

			.sub-text {
				display: none;

				@include media ('>=tablet') {
					display: inline-block;
					letter-spacing: 0.4px;
					line-height: 1.5;
					padding: 2px 0;
				}
			}
		}

		.col-frame {
			.col {
				&:first-child {
					@include media ('>=desktop') {
						width: 33%;
					}
				}
				&:first-child + .col {
					@include media ('>=desktop') {
						width: 42%;
						padding: 0 0 0 9px;
					}
				}

				&:last-child {
					@include media ('>=desktop') {
						width: 25%;
						padding: 0 0 0 10px;
					}
				}
			}
		}

		.social-links {
			@include media ('>=tablet') {
				margin-top: 13px;
			}
		}

		.footer-box {
			@include media ('>=desktop') {
				text-align: center;
			}
		}

		.footer-subscribe-box {
			@include media ('>=tablet') {
				padding-top: 13px;
			}
			@include media ('>=desktop') {
				padding-top: 19px;
			}
			p {
				@include media ('>=desktop') {
					margin-bottom: 21px;
				}
			}
			h4 {
				@include media ('>=desktop') {
					margin-bottom: 2px;
				}
			}
		}
	}

	.top-panel {
		border-top: 1px solid lighten($gray-base, 85.49%);
		border-bottom: 1px solid lighten($gray-base, 85.49%);
		padding: 13px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@include media ('>=tablet') {
			padding: 15px 0;
		}

		.holder-box {
			@include media ('>=tablet') {
				width: 70%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			.btn {
				min-width: 138px;
				display: none;
				@include media ('>=desktop') {
					display: inline-block;
					margin-right: 16px;
				}
				@include media ('>=widescreen') {
					margin-right: 32px;
				}
			}

			h5 {
				font-size: 20px;
				font-weight: bold;
				margin: 0;
				display: none;
				@include media ('>=desktop') {
					display: inline-block;
					max-width: 200px;
					margin: 0 13px 4px 0;
					text-align: right;
				}
				@include media ('>=widescreen') {
					max-width: none;
				}
			}
		}

		.btn-top {
			margin-left: 16px;
		}
	}

	.logo-box {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 12px;
		width: 80%;

		@include media ('>=tablet') {
			width: 48%;
			max-width: 304px;
		}

		@include media ('>=desktop') {
			width: 30%;
		}

		.footer-logo {
			display: inline-block;
			margin-right: 17px;
			width: 90px;
			@include media ('>=tablet') {
				margin-right: 20px;
			}
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		.sub-text {
			display:inline-block;
			font-size: 10px;
			line-height: 1.24;
			padding: 4px 0 2px;
			letter-spacing: 0.6px;
			@include media ('>=tablet') {
				font-size: 11px;
				line-height: 1.272;
			}

			em {
				font-style: normal;
				display: block;

				@include media ('>=tablet') {
					display: inline;
				}
			}
		}
	}

	.col-frame {
		border-bottom: 1px solid lighten($gray-base, 85.49%);
		padding: 19px 0 16px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: stretch;

		@include media ('>=tablet') {
			padding: 36px 0 27px;
			flex-direction: row;
			flex-wrap: wrap;
		}

		@include media ('>=desktop') {
			flex-wrap: nowrap;
			padding-top: 33px;
			padding: 30px 0 18px;
		}

		.col {
			width: 100%;

			@include media ('>=tablet') {
				width: 52%;
			}
			@include media ('>=desktop') {
				padding-top: 5px;
			}
			&:first-child {
				@include media ('>=tablet') {
					width: 46%;
				}

				@include media ('>=desktop') {
					width: 29%;
					padding-right: 3px;
				}
			}
			&:first-child + .col {
				order: -1;
				position: relative;
				margin-bottom: -15px;
				@include media ('>=tablet') {
					order: 0;
					margin-bottom: 0;
				}
				@include media ('>=desktop') {
					width: 46%;
					padding: 0 10px 0 34px;
				}
			}

			&:last-child {
				@include media ('>=tablet') {
					width: 100%;
				}
				@include media ('>=desktop') {
					width: 25%;
				}
			}
		}
	}

	.footer-box {
		padding: 13px 0;
		font-size: 11px;
		text-align: center;

		@include media ('>=tablet') {
			font-size: 12px;
			text-align: left;
			padding: 33px 0;
		}

		p {
			margin: 0;

			a {
				color: inherit;
				&:hover {
					text-decoration:none;
					color: $link-color;
				}
			}
		}

		.by {
			color: #737373;
			display: inline-block;

			a {
				font: {
					family: Georgia, "Times New Roman", Times, serif;
					weight: 700;
					style: italic;
				}
			}
		}

		.copyright {
			display: block;

			@include media ('>=tablet') {
				display: inline;
			}
		}
	}

	.social-links {
		margin: 0 0 7px;
		display: inline-block;
		vertical-align: top;
		max-width: 50%;
		position: relative;
		z-index: 4;

		@include media ('>=tablet') {
			margin-top: 29px;
			margin-bottom: 0;
		}
		@include media ('>=desktop') {
			margin-top: 40px;
		}
		li {
			padding: 0 4px 10px 0;
			margin: 0;
		}

		a {
			i {
				&:before {
					font-size: 15px;

					@include media ('>=tablet') {
						font-size: 16px;
					}
				}
			}
		}
	}

	.footer-link {
		font-size: 12px;
		line-height: 23px;
		text-transform: uppercase;
		letter-spacing: 0.6px;
		margin: 0 -0.4em 0 0;
		display: inline-block;
		vertical-align: top;
		width: 49%;

		&:first-child {
			width: 51%;
		}

		li {
			display: inline-block;
			vertical-align: top;
			width: 100%;
			padding: 0 10px 0 0;
		}

		a {
			color: $gray-base;

			&:hover {
				text-decoration: none;
				color: $link-color;
			}
		}
	}
}

.footer-subscribe-box {
	font-size: 11px;
	color: $gray-light;

	@include media ('>=tablet') {
		font-size: 12px;
		padding-top: 6px;
	}

	@include media ('>=desktop') {
		padding-top: 14px;
	}

	h4 {
		font-size: 18px;
		color: $gray-base;
		font-weight: 700;
		margin-bottom: 9px;
		margin-top: 0;
		@include media ('>=tablet') {
			font-size: 20px;
			margin-bottom: 18px;
		}
	}

	.holder-box {
		@include media ('>=tablet') {
			display: inline-block;
			vertical-align: middle;
			width: 36.2%;
		}

		@include media ('>=desktop') {
			width: 100%;
			display: block;
		}
	}

	.footer-subscribe-form {
		@include media ('>=tablet') {
			width: 61%;
			display: inline-block;
			vertical-align: middle;
			margin-top: 2px;
		}
		@include media ('>=desktop') {
			width: 100%;
			margin-top: 0;
		}
	}

	p {
		margin: 0 0 8px;
		@include media ('>=desktop') {
			margin: 0 0 23px;
		}

		span {
			display: none;
			@include media ('>=desktop') {
				display: inline;
			}
		}
	}
}

.footer-subscribe-form {
	border: 1px solid $gray-base;
	border-radius: $border-radius-small;

	.form-control {
		border: none;
		height: 32px;
		padding: 8px 14px;
		letter-spacing: 0.3px;
		font-size: 11px;

		@include media ('>=tablet') {
			height: 36px;
			padding: 8px 14px;
			font-size: 12px;
		}

		@include media ('>=desktop', '<1100px') {
			padding: 8px 7px;
		}
	}

	.btn {
		white-space: nowrap;
		letter-spacing: 1px;
		padding: 8px;
		height: 32px;
		font-size: 11px;

		@include media ('>=tablet') {
			height: 36px;
			padding: 10px 8px;
			letter-spacing: 2px;
		}
	}
}

.footer-nav {
	display: inline-block;
	ul {
		font-size: 13px;
		margin: 0;
		li {
			padding: 0 20px 4px 0;

			@include media ('>=widescreen') {
				padding: 0 38px 4px 0;
			}
		}

		a {
			color: $gray-base;
			letter-spacing: 0.5px;

			&:hover {
				text-decoration:none;
				color: $link-color;
			}
		}
	}
}