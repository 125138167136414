.pagination {
	width: 100%;
	text-align: center;
	font-weight: 500;
	border: 1px solid lighten($gray-base, 91.3725%);
	padding: 6px;
	border-radius: 0;
	font-size: 16px;

	> li {
		display: inline-block;
		vertical-align: top;

		&.active {
			a {
				pointer-events: none;
				background: none;
				color: $brand-primary;
				font-weight: bold;
			}
		}
		&.next,
		&.previous {
			float: left;
			border-right: 1px solid lighten($gray-base, 74.9%);
			a {
				width: 42px;
				font-size: 9px;
				padding: 15px 12px 11px 8px;

				@include media ('>=tablet') {
					width: 66px;
					padding: 20px 14px 16px 10px;
				}
			}
		}
		&.next {
			float: right;
			border-right: 0;
			border-left: 1px solid lighten($gray-base, 74.9%);
			a {
				padding: 15px 10px 11px 12px;

				@include media ('>=tablet') {
					padding: 20px 8px 16px 12px;
				}
			}
		}
		> a {
			margin-left: 0;
			background: none;
			border: none;
			line-height: 1.3;
			padding: 10px 8px 9px 9px;
			transition: color .2s;

			@include media ('>=tablet') {
				padding: 15px 12px 14px 13px;
			}

			&:hover {
				background: none;
			}

			&:focus,
			&:active {
				background: none;
				color: $gray-light;
			}
		}
		> span {
			margin-left: 0;
			border: none;
			line-height: 1.3;
			padding: 10px 8px 9px;

			@include media ('>=tablet') {
				padding: 15px 104px 14px 10px;
			}

			&:hover {
				color: $gray-light;
				background: none;
			}
		}
	}
}

.pager {
	&.pager-sm {
		li {
			> a {
				border: none;
				background: none;
				font-size: 9px;
				line-height: 1;
				padding: 2px 3px;
				width: auto;
				height: auto;
				margin: 0;
				transition: color .2s;

				&:hover {
					background: none;
					color: $brand-warning;
				}
			}
		}
	}
	li {
		display: inline-block;
		vertical-align: middle;
		> a {
			border-width: 2px;
			color: $gray-base;
			width: 42px;
			height: 42px;
			font-size: 10px;
			line-height: 20px;
			padding: 10px;
			margin: 0 10px;
			display: block;
			transition: color .2s, border-color .2s, background .2s;
			&:hover {
				background: $brand-warning;
				color: $white;
				border-color: $brand-warning;
			}

			&:active,
			&:focus {
				background: none;
				color: $gray-base;
				border-color: $gray-base;
			}
		}
	}
}