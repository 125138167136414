.btn {
	text-transform: uppercase;
	font-size: 11px;
	line-height: 1;
	letter-spacing: 1px;
	padding: 11px 10px;
	white-space: normal;
	transition: background .2s, border-color .2s;
	@include media ('>=tablet') {
		font-size: 12px;
		padding: 12px;
	}

	&.btn-default {
		border-color: $gray-light;
		background: $gray-light;
		&.disabled:active,
		&[disabled]:active,
		&.disabled,
		&[disabled],
		&:hover {
			background: lighten($gray-light, 13.3%);
			border-color: lighten($gray-light, 13.3%);
		}
		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			border-color: $gray-light;
			background: $gray-light;
		}

		&.btn-border {
			color: $gray-light;
		}
	}

	&.btn-warning {
		border-color: $brand-warning;
		&.disabled:active,
		&[disabled]:active,
		&.disabled,
		&[disabled],
		&:hover {
			border-color: lighten(saturate(adjust-hue($brand-warning, -0.0670), 0.0000), 14.9020);
			background: lighten(saturate(adjust-hue($brand-warning, -0.0670), 0.0000), 14.9020);
		}
		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			border-color: $brand-warning;
			background: $brand-warning;
		}

		&.btn-border {
			color: $brand-warning;
		}
	}

	&.btn-primary {
		border-color: $brand-primary;
		&.disabled:active,
		&[disabled]:active,
		&.disabled,
		&[disabled],
		&:hover {
			border-color: lighten(desaturate(adjust-hue($brand-primary, -1.0557), 25.1429), 21.1765);
			background: lighten(desaturate(adjust-hue($brand-primary, -1.0557), 25.1429), 21.1765);
		}
		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			border-color: $brand-primary;
			background: $brand-primary;
		}

		&.btn-border {
			color: $brand-primary;
		}
	}

	&.btn-info {
		border-color: $brand-info;
		&.disabled:active,
		&[disabled]:active,
		&.disabled,
		&[disabled],
		&:hover {
			border-color: lighten($brand-info, 13.3%);
			background: lighten($brand-info, 13.3%);
		}
		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			border-color: $brand-info;
			background: $brand-info;
		}

		&.btn-border {
			color: $brand-info;
		}
	}

	&.btn-success {
		border-color: $brand-success;
		&.disabled:active,
		&[disabled]:active,
		&.disabled,
		&[disabled],
		&:hover {
			border-color: lighten(desaturate(adjust-hue($brand-success, -0.0376), 2.5443), 15.0980);
			background: lighten(desaturate(adjust-hue($brand-success, -0.0376), 2.5443), 15.0980);
		}
		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			border-color: $brand-success;
			background: $brand-success;
		}

		&.btn-border {
			color: $brand-success;
		}
	}

	&.btn-danger {
		border-color: $brand-danger;
		&.disabled:active,
		&[disabled]:active,
		&.disabled,
		&[disabled],
		&:hover {
			border-color: lighten(desaturate(adjust-hue($brand-danger, -0.1140), 0.1601), 12.7451);
			background: lighten(desaturate(adjust-hue($brand-danger, -0.1140), 0.1601), 12.7451);
		}
		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			border-color: $brand-danger;
			background: $brand-danger;
		}

		&.btn-border {
			color: $brand-danger;
		}
	}

	&.btn-link {
		font-weight: 700;
		color: $gray-base;
		letter-spacing: 0.3px;
		transition: color .2s;
		padding: 5px;
		text-align: right;
		&.disabled:active,
		&[disabled]:active,
		&.disabled,
		&[disabled],
		&:hover {
			text-decoration: none;
			color: lighten($gray-base, 29.8%);
		}
		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			text-decoration: none;
			color: $gray-base;
		}

		&.pull-right {
			padding-right: 0;
			margin-right: -1px;
		}

		[class^="icon-"], [class*=" icon-"] {
			margin-left: 2px;
			@include media ('>=tablet') {
				margin-left: 6px;
			}
		}
	}

	&.btn-border {
		background: none !important;
	}

	&.btn-lg {
		padding: 14px 12px;
		line-height: 1.5;
	}

	&.btn-sm {
		padding: 7px 12px;
	}

	&.btn-xs {
		font-size: 9px;
		padding: 8px 6px 7px;
		@include media ('>=tablet') {
			font-size: 10px;
			padding: 8px 6px;
		}
	}

	&.btn-dark.btn-border,
	&.btn-dark {
		color: $gray-base;

		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			color: $gray-base;
		}
	}

	&.disabled,
	&[disabled] {
		cursor: default;
	}
	
	&.active,
	&:active {
		background: inherit;
		box-shadow: none;
	}
}

.btn-arrow {
	background: none;
	border: none;
	color: $gray-base;
	font-size: 9px;
	line-height: 1;
	padding: 2px;
	transition: color .2s;
	
	&.disabled:active,
	&[disabled]:active,
	&.disabled,
	&[disabled],
	&:hover {
		color: $brand-warning;
		background: none;
	}

	&:focus,
	&.active:focus,
	&:active:focus,
	&.active,
	&:active {
		color: $gray-base;
	}
}

.btn-top {
	display: inline-block;
	vertical-align: middle;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background: $brand-primary;
	text-align: center;
	padding: 11px;
	font-size: 13px;
	color: $white;
	text-decoration: none;
	transition: background .2s;

	@include media ('>=tablet') {
		width: 48px;
		height: 48px;
		padding: 15px;
	}

	&:hover {
		text-decoration: none;
		background: $brand-warning;
		color: $white;
	}

	&:focus,
	&:active {
		text-decoration: none;
		color: $white;
		background: $brand-primary;
	}

	i {
		line-height: 18px;
		&:before {
			@include media ('>=tablet') {
				font-size: 14px;
			}
		}
	}
}

.btn-slide {
	background: none;
	border: 2px solid $gray-base;
	padding: 9px;
	width: 38px;
	height: 38px;
	line-height: 18px;
	transition: background .2s, border-color .2s, color .2s;

	@include media ('>=tablet') {
		width: 42px;
		height: 42px;
		padding: 10px;
	}

	&.disabled:active,
	&[disabled]:active,
	&.disabled,
	&[disabled],
	&:hover {
		background: $brand-warning;
		border-color: $brand-warning;
		color: $white;
	}
	&:focus,
	&.active:focus,
	&:active:focus,
	&.active,
	&:active {
		border-color: $gray-base;
		background: none;
		color: $gray-base;
	}
}

.social-networks {
	@extend %listreset;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 10px;

	li {
		display: inline-block;
		vertical-align: middle;
		margin: 0 27px 10px 0;

		&.facebook {
			a {
				&:hover {
					color: #3c5b9a;
				}
			}
		}
		&.twitter {
			a {
				&:hover {
					color: $brand-info;
				}
			}
		}
	}

	a {
		color: $gray-base;
		text-decoration: none;
		transition: color .2s;

		&:hover {
			color: $brand-warning;
		}

		&:focus,
		&:active {
			color: $gray-base;
		}

		i {
			display: inline-block;
			vertical-align: middle;
			font-size: 16px;
			margin-right: 5px;
			margin-top: -4px;
			
			&.icon-facebook {
				font-size: 16px;
			}
			&.icon-twitter {
				font-size: 14px;
			}
			&.icon-email {
				font-size: 12px;
			}
			&.icon-print {
				font-size: 14px;
			}
		}
	}
}

.btn-group {
	&.btn-group-alt {
		margin: 0 -5px;
		.btn {
			margin: 0 5px 10px;
			border-radius: $border-radius-small !important;
		}
	}
}

.btn-social {
	border-color: $brand-warning;
	color: $brand-warning;
	background: transparent;
	font-size: 10px;
	padding: 13px 12px 10px;
	min-width: 123px;
	height: 38px;
	letter-spacing: 0.5px;
	transition: background .2s, color .2s, border-color .2s;

	&.btn-facebook,
	&.btn-twitter {
		border-color: $brand-info;
		color: $brand-info;

		&:hover {
			background: $brand-info;
			color: $white;
		}

		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			border-color: $brand-info;
			background: transparent;
			color: $brand-info;
		}

		i {
			font-size: 13px;
		}
	}

	&.btn-facebook {
		border-color: $primary-light;
		color: $primary-light;

		&:hover {
			background: $primary-light;
		}

		&:focus,
		&.active:focus,
		&:active:focus,
		&.active,
		&:active {
			border-color: $primary-light;
			color: $primary-light;
		}

		i {
			font-size: 16px;
			width: 12px;
		}
	}

	&:hover {
		background: $brand-warning;
	}

	&:focus,
	&.active:focus,
	&:active:focus,
	&.active,
	&:active {
		border-color: $brand-warning;
		background: transparent;
		color: $brand-warning;
	}

	i {
		width: 16px;
		height: 16px;
		font-size: 11px;
		line-height: 16px;
		display: inline-block;
		vertical-align: middle;
		margin:-3px 8px -1px 0;
	}
}

.social-link {
	color: $gray-base;
	text-decoration: none;
	transition: color .2s;
	display: inline-block;
	vertical-align: middle;

	&.social-sm {
		font-size: 16px;
	}

	&.facebook-link {
		&:hover {
			color: #3c5b9a;
		}
		&:active {
			color: $gray-base;
		}
	}
	&.twitter-link {
		&:hover {
			color: #2eabe1;
		}
		&:active {
			color: $gray-base;
		}
	}

	&:hover {
		color: $gray-light;
		text-decoration: none;
	}

	&:active {
		color: $gray-base;
	}

	i {
		display: inline-block;
		vertical-align: top;
	}
}

.social-links {
	margin: 0 0 20px;

	li {
		line-height: 1;
		margin-bottom: 15px;
	}
}