$grey: #e9e9e9;


//Components
@import "components/form";
//Layout
@import "layout/header";
@import "layout/footer";
//Pages
@import "pages/home";
@import "pages/annoncedetails";

//Modules
@import "modules/lastpublished";
@import "modules/annoncesune";
