@charset "UTF-8";
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?4gyhg2");
  src: url("../fonts/icomoon.eot?4gyhg2#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?4gyhg2") format("truetype"), url("../fonts/icomoon.woff?4gyhg2") format("woff"), url("../fonts/icomoon.svg?4gyhg2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
  content: "\e948";
}

.icon-chat-1:before {
  content: "\e910";
}

.icon-check-1:before {
  content: "\e912";
}

.icon-lock-circle:before {
  content: "\e947";
}

.icon-top-circle:before {
  content: "\e90d";
}

.icon-btn-top:before {
  content: "\e908";
}

.icon-chevron-down:before {
  content: "\e93f";
}

.icon-chevron-l:before {
  content: "\e940";
}

.icon-chevron-top:before {
  content: "\e941";
}

.icon-chevron-r:before {
  content: "\e915";
}

.icon-full:before {
  content: "\e942";
}

.icon-reduce:before {
  content: "\e945";
}

.icon-pause:before {
  content: "\e943";
}

.icon-play:before {
  content: "\e944";
}

.icon-stop:before {
  content: "\e946";
}

.icon-amazon:before {
  content: "\e900";
}

.icon-anchor:before {
  content: "\e901";
}

.icon-android:before {
  content: "\e902";
}

.icon-apple:before {
  content: "\e903";
}

.icon-arrow-b:before {
  content: "\e904";
}

.icon-arrow-l:before {
  content: "\e905";
}

.icon-arrow-r:before {
  content: "\e906";
}

.icon-arrow-t:before {
  content: "\e907";
}

.icon-btn-play-lg:before {
  content: "\e909";
}

.icon-btn-play-sm:before {
  content: "\e90a";
}

.icon-btn-plus:before {
  content: "\e90b";
}

.icon-btn-plus1:before {
  content: "\e90c";
}

.icon-cart:before {
  content: "\e90e";
}

.icon-chat:before {
  content: "\e90f";
}

.icon-check:before {
  content: "\e911";
}

.icon-check-lg:before {
  content: "\e913";
}

.icon-chevron-b:before {
  content: "\e914";
}

.icon-close:before {
  content: "\e916";
}

.icon-download:before {
  content: "\e917";
}

.icon-email:before {
  content: "\e918";
}

.icon-email-1:before {
  content: "\e919";
}

.icon-email-2:before {
  content: "\e91a";
}

.icon-eye:before {
  content: "\e91b";
}

.icon-facebook:before {
  content: "\e91c";
}

.icon-twitter:before {
  content: "\e93b";
}

.icon-facebook-square:before {
  content: "\e91d";
}

.icon-instagram-square:before {
  content: "\e923";
}

.icon-twitter-square:before {
  content: "\e93c";
}

.icon-rss-square:before {
  content: "\e937";
}

.icon-globe:before {
  content: "\e91e";
}

.icon-group:before {
  content: "\e91f";
}

.icon-group-1:before {
  content: "\e920";
}

.icon-img:before {
  content: "\e921";
}

.icon-info:before {
  content: "\e922";
}

.icon-label:before {
  content: "\e924";
}

.icon-like:before {
  content: "\e925";
}

.icon-lock:before {
  content: "\e926";
}

.icon-lock-1:before {
  content: "\e927";
}

.icon-marker:before {
  content: "\e928";
}

.icon-notebook:before {
  content: "\e929";
}

.icon-notes:before {
  content: "\e92a";
}

.icon-notes-1:before {
  content: "\e92b";
}

.icon-oliver:before {
  content: "\e92c";
}

.icon-pencil:before {
  content: "\e92d";
}

.icon-pencil-circle:before {
  content: "\e92e";
}

.icon-phone:before {
  content: "\e92f";
}

.icon-photo:before {
  content: "\e930";
}

.icon-pin:before {
  content: "\e931";
}

.icon-plus:before {
  content: "\e932";
}

.icon-print:before {
  content: "\e933";
}

.icon-recend:before {
  content: "\e934";
}

.icon-reload:before {
  content: "\e935";
}

.icon-rope:before {
  content: "\e936";
}

.icon-search:before {
  content: "\e938";
}

.icon-settings:before {
  content: "\e939";
}

.icon-truck:before {
  content: "\e93a";
}

.icon-user:before {
  content: "\e93d";
}

.icon-user-1:before {
  content: "\e93e";
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans-100-webfont.woff") format("woff"), url("../fonts/museosans-100-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans-100italic-webfont.woff") format("woff"), url("../fonts/museosans-100italic-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans-300-webfont.woff") format("woff"), url("../fonts/museosans-300-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans-300italic-webfont.woff") format("woff"), url("../fonts/museosans-300italic-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans_500-webfont.woff") format("woff"), url("../fonts/museosans_500-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans_500_italic-webfont.woff") format("woff"), url("../fonts/museosans_500_italic-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans_700-webfont.woff") format("woff"), url("../fonts/museosans_700-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans-700italic-webfont.woff") format("woff"), url("../fonts/museosans-700italic-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans_900-webfont.woff") format("woff"), url("../fonts/museosans_900-webfont.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Museo_sans';
  src: url("../fonts/museosans-900italic-webfont.woff") format("woff"), url("../fonts/museosans-900italic-webfont.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Kepler';
  src: url("../fonts/KeplerStd-LightDisp.woff") format("woff"), url("../fonts/KeplerStd-LightDisp.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Kepler';
  src: url("../fonts/KeplerStd-Disp.woff") format("woff"), url("../fonts/KeplerStd-Disp.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Kepler';
  src: url("../fonts/KeplerStd-MediumDisp.woff") format("woff"), url("../fonts/KeplerStd-MediumDisp.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Kepler';
  src: url("../fonts/KeplerStd-SemiboldDisp.woff") format("woff"), url("../fonts/KeplerStd-SemiboldDisp.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Kepler';
  src: url("../fonts/KeplerStd-BoldDisp.woff") format("woff"), url("../fonts/KeplerStd-BoldDisp.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Kepler';
  src: url("../fonts/KeplerStd-BlackDisp.woff") format("woff"), url("../fonts/KeplerStd-BlackDisp.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* custom checkbox styles */
.jcf-checkbox {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  overflow: hidden;
  cursor: default;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  margin: 0 3px 0 0;
  height: 20px;
  width: 20px;
}

@media (min-width: 768px) {
  .jcf-checkbox {
    height: 24px;
    width: 24px;
  }
}

.jcf-checkbox span {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.jcf-checkbox span:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  margin: -3px 0 0 -3px;
  content: "\e911";
  font-family: 'icomoon' !important;
  font-size: 8px;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .jcf-checkbox span:before {
    font-size: 10px;
    line-height: 1.5;
    margin: -5px 0 0 -5px;
  }
}

:root .jcf-checkbox span {
  margin: -5px 0 0 -5px;
}

.jcf-checkbox input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  left: 0;
  top: 0;
}

.jcf-checkbox.jcf-checked span {
  display: block;
}

/* custom radio styles */
.jcf-radio {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  margin: 0 3px 0 0;
  height: 20px;
  width: 20px;
}

@media (min-width: 768px) {
  .jcf-radio {
    width: 24px;
    height: 24px;
  }
}

.jcf-radio span {
  display: none;
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background: #2a3768;
  border-radius: 100%;
}

.jcf-radio input[type="radio"] {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
}

.jcf-radio.jcf-checked span {
  display: block;
}

/* custom select styles */
.jcf-select {
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  background: #fff;
  margin: 0 0 10px;
  min-width: 150px;
  height: 44px;
  width: 100%;
}

@media (min-width: 768px) {
  .jcf-select {
    height: 48px;
  }
}

.jcf-select select {
  z-index: 1;
  left: 0;
  top: 0;
}

.jcf-select .jcf-select-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  display: block;
  font: 900 11px/1.5 "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 50px 0 16px;
  padding: 13px 0;
}

@media (min-width: 768px) {
  .jcf-select .jcf-select-text {
    font-size: 12px;
    margin: 0 50px 0 18px;
    padding: 14px 0;
  }
}

.jcf-select .jcf-select-text .jcf-option-hideme {
  color: #b0b0b0;
}

.jcf-select .jcf-select-opener {
  position: absolute;
  text-align: center;
  cursor: pointer;
  background: #fff;
  width: 44px;
  bottom: 0;
  right: 0;
  top: 0;
  border-radius: 0 4px 4px 0;
  color: #000;
}

.jcf-select .jcf-select-opener:before {
  content: "\e914";
  font-family: 'icomoon' !important;
  line-height: 1;
  font-size: 5px;
  line-height: 1;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 19px;
  left: 16px;
}

@media (min-width: 768px) {
  .jcf-select .jcf-select-opener:before {
    top: 21px;
  }
}

body > .jcf-select-drop {
  position: absolute;
  margin: -1px 0 0;
  z-index: 9999;
}

body > .jcf-select-drop.jcf-drop-flipped {
  margin: 1px 0 0;
}

.jcf-select .jcf-select-drop {
  position: absolute;
  margin-top: 0px;
  z-index: 9999;
  top: 100%;
  left: -1px;
  right: -1px;
}

.jcf-select .jcf-drop-flipped {
  bottom: 100%;
  top: auto;
}

.jcf-select-drop .jcf-scrollbar-vertical {
  width: 0;
  z-index: 10;
}

.jcf-select-drop .jcf-scrollbar-vertical .jcf-scrollbar-handle {
  right: 9px;
}

.jcf-select-drop .jcf-select-drop-content {
  border: 1px solid #e9e9e9;
  box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);
}

.jcf-select-drop.jcf-drop-flipped .jcf-select-drop-content {
  box-shadow: -4px -4px 0 rgba(0, 0, 0, 0.1);
}

.jcf-select-drop .jcf-option-hideme {
  display: none !important;
}

/* multiple select styles */
.jcf-list-box {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #b8c3c9;
  min-width: 200px;
  margin: 0 15px;
}

/* select options styles */
.jcf-list {
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: #fff;
  font: 900 11px/1.5 "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
}

@media (min-width: 768px) {
  .jcf-list {
    font-size: 12px;
  }
}

.jcf-list .jcf-list-content {
  vertical-align: top;
  display: inline-block;
  overflow: auto;
  width: 100%;
}

.jcf-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.jcf-list ul li {
  display: block;
}

.jcf-list ul li:hover + li .jcf-option, .jcf-list ul li:hover .jcf-option {
  border-color: #ffcc00;
}

.jcf-list ul li:before {
  display: none !important;
}

.jcf-list ul li .jcf-option.jcf-selected {
  position: relative;
  z-index: 2;
  margin-bottom: -1px;
  padding-bottom: 16px;
}

.jcf-list ul li:first-child + li .jcf-option, .jcf-list ul li:first-child .jcf-option {
  border: none;
}

.jcf-list .jcf-overflow {
  overflow: auto;
}

.jcf-list .jcf-option {
  overflow: hidden;
  cursor: pointer;
  display: block;
  padding: 12px 16px 13px;
  color: #000;
  height: 1%;
  letter-spacing: .5px;
  border-top: 1px solid #e9e9e9;
  transition: background .1s, color .1s, border-color .1s;
}

@media (min-width: 768px) {
  .jcf-list .jcf-option {
    padding: 14px 13px 15px;
  }
}

.jcf-list .jcf-disabled {
  background: #fff !important;
  color: #aaa !important;
}

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
  background: #ffcc00;
  color: #fff;
  border-color: #ffcc00;
}

.jcf-list .jcf-optgroup-caption {
  white-space: nowrap;
  font-weight: bold;
  display: block;
  padding: 5px 9px;
  cursor: default;
  color: #000;
}

.jcf-list .jcf-optgroup .jcf-option {
  padding-left: 30px;
}

/* custom scrollbars styles */
.jcf-scrollable-wrapper {
  box-sizing: content-box;
  position: relative;
}

.jcf-scrollbar-vertical {
  position: absolute;
  cursor: pointer;
  background: none;
  width: 13px;
  bottom: 0;
  right: 0;
  top: 0;
}

.jcf-scrollbar-vertical .jcf-scrollbar-dec,
.jcf-scrollbar-vertical .jcf-scrollbar-inc {
  background: none;
  height: 10px;
  width: 10px;
  left: 0;
  top: 0;
}

.jcf-scrollbar-vertical .jcf-scrollbar-inc {
  top: auto;
  bottom: 0;
  height: 10px;
  width: 0;
}

.jcf-scrollbar-vertical .jcf-scrollbar-handle {
  background: rgba(0, 0, 0, 0.3);
  height: 1px;
  width: 5px;
  border-radius: 2px;
}

.jcf-scrollbar-horizontal {
  position: absolute;
  right: auto;
  top: auto;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 10px;
}

.jcf-scrollbar-horizontal .jcf-scrollbar-dec,
.jcf-scrollbar-horizontal .jcf-scrollbar-inc {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  background: #bbb;
  height: 0;
  width: 0;
}

.jcf-scrollbar-horizontal .jcf-scrollbar-inc {
  left: auto;
  right: 0;
}

.jcf-scrollbar-horizontal .jcf-scrollbar-slider {
  display: inline-block;
  position: relative;
  height: 14px;
}

.jcf-scrollbar-horizontal .jcf-scrollbar-handle {
  position: absolute;
  height: 4px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.jcf-scrollbar.jcf-inactive .jcf-scrollbar-handle {
  visibility: hidden;
}

.jcf-scrollbar.jcf-inactive .jcf-scrollbar-dec,
.jcf-scrollbar.jcf-inactive .jcf-scrollbar-inc {
  background: #e3e3e3;
}

/* common custom form elements styles */
.jcf-disabled {
  background: #ddd !important;
}

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  text-shadow: none;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("../images/fancybox/fancybox_sprite.png");
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../images/fancybox/fancybox_loading.gif") center center no-repeat;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../images/fancybox/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/1.2 "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

.top-bar {
  padding: 14px 0;
  background: #2a3768;
  margin-bottom: 80px;
}

.top-bar .header-text {
  display: inline-block;
  vertical-align: middle;
  padding-left: 20px;
  color: #fff;
  font-size: 24px;
}

.logo {
  width: 98px;
  display: inline-block;
  vertical-align: middle;
}

.logo img {
  display: block;
  width: 100%;
}

.footer {
  font-weight: 500;
}

@media (min-width: 768px) {
  .footer.item-footer .top-panel {
    padding: 21px 0 20px;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .top-panel {
    padding: 22px 0 19px;
  }
}

@media (min-width: 768px) {
  .footer.item-footer .top-panel .holder-box {
    width: 20%;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .top-panel .holder-box {
    width: 47%;
    padding-bottom: 4px;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .top-panel .holder-box .btn-top {
    margin-left: 0;
  }
}

.footer.item-footer .logo-box {
  margin: 6px 0 3px;
}

@media (min-width: 768px) {
  .footer.item-footer .logo-box {
    margin: 0;
    max-width: none;
    width: 70%;
    max-width: 530px;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .logo-box {
    width: 53%;
    max-width: 540px;
  }
}

.footer.item-footer .logo-box .footer-logo {
  width: 230px;
}

@media (min-width: 768px) {
  .footer.item-footer .logo-box .footer-logo {
    width: 250px;
    margin-right: 10px;
  }
}

.footer.item-footer .logo-box .sub-text {
  display: none;
}

@media (min-width: 768px) {
  .footer.item-footer .logo-box .sub-text {
    display: inline-block;
    letter-spacing: 0.4px;
    line-height: 1.5;
    padding: 2px 0;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .col-frame .col:first-child {
    width: 33%;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .col-frame .col:first-child + .col {
    width: 42%;
    padding: 0 0 0 9px;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .col-frame .col:last-child {
    width: 25%;
    padding: 0 0 0 10px;
  }
}

@media (min-width: 768px) {
  .footer.item-footer .social-links {
    margin-top: 13px;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .footer-box {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .footer.item-footer .footer-subscribe-box {
    padding-top: 13px;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .footer-subscribe-box {
    padding-top: 19px;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .footer-subscribe-box p {
    margin-bottom: 21px;
  }
}

@media (min-width: 1024px) {
  .footer.item-footer .footer-subscribe-box h4 {
    margin-bottom: 2px;
  }
}

.footer .top-panel {
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (min-width: 768px) {
  .footer .top-panel {
    padding: 15px 0;
  }
}

@media (min-width: 768px) {
  .footer .top-panel .holder-box {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.footer .top-panel .holder-box .btn {
  min-width: 138px;
  display: none;
}

@media (min-width: 1024px) {
  .footer .top-panel .holder-box .btn {
    display: inline-block;
    margin-right: 16px;
  }
}

@media (min-width: 1200px) {
  .footer .top-panel .holder-box .btn {
    margin-right: 32px;
  }
}

.footer .top-panel .holder-box h5 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  display: none;
}

@media (min-width: 1024px) {
  .footer .top-panel .holder-box h5 {
    display: inline-block;
    max-width: 200px;
    margin: 0 13px 4px 0;
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .footer .top-panel .holder-box h5 {
    max-width: none;
  }
}

.footer .top-panel .btn-top {
  margin-left: 16px;
}

.footer .logo-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  width: 80%;
}

@media (min-width: 768px) {
  .footer .logo-box {
    width: 48%;
    max-width: 304px;
  }
}

@media (min-width: 1024px) {
  .footer .logo-box {
    width: 30%;
  }
}

.footer .logo-box .footer-logo {
  display: inline-block;
  margin-right: 17px;
  width: 90px;
}

@media (min-width: 768px) {
  .footer .logo-box .footer-logo {
    margin-right: 20px;
  }
}

.footer .logo-box .footer-logo img {
  display: block;
  width: 100%;
  height: auto;
}

.footer .logo-box .sub-text {
  display: inline-block;
  font-size: 10px;
  line-height: 1.24;
  padding: 4px 0 2px;
  letter-spacing: 0.6px;
}

@media (min-width: 768px) {
  .footer .logo-box .sub-text {
    font-size: 11px;
    line-height: 1.272;
  }
}

.footer .logo-box .sub-text em {
  font-style: normal;
  display: block;
}

@media (min-width: 768px) {
  .footer .logo-box .sub-text em {
    display: inline;
  }
}

.footer .col-frame {
  border-bottom: 1px solid #dadada;
  padding: 19px 0 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
}

@media (min-width: 768px) {
  .footer .col-frame {
    padding: 36px 0 27px;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 1024px) {
  .footer .col-frame {
    flex-wrap: nowrap;
    padding-top: 33px;
    padding: 30px 0 18px;
  }
}

.footer .col-frame .col {
  width: 100%;
}

@media (min-width: 768px) {
  .footer .col-frame .col {
    width: 52%;
  }
}

@media (min-width: 1024px) {
  .footer .col-frame .col {
    padding-top: 5px;
  }
}

@media (min-width: 768px) {
  .footer .col-frame .col:first-child {
    width: 46%;
  }
}

@media (min-width: 1024px) {
  .footer .col-frame .col:first-child {
    width: 29%;
    padding-right: 3px;
  }
}

.footer .col-frame .col:first-child + .col {
  order: -1;
  position: relative;
  margin-bottom: -15px;
}

@media (min-width: 768px) {
  .footer .col-frame .col:first-child + .col {
    order: 0;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .footer .col-frame .col:first-child + .col {
    width: 46%;
    padding: 0 10px 0 34px;
  }
}

@media (min-width: 768px) {
  .footer .col-frame .col:last-child {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .footer .col-frame .col:last-child {
    width: 25%;
  }
}

.footer .footer-box {
  padding: 13px 0;
  font-size: 11px;
  text-align: center;
}

@media (min-width: 768px) {
  .footer .footer-box {
    font-size: 12px;
    text-align: left;
    padding: 33px 0;
  }
}

.footer .footer-box p {
  margin: 0;
}

.footer .footer-box p a {
  color: inherit;
}

.footer .footer-box p a:hover {
  text-decoration: none;
  color: #2eabe1;
}

.footer .footer-box .by {
  color: #737373;
  display: inline-block;
}

.footer .footer-box .by a {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 700;
  font-style: italic;
}

.footer .footer-box .copyright {
  display: block;
}

@media (min-width: 768px) {
  .footer .footer-box .copyright {
    display: inline;
  }
}

.footer .social-links {
  margin: 0 0 7px;
  display: inline-block;
  vertical-align: top;
  max-width: 50%;
  position: relative;
  z-index: 4;
}

@media (min-width: 768px) {
  .footer .social-links {
    margin-top: 29px;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .footer .social-links {
    margin-top: 40px;
  }
}

.footer .social-links li {
  padding: 0 4px 10px 0;
  margin: 0;
}

.footer .social-links a i:before {
  font-size: 15px;
}

@media (min-width: 768px) {
  .footer .social-links a i:before {
    font-size: 16px;
  }
}

.footer .footer-link {
  font-size: 12px;
  line-height: 23px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  margin: 0 -0.4em 0 0;
  display: inline-block;
  vertical-align: top;
  width: 49%;
}

.footer .footer-link:first-child {
  width: 51%;
}

.footer .footer-link li {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 0 10px 0 0;
}

.footer .footer-link a {
  color: #000;
}

.footer .footer-link a:hover {
  text-decoration: none;
  color: #2eabe1;
}

.footer-subscribe-box {
  font-size: 11px;
  color: #8c8c8c;
}

@media (min-width: 768px) {
  .footer-subscribe-box {
    font-size: 12px;
    padding-top: 6px;
  }
}

@media (min-width: 1024px) {
  .footer-subscribe-box {
    padding-top: 14px;
  }
}

.footer-subscribe-box h4 {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-bottom: 9px;
  margin-top: 0;
}

@media (min-width: 768px) {
  .footer-subscribe-box h4 {
    font-size: 20px;
    margin-bottom: 18px;
  }
}

@media (min-width: 768px) {
  .footer-subscribe-box .holder-box {
    display: inline-block;
    vertical-align: middle;
    width: 36.2%;
  }
}

@media (min-width: 1024px) {
  .footer-subscribe-box .holder-box {
    width: 100%;
    display: block;
  }
}

@media (min-width: 768px) {
  .footer-subscribe-box .footer-subscribe-form {
    width: 61%;
    display: inline-block;
    vertical-align: middle;
    margin-top: 2px;
  }
}

@media (min-width: 1024px) {
  .footer-subscribe-box .footer-subscribe-form {
    width: 100%;
    margin-top: 0;
  }
}

.footer-subscribe-box p {
  margin: 0 0 8px;
}

@media (min-width: 1024px) {
  .footer-subscribe-box p {
    margin: 0 0 23px;
  }
}

.footer-subscribe-box p span {
  display: none;
}

@media (min-width: 1024px) {
  .footer-subscribe-box p span {
    display: inline;
  }
}

.footer-subscribe-form {
  border: 1px solid #000;
  border-radius: 2px;
}

.footer-subscribe-form .form-control {
  border: none;
  height: 32px;
  padding: 8px 14px;
  letter-spacing: 0.3px;
  font-size: 11px;
}

@media (min-width: 768px) {
  .footer-subscribe-form .form-control {
    height: 36px;
    padding: 8px 14px;
    font-size: 12px;
  }
}

@media (min-width: 1024px) and (max-width: 1099px) {
  .footer-subscribe-form .form-control {
    padding: 8px 7px;
  }
}

.footer-subscribe-form .btn {
  white-space: nowrap;
  letter-spacing: 1px;
  padding: 8px;
  height: 32px;
  font-size: 11px;
}

@media (min-width: 768px) {
  .footer-subscribe-form .btn {
    height: 36px;
    padding: 10px 8px;
    letter-spacing: 2px;
  }
}

.footer-nav {
  display: inline-block;
}

.footer-nav ul {
  font-size: 13px;
  margin: 0;
}

.footer-nav ul li {
  padding: 0 20px 4px 0;
}

@media (min-width: 1200px) {
  .footer-nav ul li {
    padding: 0 38px 4px 0;
  }
}

.footer-nav ul a {
  color: #000;
  letter-spacing: 0.5px;
}

.footer-nav ul a:hover {
  text-decoration: none;
  color: #2eabe1;
}

#wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.container {
  width: 100%;
  max-width: 1224px;
}

@media (max-width: 1024px) {
  .container {
    padding: 0 10px;
  }
}

.bg-default {
  background: #f2f2f2;
}

.bg-warning {
  background: #ffcc00;
}

.bg-success {
  background: #2ecc71;
}

.bg-danger {
  background: #e74c3c;
}

.bg-primary {
  background: #2a3768;
}

.bg-info {
  background: #2eabe1;
}

.panel {
  box-shadow: none;
}

.panel.fancy-panel .panel-list {
  margin: 0 0 -17px;
}

.panel.fancy-panel .panel-list li {
  border-width: 1px 0 0;
}

.panel.fancy-panel .panel-list li.active a {
  color: #ffcc00;
}

.panel.fancy-panel .panel-list li:first-child {
  border: none;
}

.panel.fancy-panel .panel-list li a:hover {
  color: #ffcc00;
}

.panel.fancy-panel .panel-list li a:active {
  color: #000;
}

.panel .btn-block {
  margin-bottom: 0;
}

.panel .panel-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 17px;
}

.panel .panel-list {
  margin: 0 0 15px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
}

.panel .panel-list li {
  border-radius: 0;
  border: 1px solid #e3e3e3;
  border-width: 0 0 1px;
  padding-left: 0;
}

.panel .panel-list li.active a {
  color: #2a3768;
  font-weight: 800;
}

.panel .panel-list li a {
  display: block;
  padding: 12px 10px 11px 0;
  position: relative;
  color: #000;
  text-decoration: none;
  transition: color .2s;
  min-height: 41px;
  display: block;
  width: 100%;
  height: auto;
}

.panel .panel-list li a:before {
  content: '›';
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 0;
  line-height: 1;
}

.panel .panel-list li a:hover {
  font-weight: 800;
  color: #2a3768;
}

.panel .panel-list li a:active {
  color: #2a3768;
}

.panel .panel-list li strong {
  font-weight: 800;
}

.text-warning {
  color: #ffcc00;
}

.text-primary {
  color: #2a3768;
}

.text-success {
  color: #2ecc71;
}

.text-danger {
  color: #e74c3c;
}

.font-serif {
  font-family: "Kepler", Georgia, "Times New Roman", Times, serif;
}

.font-sans-serif {
  font-family: "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.example-block {
  margin-left: 0;
  margin-right: 0;
}

.example-head {
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 80px;
}

.example-head .example-title {
  color: #2a3768;
  margin-bottom: 26px;
}

.example-title-sm,
.example-title {
  font-size: 24px;
  color: #8c8c8c;
  font-family: "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 40px;
}

.example-title-sm {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  text-transform: uppercase;
}

.example-wrapper {
  border-top: 1px solid #e9e9e9;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .example-wrapper {
    margin-bottom: 40px;
  }
}

.example-row {
  overflow: hidden;
  width: 100%;
  margin-bottom: 50px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
}

.example-row .btn-block {
  margin-bottom: 20px;
}

.example-row .panel .btn-block {
  margin-bottom: 0;
}

.example-row .lead {
  font-size: 44px;
  line-height: 0.909;
  font-weight: normal;
  margin: 0 0 48px;
}

.example-row .lead.font-sans-serif {
  font-weight: 100;
}

.example-row .validation-row {
  margin-bottom: 34px;
}

.btn-row .example-title-sm {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 20px;
  width: 150px;
  text-align: right;
}

.btn-row nav {
  display: inline-block;
  vertical-align: middle;
}

.btn-row .pager {
  margin: 0 0 20px 40px;
}

.btn-row .btn-top,
.btn-row .btn {
  margin-left: 40px;
  margin-bottom: 20px;
}

.btn-row .btn-arrow + .btn-arrow {
  margin-left: 1px;
}

.example-list {
  font-size: 44px;
  line-height: 1.136;
  padding-top: 5px;
  margin-bottom: 24px;
}

.list-fancybox li {
  margin-bottom: 10px;
}

.fw-100 {
  font-weight: 100;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

.step-list {
  text-align: center;
  counter-reset: newCounter;
  font-size: 13px;
  padding: 0 20px;
  margin-bottom: 35px;
  letter-spacing: .6px;
}

.step-list li {
  padding: 0 0 20px;
}

@media (min-width: 768px) {
  .step-list li {
    padding: 0;
  }
}

.step-list li.active a:before {
  background: #ffcc00;
}

.step-list li a {
  display: block;
  position: relative;
  padding: 37px 20px 0;
  color: #000;
}

.step-list li a:before {
  font: 700 20px/32px "Times New Roman", Times, serif;
  counter-increment: newCounter;
  content: counters(newCounter, ".") " ";
  background: #bfbfbf;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -16px;
  transition: background .2s;
}

.step-list li a:focus, .step-list li a:active:focus, .step-list li a:active, .step-list li a:hover {
  background: none;
}

.step-list li a:focus:before, .step-list li a:active:focus:before, .step-list li a:active:before, .step-list li a:hover:before {
  background: #ffcc00;
}

.public-steps {
  text-align: center;
  counter-reset: newCounter;
  font-size: 14px;
  padding: 0 20px;
  margin-bottom: 35px;
  letter-spacing: 0;
  font-weight: 700;
}

.public-steps li {
  position: relative;
  padding: 60px 20px 0;
  margin: 0 0 20px;
  color: #bfbfbf;
  transition: color .2s;
}

@media (min-width: 768px) {
  .public-steps li {
    padding: 78px 20px 0;
    margin: 0;
  }
}

.public-steps li:before {
  z-index: 2;
  font: 700 28px/50px "Times New Roman", Times, serif;
  counter-increment: newCounter;
  content: counters(newCounter, ".") " ";
  background: #bfbfbf;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -25px;
  transition: background .2s, color .2s;
}

@media (min-width: 768px) {
  .public-steps li:before {
    width: 64px;
    height: 64px;
    margin-left: -32px;
    font-size: 42px;
    line-height: 64px;
  }
}

.public-steps li.active {
  color: #000;
}

.public-steps li.active:before {
  background: #ffcc00;
  color: #000;
}

.public-steps li.active:after {
  background: #ffcc00;
}

.public-steps li:after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 32px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #e9e9e9;
  display: none;
}

@media (min-width: 768px) {
  .public-steps li:after {
    display: block;
  }
}

.public-steps li:first-child:after {
  width: 52%;
  left: 50%;
}

.public-steps li:last-child:after {
  width: 52%;
  right: 50%;
  left: auto;
}

.example-loader .example-title-sm {
  margin-bottom: 10px;
}

.example-loader .loader {
  margin-bottom: 30px;
}

.loader {
  display: inline-block;
  vertical-align: top;
  width: 150px;
  height: 150px;
  position: relative;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .loader {
    width: 210px;
    height: 210px;
    margin-top: 40px;
  }
}

.loader .circle canvas {
  width: 150px !important;
  height: 150px !important;
}

@media (min-width: 768px) {
  .loader .circle canvas {
    width: 210px !important;
    height: 210px !important;
  }
}

.loader .logo-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
}

@media (min-width: 768px) {
  .loader .logo-img {
    transform: translate(-50%, -50%) scale(1);
  }
}

.loader .logo-img.item {
  top: 46%;
}

.loader .progress {
  position: absolute;
  bottom: 100%;
  margin: 0 0 5px;
  font-weight: 700;
  left: 0;
  right: 0;
  width: auto;
  text-align: center;
  color: #8c8c8c;
  font-size: 14px;
}

@media (min-width: 768px) {
  .loader .progress {
    font-size: 16px;
  }
}

.loader .progress em {
  font-style: normal;
}

.example-icons-list.align-middle li {
  vertical-align: middle;
}

.example-icons-list li {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px 15px;
}

.example-icons-list li img {
  vertical-align: top;
}

.banner-box {
  max-width: 140px;
  background: #f2f2f2;
  margin: 0 auto 24px;
}

@media (min-width: 768px) {
  .banner-box {
    max-width: 384px;
    margin: 0 0 24px;
  }
}

@media (min-width: 1024px) {
  .banner-box {
    padding: 42px;
  }
}

.banner-box.primary-box a {
  border-color: #113b70;
}

.banner-box.horizontal-box {
  max-width: 812px;
}

.banner-box a {
  display: block;
  border: 1px solid #000;
}

.banner-box img {
  width: 100%;
  display: block;
  height: auto;
}

.product-block {
  width: 100%;
  position: relative;
  font-size: 14px;
  line-height: 1.285;
  margin-bottom: 24px;
  max-width: 384px;
  margin: 0 auto;
}

.product-block img {
  height: auto;
  max-width: 100%;
}

.product-block .holder-box {
  color: #8c8c8c;
  display: block;
  padding-bottom: 24px;
}

@media (min-width: 768px) {
  .product-block .holder-box {
    padding-bottom: 25px;
  }
}

.product-block .holder-box.hover h3 {
  color: #2a3768;
}

.product-block .holder-box.hover .slide-box {
  height: 93px;
}

.product-block .holder-box img {
  display: block;
  width: 100%;
}

.product-block .text-box {
  background: #fff;
  padding: 25px 25px 0;
  position: absolute;
  bottom: 0;
  left: 14px;
  right: 14px;
}

@media (min-width: 768px) {
  .product-block .text-box {
    left: 27px;
    right: 27px;
    padding: 28px 25px 0;
  }
}

@media (min-width: 1024px) {
  .product-block .text-box {
    left: 42px;
    right: 42px;
  }
}

.product-block .category-title {
  font-size: 11px;
  line-height: 1;
  letter-spacing: 1.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .product-block .category-title {
    font-size: 12px;
  }
}

.product-block h3 {
  font-size: 25px;
  line-height: 1;
  letter-spacing: -0.7px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 6px;
  font-weight: 700;
  transition: color .2s;
}

@media (min-width: 768px) {
  .product-block h3 {
    font-size: 28px;
    margin-top: 12px;
    margin-bottom: 0;
    letter-spacing: -0.7px;
    min-height: 60px;
  }
}

.product-block h3 a {
  color: #000;
}

.product-block h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.product-block p {
  margin-bottom: 13px;
}

.product-block .detail {
  display: block;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
}

.product-block .slide-box {
  overflow: hidden;
  height: 0;
  transition: height .3s;
}

.product-block .slide-box p {
  margin-top: 12px;
}

.product-block .icon-lock-circle {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffcc00;
}

.product-block .icon-lock-circle:after {
  z-index: 0;
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: #fff;
  border-radius: 50%;
}

.product-block .icon-lock-circle:before {
  position: relative;
  z-index: 2;
}

.product-block .text-wrapp {
  padding: 18px 10px 6px;
}

.product-block .text-wrapp .sub-title {
  display: block;
  font-size: 11px;
  line-height: 1.1;
  font-weight: 500;
  padding: 7px 0 14px;
}

@media (min-width: 768px) {
  .product-block .text-wrapp .sub-title {
    font-size: 12px;
    padding: 7px 0 17px;
  }
}

.product-block .price {
  font: 700 22px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  display: block;
  text-transform: uppercase;
  padding-bottom: 8px;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .product-block .price {
    font-size: 24px;
    padding-bottom: 11px;
  }
}

.product-block .price .sup {
  font-size: 12px;
  line-height: 1;
  padding-top: 0px;
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 768px) {
  .product-block .price .sup {
    font-size: 14px;
    padding-top: 2px;
  }
}

.product-block .note-box {
  display: block;
  padding: 0 0 12px;
}

@media (min-width: 768px) {
  .product-block .note-box {
    padding: 0 0 15px;
  }
}

.product-block .rating {
  width: 60px;
  display: inline-block;
  vertical-align: middle;
  padding: 5px 1px;
}

.product-block .rating img {
  display: block;
  width: 100%;
}

.product-block .note {
  padding: 5px 4px 4px;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  color: #8c8c8c;
}

.product-block .link-holder {
  padding: 14px 0 0;
}

.product-block .like {
  display: inline-block;
  vertical-align: top;
  color: #000;
  position: relative;
  padding-left: 17px;
  font-size: 11px;
}

@media (min-width: 768px) {
  .product-block .like {
    font-size: 12px;
    padding-left: 19px;
  }
}

.product-block .like i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-52%);
  color: #2a3768;
}

.product-block .like i:before {
  font-size: 9px;
}

@media (min-width: 768px) {
  .product-block .like i:before {
    font-size: 10px;
  }
}

.partner-section {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 30px;
  padding: 18px 15px 0;
}

@media (min-width: 768px) {
  .partner-section {
    margin-bottom: 40px;
    padding: 22px 0 0;
  }
}

.partner-section .heading-title {
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .partner-section .heading-title {
    text-align: center;
    margin-bottom: 31px;
  }
}

.item-box .img-box,
.partner-box .img-box {
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .item-box .img-box,
  .partner-box .img-box {
    height: 120px;
  }
}

.item-box .img-box img,
.partner-box .img-box img {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 120px;
  width: auto;
}

.item-box .category-title,
.partner-box .category-title {
  font-size: 10px;
  line-height: 1;
  letter-spacing: 1.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .item-box .category-title,
  .partner-box .category-title {
    font-size: 12px;
  }
}

.item-box h3,
.partner-box h3 {
  font-size: 22px;
  line-height: 1.1;
  margin-top: 10px;
  letter-spacing: -0.1px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .item-box h3,
  .partner-box h3 {
    font-size: 25px;
  }
}

.item-box h3 a,
.partner-box h3 a {
  color: #000;
}

.item-box h3 a:hover,
.partner-box h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.item-box {
  padding-bottom: 5px;
}

@media (min-width: 768px) {
  .item-box {
    padding-bottom: 0;
  }
}

.item-box .img-box {
  height: auto !important;
  display: block;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .item-box .img-box {
    margin-bottom: 18px;
  }
}

.item-box .img-box img {
  display: block;
  width: 100%;
  height: auto;
  max-height: none;
}

.item-box h3 {
  font-size: 27px;
  margin-top: 6px;
  margin-bottom: 10px;
  letter-spacing: -0.7px;
}

@media (min-width: 768px) {
  .item-box h3 {
    font-size: 30px;
    margin-top: 5px;
  }
}

.item-box .sub-text {
  text-transform: uppercase;
  color: #bfbfbf;
  font-size: 9px;
  line-height: 1;
  display: block;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .item-box .sub-text {
    font-size: 10px;
  }
}

.holder-section {
  padding: 18px 0 0;
  margin-bottom: 24px;
  border-top: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  .holder-section {
    padding: 43px 0 0;
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) {
  .holder-section {
    padding: 32px 0 0;
  }
}

.holder-section .gallery-wrapp {
  margin: 0;
}

.heading-title {
  font: 700 22px/1.14 "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: -0.7px;
  margin: 0 0 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .heading-title {
    letter-spacing: -0.6px;
    font-size: 24px;
    line-height: 1.1;
    text-align: left;
    margin: 0 0 24px;
  }
}

.heading-title mark {
  background: none;
  font-weight: 300;
  padding: 0;
}

.info-section {
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
  padding: 12px 20px 20px;
  margin: 0 0 24px;
  overflow: hidden;
  text-align: center;
}

@media (min-width: 768px) {
  .info-section {
    padding: 21px 0 30px;
    margin: 0 0 30px;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .info-section {
    text-align: left;
    padding: 21px 0 30px;
    margin: 0 0 40px;
  }
}

.info-section .heading-title {
  position: relative;
  z-index: 10;
  margin: 0 0 12px;
  padding: 0 25px;
  line-height: 1.1;
}

@media (min-width: 768px) {
  .info-section .heading-title {
    padding-right: 60px;
    margin-bottom: -22px;
    padding-left: 0;
  }
}

.heading-box {
  padding: 6px 0 15px;
}

@media (min-width: 768px) {
  .heading-box {
    padding: 11px 0 22px;
  }
}

.heading-box .heading-title {
  margin: 0;
}

@media (min-width: 768px) {
  .heading-box .heading-title {
    float: left;
  }
}

.heading-box .btn-link {
  margin-top: 3px;
}

@media (max-width: 767px) {
  .heading-box .btn-link.pull-right {
    float: none !important;
    display: inline-block;
    vertical-align: top;
  }
}

.popup-holder {
  position: relative;
  overflow: hidden;
  height: 0;
}

div.lightbox {
  padding: 43px 13px 13px;
  width: 420px;
  background: #fff;
  position: relative;
  max-width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  div.lightbox {
    max-width: none;
    width: 708px;
    padding: 48px 40px 40px;
  }
}

@media (min-width: 1024px) {
  div.lightbox {
    width: 738px;
  }
}

div.lightbox.bg-info, div.lightbox.bg-primary, div.lightbox.bg-danger, div.lightbox.bg-success, div.lightbox.bg-warning {
  background: #ffcc00;
}

div.lightbox.bg-info .close, div.lightbox.bg-primary .close, div.lightbox.bg-danger .close, div.lightbox.bg-success .close, div.lightbox.bg-warning .close {
  color: #fff;
}

div.lightbox.bg-info .close:hover, div.lightbox.bg-primary .close:hover, div.lightbox.bg-danger .close:hover, div.lightbox.bg-success .close:hover, div.lightbox.bg-warning .close:hover {
  color: #000;
}

div.lightbox.bg-info .close:active, div.lightbox.bg-primary .close:active, div.lightbox.bg-danger .close:active, div.lightbox.bg-success .close:active, div.lightbox.bg-warning .close:active {
  color: #fff;
}

div.lightbox.bg-info .form-control, div.lightbox.bg-primary .form-control, div.lightbox.bg-danger .form-control, div.lightbox.bg-success .form-control, div.lightbox.bg-warning .form-control {
  border-color: #fff;
}

div.lightbox.bg-success {
  background: #2ecc71;
}

div.lightbox.bg-danger {
  background: #e74c3c;
}

div.lightbox.bg-primary {
  background: #2a3768;
}

div.lightbox.bg-info {
  background: #2eabe1;
}

@media (min-width: 1024px) {
  div.lightbox.fancy-lg {
    width: 907px;
  }
}

div.lightbox.fancy-sm {
  padding: 47px 13px 13px;
}

@media (min-width: 768px) {
  div.lightbox.fancy-sm {
    padding: 48px 40px 40px;
  }
}

@media (min-width: 1024px) {
  div.lightbox.fancy-sm {
    width: 708px;
  }
}

div.lightbox .close {
  color: #8c8c8c;
  text-decoration: none;
  position: absolute;
  top: 17px;
  right: 18px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 8040;
}

@media (min-width: 768px) {
  div.lightbox .close {
    width: 16px;
    height: 16px;
    top: 20px;
    right: 20px;
  }
}

div.lightbox .close i {
  display: inline-block;
  vertical-align: top;
}

div.lightbox .close i:before {
  font-size: 14px;
}

@media (min-width: 768px) {
  div.lightbox .close i:before {
    font-size: 16px;
  }
}

div.lightbox .close:hover {
  text-decoration: none;
  color: #ffcc00;
}

div.lightbox .close:active {
  color: #8c8c8c;
}

div.lightbox h1, div.lightbox h2, div.lightbox h3, div.lightbox h4, div.lightbox h5, div.lightbox h6 {
  margin-top: 0;
}

div.lightbox h1 mark, div.lightbox h2 mark, div.lightbox h3 mark, div.lightbox h4 mark, div.lightbox h5 mark, div.lightbox h6 mark {
  font-weight: 400;
}

div.lightbox h3 {
  font-size: 30px;
  line-height: 29px;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
  margin-left: -6px;
  margin-right: -6px;
}

@media (min-width: 768px) {
  div.lightbox h3 {
    font-size: 44px;
    line-height: 1;
    letter-spacing: 0;
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
  }
}

div.lightbox h4 {
  font-size: 27px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 17px;
}

@media (min-width: 768px) {
  div.lightbox h4 {
    font-size: 30px;
    margin-bottom: 15px;
  }
}

div.lightbox .fancy-body {
  font-size: 14px;
  line-height: 1.571;
}

@media (min-width: 768px) {
  div.lightbox .fancy-body {
    font-size: 16px;
    line-height: 1.5;
    padding: 0 40px 21px;
  }
}

div.lightbox .fancy-body h3 {
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  div.lightbox .fancy-body h3 {
    margin-bottom: 13px;
  }
}

div.lightbox .fancy-body p {
  margin-bottom: 7px;
}

@media (min-width: 768px) {
  div.lightbox .fancy-body p {
    margin-bottom: 24px;
  }
}

div.lightbox .fancy-body .btn-holder {
  padding-top: 8px;
  padding-bottom: 41px;
}

@media (min-width: 768px) {
  div.lightbox .fancy-body .btn-holder {
    padding-top: 20px;
    padding-bottom: 0;
  }
}

div.lightbox .fancy-body .btn-holder .btn {
  display: block;
}

@media (min-width: 768px) {
  div.lightbox .fancy-body .btn-holder .btn {
    padding: 14px 20px;
    display: inline-block;
  }
}

div.lightbox .col-holder {
  padding: 0 5px 13px;
  overflow: hidden;
  position: relative;
}

@media (min-width: 768px) {
  div.lightbox .col-holder {
    padding: 42px 0 20px;
    margin: 0 -20px;
  }
}

@media (min-width: 1024px) {
  div.lightbox .col-holder {
    margin: 0 -40px;
  }
}

div.lightbox .col-holder:before {
  content: '';
  background: #e9e9e9;
  width: 1px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 20px;
  bottom: 0;
  margin-left: -1px;
  display: none;
}

@media (min-width: 768px) {
  div.lightbox .col-holder:before {
    display: block;
  }
}

div.lightbox .col-holder .col {
  padding: 17px 0 0;
}

@media (min-width: 768px) {
  div.lightbox .col-holder .col {
    float: left;
    width: 50%;
    padding: 0 0 10px 24px;
  }
}

@media (min-width: 1024px) {
  div.lightbox .col-holder .col {
    padding: 0 80px 10px 40px;
  }
}

div.lightbox .col-holder .col:first-child {
  padding: 0 0 27px;
  border-bottom: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  div.lightbox .col-holder .col:first-child {
    padding: 0 30px 0 4px;
    border: none;
  }
}

@media (min-width: 1024px) {
  div.lightbox .col-holder .col:first-child {
    padding: 0 16px 0 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  div.lightbox .col-holder .col:first-child h4 {
    max-width: 90%;
    margin: 0 auto 15px;
  }
}

div.lightbox .col-holder .col:first-child p {
  margin: 0 auto 24px;
}

@media (min-width: 1024px) {
  div.lightbox .col-holder .col:first-child p {
    max-width: 70%;
  }
}

div.lightbox .col-holder .col:last-child h4 {
  margin-bottom: 13px;
}

@media (min-width: 1024px) {
  div.lightbox .col-holder .col:last-child h4 {
    margin-bottom: 15px;
  }
}

div.lightbox .form {
  width: 100%;
  margin: 0 auto;
}

div.lightbox .form .input-group {
  width: 100%;
  display: block;
}

@media (min-width: 768px) {
  div.lightbox .form .input-group {
    display: table;
  }
}

div.lightbox .form .input-group .form-control {
  border-radius: 3px;
}

div.lightbox .form .input-group .btn {
  width: 100%;
  border-radius: 3px;
}

@media (min-width: 768px) {
  div.lightbox .form .input-group .btn {
    min-width: 174px;
  }
}

div.lightbox .form .input-group .input-group-btn {
  width: 100%;
  float: left;
  padding-top: 9px;
}

@media (min-width: 768px) {
  div.lightbox .form .input-group .input-group-btn {
    padding-left: 10px;
    padding-top: 0;
    float: none;
    width: 1%;
  }
}

div.lightbox .form-group {
  margin-bottom: 9px;
}

@media (min-width: 768px) {
  div.lightbox .form-group {
    margin-bottom: 10px;
  }
}

div.lightbox .form-control {
  font-size: 11px;
  line-height: 1;
  padding: 14px 16px;
  height: 44px;
}

@media (min-width: 768px) {
  div.lightbox .form-control {
    height: 48px;
    font-size: 12px;
    padding: 14px 18px;
  }
}

div.lightbox .link {
  display: inline-block;
  vertical-align: top;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin-top: 18px;
  text-decoration: underline;
}

@media (min-width: 768px) {
  div.lightbox .link {
    margin-top: 22px;
    letter-spacing: 0.7px;
  }
}

div.lightbox .link:hover {
  color: #2eabe1;
}

@media (min-width: 768px) {
  div.lightbox .btn {
    min-width: 174px;
  }
}

div.lightbox .btn.btn-lg {
  padding: 13px 12px 12px;
}

@media (min-width: 768px) {
  div.lightbox .btn.btn-lg {
    padding: 14px 12px;
  }
}

div.lightbox .btn.btn-small {
  min-width: 110px;
}

@media (min-width: 768px) {
  div.lightbox .btn.btn-small {
    min-width: 124px;
  }
}

@media (min-width: 768px) {
  div.lightbox .reg-form {
    padding: 11px 19px 0 17px;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  div.lightbox .reg-form {
    padding: 9px 0 0;
  }
}

div.lightbox .form-subscribe {
  margin: 0 auto;
  max-width: 468px;
}

@media (min-width: 768px) {
  div.lightbox .form-subscribe {
    margin-bottom: 22px;
  }
}

div.lightbox .form-subscribe .input-group-btn .btn {
  min-width: 124px;
}

div.lightbox .form-email {
  margin: 0 auto 3px;
  max-width: 518px;
}

@media (min-width: 768px) {
  div.lightbox .form-email {
    padding-top: 10px;
    margin-bottom: 0;
  }
}

div.lightbox .form-email .link {
  margin-top: 11px;
}

@media (min-width: 768px) {
  div.lightbox .form-email .link {
    margin-top: 28px;
    margin-bottom: 2px;
  }
}

div.lightbox .form-alert {
  margin: 0 auto 3px;
  max-width: 518px;
  text-align: left;
}

@media (min-width: 768px) {
  div.lightbox .form-alert {
    margin-bottom: 25px;
  }
}

div.lightbox .form-alert.validation-success {
  margin-bottom: 0;
}

div.lightbox .form-alert.validation-success .hidden-box {
  display: none;
}

div.lightbox .form-alert .input-group {
  margin-bottom: 18px;
}

@media (max-width: 767px) {
  div.lightbox .form-alert .input-group {
    display: table;
    margin-bottom: 9px;
  }
}

@media (max-width: 767px) {
  div.lightbox .form-alert .input-group .form-control {
    border-radius: 2px 0 0 2px;
    border-right: 0;
    height: 38px;
    font-size: 10px;
    padding: 10px 15px;
  }
}

@media (max-width: 767px) {
  div.lightbox .form-alert .input-group .input-group-btn {
    float: none;
    padding-left: 0;
    padding-top: 0;
    width: 1%;
  }
}

@media (max-width: 767px) {
  div.lightbox .form-alert .input-group .input-group-btn .btn {
    border-radius: 0 2px 2px 0;
    margin-left: 0;
    height: 38px;
    min-width: 88px;
    font-size: 10px;
    padding: 5px 12px;
  }
}

div.lightbox .form-alert .success-message {
  padding-top: 8px;
  padding-bottom: 10px;
  margin-top: -5px;
  border-top: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  div.lightbox .form-alert .success-message {
    margin-top: 0;
    padding-top: 20px;
    padding-bottom: 0;
  }
}

div.lightbox .form-alert .success-message .email-title {
  font-weight: 900;
  display: block;
  letter-spacing: 0.2px;
}

div.lightbox .form-alert .success-message p {
  margin-bottom: 13px;
}

@media (min-width: 768px) {
  div.lightbox .form-alert .success-message p {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  div.lightbox .form-alert .success-message .btn {
    min-width: 140px;
    font-size: 10px;
    padding: 11px 12px;
  }
}

div.lightbox .card-box {
  font-size: 13px;
  line-height: 1.615;
}

@media (min-width: 768px) {
  div.lightbox .card-box {
    font-size: 14px;
    line-height: 1.714;
    padding: 10px 13px 0;
  }
}

@media (min-width: 1024px) {
  div.lightbox .card-box {
    overflow: hidden;
    padding: 0 0 20px 14px;
  }
}

div.lightbox .card-box h3 {
  padding: 0 5px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 17px;
}

@media (min-width: 768px) {
  div.lightbox .card-box h3 {
    line-height: 44px;
    padding: 0 70px;
  }
}

@media (min-width: 1024px) {
  div.lightbox .card-box h3 {
    padding: 0;
    margin-bottom: 25px;
  }
}

div.lightbox .card-box .img-box {
  padding: 0 21px 0 16px;
}

@media (min-width: 768px) {
  div.lightbox .card-box .img-box {
    padding: 15px 101px 22px 40px;
  }
}

@media (min-width: 1024px) {
  div.lightbox .card-box .img-box {
    float: left;
    width: 57%;
    padding: 6px 4px 0 0;
  }
}

div.lightbox .card-box .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

div.lightbox .card-box .sub-text {
  display: block;
  padding-right: 6px;
}

@media (min-width: 768px) {
  div.lightbox .card-box .sub-text {
    padding-right: 0;
  }
}

div.lightbox .card-box .text-box {
  padding: 10px 0 0 0;
  overflow: hidden;
  font-weight: 500;
}

@media (min-width: 768px) {
  div.lightbox .card-box .text-box {
    padding: 8px 0 0 35px;
  }
}

@media (min-width: 1024px) {
  div.lightbox .card-box .text-box {
    padding-top: 0;
  }
}

div.lightbox .card-box .text-box ul {
  line-height: 1.67;
  margin-bottom: 11px;
}

@media (min-width: 768px) {
  div.lightbox .card-box .text-box ul {
    margin-bottom: 29px;
    line-height: 1.714;
    margin-bottom: 14px;
    float: left;
    width: 72%;
  }
}

@media (min-width: 1024px) {
  div.lightbox .card-box .text-box ul {
    width: 100%;
    float: none;
    margin-bottom: 30px;
  }
}

div.lightbox .card-box .text-box ul li:before {
  margin-right: 4px;
}

@media (min-width: 768px) {
  div.lightbox .card-box .text-box ul li:before {
    margin-right: 6px;
  }
}

div.lightbox .card-box .btn {
  display: block;
}

@media (min-width: 768px) {
  div.lightbox .card-box .btn {
    display: inline-block;
    margin-top: 14px;
  }
}

@media (min-width: 1024px) {
  div.lightbox .card-box .btn {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  div.lightbox .product-body {
    padding-top: 25px;
    padding-bottom: 37px;
    overflow: hidden;
  }
}

div.lightbox .product-body h3 {
  margin-bottom: 9px;
}

@media (min-width: 768px) {
  div.lightbox .product-body h3 {
    margin-bottom: 50px;
  }
}

div.lightbox .product-body .img-box {
  width: 78px;
  margin: 0 auto 20px;
}

@media (min-width: 768px) {
  div.lightbox .product-body .img-box {
    width: 144px;
    margin: 0;
    display: inline-block;
  }
}

div.lightbox .product-body .img-box img {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  div.lightbox .product-body .product-item {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  div.lightbox .product-body .text-box {
    display: inline-block;
    text-align: left;
    padding-top: 13px;
    padding-left: 22px;
  }
}

div.lightbox .product-body h4 {
  font-size: 21px;
  line-height: 1;
  margin-bottom: 19px;
}

@media (min-width: 768px) {
  div.lightbox .product-body h4 {
    font-size: 32px;
    line-height: 0.97;
    margin-bottom: 14px;
  }
}

@media (min-width: 768px) {
  div.lightbox .product-body h4 .price,
  div.lightbox .product-body h4 mark {
    display: block;
  }
}

@media (min-width: 768px) {
  div.lightbox .product-body h4 .price {
    font-size: 24px;
    line-height: 1.5;
  }
}

div.lightbox .product-body .price {
  font-weight: 400;
}

div.lightbox .product-body .btn {
  display: block;
}

@media (min-width: 768px) {
  div.lightbox .product-body .btn {
    display: inline-block;
  }
}

div.lightbox .alert-body {
  font-size: 13px;
  padding: 0 0 7px;
}

@media (min-width: 768px) {
  div.lightbox .alert-body {
    padding: 8px 0 14px;
    font-size: 16px;
  }
}

div.lightbox .alert-body h3 {
  font-size: 33px;
  margin-bottom: 11px;
}

@media (min-width: 768px) {
  div.lightbox .alert-body h3 {
    margin-bottom: 19px;
    font-size: 44px;
  }
}

div.lightbox .alert-body p {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  div.lightbox .alert-body p {
    margin-bottom: 24px;
  }
}

div.lightbox .alert-body .checkbox-inline {
  margin-bottom: 0;
}

div.lightbox .label-list {
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
  margin: 0 -7px 16px;
}

@media (min-width: 768px) {
  div.lightbox .label-list {
    margin: 0 0 10px;
  }
}

div.lightbox .label-list li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px 10px;
}

@media (min-width: 768px) {
  div.lightbox .label-list li {
    padding: 0 10px 20px;
  }
}

div.lightbox .label-list .label {
  font-weight: 700;
  letter-spacing: 0.4px;
  font-size: 10px;
  line-height: 1;
  padding: 12px 14px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
}

@media (min-width: 768px) {
  div.lightbox .label-list .label {
    font-size: 12px;
    padding: 16px 19px;
    letter-spacing: 0.4px;
  }
}

div.lightbox .swiper-button-next,
div.lightbox .swiper-button-prev {
  min-width: 0 !important;
}

div.lightbox .product-box .btn {
  min-width: 0;
  letter-spacing: 1px;
}

div.lightbox .product-box .btn.btn-sm {
  padding: 7px 15px;
  line-height: 11px;
  font-size: 10px;
}

@media (min-width: 768px) {
  div.lightbox .product-box .btn.btn-sm {
    padding: 8px 17px 7px;
    font-size: 11px;
  }
}

div.lightbox .product-wrapper {
  margin-top: -5px;
}

@media (min-width: 768px) {
  div.lightbox .product-wrapper {
    margin-top: 0;
    padding: 8px 7px 0;
  }
}

div.lightbox .product-wrapper .footer-box {
  text-align: left;
}

@media (min-width: 1024px) {
  div.lightbox .product-wrapper .footer-box {
    margin-bottom: -32px;
  }
}

div.lightbox .product-wrapper .gallery-wrapp {
  padding: 0;
  margin: 0 -13px 16px;
}

@media (min-width: 768px) {
  div.lightbox .product-wrapper .gallery-wrapp {
    margin: 0 -17px 30px;
    padding: 0 87px;
  }
}

@media (min-width: 1024px) {
  div.lightbox .product-wrapper .gallery-wrapp {
    margin: 0 -30px 30px;
    padding: 0 45px;
  }
}

div.lightbox .product-wrapper h3 {
  margin-bottom: 9px;
}

@media (min-width: 768px) {
  div.lightbox .product-wrapper h3 {
    margin-bottom: 33px;
  }
}

div.lightbox .product-wrapper .cart-total {
  font-size: 14px;
  display: block;
  font-weight: 300;
  padding: 0 0 8px;
}

@media (min-width: 768px) {
  div.lightbox .product-wrapper .cart-total {
    font-size: 16px;
    padding: 0 0 15px;
  }
}

div.lightbox .product-wrapper .cart-total span {
  text-transform: uppercase;
  font: 700 29px/1.1 "Kepler", Georgia, "Times New Roman", Times, serif;
  padding-top: 2px;
  display: block;
}

@media (min-width: 768px) {
  div.lightbox .product-wrapper .cart-total span {
    font-size: 45px;
    line-height: 1.1;
    padding-top: 0;
    display: inline-block;
    padding-left: 12px;
  }
}

div.lightbox .product-wrapper .cart-total span i {
  font-style: normal;
  font-size: 17px;
  line-height: 1;
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 768px) {
  div.lightbox .product-wrapper .cart-total span i {
    font-size: 27px;
  }
}

div.lightbox .product-wrapper .swiper-button-next,
div.lightbox .product-wrapper .swiper-button-prev {
  display: none;
}

@media (min-width: 768px) {
  div.lightbox .product-wrapper .swiper-button-next,
  div.lightbox .product-wrapper .swiper-button-prev {
    display: block;
    margin-top: -17px;
  }
}

@media (min-width: 768px) {
  div.lightbox .product-wrapper .btn-box {
    padding-bottom: 30px;
  }
}

div.lightbox .product-wrapper .btn-box .btn {
  display: block;
}

@media (min-width: 768px) {
  div.lightbox .product-wrapper .btn-box .btn {
    display: inline-block;
    min-width: 184px;
  }
}

div.lightbox .footer-box {
  padding: 17px 0 0;
  border-top: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  div.lightbox .footer-box {
    padding: 20px 26px 0;
    margin: 0 -15px -13px;
  }
}

@media (min-width: 1024px) {
  div.lightbox .footer-box {
    padding: 20px;
    margin: 0 32px -20px;
  }
}

div.lightbox .footer-box .btn {
  min-width: 0;
}

div.lightbox .footer-box .subscribe-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

div.lightbox .footer-box .subscribe-box .img-box {
  width: 113px;
  margin-right: 26px;
}

div.lightbox .footer-box .subscribe-box h4 {
  font-size: 24px;
  line-height: 1.06;
  letter-spacing: 0.7px;
  margin-bottom: 18px;
  width: 64%;
}

@media (min-width: 1024px) {
  div.lightbox .footer-box .subscribe-box h4 {
    margin-bottom: 15px;
  }
}

div.lightbox .footer-box .subscribe-box h4 mark {
  letter-spacing: 0;
  display: block;
}

@media (min-width: 1024px) {
  div.lightbox .footer-box .subscribe-box h4 mark {
    display: inline-block;
  }
}

div.lightbox .footer-box .subscribe-box h4 .price {
  display: block;
  font-size: 18px;
  font-weight: 400;
  padding-top: 2px;
  letter-spacing: 0;
}

@media (min-width: 1024px) {
  div.lightbox .footer-box .subscribe-box h4 .price {
    padding-top: 3px;
  }
}

@media (min-width: 768px) {
  div.lightbox .footer-box .subscribe-box .btn {
    width: 170px;
  }
}

@media (min-width: 1024px) {
  div.lightbox .footer-box .subscribe-box .btn {
    width: 150px;
  }
}

div.lightbox .footer-box .btn-block .btn {
  display: block;
}

div.lightbox .footer-box .btn-block .btn + .btn {
  margin-top: 10px;
}

@media (min-width: 768px) {
  div.lightbox .footer-box .btn-block .btn + .btn {
    margin-top: 0;
  }
}

div.lightbox .footer-box .btn-block .btn.btn-default {
  background: none;
  color: #000;
  border-color: #e9e9e9;
}

div.lightbox .footer-box .btn-block .btn.btn-default:hover {
  background: #e9e9e9;
}

div.lightbox .footer-box .btn-block .btn.btn-default:focus, div.lightbox .footer-box .btn-block .btn.btn-default.active:focus, div.lightbox .footer-box .btn-block .btn.btn-default:active:focus, div.lightbox .footer-box .btn-block .btn.btn-default.active, div.lightbox .footer-box .btn-block .btn.btn-default:active {
  background: none;
}

@media (min-width: 768px) {
  div.lightbox .footer-box .btn-block .btn {
    width: 274px;
    margin: 0 22px;
    display: inline-block;
  }
}

@media (min-width: 768px) {
  div.lightbox .product-detail {
    padding-top: 10px;
  }
}

div.lightbox .product-detail h3 {
  font-size: 26px;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 21px;
}

@media (min-width: 768px) {
  div.lightbox .product-detail h3 {
    font-size: 32px;
    line-height: 1;
    letter-spacing: 0;
    margin-bottom: 34px;
  }
}

div.lightbox .product-detail h4 {
  font-size: 18px;
  line-height: 1;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  div.lightbox .product-detail h4 {
    font-size: 26px;
    letter-spacing: -0.6px;
    margin-bottom: 8px;
  }
}

div.lightbox .product-detail .img-box {
  width: 88px;
}

@media (min-width: 768px) {
  div.lightbox .product-detail .img-box {
    width: 126px;
    margin-right: 20px;
  }
}

div.lightbox .product-detail .text-box {
  overflow: hidden;
  font-size: 9px;
}

@media (min-width: 768px) {
  div.lightbox .product-detail .text-box {
    font-size: 12px;
  }
}

div.lightbox .product-detail .text-box p {
  margin: 0 0 4px;
}

@media (min-width: 768px) {
  div.lightbox .product-detail .text-box p {
    margin-bottom: 14px;
  }
}

div.lightbox .product-detail .holder {
  padding: 0 0 12px;
  margin-right: -5px;
}

@media (min-width: 768px) {
  div.lightbox .product-detail .holder {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
  }
}

@media (min-width: 768px) {
  div.lightbox .product-detail .footer-box {
    margin: 0 -15px;
    padding: 20px 0 0;
  }
}

.support-box {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .support-box {
    justify-content: flex-start;
    padding-top: 2px;
  }
}

@media (min-width: 1024px) {
  .support-box {
    padding-top: 3px;
  }
}

.support-box img {
  width: 100%;
  height: auto;
}

.support-box .img-holder {
  float: left;
  width: 50%;
  padding: 1px 6px 0 1px;
  margin-right: 4px;
  max-width: 185px;
}

@media (min-width: 768px) {
  .support-box .img-holder {
    width: 54%;
    max-width: 210px;
    padding-left: 3px;
    margin-right: 6px;
    padding-top: 0;
  }
}

@media (min-width: 1024px) {
  .support-box .img-holder {
    padding: 5px 0 0 2px;
    margin-right: 23px;
  }
}

.support-box .text-wrapp {
  overflow: hidden;
}

.support-box .available-list {
  margin: 0;
  line-height: 1;
}

.support-box .available-list li {
  vertical-align: middle;
  padding: 0 7px 5px 0;
}

.support-box .available-list a {
  color: #2a3768;
}

.support-box .available-list a:hover {
  color: #2eabe1;
  text-decoration: none;
}

.support-box .available-list a i {
  display: inline-block;
}

.support-box .available-list a i:before {
  font-size: 16px;
}

@media (min-width: 768px) {
  .support-box .available-list a i:before {
    font-size: 19px;
  }
}

.support-box .available-list a i.icon-android {
  margin-top: 2px;
}

.support-box .available-list a i.icon-amazon {
  margin-top: 4px;
}

.support-box .available-list a i.icon-amazon:before {
  font-size: 18px;
}

@media (min-width: 768px) {
  .support-box .available-list a i.icon-amazon:before {
    font-size: 20px;
  }
}

.support-box .sub-title {
  color: #2a3768;
  font-size: 9px;
  display: block;
  letter-spacing: 0.3px;
}

@media (min-width: 768px) {
  .support-box .sub-title {
    font-size: 10px;
  }
}

.support-box h4 {
  line-height: 1;
  font-size: 18px;
  margin: 0 0 9px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .support-box h4 {
    font-size: 20px;
    margin-bottom: 11px;
  }
}

@media (min-width: 1024px) {
  .support-box h4 {
    font-size: 26px;
    margin-bottom: 10px;
  }
}

.support-box h4 mark {
  display: block;
  font-weight: 400;
}

.support-box .btn.btn-default {
  font-size: 10px;
  padding: 12px 12px 10px;
  max-width: 146px;
  letter-spacing: 1.7px;
  border: 1px solid #000;
  background: none;
  display: block;
  color: #000;
  margin-bottom: 9px;
  transition: background .2s, color .2s, border-color .2s;
}

@media (min-width: 768px) {
  .support-box .btn.btn-default {
    max--width: 162px;
    padding: 13px 12px 11px;
    font-size: 12px;
    letter-spacing: 1.2px;
    margin-bottom: 10px;
  }
}

.support-box .btn.btn-default:hover {
  border-color: #000;
  background: #000;
  color: #fff;
}

.support-box .btn.btn-default:focus, .support-box .btn.btn-default:active:focus, .support-box .btn.btn-default:active {
  background: none;
  color: #000;
  border-color: #000;
}

.support-box-sm {
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  padding: 20px 10px 18px;
  text-align: center;
  max-width: 280px;
  margin: 0 auto 24px;
}

@media (min-width: 768px) {
  .support-box-sm {
    margin: 0 0 24px;
  }
}

@media (min-width: 1024px) {
  .support-box-sm {
    text-align: left;
    max-width: 384px;
    padding: 20px 0 23px;
  }
}

.support-box-sm .img-holder {
  margin: 0 auto 3px;
}

@media (min-width: 1024px) {
  .support-box-sm .img-holder {
    margin: 3px 10px 0 0;
    max-width: none;
    float: left;
    width: 44.6%;
    text-align: center;
  }
}

.support-box-sm .img-holder .img-box {
  max-width: 134px;
  margin: 0 auto -4px;
}

@media (min-width: 1024px) {
  .support-box-sm .img-holder .img-box {
    margin: 0 0 -10px;
    max-width: none;
  }
}

.support-box-sm .img-holder .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.support-box-sm .text-wrapp {
  overflow: hidden;
}

.support-box-sm h4 {
  line-height: 1.06;
  font-size: 24px;
  margin: 0 0 9px;
  font-weight: 700;
  letter-spacing: 0.8px;
}

@media (min-width: 1024px) {
  .support-box-sm h4 {
    margin-bottom: 20px;
    line-height: 1.03;
  }
}

.support-box-sm h4 mark {
  display: block;
  font-weight: 400;
  letter-spacing: 0;
}

.support-box-sm .price {
  display: block;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  padding: 4px 0 0;
}

.support-box-sm .available-list {
  margin: 0;
  line-height: 1;
}

.support-box-sm .available-list li {
  vertical-align: middle;
  padding: 0 3px 5px;
}

.support-box-sm .available-list a {
  color: #2a3768;
}

.support-box-sm .available-list a:hover {
  color: #2eabe1;
  text-decoration: none;
}

.support-box-sm .available-list a i {
  display: inline-block;
}

.support-box-sm .available-list a i:before {
  font-size: 18px;
}

.support-box-sm .available-list a i.icon-android {
  margin-top: 2px;
}

.support-box-sm .available-list a i.icon-amazon {
  margin-top: 4px;
}

.support-box-sm .available-list a i.icon-amazon:before {
  font-size: 18px;
}

@media (min-width: 1024px) {
  .support-box-sm .btn {
    width: 90%;
  }
}

.product-detail .img-box {
  width: 88px;
  margin-right: 13px;
  float: left;
}

@media (min-width: 768px) {
  .product-detail .img-box {
    width: 126px;
    margin-right: 20px;
  }
}

.product-detail .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.product-detail h4 {
  font-size: 18px;
  line-height: 1;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .product-detail h4 {
    font-size: 30px;
  }
}

.product-detail h4 a {
  color: inherit;
}

.product-detail h4 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.product-detail .category-title {
  font-size: 8px;
  line-height: 1;
  letter-spacing: 1px;
  padding-bottom: 7px;
}

@media (min-width: 768px) {
  .product-detail .category-title {
    font-size: 12px;
    padding-bottom: 10px;
  }
}

.product-detail .text-box {
  overflow: hidden;
  font-size: 9px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .product-detail .text-box {
    font-size: 12px;
  }
}

.product-detail .text-box p {
  margin: 0 0 4px;
}

@media (min-width: 768px) {
  .product-detail .text-box p {
    margin-bottom: 14px;
  }
}

.product-detail .rating-box {
  padding-bottom: 3px;
}

@media (min-width: 768px) {
  .product-detail .rating-box {
    padding-bottom: 8px;
  }
}

.product-detail .rating-box .rating-img {
  width: 63px;
  height: 11px;
  display: block;
}

@media (min-width: 768px) {
  .product-detail .rating-box .rating-img {
    width: 91px;
    height: 16px;
  }
}

.product-detail .rating-box .note {
  display: block;
  color: #8c8c8c;
  padding-top: 4px;
}

@media (min-width: 768px) {
  .product-detail .rating-box .note {
    font-size: 10px;
    padding-top: 4px;
  }
}

.product-detail .price {
  font: 700 14px/1.1 "Kepler", Georgia, "Times New Roman", Times, serif;
  display: block;
  padding-bottom: 2px;
}

@media (min-width: 768px) {
  .product-detail .price {
    font-size: 20px;
    padding-bottom: 5px;
  }
}

.product-detail .price span {
  padding-right: 4px;
}

@media (min-width: 768px) {
  .product-detail .price span {
    padding-right: 10px;
  }
}

.product-detail .price span i {
  font-size: 9px;
  font-style: normal;
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 768px) {
  .product-detail .price span i {
    font-size: 12px;
  }
}

.product-detail .price .old-price {
  font-size: 13px;
  display: inline-block;
  color: #bfbfbf;
  position: relative;
}

.product-detail .price .old-price:before {
  content: '';
  width: 46%;
  border-top: 2px solid #000;
  position: absolute;
  top: 6px;
  left: 43%;
  height: 2px;
  transform: translateX(-50%) rotate(-45deg);
}

@media (min-width: 768px) {
  .product-detail .price .old-price:before {
    width: 46%;
    top: 9px;
  }
}

@media (min-width: 768px) {
  .product-detail .price .old-price {
    font-size: 17px;
  }
}

.product-detail .price .old-price i {
  font-size: 7px;
}

@media (min-width: 768px) {
  .product-detail .price .old-price i {
    font-size: 11px;
  }
}

.product-box {
  width: 100%;
  padding-top: 3px;
}

.product-box.product-active .img-box {
  border-color: #ffcc00;
  border-width: 3px;
  border-radius: 3px;
  box-shadow: none;
  margin: -2px -2px 15px;
}

@media (min-width: 768px) {
  .product-box.product-active .img-box {
    margin: -2px -2px 17px;
  }
}

.product-box.product-active .btn {
  display: none;
}

.product-box.product-active .check {
  display: inline-block;
}

.product-box .img-box {
  background: #fff;
  border: 1px solid #bfbfbf;
  box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.1);
  transform: translate3d(0, 0, 0);
  margin-bottom: 17px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .product-box .img-box {
    margin-bottom: 19px;
  }
}

.product-box .img-box img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}

.product-box .btn {
  letter-spacing: 1px;
}

.product-box .btn.btn-sm {
  padding: 7px 15px;
  font-size: 10px;
}

@media (min-width: 768px) {
  .product-box .btn.btn-sm {
    padding: 8px 17px 7px;
    font-size: 11px;
  }
}

.product-box .check {
  display: none;
  vertical-align: top;
  color: #fff;
  background: #ffcc00;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  text-align: center;
}

@media (min-width: 768px) {
  .product-box .check {
    width: 28px;
    height: 28px;
  }
}

.product-box .check i {
  display: inline-block;
  line-height: 21px;
  vertical-align: top;
}

@media (min-width: 768px) {
  .product-box .check i {
    line-height: 25px;
  }
}

.product-box .check i:before {
  font-size: 10px;
  display: inline-block;
}

@media (min-width: 768px) {
  .product-box .check i:before {
    font-size: 12px;
    margin-left: 1px;
  }
}

.product-box .number {
  display: block;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  padding-bottom: 6px;
}

@media (min-width: 768px) {
  .product-box .number {
    font-size: 12px;
    padding-bottom: 8px;
  }
}

.card-block {
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  padding: 15px 0;
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .card-block {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 1024px) {
  .card-block {
    padding: 36px 30px 22px;
  }
}

.card-block .img-box {
  padding: 0 27px 11px 21px;
  max-width: 300px;
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 768px) {
  .card-block .img-box {
    padding: 17px 20px 0 5px;
    width: 53%;
    max-width: 400px;
  }
}

@media (min-width: 1024px) {
  .card-block .img-box {
    width: 45%;
    max-width: none;
    padding: 2px 50px 0 3px;
  }
}

.card-block .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .card-block .text-box {
    text-align: left;
    padding: 0 0 0 2px;
    font-size: 14px;
    line-height: 1.714;
    font-weight: 500;
    width: 47%;
  }
}

@media (min-width: 1024px) {
  .card-block .text-box {
    width: 48%;
    padding: 12px 3px 0 0;
  }
}

@media (min-width: 1024px) {
  .card-block .text-box .text-wrapp {
    float: left;
    width: 72%;
  }
}

.card-block h3 {
  font-size: 22px;
  margin: 0 0 10px;
  line-height: 0.96;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {
  .card-block h3 {
    font-size: 28px;
    line-height: 0.98;
    letter-spacing: -0.4px;
    margin: 0 0 12px;
  }
}

@media (min-width: 1024px) {
  .card-block h3 {
    font-size: 34px;
    margin-bottom: 6px;
  }
}

.card-block h3 mark {
  display: block;
}

@media (min-width: 768px) {
  .card-block h3 mark {
    letter-spacing: 0.4px;
  }
}

.card-block h3 .price {
  font-size: 16px;
  font-weight: 400;
  display: block;
  letter-spacing: 0;
  padding-top: 5px;
}

.card-block .sub-text {
  display: none;
}

@media (min-width: 768px) {
  .card-block .sub-text {
    display: block;
  }
}

.card-block ul {
  display: none;
}

@media (min-width: 768px) {
  .card-block ul {
    display: block;
  }
}

@media (min-width: 1024px) {
  .card-block .btn {
    float: right;
    margin-top: 2px;
  }
}

.card-block .btn.btn-lg {
  padding: 4px 9px 3px;
}

@media (min-width: 768px) {
  .card-block .btn.btn-lg {
    padding: 14px 12px;
    min-width: 124px;
  }
}

.item-product-box {
  font-weight: 500;
}

.item-product-box .img-box {
  margin: 0 auto 12px;
  max-width: 187px;
}

.item-product-box .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .item-product-box .img-box {
    max-width: 206px;
    margin-bottom: 16px;
  }
}

.item-product-box .category-title {
  font-size: 11px;
  letter-spacing: 1px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .item-product-box .category-title {
    font-size: 12px;
  }
}

.item-product-box h4 {
  font-size: 26px;
  margin: 0 0 7px;
  letter-spacing: -1px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .item-product-box h4 {
    letter-spacing: 0.2px;
    margin-bottom: 9px;
  }
}

.item-product-box h4 a {
  color: inherit;
}

.item-product-box h4 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.item-product-box h4 span {
  display: block;
}

.item-product-box .month {
  display: block;
  font-size: 11px;
  padding-bottom: 22px;
}

@media (min-width: 768px) {
  .item-product-box .month {
    font-size: 12px;
    padding-bottom: 25px;
  }
}

.item-product-box .btn {
  min-width: 110px;
}

@media (min-width: 768px) {
  .item-product-box .btn {
    min-width: 122px;
  }
}

.postcard-block {
  padding: 20px 0 30px;
  margin-bottom: 20px;
  font-weight: 500;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}

@media (min-width: 768px) {
  .postcard-block {
    margin-bottom: 30px;
    padding: 52px 0 60px;
  }
}

.postcard-block h2 {
  font-size: 36px;
  margin: 0 0 18px;
}

@media (min-width: 768px) {
  .postcard-block h2 {
    font-size: 56px;
    margin-bottom: 13px;
  }
}

.postcard-block h5 {
  margin: 0 0 23px;
  letter-spacing: -0.4px;
}

@media (min-width: 768px) {
  .postcard-block h5 {
    font-size: 32px;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
  }
}

@media (min-width: 1024px) {
  .postcard-block h5 {
    margin-bottom: 35px;
    letter-spacing: -0.9px;
  }
}

@media (min-width: 480px) {
  .postcard-block h5 br {
    display: none;
  }
}

.postcard-block h5 mark {
  letter-spacing: 0;
  font-weight: 400;
}

@media (min-width: 640px) {
  .postcard-block h5 mark {
    display: block;
  }
}

@media (min-width: 1024px) {
  .postcard-block h5 mark {
    display: inline;
    letter-spacing: -0.4px;
  }
}

.postcard-block .sub-title {
  font-size: 13px;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: 0.6px;
  display: block;
  text-transform: uppercase;
  padding: 0 20px 28px;
}

@media (min-width: 768px) {
  .postcard-block .sub-title {
    font-size: 15px;
    letter-spacing: 2.2px;
    padding-bottom: 44px;
  }
}

.postcard-block .btn.btn-lg {
  padding: 12px;
}

@media (min-width: 768px) {
  .postcard-block .btn.btn-lg {
    padding: 14px 15px;
  }
}

.postcard-list {
  white-space: nowrap;
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
  overflow: hidden;
  padding: 42px 0 0;
  margin: 0 -12px 24px;
  padding: 95px 0 0;
}

@media (min-width: 768px) {
  .postcard-list {
    margin: 0 0 53px;
  }
}

@media (min-width: 1024px) {
  .postcard-list {
    margin: 0 -26px 53px;
  }
}

.postcard-list li {
  display: inline-block;
  vertical-align: top;
  width: 33.332%;
  max-width: 150px;
}

@media (min-width: 768px) {
  .postcard-list li {
    max-width: 250px;
  }
}

@media (min-width: 1024px) {
  .postcard-list li {
    width: 20%;
  }
}

.postcard-list li:nth-child(even) {
  background: #f00;
  margin: -42px -12px 0;
  position: relative;
}

@media (min-width: 768px) {
  .postcard-list li:nth-child(even) {
    margin: -95px -30px 0;
  }
}

.postcard-list img {
  display: block;
  width: 100%;
  height: auto;
}

.subscribe-block {
  position: relative;
  text-align: center;
  padding: 14px 20px 12px;
  font-size: 14px;
  line-height: 1.2855;
  letter-spacing: 0.2px;
  font-weight: 500;
  margin-bottom: 30px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .subscribe-block {
    text-align: left;
    font-size: 16px;
    line-height: 1.25;
    padding: 28px 36px 21px;
  }
}

@media (min-width: 1024px) {
  .subscribe-block {
    padding-left: 115px;
    margin-bottom: 40px;
  }
}

.subscribe-block * {
  position: relative;
}

.subscribe-block .decor-box {
  display: none;
}

@media (min-width: 768px) {
  .subscribe-block .decor-box {
    width: 770px;
    height: 1200px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 100%;
    top: 100%;
    margin-top: -315px;
    transform: translateX(-48.5%);
    border: solid #fff;
    border-width: 40px 3px;
  }
}

@media (min-width: 768px) and (min-width: 1024px) {
  .subscribe-block .decor-box {
    width: 995px;
    height: 950px;
    transform: translateX(-46.1%);
    margin-top: -346px;
    border-width: 40px 7px;
    border-radius: 48%;
  }
}

.subscribe-block .decor-box img {
  position: absolute;
  width: 650px;
  height: auto;
  top: -97px;
  left: 33px;
}

@media (min-width: 1024px) {
  .subscribe-block .decor-box img {
    left: -5px;
    top: -67px;
  }
}

.subscribe-block h3 {
  font-size: 28px;
  margin: 0 0 5px;
  letter-spacing: -0.5px;
}

@media (min-width: 768px) {
  .subscribe-block h3 {
    font-size: 48px;
    letter-spacing: 0;
    margin-bottom: 12px;
  }
}

.subscribe-block p {
  margin: 0 0 14px;
}

@media (min-width: 768px) {
  .subscribe-block p {
    margin: 0 0 29px;
    max-width: 60%;
  }
}

@media (min-width: 1024px) {
  .subscribe-block p {
    max-width: 40%;
  }
}

.subscribe-block .link {
  color: inherit;
  font-size: 10px;
  line-height: 16px;
  display: inline-block;
  vertical-align: top;
  max-width: 68%;
  letter-spacing: 0.4px;
}

@media (min-width: 768px) {
  .subscribe-block .link {
    font-size: 12px;
    letter-spacing: 0;
  }
}

.subscribe-block .link span {
  padding-left: 2px;
  display: inline-block;
}

.subscribe-block-md {
  position: relative;
  text-align: center;
  padding: 14px 20px 12px;
  font-size: 14px;
  line-height: 1.2855;
  letter-spacing: 0.2px;
  font-weight: 500;
  margin-bottom: 30px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .subscribe-block-md {
    text-align: left;
    font-size: 16px;
    line-height: 1.25;
    padding: 15px 19px 20px;
    letter-spacing: 0;
  }
}

@media (min-width: 1024px) {
  .subscribe-block-md {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    padding: 23px 30px 10px 19px;
  }
}

.subscribe-block-md * {
  position: relative;
}

.subscribe-block-md .decor-box {
  display: none;
}

@media (min-width: 768px) {
  .subscribe-block-md .decor-box {
    width: 540px;
    height: 450px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 100%;
    top: 100%;
    margin-top: -155px;
    transform: translateX(-43.5%);
    border: solid #fff;
    border-width: 15px 4px;
  }
}

@media (min-width: 768px) and (min-width: 1024px) {
  .subscribe-block-md .decor-box {
    transform: translateX(-61.5%);
    border-width: 17px 3px;
    border-radius: 55% 50%;
  }
}

.subscribe-block-md .decor-box img {
  position: absolute;
  width: 496px;
  height: auto;
  top: -97px;
  left: -28px;
}

@media (min-width: 1024px) {
  .subscribe-block-md .decor-box img {
    width: 494px;
    left: -21px;
    top: -98px;
  }
}

.subscribe-block-md h3 {
  font-size: 28px;
  margin: 0 0 5px;
  letter-spacing: -0.5px;
}

@media (min-width: 768px) {
  .subscribe-block-md h3 {
    font-size: 30px;
    letter-spacing: -0.1px;
    margin-bottom: 6px;
  }
}

.subscribe-block-md p {
  margin: 0 0 14px;
}

@media (min-width: 768px) {
  .subscribe-block-md p {
    margin: 0 0 16px;
    max-width: 60%;
  }
}

@media (min-width: 1024px) {
  .subscribe-block-md p {
    max-width: none;
  }
}

.subscribe-block-md .link {
  color: inherit;
  font-size: 10px;
  line-height: 16px;
  display: inline-block;
  vertical-align: top;
  max-width: 68%;
  letter-spacing: 0.4px;
}

@media (min-width: 768px) {
  .subscribe-block-md .link {
    display: none;
  }
}

.subscribe-block-md .link span {
  padding-left: 2px;
  display: inline-block;
}

@media (min-width: 1024px) {
  .subscribe-block-md .holder-box {
    width: 45%;
    padding-right: 20px;
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .subscribe-block-md .form-subscribe-1 {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .subscribe-block-md .form-subscribe-1 {
    width: 55%;
    padding-left: 4px;
    margin-bottom: 12px;
  }
}

@media (min-width: 768px) {
  .subscribe-block-md .form-subscribe-1 .form-control {
    border: none;
    box-shadow: none;
    border-radius: 3px 0 0 3px !important;
  }
}

@media (min-width: 1024px) {
  .subscribe-block-md .form-subscribe-1 .form-control {
    height: 48px;
    font-size: 12px;
    padding: 14px 18px;
    letter-spacing: 0.3px;
    border-radius: 3px !important;
  }
}

@media (min-width: 768px) {
  .subscribe-block-md .form-subscribe-1 .input-group-btn {
    padding-left: 0;
  }
}

@media (min-width: 1024px) {
  .subscribe-block-md .form-subscribe-1 .input-group-btn {
    padding-left: 12px;
  }
}

@media (min-width: 768px) {
  .subscribe-block-md .form-subscribe-1 .btn.btn-lg {
    padding: 10px 15px;
    border-radius: 0 3px 3px 0 !important;
  }
}

@media (min-width: 1024px) {
  .subscribe-block-md .form-subscribe-1 .btn.btn-lg {
    height: 48px;
    padding: 14px 16px;
    border-radius: 2px !important;
    min-width: 123px;
  }
}

.subscribe-block-sm {
  overflow: hidden;
  max-width: 280px;
  margin: 0 auto 24px;
  font-weight: 500;
  position: relative;
  padding: 23px 20px 20px;
  line-height: 1.25;
}

@media (min-width: 768px) {
  .subscribe-block-sm {
    margin: 0 0 24px;
  }
}

@media (min-width: 1024px) {
  .subscribe-block-sm {
    max-width: 384px;
    background: none;
    padding: 16px 30px 52px;
  }
}

.subscribe-block-sm.bg-warning .decor {
  background: #ffcc00;
}

.subscribe-block-sm.bg-primary .decor {
  background: #2a3768;
}

.subscribe-block-sm * {
  position: relative;
  z-index: 5;
}

.subscribe-block-sm p {
  margin: 0 0 16px;
}

@media (min-width: 1024px) {
  .subscribe-block-sm p {
    margin: 0 0 14px;
  }
}

.subscribe-block-sm h4 {
  font-size: 24px;
  margin: 0 0 9px;
}

@media (min-width: 1024px) {
  .subscribe-block-sm h4 {
    font-size: 32px;
    margin: 0 0 4px;
  }
}

.subscribe-block-sm .decor {
  width: 651px;
  height: 400px;
  border: solid #fff;
  border-width: 5px 69px 3px 3px;
  border-radius: 50%;
  position: absolute;
  bottom: 67px;
  left: 46%;
  transform: translateX(-50%);
  z-index: 2 !important;
  display: none;
}

@media (min-width: 1024px) {
  .subscribe-block-sm .decor {
    display: block;
  }
}

.subscribe-block-sm img {
  bottom: -152px;
  height: auto;
  left: -64px;
  position: absolute !important;
  width: 128%;
  z-index: 0 !important;
  display: none;
}

@media (min-width: 1024px) {
  .subscribe-block-sm img {
    display: block;
  }
}

.product-banner {
  margin-bottom: 30px;
  padding: 18px 20px 15px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .product-banner {
    margin-bottom: 40px;
    padding: 10px 20px 16px;
  }
}

@media (min-width: 1024px) {
  .product-banner {
    padding: 10px 15px 11px 20px;
  }
}

@media (min-width: 1024px) {
  .product-banner .holder {
    overflow: hidden;
  }
}

.product-banner .img-box {
  float: left;
  width: 69px;
  margin: 0 12px 0 9px;
}

@media (min-width: 768px) {
  .product-banner .img-box {
    width: 78px;
    margin: 0 9px 0 -4px;
  }
}

.product-banner .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.product-banner h3 {
  font-size: 28px;
  margin: 4px 0 10px;
  letter-spacing: -0.5px;
}

@media (min-width: 768px) {
  .product-banner h3 {
    font-size: 32px;
    margin: 6px 0 4px;
  }
}

.product-banner h3 mark {
  letter-spacing: 0.5px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .product-banner h3 mark {
    letter-spacing: 0;
    padding-right: 5px;
  }
}

.product-banner h3 a {
  color: inherit;
}

.product-banner h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.product-banner .text-wrapp {
  font-size: 14px;
  line-height: 1.15;
  text-align: center;
  padding: 12px 15px 3px;
  width: 100%;
  clear: both;
}

@media (min-width: 768px) {
  .product-banner .text-wrapp {
    padding: 7px 0 0;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .product-banner .text-wrapp {
    clear: none;
    padding: 0 15px 0 0;
    width: 57%;
    display: inline-block;
    vertical-align: top;
  }
}

.product-banner .text-wrapp p {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .product-banner .text-wrapp p {
    margin-bottom: 8px;
  }
}

.product-banner .text-wrapp .link {
  font-size: 9px;
  color: #2a3768;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .product-banner .text-wrapp .link {
    font-size: 10px;
    letter-spacing: 0.1px;
  }
}

.product-banner .text-wrapp .link:hover {
  text-decoration: none;
}

.product-banner .holder-box {
  text-align: center;
}

@media (min-width: 768px) {
  .product-banner .holder-box {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .product-banner .holder-box {
    display: inline-block;
    vertical-align: top;
    width: 42%;
    padding-top: 8px;
  }
}

.product-banner .price {
  font-size: 11px;
  display: inline-block;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 22px 0 3px;
  max-width: 50%;
}

@media (min-width: 768px) {
  .product-banner .price {
    font-size: 12px;
    line-height: 1.1;
    padding: 0 27px 0 0;
  }
}

@media (min-width: 1024px) {
  .product-banner .price {
    max-width: none;
  }
}

.product-banner .price strong {
  display: block;
  color: #2a3768;
  font: 700 30px/0.9 "Kepler", Georgia, "Times New Roman", Times, serif;
  letter-spacing: 0.5px;
}

@media (min-width: 768px) {
  .product-banner .price strong {
    font-size: 34px;
    font-weight: 900;
    padding: 2px 0 0 0;
    letter-spacing: 0;
  }
}

.product-banner .price .sup {
  display: inline-block;
  vertical-align: top;
  font-style: normal;
  font-size: 20px;
}

@media (min-width: 768px) {
  .product-banner .price .sup {
    font-size: 19px;
    padding-top: 2px;
  }
}

.product-banner .btn {
  margin-top: 4px;
}

.product-banner-md {
  padding: 13px 0 6px;
  font-weight: 500;
  max-width: 280px;
  margin: 0 auto 30px;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  .product-banner-md {
    margin-bottom: 40px;
    padding: 13px 14px 7px 0;
  }
}

@media (min-width: 1024px) {
  .product-banner-md {
    padding: 14px 0 11px;
    max-width: 392px;
  }
}

@media (min-width: 1024px) {
  .product-banner-md .holder {
    overflow: hidden;
  }
}

.product-banner-md .img-box {
  float: left;
  width: 90px;
  margin: 0 10px 0 0;
}

@media (min-width: 768px) {
  .product-banner-md .img-box {
    width: 93px;
    margin: 0 6px 0 -2px;
  }
}

.product-banner-md .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.product-banner-md h3 {
  font-size: 28px;
  margin: 2px 0 10px;
  letter-spacing: -0.5px;
}

@media (min-width: 768px) {
  .product-banner-md h3 {
    font-size: 32px;
    margin: 2px 0 4px;
  }
}

@media (min-width: 1024px) {
  .product-banner-md h3 {
    margin: 1px 0 -4px;
  }
}

.product-banner-md h3 mark {
  letter-spacing: 0.5px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .product-banner-md h3 mark {
    letter-spacing: 0;
    padding-right: 5px;
  }
}

@media (min-width: 1024px) {
  .product-banner-md h3 mark {
    padding: 0;
  }
}

.product-banner-md h3 a {
  color: inherit;
}

.product-banner-md h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.product-banner-md .text-wrapp {
  font-size: 14px;
  line-height: 1.15;
  padding: 8px 0 0;
  width: 100%;
  clear: both;
}

@media (min-width: 768px) {
  .product-banner-md .text-wrapp {
    padding: 8px 0 0;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .product-banner-md .text-wrapp {
    padding: 8px 24px 0 0;
  }
}

.product-banner-md .text-wrapp p {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .product-banner-md .text-wrapp p {
    margin-bottom: 5px;
  }
}

@media (min-width: 1024px) {
  .product-banner-md .text-wrapp p {
    margin-bottom: 6px;
  }
}

.product-banner-md .holder-box {
  padding: 0 0 5px;
}

.product-banner-md .link {
  font-size: 9px;
  color: #2a3768;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .product-banner-md .link {
    font-size: 10px;
    letter-spacing: 0.1px;
  }
}

.product-banner-md .link:hover {
  text-decoration: none;
}

.product-banner-md .price {
  font-size: 11px;
  display: inline-block;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 22px 0 0;
  max-width: 50%;
}

@media (min-width: 768px) {
  .product-banner-md .price {
    font-size: 12px;
    line-height: 1.1;
    padding: 0 11px 0 0;
  }
}

@media (min-width: 1024px) {
  .product-banner-md .price {
    padding: 0 14px 0 0;
  }
}

.product-banner-md .price strong {
  display: block;
  color: #2a3768;
  font: 700 30px/0.9 "Kepler", Georgia, "Times New Roman", Times, serif;
  letter-spacing: 0.5px;
}

@media (min-width: 768px) {
  .product-banner-md .price strong {
    font-size: 34px;
    font-weight: 900;
    padding: 2px 0 0 0;
    letter-spacing: 0;
  }
}

.product-banner-md .price .sup {
  display: inline-block;
  vertical-align: top;
  font-style: normal;
  font-size: 20px;
}

@media (min-width: 768px) {
  .product-banner-md .price .sup {
    font-size: 19px;
    padding-top: 2px;
  }
}

.product-banner-md .btn {
  margin-top: 4px;
}

.product-banner-sm {
  padding: 14px 18px 11px;
  font-weight: 500;
  max-width: 280px;
  margin: 0 auto 30px;
  font-size: 14px;
  line-height: 1.4285;
}

@media (min-width: 768px) {
  .product-banner-sm {
    margin-bottom: 40px;
  }
}

@media (min-width: 1024px) {
  .product-banner-sm {
    max-width: 392px;
    padding: 13px 18px 2px;
  }
}

.product-banner-sm h3 {
  font-size: 25px;
  margin: 0 -2px 8px;
}

@media (min-width: 1024px) {
  .product-banner-sm h3 {
    margin: 1px 0 5px;
  }
}

.product-banner-sm h3 mark {
  letter-spacing: 0.5px;
  font-weight: 400;
  padding-right: 4px;
}

.product-banner-sm h3 a {
  color: inherit;
}

.product-banner-sm h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.product-banner-sm p {
  margin-bottom: 11px;
  max-width: 90%;
}

@media (min-width: 1024px) {
  .product-banner-sm p {
    margin-bottom: 2px;
    max-width: none;
  }
}

.product-banner-sm .holder-box {
  padding: 0 0 6px;
}

.product-banner-sm .link {
  font-size: 10px;
  color: #2a3768;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  text-decoration: underline;
  letter-spacing: 0.2px;
}

.product-banner-sm .link:hover {
  text-decoration: none;
}

.product-banner-sm .price {
  font-size: 12px;
  line-height: 1.1;
  display: inline-block;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.5);
  padding: 2px 10px 0 0;
  max-width: 50%;
}

@media (min-width: 1024px) {
  .product-banner-sm .price {
    padding: 2px 9px 0 0;
  }
}

.product-banner-sm .price strong {
  display: block;
  color: #2a3768;
  font: 700 27px/0.9 "Kepler", Georgia, "Times New Roman", Times, serif;
  padding: 3px 0 0;
  letter-spacing: 0.7px;
}

.product-banner-sm .price .sup {
  display: inline-block;
  vertical-align: top;
  font-style: normal;
  font-size: 18px;
  padding-top: 2px;
}

.product-banner-sm .btn {
  font-size: 10px;
  padding: 13px 12px;
  margin-top: 4px;
}

.partner-banner {
  padding: 21px 15px 30px;
  max-width: 280px;
  margin: 0 auto 24px;
  text-align: center;
}

@media (min-width: 768px) {
  .partner-banner {
    padding: 21px 30px 20px;
    margin: 0 0 24px;
  }
}

@media (min-width: 1024px) {
  .partner-banner {
    text-align: left;
    padding: 21px 20px 30px 30px;
    max-width: 384px;
  }
}

.partner-banner h4 {
  font-weight: 400;
  font-size: 22px;
  margin: 0 0 15px;
}

@media (min-width: 1024px) {
  .partner-banner h4 {
    margin: 0 0 13px;
  }
}

.partner-banner h5 {
  font-size: 25px;
  margin: 1px 0 6px;
  letter-spacing: 0.5px;
}

.partner-banner .img-box {
  width: 100px;
  margin: 0 auto 12px;
}

@media (min-width: 1024px) {
  .partner-banner .img-box {
    float: left;
    width: 31.2%;
    margin: 0 20px 10px 0;
  }
}

.partner-banner .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.partner-banner .text-box {
  overflow: hidden;
}

.partner-banner .btn {
  min-width: 144px;
}

.postcard-box-sm {
  max-width: 280px;
  margin: 0 auto 24px;
  padding: 0 0 30px;
  border-bottom: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  .postcard-box-sm {
    margin: 0 0 24px;
  }
}

@media (min-width: 1024px) {
  .postcard-box-sm {
    max-width: 384px;
    padding: 0 0 25px;
  }
}

.postcard-box-sm h4 {
  font-size: 28px;
  margin: 0 0 14px;
}

@media (min-width: 1024px) {
  .postcard-box-sm h4 {
    margin: 0 0 11px;
  }
}

.postcard-box-sm h5 {
  margin: 0;
  font-size: 18px;
  letter-spacing: -0.2px;
  max-width: 65%;
}

@media (min-width: 1024px) {
  .postcard-box-sm h5 {
    font-size: 22px;
  }
}

@media (min-width: 1024px) {
  .postcard-box-sm h5 {
    max-width: none;
  }
}

.postcard-box-sm h5 mark {
  letter-spacing: 0.3px;
  display: block;
}

@media (min-width: 1024px) {
  .postcard-box-sm h5 mark {
    display: inline;
  }
}

.postcard-box-sm .img-list {
  overflow: hidden;
  margin: 0 -4px 17px;
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
}

@media (min-width: 1024px) {
  .postcard-box-sm .img-list {
    margin: 0 -5px 16px;
  }
}

.postcard-box-sm .img-list li {
  display: inline-block;
  vertical-align: top;
  padding: 4px;
  width: 50%;
}

@media (min-width: 1024px) {
  .postcard-box-sm .img-list li {
    padding: 5px;
  }
}

.postcard-box-sm .img-list li img {
  display: block;
  width: 100%;
  height: auto;
}

.postcard-box-sm .holder {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 4px;
}

@media (min-width: 1024px) {
  .postcard-box-sm .holder {
    padding: 0 0 0 20px;
  }
}

.postcard-box-sm .img-holder {
  position: relative;
  margin-right: 13px;
}

@media (min-width: 1024px) {
  .postcard-box-sm .img-holder {
    margin-right: 20px;
  }
}

.postcard-box-sm .img-box {
  width: 68px;
  height: 68px;
  overflow: hidden;
  border: 2px solid #fff;
  background: #ffcc00;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

.postcard-box-sm .img-box.item {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transform: rotate(-10deg);
}

.postcard-box-sm .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.contact-banner {
  overflow: hidden;
  position: relative;
  padding: 20px 15px 18px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 500;
}

@media (min-width: 768px) {
  .contact-banner {
    margin-bottom: 40px;
    text-align: left;
    font-size: 16px;
    padding: 21px 18px 19px;
  }
}

@media (min-width: 1024px) {
  .contact-banner {
    padding: 21px 300px 14px 39px;
  }
}

.contact-banner * {
  position: relative;
}

.contact-banner h3 {
  font-size: 27px;
  margin: 0 0 4px;
}

@media (min-width: 768px) {
  .contact-banner h3 {
    font-size: 30px;
  }
}

.contact-banner h3 mark {
  font-size: 20px;
  display: block;
  margin-bottom: -2px;
}

@media (min-width: 768px) {
  .contact-banner h3 mark {
    font-size: 22px;
    letter-spacing: 0.2px;
  }
}

.contact-banner p {
  margin: 0 0 14px;
}

@media (min-width: 768px) {
  .contact-banner p {
    margin: 0 0 16px;
  }
}

.contact-banner .decor-box {
  display: none;
}

@media (min-width: 768px) {
  .contact-banner .decor-box {
    width: 540px;
    height: 450px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 100%;
    top: 100%;
    margin-top: -158px;
    transform: translateX(-46.9%);
    border: solid #fff;
    border-width: 21px 9px;
  }
}

@media (min-width: 768px) and (min-width: 1024px) {
  .contact-banner .decor-box {
    transform: translateX(-57.2%);
    border-width: 23px 16px;
    border-radius: 51% 50%;
    margin-top: -153px;
  }
}

.contact-banner .decor-box img {
  position: absolute;
  width: 380px;
  height: auto;
  top: -84px;
  left: -7px;
}

@media (min-width: 1024px) {
  .contact-banner .decor-box img {
    width: 380px;
    left: -14px;
    top: -84px;
  }
}

@media (min-width: 1024px) {
  .contact-banner .wrapp-box {
    display: inline-block;
    vertical-align: middle;
    width: 54.4%;
  }
}

.contact-banner .btn {
  min-width: 164px;
}

@media (min-width: 768px) {
  .contact-banner .btn {
    min-width: 184px;
    padding: 13px;
  }
}

@media (min-width: 1024px) {
  .contact-banner .btn {
    margin-bottom: 7px;
  }
}

.subscription-banner {
  position: relative;
  overflow: hidden;
  padding: 15px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 1.5714;
  font-weight: 500;
}

@media (min-width: 768px) {
  .subscription-banner {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.5;
    padding: 16px 16px 20px;
  }
}

@media (min-width: 1024px) {
  .subscription-banner {
    padding: 23px 30px 20px 18px;
    text-align: left;
  }
}

.subscription-banner h3 {
  font-size: 28px;
  line-height: 0.98;
  margin: 0 0 3px;
  letter-spacing: -0.5px;
}

@media (min-width: 768px) {
  .subscription-banner h3 {
    font-size: 30px;
    line-height: 1;
    letter-spacing: 0;
    margin-bottom: 5px;
  }
}

@media (min-width: 1024px) {
  .subscription-banner h3 {
    margin: 0 0 4px;
  }
}

.subscription-banner h3 mark {
  font-weight: 400;
  letter-spacing: -0.3px;
  display: block;
}

@media (min-width: 768px) {
  .subscription-banner h3 mark {
    letter-spacing: 0;
  }
}

@media (min-width: 1024px) {
  .subscription-banner h3 mark {
    display: inline;
  }
}

.subscription-banner p {
  margin-bottom: 9px;
}

@media (min-width: 768px) {
  .subscription-banner p {
    margin-bottom: 14px;
  }
}

@media (min-width: 1024px) {
  .subscription-banner p {
    margin: 0;
    max-width: 92%;
  }
}

.subscription-banner .btn.btn-lg {
  padding: 12px 18px;
}

@media (min-width: 768px) {
  .subscription-banner .btn.btn-lg {
    padding: 14px 22px;
  }
}

@media (min-width: 1024px) {
  .subscription-banner .btn.btn-lg {
    white-space: nowrap;
  }
}

@media (min-width: 1024px) {
  .subscription-banner .wrapp-box {
    width: 64%;
  }
}

.subscription-banner .holder-box {
  width: 100%;
  position: relative;
}

@media (min-width: 1024px) {
  .subscription-banner .holder-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.subscription-banner .decor-box {
  background: #fff;
  display: none;
}

@media (min-width: 1024px) {
  .subscription-banner .decor-box {
    width: 600px;
    height: 506px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    position: absolute;
    left: 100%;
    top: 100%;
    margin-top: -160px;
    transform: translateX(-56%);
    border: solid #fff;
    border-width: 20px 3px;
  }
}

.subscription-banner .decor-box img {
  position: absolute;
  width: 311px;
  height: auto;
  top: 15px;
  left: 22px;
}

.publish-banner {
  overflow: hidden;
  position: relative;
  padding: 15px 15px 18px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 500;
}

@media (min-width: 768px) {
  .publish-banner {
    margin-bottom: 40px;
    text-align: left;
    font-size: 16px;
    padding: 15px 18px 20px;
  }
}

@media (min-width: 1024px) {
  .publish-banner {
    padding: 20px 40px 25px 46px;
  }
}

.publish-banner * {
  position: relative;
}

@media (min-width: 1024px) {
  .publish-banner .holder-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (min-width: 1024px) {
  .publish-banner .text-wrapp {
    display: inline-block;
    padding-right: 36px;
    max-width: 52%;
  }
}

.publish-banner h3 {
  font-size: 27px;
  margin: 0 0 4px;
}

@media (min-width: 768px) {
  .publish-banner h3 {
    font-size: 32px;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
  }
}

@media (min-width: 1024px) {
  .publish-banner h3 {
    font-size: 30px;
    line-height: 1;
    margin: 0;
    letter-spacing: 0.1px;
  }
}

.publish-banner h3 mark {
  font-size: 26px;
  display: block;
  margin-bottom: -3px;
}

@media (min-width: 768px) {
  .publish-banner h3 mark {
    font-size: 30px;
    letter-spacing: 0.7px;
    margin-bottom: 2px;
  }
}

@media (min-width: 1024px) {
  .publish-banner h3 mark {
    margin-bottom: 0;
  }
}

.publish-banner p {
  margin: 0 0 14px;
}

@media (min-width: 768px) {
  .publish-banner p {
    margin: 0 0 16px;
  }
}

@media (min-width: 1024px) {
  .publish-banner p {
    margin: 0;
  }
}

.publish-banner .icon {
  display: none;
}

@media (min-width: 1024px) {
  .publish-banner .icon {
    display: inline-block;
    margin: 2px 21px 0 0;
  }
}

.publish-banner .btn {
  padding: 17px 27px;
}

@media (min-width: 1024px) {
  .publish-banner .btn {
    margin-top: 5px;
  }
}

.publish-banner .decor-box {
  display: none;
}

@media (min-width: 768px) {
  .publish-banner .decor-box {
    width: 540px;
    height: 450px;
    border-radius: 52% 50%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 100%;
    top: 100%;
    margin-top: -154px;
    transform: translateX(-43.9%);
    border: solid #fff;
    border-width: 19px 3px;
  }
}

@media (min-width: 768px) and (min-width: 1024px) {
  .publish-banner .decor-box {
    transform: translateX(-61.3%);
    border-width: 18px 4px;
    border-radius: 52% 50%;
    margin-top: -152px;
  }
}

.publish-banner .decor-box img {
  position: absolute;
  width: 366px;
  height: auto;
  top: -49px;
  left: -85px;
}

@media (min-width: 1024px) {
  .publish-banner .decor-box img {
    left: -123px;
    top: -69px;
    width: 494px;
  }
}

.contact-banner-sm,
.publish-banner-sm {
  line-height: 1.25;
  font-weight: 500;
  padding: 14px 20px 20px;
  overflow: hidden;
  margin: 0 auto 24px;
  max-width: 280px;
  position: relative;
}

@media (min-width: 768px) {
  .contact-banner-sm,
  .publish-banner-sm {
    margin: 0 0 24px;
  }
}

@media (min-width: 1024px) {
  .contact-banner-sm,
  .publish-banner-sm {
    max-width: 384px;
    padding: 14px 20px 10px;
  }
}

.contact-banner-sm *,
.publish-banner-sm * {
  position: relative;
}

.contact-banner-sm h4,
.publish-banner-sm h4 {
  font-size: 30px;
  line-height: 25px;
  margin: 0 0 6px;
}

.contact-banner-sm h4 mark,
.publish-banner-sm h4 mark {
  display: block;
  font-size: 22px;
  letter-spacing: 0.4px;
}

.contact-banner-sm p,
.publish-banner-sm p {
  max-width: 50%;
  margin: 0 0 15px;
}

@media (min-width: 1024px) {
  .contact-banner-sm p,
  .publish-banner-sm p {
    max-width: 40%;
    margin: 0 0 5px;
  }
}

.contact-banner-sm .decor,
.publish-banner-sm .decor {
  display: none;
}

@media (min-width: 1024px) {
  .contact-banner-sm .decor,
  .publish-banner-sm .decor {
    background: #fff;
    overflow: hidden;
    display: block;
    position: absolute;
    width: 258px;
    height: 400px;
    left: 100%;
    top: 100%;
    margin: -147px 0 0 -140px;
    border-radius: 50%;
    border: solid #fff;
    border-width: 20px 4px 4px;
  }
}

.contact-banner-sm .decor img,
.publish-banner-sm .decor img {
  width: 274px;
  height: auto;
  position: absolute;
  left: -115px;
  top: -31px;
}

@media (min-width: 1024px) {
  .contact-banner-sm .text-wrapp,
  .publish-banner-sm .text-wrapp {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.contact-banner-sm .btn,
.publish-banner-sm .btn {
  font-size: 12px;
}

@media (min-width: 1024px) {
  .contact-banner-sm .btn,
  .publish-banner-sm .btn {
    display: inline-block;
    width: auto;
    margin-top: 7px;
    min-width: 184px;
  }
}

.contact-banner-sm p {
  max-width: none;
}

@media (min-width: 1024px) {
  .contact-banner-sm p {
    max-width: 40%;
  }
}

.contact-banner-sm .decor img {
  width: 204px;
  left: -2px;
  top: -2px;
}

.bio-block {
  margin: 0 auto 24px;
  padding: 13px 19px 15px;
  font-size: 14px;
  line-height: 1.4285;
  max-width: 280px;
}

@media (min-width: 768px) {
  .bio-block {
    margin: 0 0 24px;
  }
}

.bio-block .img-box {
  width: 64px;
  float: left;
  overflow: hidden;
  border-radius: 2px;
  margin: 4px 15px 11px 0;
}

.bio-block .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.bio-block .text-box {
  overflow: hidden;
}

.bio-block h5 {
  margin: 0 0 1px;
  font-size: 28px;
}

.bio-block p {
  margin: 0 0 10px;
}

.bio-block .btn {
  clear: both;
  padding: 13px 10px 12px;
  letter-spacing: 2px;
}

.process-box {
  border: 2px solid #e9e9e9;
  padding: 27px 20px 30px;
  max-width: 280px;
  margin: 0 auto 24px;
}

@media (min-width: 768px) {
  .process-box {
    margin: 0 0 24px;
  }
}

@media (min-width: 1024px) {
  .process-box {
    max-width: 384px;
    padding: 27px 32px 30px;
  }
}

.process-box .step-title {
  display: block;
  text-transform: uppercase;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 900;
  margin: 0 0 -2px;
}

.process-box h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  margin: 0 0 11px;
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: -0.6px;
}

.process-box .process-list {
  color: #8c8c8c;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4285;
  margin: 0 0 15px;
  border-bottom: 1px solid #e9e9e9;
}

.process-box .process-list li {
  padding: 0 0 0 32px;
  position: relative;
  margin: 0 0 16px;
}

.process-box .process-list li.available {
  color: #000;
}

.process-box .process-list li.available:before {
  color: #2ecc71;
}

.process-box .process-list li:before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  color: #bfbfbf;
  content: "\e912";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.process-box .total-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 12px;
  margin: -4px 0 20px;
  border-bottom: 1px solid #e9e9e9;
}

.process-box .total-box h5 {
  text-overflow: auto;
  overflow: visible;
  white-space: normal;
  margin: 0;
}

.process-box .price {
  white-space: nowrap;
  display: inline-block;
  font: 700 22px/1.3 "Kepler", Georgia, "Times New Roman", Times, serif;
  letter-spacing: -0.3px;
}

.process-box .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
}

.process-box .btn {
  font-size: 12px;
}

.cart-box-1,
.cart-box {
  font-weight: 500;
  padding: 21px 10px 10px;
  font-size: 11px;
  max-width: 170px;
  margin: 0 auto 24px;
}

@media (min-width: 768px) {
  .cart-box-1,
  .cart-box {
    margin: 0 0 24px;
    max-width: 280px;
    padding: 34px 20px 30px;
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  .cart-box-1,
  .cart-box {
    padding: 38px 42px 26px;
    max-width: 384px;
  }
}

.cart-box-1 .old-price,
.cart-box .old-price {
  font: 700 22px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  display: inline-block;
  color: #737373;
  position: relative;
  margin-bottom: 3px;
}

@media (min-width: 768px) {
  .cart-box-1 .old-price,
  .cart-box .old-price {
    font-size: 24px;
    margin-bottom: 12px;
  }
}

.cart-box-1 .old-price:before,
.cart-box .old-price:before {
  content: '';
  width: 50%;
  border-top: 2px solid #000;
  position: absolute;
  top: 10px;
  left: 52%;
  height: 3px;
  transform: translateX(-50%) rotate(-45deg);
}

@media (min-width: 768px) {
  .cart-box-1 .old-price:before,
  .cart-box .old-price:before {
    top: 12px;
  }
}

.cart-box-1 .old-price .sup,
.cart-box .old-price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
}

@media (min-width: 768px) {
  .cart-box-1 .old-price .sup,
  .cart-box .old-price .sup {
    font-size: 14px;
  }
}

.cart-box-1 .price,
.cart-box .price {
  font: 700 32px/1.2 "Kepler", Georgia, "Times New Roman", Times, serif;
  display: block;
  padding: 0 0 1px;
}

@media (min-width: 768px) {
  .cart-box-1 .price,
  .cart-box .price {
    font-size: 45px;
    line-height: 1;
    padding: 0 0 3px;
  }
}

.cart-box-1 .price .sup,
.cart-box .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  padding-top: 2px;
}

@media (min-width: 768px) {
  .cart-box-1 .price .sup,
  .cart-box .price .sup {
    font-size: 26px;
    padding-top: 0;
  }
}

.cart-box-1 .download,
.cart-box .download {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-left: 23px;
  color: #000;
  font-size: 10px;
  margin: 5px 0 13px;
  letter-spacing: 0.1px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .cart-box-1 .download,
  .cart-box .download {
    font-size: 12px;
    margin: 6px 0 20px;
    padding-left: 26px;
  }
}

.cart-box-1 .download:hover,
.cart-box .download:hover {
  text-decoration: none;
  color: #ffcc00;
}

.cart-box-1 .download i,
.cart-box .download i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.cart-box-1 .cart-row,
.cart-box .cart-row {
  padding: 7px 0 0;
  border-top: 1px solid #8c8c8c;
}

@media (min-width: 768px) {
  .cart-box-1 .cart-row,
  .cart-box .cart-row {
    padding: 13px 0 0;
  }
}

.cart-box-1 p,
.cart-box p {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .cart-box-1 p,
  .cart-box p {
    margin-bottom: 15px;
  }
}

.cart-box-1 .count-box,
.cart-box .count-box {
  border-top: 1px solid #8c8c8c;
  padding: 8px 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .cart-box-1 .count-box,
  .cart-box .count-box {
    font-size: 14px;
    line-height: 1.2857;
    padding: 8px 0 11px;
  }
}

.cart-box-1 .count-box .number,
.cart-box .count-box .number {
  font: 700 32px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  display: inline-block;
  padding: 0 5px 3px 0;
}

@media (min-width: 768px) {
  .cart-box-1 .count-box .number,
  .cart-box .count-box .number {
    font-size: 45px;
    padding: 0 7px 0 0;
  }
}

.cart-box-1 .count-box p,
.cart-box .count-box p {
  margin: 0;
}

@media (min-width: 1024px) {
  .cart-box-1 .count-box p,
  .cart-box .count-box p {
    max-width: 50%;
  }
}

.cart-box-1 .btn.alt-btn,
.cart-box .btn.alt-btn {
  margin-top: -3px;
  padding: 22px 5px;
}

@media (min-width: 768px) {
  .cart-box-1 .btn.alt-btn,
  .cart-box .btn.alt-btn {
    margin-top: 31px;
    padding: 25px 5px 24px;
  }
}

.cart-box-1 .category-title,
.cart-box .category-title {
  color: #000;
  font-size: 11px;
  letter-spacing: 0.4px;
  padding: 0 0 6px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .cart-box-1 .category-title,
  .cart-box .category-title {
    font-size: 12px;
    letter-spacing: 0.5px;
    padding: 0 0 5px;
  }
}

.cart-box-1 {
  padding: 1px 10px;
}

@media (min-width: 768px) {
  .cart-box-1 {
    padding: 30px 20px 32px;
  }
}

@media (min-width: 1024px) {
  .cart-box-1 {
    padding: 22px 42px 40px;
  }
}

.cart-box-1 .cart-row {
  padding: 18px 0 11px;
}

@media (min-width: 768px) {
  .cart-box-1 .cart-row {
    padding: 19px 0 11px;
  }
}

.cart-box-1 .cart-row:first-child {
  border: none;
}

.cart-box-1 .price {
  padding: 0 0 5px;
}

@media (min-width: 768px) {
  .cart-box-1 .price {
    padding: 0 0 11px;
  }
}

.cart-box-1 .download {
  margin: 4px 0 14px;
}

@media (min-width: 768px) {
  .cart-box-1 .download {
    margin: 0 0 15px;
  }
}

.cart-box-1 .btn.btn-lg {
  padding: 13px 1px 12px;
}

@media (min-width: 768px) {
  .cart-box-1 .btn.btn-lg {
    padding: 15px 1px 13px;
  }
}

.cart-box-1 .btn.btn-warning {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .cart-box-1 .btn.btn-warning {
    margin-bottom: 7px;
  }
}

.cart-box-1 .btn.btn-primary {
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .cart-box-1 .btn.btn-primary {
    margin-bottom: 13px;
  }
}

.cart-box-1 p {
  margin: 0;
}

.banner-box-sm {
  max-width: 280px;
  margin: 0 auto 24px;
  font-weight: 500;
  line-height: 1.25;
}

.banner-box-sm.alt-banner h4 {
  margin-bottom: 6px;
}

.banner-box-sm.alt-banner p {
  max-width: 80%;
  margin: 0 auto 25px;
}

.banner-box-sm.alt-banner .btn.btn-lg {
  padding: 15px 10px 14px;
}

@media (min-width: 1024px) {
  .banner-box-sm.alt-banner .btn.btn-lg {
    padding: 14px 10px;
  }
}

@media (min-width: 768px) {
  .banner-box-sm {
    margin: 0 0 24px;
  }
}

@media (min-width: 1024px) {
  .banner-box-sm {
    padding: 42px;
    max-width: 384px;
  }
}

.banner-box-sm .holder {
  position: relative;
  background: none;
  overflow: hidden;
  padding: 21px 15px 35px;
}

.banner-box-sm .holder.bg-warning .decor {
  background: #ffcc00;
}

.banner-box-sm .holder.bg-primary .decor {
  background: #2a3768;
}

.banner-box-sm .holder * {
  position: relative;
  z-index: 5;
}

.banner-box-sm .decor {
  width: 475px;
  height: 400px;
  border: solid #fff;
  border-width: 5px 60px 3px 3px;
  border-radius: 50%;
  position: absolute;
  left: 49%;
  bottom: 56px;
  transform: translateX(-50%);
  z-index: 2 !important;
}

@media (min-width: 1024px) {
  .banner-box-sm .decor {
    border-width: 5px 40px 4px 4px;
    bottom: 58px;
    left: 45%;
    width: 505px;
    height: 421px;
  }
}

.banner-box-sm img {
  bottom: -5px;
  height: auto;
  left: -3px;
  position: absolute !important;
  width: 110%;
  z-index: 0 !important;
}

@media (min-width: 1024px) {
  .banner-box-sm img {
    left: -2px;
    width: 102%;
  }
}

.banner-box-sm h4 {
  font-size: 28px;
  margin: 0 0 5px;
  padding: 0 7px;
}

@media (min-width: 1024px) {
  .banner-box-sm h4 {
    line-height: 1;
    padding: 0 17px;
  }
}

.banner-box-sm p {
  margin: 0 0 18px;
}

.banner-box-sm .btn {
  letter-spacing: 1.8px;
}

@media (min-width: 1024px) {
  .banner-box-sm .btn {
    letter-spacing: 1.2px;
  }
}

.banner-box-sm .btn.btn-lg {
  padding: 15px 14px 14px;
}

@media (min-width: 1024px) {
  .banner-box-sm .btn.btn-lg {
    padding: 14px;
  }
}

.category-title {
  display: block;
  color: #bfbfbf;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .category-title {
    font-size: 12px;
  }
}

.item-post {
  padding: 15px 0 11px;
  color: #8c8c8c;
  font-size: 13px;
  line-height: 1.23;
  font-weight: 500;
  border-bottom: 1px solid #e9e9e9;
}

@media (min-width: 1024px) {
  .item-post.alt-post {
    padding: 24px 0 20px;
  }
}

@media (min-width: 768px) {
  .item-post {
    padding: 20px 0 16px;
    font-size: 14px;
    line-height: 1.2875;
  }
}

@media (min-width: 1024px) {
  .item-post {
    padding: 30px 0 26px;
    display: flex;
  }
}

.item-post .holder-box {
  width: 100%;
}

@media (min-width: 1024px) {
  .item-post .text-box {
    width: 57.8%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.item-post .wrapp-box {
  overflow: hidden;
  width: 100%;
}

@media (min-width: 768px) {
  .item-post .wrapp-box {
    padding-top: 3px;
  }
}

@media (min-width: 1024px) {
  .item-post .wrapp-box {
    padding-top: 0;
    margin-top: -3px;
  }
}

.item-post .img-box {
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .item-post .img-box {
    float: left;
    width: 200px;
    margin: 0 9px 13px 0;
  }
}

@media (min-width: 1024px) {
  .item-post .img-box {
    width: 41.8%;
    float: none;
    display: inline-block;
    margin: 0 30px 4px 0;
  }
}

.item-post .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.item-post .category-title {
  padding: 0 0 6px;
}

.item-post h3 {
  font-size: 27px;
  margin: 0 0 7px;
  font-weight: 700;
  max-height: 60px;
  letter-spacing: -0.7px;
  color: #000;
}

@media (min-width: 768px) {
  .item-post h3 {
    font-size: 30px;
  }
}

@media (min-width: 1024px) {
  .item-post h3 {
    margin: 0 0 6px;
  }
}

.item-post h3 a {
  color: inherit;
}

.item-post h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.item-post .info-tag {
  color: #bfbfbf;
  font-size: 10px;
  padding-top: 4px;
  letter-spacing: 0.3px;
  overflow: hidden;
  margin: 0 0 5px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .item-post .info-tag {
    width: 100%;
    letter-spacing: 0.9px;
    margin: 0 0 10px;
  }
}

@media (min-width: 1024px) {
  .item-post .info-tag {
    padding-top: 0;
  }
}

.item-post .info-tag li {
  padding: 0 9px 0 0;
  position: relative;
}

@media (min-width: 768px) {
  .item-post .info-tag li {
    padding: 0 11px 0 0;
  }
}

.item-post .info-tag li:before {
  content: '';
  width: 3px;
  height: 3px;
  background: #bfbfbf;
  border-radius: 50%;
  position: absolute;
  top: 42%;
  left: -8px;
  transform: translateY(-50%);
}

.item-post .members {
  display: block;
  font-size: 11px;
  line-height: 1.45;
  padding: 0 5px 0 0;
  font-weight: 900;
}

@media (min-width: 768px) {
  .item-post .members {
    font-size: 12px;
  }
}

.item-post .members span {
  display: block;
  font-weight: 500;
  letter-spacing: 0;
}

.item-post .price {
  display: block;
  color: #000;
  font: 700 21px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  padding: 2px 0 5px;
}

@media (min-width: 768px) {
  .item-post .price {
    font-size: 24px;
    padding: 3px 0 5px;
  }
}

@media (min-width: 1024px) {
  .item-post .price {
    padding-top: 10px;
  }
}

.item-post .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
}

@media (min-width: 1024px) {
  .item-post .price .sup {
    font-size: 14px;
  }
}

.item-post .price-item {
  float: right;
  width: 54%;
  color: #2a3768;
  font: 700 26px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  padding: 0 0 5px;
  text-align: right;
}

@media (min-width: 768px) {
  .item-post .price-item {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  .item-post .price-item {
    width: 100%;
    float: none;
    text-align: left;
  }
}

.item-post .price-item .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  padding-top: 2px;
}

@media (min-width: 768px) {
  .item-post .price-item .sup {
    font-size: 20px;
    padding-top: 0;
  }
}

.item-post .buy-link {
  display: inline-block;
  vertical-align: top;
  color: #000;
  font-size: 10px;
  line-height: 0.96;
  letter-spacing: 0.3px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .item-post .buy-link {
    letter-spacing: 0.9px;
    margin: 0 0 13px;
  }
}

.item-post .members-lock {
  padding: 12px 0 11px 38px;
  position: relative;
  color: #ffcc00;
  font-size: 10px;
  line-height: 1;
  display: block;
  margin: 8px 0 2px;
  font-weight: 900;
  width: 100%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .item-post .members-lock {
    margin: 14px 0 4px;
    letter-spacing: 0.6px;
    padding-left: 40px;
  }
}

.item-post .members-lock .icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.item-post .members-lock .icon:before {
  font-size: 28px;
}

@media (min-width: 768px) {
  .item-post .members-lock .icon:before {
    font-size: 32px;
  }
}

.item-post .details {
  float: left;
  width: 45%;
}

@media (min-width: 768px) {
  .item-post .details {
    margin-bottom: -2px;
  }
}

@media (min-width: 1024px) {
  .item-post .details {
    float: none;
    width: 100%;
    margin-bottom: 21px;
  }
}

.item-post p {
  margin: 0 0 11px;
}

@media (min-width: 768px) {
  .item-post p {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .item-post p {
    margin: 0 0 14px;
    max-width: 80%;
  }
}

.details {
  overflow: hidden;
  margin: 0;
  font-size: 11px;
  color: #8c8c8c;
}

@media (min-width: 768px) {
  .details {
    letter-spacing: 0.5px;
  }
}

@media (min-width: 1024px) {
  .details {
    margin-bottom: 20px;
  }
}

.details dt {
  float: left;
  clear: both;
  max-width: 50%;
  padding: 0 2px 0 0;
  line-height: 1.66;
  max-width: 50%;
  font-weight: 500;
}

.details dd {
  max-width: 50%;
  float: left;
  font-weight: 700;
  line-height: 1.66;
}

.product-post {
  padding: 15px 0 12px;
  font-size: 13px;
  line-height: 1.23;
  font-weight: 500;
  border-bottom: 1px solid #e9e9e9;
}

@media (min-width: 1024px) {
  .product-post.alt-post {
    padding: 24px 0 19px;
  }
}

@media (min-width: 768px) {
  .product-post {
    padding: 23px 0 20px;
    font-size: 14px;
    line-height: 1.2875;
  }
}

@media (min-width: 1024px) {
  .product-post {
    padding: 30px 0 25px;
  }
}

.product-post .product-block {
  margin: 0;
  max-width: none;
}

.product-post .img-box {
  float: left;
  width: 107px;
  text-align: center;
  margin: 0 10px 0 0;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .product-post .img-box {
    text-align: left;
    width: 178px;
    margin: 0 19px 0 0;
  }
}

@media (min-width: 1024px) {
  .product-post .img-box {
    width: 40.3%;
    margin: 0 30px 4px 0;
  }
}

.product-post .img-box img {
  max-width: 100%;
  height: auto;
  width: auto;
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .product-post .img-box img {
    max-width: 120px;
  }
}

@media (min-width: 1024px) {
  .product-post .img-box img {
    max-width: 100%;
  }
}

.product-post h3 {
  margin-bottom: 13px;
  min-height: 0;
  max-height: 56px;
}

@media (min-width: 768px) {
  .product-post h3 {
    margin-bottom: 15px;
  }
}

@media (min-width: 1024px) {
  .product-post h3 {
    margin-bottom: 13px;
  }
}

.product-post .price {
  padding-bottom: 0;
}

.product-post .rating {
  width: 54px;
  padding: 5px 0;
}

@media (min-width: 768px) {
  .product-post .rating {
    width: 60px;
  }
}

@media (min-width: 768px) {
  .product-post .note {
    padding-top: 6px;
  }
}

.product-post .wrapp {
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .product-post .wrapp {
    overflow: visible;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .product-post .footer-holder {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .product-post .link-holder {
    padding: 8px 10px 5px 0;
    width: 200px;
  }
}

@media (min-width: 1024px) {
  .product-post .link-holder {
    padding: 18px 0 0;
  }
}

.selling-post {
  padding: 15px 0 9px;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  .selling-post {
    padding: 18px 0 12px;
    font-size: 14px;
    line-height: 1.2875;
  }
}

@media (min-width: 1024px) {
  .selling-post {
    padding: 18px 0 16px;
  }
}

.selling-post .img-box {
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .selling-post .img-box {
    float: left;
    width: 200px;
    margin: 2px 21px 0 0;
  }
}

@media (min-width: 1024px) {
  .selling-post .img-box {
    width: 248px;
  }
}

.selling-post .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 1024px) {
  .selling-post .text-box {
    overflow: hidden;
    position: relative;
  }
}

.selling-post h3 {
  font: 700 16px/1.2 "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  color: #000;
}

@media (min-width: 768px) {
  .selling-post h3 {
    font-size: 18px;
    line-height: 1;
    margin: 0 0 2px;
  }
}

@media (min-width: 1024px) {
  .selling-post h3 {
    max-width: 65%;
  }
}

.selling-post h3 a {
  color: inherit;
}

.selling-post h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.selling-post .details {
  font-size: 9px;
  margin: 0 0 8px;
}

@media (min-width: 768px) {
  .selling-post .details {
    margin: 0 0 10px;
  }
}

@media (min-width: 1024px) {
  .selling-post .details {
    margin-bottom: 16px;
    max-width: 65%;
  }
}

.selling-post .details dt, .selling-post .details dd {
  line-height: 1.8;
}

@media (min-width: 768px) {
  .selling-post .details dt, .selling-post .details dd {
    line-height: 2;
  }
}

.selling-post .sub-title {
  display: block;
  text-transform: uppercase;
  font-size: 9px;
}

@media (min-width: 768px) {
  .selling-post .sub-title {
    font-size: 10px;
    padding: 0 0 3px;
  }
}

.selling-post .name {
  color: #000;
  display: block;
  padding: 0 0 3px;
}

@media (min-width: 768px) {
  .selling-post .name {
    font-weight: 500;
    letter-spacing: -0.3px;
    padding: 0 0 7px;
  }
}

.selling-post .link-box {
  font-size: 9px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 11px;
  margin: 0 0 7px;
}

@media (min-width: 768px) {
  .selling-post .link-box {
    font-size: 10px;
    padding: 0 0 18px;
    margin: 0 0 12px;
  }
}

.selling-post .link-box a {
  display: inline-block;
  color: #000;
}

.selling-post .link-box a:hover {
  text-decoration: none;
  color: #2eabe1;
}

.selling-post .link-box .location-link {
  font-weight: 700;
  position: relative;
  display: inline-block;
  padding-left: 12px;
}

@media (min-width: 768px) {
  .selling-post .link-box .location-link {
    padding-left: 14px;
  }
}

.selling-post .link-box .location-link i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-52%);
}

.selling-post .builder {
  font-size: 11px;
  line-height: 1.4;
  float: left;
  max-width: 50%;
  padding-right: 10px;
  font-weight: 900;
}

@media (min-width: 768px) {
  .selling-post .builder {
    font-size: 12px;
    float: none;
    padding-top: 4px;
    display: inline-block;
    vertical-align: middle;
    width: 56%;
    max-width: none;
    margin-right: -4px;
  }
}

@media (min-width: 1024px) {
  .selling-post .builder {
    padding: 2px 0 0 2px;
    margin: 0;
    display: block;
    width: 100%;
  }
}

.selling-post .builder span {
  font-weight: 500;
  padding: 0 2px 0 0;
}

.selling-post .price {
  float: right;
  max-width: 50%;
  text-align: right;
  font: 700 22px/1.2 "Kepler", Georgia, "Times New Roman", Times, serif;
  padding: 4px 2px 0 0;
  color: #2a3768;
}

@media (min-width: 768px) {
  .selling-post .price {
    padding: 0;
    float: none;
    display: inline-block;
    vertical-align: middle;
    width: 44%;
    max-width: none;
    margin-right: -4px;
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .selling-post .price {
    position: absolute;
    top: -4px;
    right: 0;
    margin: 0;
    max-width: 38%;
    padding-left: 10px;
  }
}

.selling-post .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  padding-top: 2px;
}

@media (min-width: 768px) {
  .selling-post .price .sup {
    font-size: 14px;
    padding-top: 3px;
  }
}

.contact-block {
  padding: 20px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .contact-block {
    padding: 36px 34px 29px 36px;
    margin-bottom: 40px;
  }
}

.contact-block:before {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  content: '';
  height: auto;
  width: auto;
  border: 2px solid #fff;
}

.contact-block .holder {
  position: relative;
}

.contact-block h4 {
  font-size: 20px;
  font: 700 20px/1.1 "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 0 15px;
  letter-spacing: -0.7px;
}

@media (min-width: 768px) {
  .contact-block h4 {
    font-size: 24px;
    margin: 0 0 24px;
  }
}

@media (min-width: 1024px) {
  .contact-block .footer-holder {
    float: right;
  }
}

@media (min-width: 1024px) {
  .contact-block .form-col {
    float: left;
    width: 54%;
  }
}

@media (min-width: 1024px) {
  .contact-block .form-col:first-child {
    width: 46%;
    padding-right: 10px;
  }
}

.contact-block .contact-box {
  background: #182149;
  position: relative;
  padding: 28px 29px 16px;
}

.contact-block .contact-box:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0;
  width: 0;
  border: solid 17px;
  border-color: rgba(255, 255, 255, 0.5) #2a3768 #2a3768 rgba(255, 255, 255, 0.5);
}

.contact-block .contact-box .title {
  display: block;
  font: 700 18px/1.2 "Kepler", Georgia, "Times New Roman", Times, serif;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: -0.5px;
  padding: 0 0 7px;
}

.contact-block .contact-box .advertiser {
  display: block;
  font-size: 24px;
  line-height: 1.1;
  margin-left: -2px;
  letter-spacing: -0.6px;
  padding: 0 0 7px;
}

.contact-block .contact-list {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  padding-left: 1px;
  margin: 0 0 15px;
}

.contact-block .contact-list li {
  margin-bottom: 7px;
}

.contact-block .contact-list a {
  color: #fff;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-left: 20px;
}

.contact-block .contact-list a:hover {
  text-decoration: none;
  color: #ffcc00;
}

.contact-block .contact-list a i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.contact-block .contact-list a i.icon-phone {
  margin-top: -2px;
}

.contact-block .contact-list a i.icon-phone:before {
  font-size: 14px;
}

.contact-block .contact-list a i:before {
  font-size: 15px;
}

.contact-block-sm {
  padding: 7px 14px 5px;
  overflow: hidden;
  position: relative;
  margin: 0 auto 24px;
  max-width: 170px;
}

@media (min-width: 768px) {
  .contact-block-sm {
    max-width: 280px;
    margin: 0 0 24px;
    padding: 21px 24px 36px;
  }
}

@media (min-width: 1024px) {
  .contact-block-sm {
    max-width: 384px;
    padding: 21px 34px 30px;
  }
}

.contact-block-sm:before {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  content: '';
  height: auto;
  width: auto;
  border: 2px solid #fff;
}

.contact-block-sm .box {
  text-align: center;
  padding: 10px 0;
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

@media (min-width: 768px) {
  .contact-block-sm .box {
    text-align: left;
    padding: 20px 0 12px;
  }
}

@media (min-width: 768px) {
  .contact-block-sm .box.alt {
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .contact-block-sm .box.alt {
    padding-bottom: 7px;
  }
}

.contact-block-sm .box:first-child {
  border: none;
}

.contact-block-sm .title {
  display: block;
  font: 700 18px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: -0.5px;
  padding: 0 0 12px;
}

@media (min-width: 768px) {
  .contact-block-sm .title {
    font-size: 20px;
    padding: 0 0 21px;
  }
}

.contact-block-sm .advertiser {
  display: block;
  font-size: 18px;
  line-height: 1;
  letter-spacing: -0.4px;
  padding: 0 0 13px;
}

@media (min-width: 768px) {
  .contact-block-sm .advertiser {
    letter-spacing: -0.6px;
    font-size: 22px;
    padding: 0 0 9px;
  }
}

@media (min-width: 1024px) {
  .contact-block-sm .advertiser {
    letter-spacing: 0.4px;
  }
}

.contact-block-sm .contact-list {
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.3px;
  margin: 0 0 -4px;
  text-align: left;
}

@media (min-width: 768px) {
  .contact-block-sm .contact-list {
    font-size: 12px;
    margin: 0 0 18px;
  }
}

.contact-block-sm .contact-list li {
  margin-bottom: 6px;
}

@media (min-width: 768px) {
  .contact-block-sm .contact-list li {
    margin-bottom: 7px;
  }
}

.contact-block-sm .contact-list a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-left: 17px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .contact-block-sm .contact-list a {
    padding-left: 20px;
  }
}

.contact-block-sm .contact-list a:hover {
  text-decoration: none;
  color: #ffcc00;
}

.contact-block-sm .contact-list a i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.contact-block-sm .contact-list a i.icon-phone {
  margin-top: -2px;
}

.contact-block-sm .contact-list a i.icon-phone:before {
  font-size: 12px;
}

@media (min-width: 768px) {
  .contact-block-sm .contact-list a i.icon-phone:before {
    font-size: 14px;
  }
}

.contact-block-sm .contact-list a i:before {
  font-size: 13px;
}

@media (min-width: 768px) {
  .contact-block-sm .contact-list a i:before {
    font-size: 15px;
  }
}

.contact-block-sm .price-title {
  display: block;
  font: 700 20px/1.2 "Kepler", Georgia, "Times New Roman", Times, serif;
  color: rgba(255, 255, 255, 0.5);
  padding: 1px 0 2px;
}

@media (min-width: 768px) {
  .contact-block-sm .price-title {
    font-size: 24px;
    letter-spacing: -0.5px;
    padding: 3px 0 7px;
  }
}

@media (min-width: 1024px) {
  .contact-block-sm .price-title {
    padding: 3px 0 5px;
  }
}

.contact-block-sm .price {
  font: 700 24px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  display: block;
  padding: 0 0 4px;
}

@media (min-width: 768px) {
  .contact-block-sm .price {
    font-size: 40px;
    padding: 0 0 11px;
  }
}

@media (min-width: 1024px) {
  .contact-block-sm .price {
    font-size: 48px;
    padding: 0 0 10px;
  }
}

.contact-block-sm .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  padding-top: 4px;
}

@media (min-width: 768px) {
  .contact-block-sm .price .sup {
    font-size: 23px;
    padding-top: 3px;
  }
}

@media (min-width: 1024px) {
  .contact-block-sm .price .sup {
    font-size: 28px;
    padding-top: 5px;
  }
}

.contact-block-sm .link {
  color: #fff;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  font-size: 11px;
  line-height: 1.1;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .contact-block-sm .link {
    font-size: 12px;
    line-height: 1.14;
    letter-spacing: 0.2px;
    margin-bottom: 0;
  }
}

.contact-block-sm .link:hover {
  text-decoration: none;
  color: #ffcc00;
}

.contact-block-sm .btn.btn-contact {
  line-height: 1;
  padding: 9px 12px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .contact-block-sm .btn.btn-contact {
    padding: 14px 12px;
    line-height: 1.5;
    margin-bottom: 9px;
  }
}

@media (min-width: 1024px) {
  .contact-block-sm .btn.btn-contact {
    margin-bottom: 18px;
  }
}

.contact-block-sm .btn.btn-add {
  background: #1f2a55;
  border-color: #1f2a55;
  letter-spacing: -0.1px;
  margin: 5px 0 0;
  padding: 8px 20px 7px;
}

@media (min-width: 768px) {
  .contact-block-sm .btn.btn-add {
    margin: -3px 0 0;
    padding: 11px 5px 10px;
    letter-spacing: 0;
  }
}

.contact-block-sm .btn.btn-add:focus, .contact-block-sm .btn.btn-add:active:focus, .contact-block-sm .btn.btn-add:active {
  background: #1f2a55;
  border-color: #1f2a55;
}

.contact-block-sm .btn.btn-add i {
  display: none;
}

@media (min-width: 768px) {
  .contact-block-sm .btn.btn-add i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
}

.plans-block {
  padding: 20px 51px 0;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .plans-block {
    background: #fbfbfb;
    border: 1px solid #e9e9e9;
    padding: 25px 17px 24px;
    margin-bottom: 59px;
  }
}

@media (min-width: 1024px) {
  .plans-block {
    padding: 16px 18px 24px;
    margin-bottom: 40px;
  }
}

.plans-block .swiper-pagination {
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .plans-block .swiper-pagination {
    display: none;
  }
}

.plans-block .swiper-container {
  padding-bottom: 46px;
  max-width: 250px;
}

@media (min-width: 768px) {
  .plans-block .swiper-container {
    padding: 0;
    max-width: none;
  }
}

.plans-list {
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .plans-list {
    max-width: none;
    padding: 0;
  }
}

.plans-list > li {
  overflow: hidden;
  position: relative;
}

@media (min-width: 768px) {
  .plans-list > li {
    width: 33.332% !important;
    float: left;
    padding: 0 5px;
  }
}

@media (min-width: 1024px) {
  .plans-list > li {
    padding: 0 4px;
  }
}

.plans-list > li.active .plan-box {
  background: whitesmoke;
  border-color: #bfbfbf;
}

.plans-list > li.active .plan-box .holder-box {
  border: solid #b83e22;
  border-width: 5px 0 0;
  padding-top: 9px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .plans-list > li.active .plan-box .holder-box {
    border-width: 6px 0 0;
  }
}

.plans-list > li.active .plan-box .holder-box:before {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 2px 0;
  border-color: #b83e22 transparent transparent transparent;
}

.plans-list > li.active .img-box {
  margin: 0 -8px 6px;
}

@media (min-width: 768px) {
  .plans-list > li.active .img-box {
    margin: 0 -10px 7px;
  }
}

.plans-list > li.active .img-box:before {
  left: 50%;
  margin-left: -6px;
}

.plans-list > li.active h5 {
  text-align: center;
  margin: 0 0 10px;
}

.plans-list > li.active .fake-radio:before {
  opacity: 1;
  visibility: visible;
}

.plans-list > li.active .price {
  text-align: center;
  font: 700 20px/1.2 "Kepler", Georgia, "Times New Roman", Times, serif;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {
  .plans-list > li.active .price {
    font-size: 22px;
    line-height: 1.2;
    letter-spacing: 0.2px;
  }
}

.plans-list > li.active .price .sup {
  font-size: 12px;
}

.plans-list > li.active .contact-list {
  font-weight: 400;
  text-align: center;
  background: whitesmoke;
  overflow: hidden;
  margin: 6px -11px;
  padding: 6px 10px;
}

@media (min-width: 768px) {
  .plans-list > li.active .contact-list {
    margin: 8px -12px 7px;
    padding: 6px 10px 8px;
  }
}

.plan-box {
  border-radius: 3px;
  padding: 6px 4px 5px;
  font-size: 12px;
  line-height: 1.2;
  border: 1px solid #e9e9e9;
  background: #fbfbfb;
  transition: background .2s, border-color .2s;
}

@media (min-width: 768px) {
  .plan-box {
    padding: 6px 4px 5px;
    font-size: 13px;
    line-height: 1.23;
  }
}

@media (min-width: 1024px) {
  .plan-box {
    padding: 6px;
  }
}

.plan-box .fake-radio {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  width: 23px;
  height: 23px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #e9e9e9;
  transition: background .2s;
}

@media (min-width: 768px) {
  .plan-box .fake-radio {
    margin-top: -12px;
    width: 24px;
    height: 24px;
  }
}

.plan-box .fake-radio:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background: #2ecc71;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s, visibility .2s;
}

.plan-box .img-box {
  position: relative;
  overflow: hidden;
  margin: -5px -11px 7px;
}

.plan-box .img-box:before {
  position: absolute;
  top: 0;
  left: 10px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 5px 0;
  border-color: #fff transparent transparent transparent;
}

@media (min-width: 768px) {
  .plan-box .img-box:before {
    border-width: 12px 6px 0;
    left: 12px;
  }
}

.plan-box .img-box img {
  width: 100%;
  height: auto;
  display: block;
}

.plan-box .heading {
  white-space: nowrap;
  text-align: center;
  padding: 0 2px 3px 4px;
}

.plan-box .heading:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  min-height: 46px;
}

@media (min-width: 768px) {
  .plan-box .heading:after {
    min-height: 52px;
  }
}

@media (min-width: 768px) {
  .plan-box .heading {
    padding: 0 2px 4px 7px;
  }
}

.plan-box .title {
  white-space: normal;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 26px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 900;
  text-align: left;
}

@media (min-width: 768px) {
  .plan-box .title {
    font-size: 16px;
    padding-left: 29px;
  }
}

.plan-box h5 {
  font: 300 17px/1.2 "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 0 6px;
  letter-spacing: -0.5px;
}

@media (min-width: 768px) {
  .plan-box h5 {
    font-size: 20px;
    margin-bottom: 7px;
  }
}

.plan-box .holder-box {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 0 0 2px 2px;
  padding: 4px 9px 5px;
  position: relative;
}

@media (min-width: 768px) {
  .plan-box .holder-box {
    padding: 2px 11px 5px;
  }
}

.plan-box .holder-box p {
  margin: 0;
}

.plan-box .price {
  display: block;
  font-weight: 300;
  padding: 0 0 2px;
}

.plan-box .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 8px;
}

.plan-box .contact-list {
  font-size: 11px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {
  .plan-box .contact-list {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.4px;
  }
}

.plan-box .contact-list li:first-child {
  letter-spacing: 0.6px;
}

.plan-box .contact-list a {
  color: inherit;
}

.plan-box .contact-list a:hover {
  text-decoration: none;
  color: #2eabe1;
}

.detail-post {
  padding: 0 0 9px;
  color: #8c8c8c;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 500;
  max-width: 248px;
  width: 100%;
  margin: 0 auto 30px;
}

@media (min-width: 768px) {
  .detail-post {
    margin-bottom: 40px;
    padding: 0 0 12px;
    font-size: 14px;
    line-height: 1.2875;
  }
}

@media (min-width: 1024px) {
  .detail-post {
    padding: 0 0 16px;
  }
}

.detail-post.hover .hover-box {
  opacity: 1;
  visibility: visible;
}

.detail-post.hover h3 {
  color: #2a3768;
}

.detail-post.hover h3 a {
  color: #2a3768;
}

.detail-post .img-box {
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .detail-post .img-box {
    margin-bottom: 15px;
  }
}

.detail-post .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.detail-post .hover-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: rgba(42, 55, 104, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: visibility .2s, opacity .2s;
}

.detail-post .hover-box i {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  margin: auto;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  color: #2a3768;
}

.detail-post .hover-box i:before {
  line-height: 44px;
}

.detail-post h3 {
  font: 700 18px/1.2 "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 0 1px;
  color: #000;
}

@media (min-width: 768px) {
  .detail-post h3 {
    font-size: 18px;
    line-height: 1;
    margin: 0 0 2px;
  }
}

.detail-post h3 a {
  color: inherit;
}

.detail-post h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.detail-post .details {
  font-size: 10px;
  margin: 0 0 4px;
}

@media (min-width: 768px) {
  .detail-post .details {
    font-size: 10px;
    letter-spacing: 0;
  }
}

.detail-post .details dt, .detail-post .details dd {
  line-height: 1.8;
}

.detail-post .link-box {
  font-size: 10px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 14px;
  margin: 0 0 13px;
}

@media (min-width: 768px) {
  .detail-post .link-box {
    margin: 0 0 12px;
  }
}

.detail-post .link-box a {
  display: inline-block;
  color: #000;
}

.detail-post .link-box a:hover {
  text-decoration: none;
  color: #2eabe1;
}

.detail-post .builder {
  padding-right: 10px;
  font-weight: 900;
}

@media (min-width: 768px) {
  .detail-post .builder {
    font-size: 12px;
    padding-top: 4px;
    margin-right: -4px;
  }
}

@media (min-width: 1024px) {
  .detail-post .builder {
    padding: 2px 0 0 2px;
    margin: 0;
  }
}

.detail-post .builder span {
  font-weight: 500;
  padding: 0 4px 0 0;
}

.detail-post .price {
  font: 700 24px/1.2 "Kepler", Georgia, "Times New Roman", Times, serif;
  color: #2a3768;
}

@media (min-width: 768px) {
  .detail-post .price {
    padding: 0;
    font-size: 24px;
  }
}

.detail-post .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  padding-top: 3px;
}

.document-post {
  padding: 17px 0;
  color: #8c8c8c;
  font-size: 13px;
  line-height: 1.23;
  font-weight: 500;
  border-bottom: 1px solid #e9e9e9;
}

.document-post.item .img-box {
  max-width: 21%;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .document-post.item .img-box {
    margin-right: 21px;
  }
}

.document-post.item h3 {
  max-width: 80%;
}

.document-post.item .btn-group .btn-xs {
  padding: 9px 6px 7px;
  min-width: 110px;
}

@media (min-width: 768px) {
  .document-post.item .btn-group .btn-xs {
    padding: 8px;
    min-width: 124px;
  }
}

@media (min-width: 768px) {
  .document-post {
    padding: 20px 0;
    font-size: 14px;
    line-height: 1.2875;
  }
}

@media (min-width: 768px) {
  .document-post .text-box {
    overflow: hidden;
  }
}

.document-post .holder-box {
  overflow: hidden;
  width: 100%;
  padding: 10px 0 0;
}

@media (min-width: 768px) {
  .document-post .holder-box {
    padding: 0;
    overflow: visible;
  }
}

.document-post .img-box {
  float: left;
  margin-bottom: 14px;
  max-width: 28.2%;
  margin: 2px 15px 0 0;
}

@media (min-width: 768px) {
  .document-post .img-box {
    max-width: 40%;
    margin: 0 19px 0 0;
  }
}

@media (min-width: 1024px) {
  .document-post .img-box {
    margin: 0 19px 0 0;
    max-width: 29%;
  }
}

.document-post .img-box img {
  height: auto;
  max-width: 100%;
}

.document-post .category-title {
  padding: 0 0 5px;
}

.document-post h3 {
  font-size: 27px;
  margin: 0 0 7px;
  font-weight: 700;
  letter-spacing: -0.7px;
  color: #000;
  max-width: 90%;
}

@media (min-width: 768px) {
  .document-post h3 {
    font-size: 30px;
    max-width: none;
  }
}

@media (min-width: 1024px) {
  .document-post h3 {
    margin: 0 0 6px;
  }
}

.document-post h3 a {
  color: inherit;
}

.document-post h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.document-post .info-tag {
  color: #bfbfbf;
  font-size: 9px;
  letter-spacing: 0.8px;
  overflow: hidden;
  margin: 3px 0 14px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .document-post .info-tag {
    width: 100%;
    letter-spacing: 0.9px;
    font-size: 10px;
    margin: 0;
    padding-top: 4px;
  }
}

.document-post .info-tag li {
  display: inline-block;
  vertical-align: top;
  padding: 0 9px 0 0;
  position: relative;
}

@media (min-width: 768px) {
  .document-post .info-tag li {
    padding: 0 11px 0 0;
  }
}

.document-post .info-tag li:before {
  content: '';
  width: 3px;
  height: 3px;
  background: #bfbfbf;
  border-radius: 50%;
  position: absolute;
  top: 42%;
  left: -8px;
  transform: translateY(-50%);
}

.document-post .btn-group.btn-group-alt .btn {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .document-post .btn-group.btn-group-alt .btn {
    margin-top: 17px;
  }
}

.document-post .btn-group .btn-xs {
  padding: 9px 17px 7px;
  min-width: 102px;
}

@media (min-width: 768px) {
  .document-post .btn-group .btn-xs {
    padding: 8px 19px;
    min-width: 114px;
  }
}

.document-post p {
  margin: 0 0 11px;
}

@media (min-width: 768px) {
  .document-post p {
    margin: 0 0 9px;
    max-width: 340px;
  }
}

@media (min-width: 1024px) {
  .document-post p {
    margin: 0 0 10px;
  }
}

.table-wrapp {
  margin-bottom: 30px;
  width: 100%;
}

.table td, .table th {
  text-align: center;
  border-top: 0 !important;
  border-bottom: 1px solid #e9e9e9;
}

.table th {
  text-transform: uppercase;
  color: #bfbfbf;
  font-size: 9px;
  padding-top: 8px !important;
  padding-bottom: 5px !important;
}

@media (min-width: 768px) {
  .table th {
    font-size: 10px;
    padding-bottom: 6px !important;
  }
}

.table .price {
  display: inline-block;
  vertical-align: top;
  font: 700 18px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  padding: 1px 0 0;
}

@media (min-width: 768px) {
  .table .price {
    font-size: 20px;
    padding: 2px 0 0;
  }
}

.table .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 10px;
}

@media (min-width: 768px) {
  .table .price .sup {
    font-size: 12px;
  }
}

.table .old-price {
  font: 700 16px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  display: inline-block;
  color: #bfbfbf;
  position: relative;
  padding-bottom: 10px;
  margin-top: 3px;
}

.table .old-price:before {
  content: '';
  width: 50%;
  border-top: 2px solid #000;
  position: absolute;
  top: 7px;
  left: 48%;
  height: 2px;
  transform: translateX(-50%) rotate(-45deg);
}

@media (min-width: 768px) {
  .table .old-price {
    font-size: 18px;
    padding-bottom: 12px;
    margin-left: 4px;
  }
}

@media (min-width: 1024px) {
  .table .old-price {
    margin-left: 3px;
  }
}

.table .old-price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 9px;
}

@media (min-width: 768px) {
  .table .old-price .sup {
    font-size: 10px;
  }
}

.table time {
  font-weight: 700;
}

.table h5 {
  font-size: 18px;
  font-weight: 400;
  margin: 1px 0 0;
}

@media (min-width: 768px) {
  .table h5 {
    font-size: 20px;
  }
}

.table .btn-del {
  display: inline-block;
  vertical-align: middle;
  color: #8c8c8c;
  text-transform: uppercase;
  position: relative;
  padding-left: 14px;
  font-size: 9px;
  line-height: 1.1;
  white-space: nowrap;
  margin-top: -2px;
}

@media (min-width: 768px) {
  .table .btn-del {
    padding-left: 16px;
    font-size: 10px;
  }
}

@media (min-width: 1024px) {
  .table .btn-del {
    margin-top: 0;
  }
}

.table .btn-del:hover {
  text-decoration: none;
  color: #ffcc00;
}

.table .btn-del:focus, .table .btn-del:active {
  color: #8c8c8c;
}

.table .btn-del i {
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin-top: -1px;
}

.table .btn-del i:before {
  font-size: 9px;
}

@media (min-width: 768px) {
  .table .btn-del i:before {
    font-size: 10px;
  }
}

.table .dash {
  display: inline-block;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.5);
  width: 10px;
  height: 2px;
  border-radius: 2px;
}

.table-1 {
  font-size: 12px;
}

@media (min-width: 768px) {
  .table-1 {
    font-size: 14px;
  }
}

.table-1 td {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

@media (min-width: 768px) {
  .table-1 td {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}

.table-1 tr td:first-child, .table-1 tr th:first-child {
  text-align: left;
  padding-left: 0;
}

.table-1 tr td:last-child, .table-1 tr th:last-child {
  padding-right: 0;
  text-align: right;
}

.table-1 .col-1 {
  width: 38%;
}

@media (min-width: 768px) {
  .table-1 .col-1 {
    width: 32%;
  }
}

@media (min-width: 1024px) {
  .table-1 .col-1 {
    width: 37%;
  }
}

.table-1 .col-2 {
  width: 34%;
}

@media (min-width: 768px) {
  .table-1 .col-2 {
    width: 25.5%;
  }
}

@media (min-width: 1024px) {
  .table-1 .col-2 {
    width: 27.5%;
  }
}

.table-1 .col-3 {
  width: 25.5%;
}

@media (min-width: 1024px) {
  .table-1 .col-3 {
    width: 20.5%;
  }
}

.table-1 .col-4 {
  width: 28%;
}

@media (min-width: 768px) {
  .table-1 .col-4 {
    width: 16%;
  }
}

@media (min-width: 1024px) {
  .table-1 .col-4 {
    width: 15%;
  }
}

@media (max-width: 767px) {
  .table-1 tr td:nth-last-child(2), .table-1 tr th:nth-last-child(2) {
    display: none;
  }
}

.table-responsive {
  border: none;
}

.table-responsive .table {
  width: 730px;
  max-width: none;
}

@media (min-width: 768px) {
  .table-responsive .table {
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
  }
}

.table-2 {
  font-weight: 900;
  font-size: 11px;
}

@media (min-width: 768px) {
  .table-2 {
    font-size: 12px;
  }
}

.table-2 tr td {
  padding-top: 20px !important;
  padding-bottom: 21px !important;
}

@media (min-width: 768px) {
  .table-2 tr td {
    padding-top: 23px !important;
    padding-bottom: 23px !important;
  }
}

.table-2 tr td:first-child, .table-2 tr th:first-child {
  padding-left: 20px;
  text-align: left;
}

.table-2 tr td:last-child, .table-2 tr th:last-child {
  padding-left: 29px;
}

@media (min-width: 1024px) {
  .table-2 tr td:last-child, .table-2 tr th:last-child {
    padding-left: 26px;
  }
}

.table-2 tr td:nth-last-child(2), .table-2 tr th:nth-last-child(2) {
  text-align: right;
}

.table-2 .col-1 {
  width: 19.5%;
}

@media (min-width: 1024px) {
  .table-2 .col-1 {
    width: 22.45%;
  }
}

.table-2 .col-2 {
  width: 13%;
}

@media (min-width: 1024px) {
  .table-2 .col-2 {
    width: 13%;
  }
}

.table-2 .col-3 {
  width: 15.5%;
}

@media (min-width: 1024px) {
  .table-2 .col-3 {
    width: 18%;
  }
}

.table-2 .col-4 {
  width: 23.5%;
}

@media (min-width: 1024px) {
  .table-2 .col-4 {
    width: 20.5%;
  }
}

.table-2 .col-5 {
  width: 11.2%;
}

@media (min-width: 1024px) {
  .table-2 .col-5 {
    width: 9.8%;
  }
}

.table-2 .col-6 {
  width: 17.3%;
}

@media (min-width: 1024px) {
  .table-2 .col-6 {
    width: 16.25%;
  }
}

.table-striped tr:first-child {
  background: none !important;
}

.table-striped tr:nth-child(odd) {
  background: #f00;
}

.article-box {
  padding: 18px 0 1px;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  .article-box.item-box {
    padding-bottom: 12px;
  }
}

@media (min-width: 1024px) {
  .article-box.item-box {
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .article-box.item-box .img-box {
    margin-bottom: 8px;
  }
}

@media (min-width: 1024px) {
  .article-box.item-box .img-box {
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .article-box.item-box .holder-box {
    max-width: none;
  }
}

@media (min-width: 768px) {
  .article-box.item-box .footer-box {
    padding: 10px 0 0;
  }
}

@media (min-width: 768px) {
  .article-box.item-box .builder {
    padding-top: 4px;
  }
}

@media (min-width: 1024px) {
  .article-box.item-box .builder {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .article-box {
    padding: 18px 0 15px;
    font-size: 14px;
    line-height: 1.2875;
  }
}

@media (min-width: 1024px) {
  .article-box {
    padding: 18px 0 15px;
  }
}

.article-box .img-box {
  float: left;
  width: 33.2%;
  margin: 0 10px 15px 0;
}

@media (min-width: 768px) {
  .article-box .img-box {
    float: left;
    width: 200px;
    margin: 2px 21px 5px 0;
  }
}

@media (min-width: 1024px) {
  .article-box .img-box {
    width: 230px;
    margin: 2px 20px 5px 0;
  }
}

.article-box .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .article-box .text-box {
    overflow: hidden;
    position: relative;
  }
}

.article-box .link-box {
  font-size: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 12px;
}

@media (min-width: 768px) {
  .article-box .link-box {
    font-size: 10px;
    padding-bottom: 16px;
  }
}

@media (min-width: 1024px) {
  .article-box .link-box {
    padding-bottom: 9px;
  }
}

.article-box .link-box a {
  display: none;
  color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 1024px) {
  .article-box .link-box a {
    display: inline-block;
  }
}

.article-box .link-box a:hover {
  text-decoration: none;
  color: #000;
}

.article-box .builder {
  font-size: 11px;
  line-height: 1.5;
  max-width: 44%;
  display: inline-block;
  padding-right: 10px;
  font-weight: 900;
}

@media (min-width: 768px) {
  .article-box .builder {
    font-size: 12px;
    padding-top: 1px;
    width: 56%;
    max-width: none;
  }
}

.article-box .builder span {
  font-weight: 500;
  padding: 0 2px 0 0;
}

.article-box .price {
  font: 700 22px/1.2 "Kepler", Georgia, "Times New Roman", Times, serif;
  color: #2a3768;
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .article-box .price {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .article-box .price {
    display: inline-block;
    width: auto;
  }
}

.article-box .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  padding-top: 2px;
}

@media (min-width: 768px) {
  .article-box .price .sup {
    font-size: 14px;
    padding-top: 3px;
  }
}

.article-box .category-title {
  padding-bottom: 5px;
}

@media (min-width: 768px) {
  .article-box .category-title {
    padding-bottom: 4px;
  }
}

.article-box h3 {
  font-size: 24px;
  line-height: 1.2;
  margin: 0 0 5px;
  letter-spacing: -0.8px;
  color: #000;
}

@media (min-width: 768px) {
  .article-box h3 {
    font-size: 30px;
    margin: 0 0 4px;
    letter-spacing: -1px;
  }
}

.article-box h3 a {
  color: inherit;
}

.article-box h3 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.article-box .details {
  font-size: 9px;
  margin: 0 0 4px;
}

@media (min-width: 768px) {
  .article-box .details {
    margin: 0 0 4px;
  }
}

@media (min-width: 1024px) {
  .article-box .details {
    margin-bottom: 4px;
  }
}

.article-box .details dt, .article-box .details dd {
  line-height: 1.8;
}

@media (min-width: 768px) {
  .article-box .details dt, .article-box .details dd {
    line-height: 2;
  }
}

.article-box .holder-box {
  overflow: hidden;
}

@media (min-width: 768px) {
  .article-box .holder-box {
    max-width: 63%;
  }
}

.article-box .footer-box {
  border-top: 1px solid #e9e9e9;
  overflow: hidden;
  width: 100%;
  clear: both;
  padding: 6px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 768px) {
  .article-box .footer-box {
    padding: 16px 0 0;
  }
}

@media (min-width: 1024px) {
  .article-box .footer-box {
    padding: 13px 0 0;
  }
}

.article-box .footer-box .btn {
  min-width: 138px;
}

@media (min-width: 768px) {
  .article-box .footer-box .btn {
    min-width: 154px;
  }
}

.article-box .detail-box {
  border-top: 1px solid #e9e9e9;
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 768px) {
  .article-box .detail-box {
    max-width: 36%;
    position: absolute;
    top: 0;
    right: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: none;
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .article-box .detail-box {
    max-width: 35%;
  }
}

.article-box .detail-box .btn {
  min-width: 100px;
}

@media (min-width: 768px) {
  .article-box .detail-box .btn {
    min-width: 113px;
  }
}

.article-box .info-detail {
  display: inline-block;
  position: relative;
  padding: 0 15px 0 21px;
  font-size: 9px;
  line-height: 1;
  max-width: 63%;
  margin-top: 2px;
}

@media (min-width: 768px) {
  .article-box .info-detail {
    max-width: none;
    display: block;
    font-size: 10px;
    padding-left: 24px;
    padding-right: 0;
    margin-bottom: 7px;
  }
}

.article-box .info-detail i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-58%);
  color: 1;
}

.article-box .info-detail i:before {
  font-size: 14px;
}

@media (min-width: 768px) {
  .article-box .info-detail i:before {
    font-size: 16px;
  }
}

.article-box .btn.btn-default {
  border-color: #bfbfbf;
  background: #bfbfbf;
}

.article-box .btn.btn-default:hover {
  border-color: #d4d4d4;
  background: #d4d4d4;
}

.article-box .btn.btn-default:focus, .article-box .btn.btn-default:active:focus, .article-box .btn.btn-default:active {
  border-color: #bfbfbf;
  background: #bfbfbf;
}

.modifer-list {
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  margin: 0;
}

@media (min-width: 1024px) {
  .modifer-list {
    margin-top: 1px;
  }
}

.modifer-list li {
  display: inline-block;
  vertical-align: middle;
  margin-left: 24px;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .modifer-list li {
    margin-left: 26px;
  }
}

.modifer-list li:first-child {
  margin: 0;
}

.modifer-list a {
  display: inline-block;
  vertical-align: middle;
  color: #8c8c8c;
  text-transform: uppercase;
  position: relative;
  padding-left: 14px;
  font-size: 9px;
  line-height: 1.1;
  white-space: nowrap;
  margin-top: -2px;
}

@media (min-width: 768px) {
  .modifer-list a {
    padding-left: 16px;
    font-size: 10px;
  }
}

@media (min-width: 1024px) {
  .modifer-list a {
    margin-top: 0;
  }
}

.modifer-list a:hover {
  text-decoration: none;
  color: #ffcc00;
}

.modifer-list a:focus, .modifer-list a:active {
  color: #8c8c8c;
}

.modifer-list a i {
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin-top: -1px;
}

.modifer-list a i:before {
  font-size: 9px;
}

@media (min-width: 768px) {
  .modifer-list a i:before {
    font-size: 10px;
  }
}

.table-checkout {
  font-size: 11px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .table-checkout {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .table-checkout.cart-item tr td:first-child, .table-checkout.cart-item tr th:first-child {
    width: 51.6%;
  }
}

@media (min-width: 1024px) {
  .table-checkout.cart-item tr td:first-child, .table-checkout.cart-item tr th:first-child {
    width: 55.5%;
  }
}

@media (min-width: 768px) {
  .table-checkout.cart-item tr td:first-child + th,
  .table-checkout.cart-item tr td:first-child + td, .table-checkout.cart-item tr th:first-child + th,
  .table-checkout.cart-item tr th:first-child + td {
    width: 21.4%;
    padding-top: 45px !important;
  }
}

@media (min-width: 1024px) {
  .table-checkout.cart-item tr td:first-child + th,
  .table-checkout.cart-item tr td:first-child + td, .table-checkout.cart-item tr th:first-child + th,
  .table-checkout.cart-item tr th:first-child + td {
    width: 19.5%;
  }
}

@media (min-width: 768px) {
  .table-checkout.cart-item tr td:nth-last-child(2), .table-checkout.cart-item tr th:nth-last-child(2) {
    width: 13%;
    padding-top: 60px !important;
  }
}

@media (min-width: 1024px) {
  .table-checkout.cart-item tr td:nth-last-child(2), .table-checkout.cart-item tr th:nth-last-child(2) {
    width: 12%;
  }
}

@media (min-width: 768px) {
  .table-checkout.cart-item tr td:last-child, .table-checkout.cart-item tr th:last-child {
    width: 14%;
    padding-top: 78px !important;
  }
}

@media (min-width: 1024px) {
  .table-checkout.cart-item tr td:last-child, .table-checkout.cart-item tr th:last-child {
    width: 13%;
  }
}

.table-checkout.cart-item tr th:nth-last-child(2), .table-checkout.cart-item tr th:last-child,
.table-checkout.cart-item tr th:first-child + th, .table-checkout.cart-item tr th:first-child {
  padding-top: 8px !important;
}

@media (max-width: 767px) {
  .table-checkout thead {
    display: none;
  }
}

.table-checkout thead tr th {
  padding-top: 8px !important;
  border-bottom-width: 1px;
}

.table-checkout tr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
  padding: 18px 0 15px;
}

@media (min-width: 768px) {
  .table-checkout tr {
    border: none;
    float: none;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .table-checkout tr td {
    padding: 0 2px 0 0 !important;
    border-width: 0;
  }
}

@media (min-width: 768px) {
  .table-checkout tr td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.table-checkout tr td, .table-checkout tr th {
  float: right;
  width: 35%;
  text-align: right;
}

@media (min-width: 768px) {
  .table-checkout tr td, .table-checkout tr th {
    float: none;
  }
}

.table-checkout tr td:first-child, .table-checkout tr th:first-child {
  text-align: left;
  float: left;
  width: 65%;
}

@media (min-width: 768px) {
  .table-checkout tr td:first-child, .table-checkout tr th:first-child {
    width: 51.8%;
    float: none;
    padding-left: 0 !important;
  }
}

@media (min-width: 1024px) {
  .table-checkout tr td:first-child, .table-checkout tr th:first-child {
    width: 56%;
  }
}

@media (min-width: 768px) {
  .table-checkout tr td:first-child + th,
  .table-checkout tr td:first-child + td, .table-checkout tr th:first-child + th,
  .table-checkout tr th:first-child + td {
    width: 16.2%;
    text-align: left;
    padding-top: 42px !important;
  }
}

@media (min-width: 1024px) {
  .table-checkout tr td:first-child + th,
  .table-checkout tr td:first-child + td, .table-checkout tr th:first-child + th,
  .table-checkout tr th:first-child + td {
    width: 18%;
  }
}

.table-checkout tr td:last-child, .table-checkout tr th:last-child {
  padding-right: 0;
}

@media (min-width: 768px) {
  .table-checkout tr td:last-child, .table-checkout tr th:last-child {
    width: 21%;
    padding-top: 75px !important;
  }
}

@media (min-width: 1024px) {
  .table-checkout tr td:last-child, .table-checkout tr th:last-child {
    width: 16%;
  }
}

@media (min-width: 768px) {
  .table-checkout tr td:last-child .box, .table-checkout tr th:last-child .box {
    margin-top: -33px;
  }
}

@media (min-width: 768px) {
  .table-checkout tr td:nth-last-child(2), .table-checkout tr th:nth-last-child(2) {
    width: 11%;
    padding-top: 75px !important;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .table-checkout tr td:nth-last-child(2), .table-checkout tr th:nth-last-child(2) {
    width: 10%;
  }
}

.table-checkout tr th:nth-last-child(2), .table-checkout tr th:last-child,
.table-checkout tr th:first-child + th, .table-checkout tr th:first-child {
  padding-top: 8px !important;
}

.table-checkout .category-title {
  padding-bottom: 14px;
}

@media (min-width: 768px) {
  .table-checkout .category-title {
    padding-bottom: 15px;
  }
}

.table-checkout h4 {
  font: 700 18px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  letter-spacing: -0.5px;
  margin: 0 0 7px;
}

@media (min-width: 768px) {
  .table-checkout h4 {
    font-size: 20px;
    letter-spacing: -0.5px;
    margin: 0 0 9px;
  }
}

.table-checkout h4 br {
  display: none;
}

@media (min-width: 768px) {
  .table-checkout h4 br {
    display: inline;
  }
}

.table-checkout h4 a {
  color: inherit;
}

.table-checkout h4 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.table-checkout .price {
  padding: 1px 0 8px;
  display: block;
}

@media (min-width: 768px) {
  .table-checkout .price {
    padding: 0 0 10px;
  }
}

@media (min-width: 768px) {
  .table-checkout .old-price {
    margin-top: 3px;
  }
}

.table-checkout .img-box {
  float: left;
  width: 28.4%;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .table-checkout .img-box {
    width: 127px;
    margin-right: 20px;
  }
}

.table-checkout .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.table-checkout .text-box {
  overflow: hidden;
}

.table-checkout .text-box p {
  margin: 0 0 12px;
}

@media (min-width: 768px) {
  .table-checkout .text-box p {
    margin-bottom: 14px;
  }
}

.table-checkout .rating-box {
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .table-checkout .rating-box {
    padding-bottom: 19px;
  }
}

.table-checkout .rating-box .rating {
  width: 82px;
  height: 14px;
  display: block;
}

@media (min-width: 768px) {
  .table-checkout .rating-box .rating {
    width: 91px;
    height: 16px;
  }
}

.table-checkout .rating-box .rating img {
  display: block;
  width: 100%;
  height: auto;
}

.table-checkout .rating-box .note {
  display: block;
  color: #8c8c8c;
  padding-top: 4px;
  font-size: 9px;
}

@media (min-width: 768px) {
  .table-checkout .rating-box .note {
    padding-top: 4px;
    font-size: 10px;
  }
}

.table-checkout .number {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font: 700 18px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  padding: 7px 0 10px;
  width: 66px;
}

@media (min-width: 768px) {
  .table-checkout .number {
    font-size: 20px;
    padding: 0;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .table-checkout .number {
    padding-left: 10px;
  }
}

.table-checkout .detail {
  display: block;
  color: #bfbfbf;
  font-size: 11px;
  letter-spacing: -0.2px;
}

@media (min-width: 768px) {
  .table-checkout .detail {
    font-size: 12px;
    letter-spacing: 0;
  }
}

.table-checkout .btn {
  min-width: 128px;
}

@media (min-width: 768px) {
  .table-checkout .btn {
    min-width: 153px;
  }
}

.table-checkout .btn.btn-default {
  border-color: #bfbfbf;
  background: #bfbfbf;
}

.table-checkout .btn.btn-default:hover {
  border-color: #d4d4d4;
  background: #d4d4d4;
}

.table-checkout .btn.btn-default:focus, .table-checkout .btn.btn-default:active:focus, .table-checkout .btn.btn-default:active {
  border-color: #bfbfbf;
  background: #bfbfbf;
}

.table-checkout .jcf-select {
  width: auto;
  min-width: 63px;
  height: 44px;
  margin: 4px 0 11px;
}

@media (min-width: 768px) {
  .table-checkout .jcf-select {
    height: 48px;
    width: 70px;
  }
}

.table-checkout .jcf-select .jcf-select-opener {
  width: 26px;
  text-align: left;
}

@media (min-width: 768px) {
  .table-checkout .jcf-select .jcf-select-opener {
    width: 28px;
  }
}

.table-checkout .jcf-select .jcf-select-opener:before {
  left: 0;
  top: 19px;
}

@media (min-width: 768px) {
  .table-checkout .jcf-select .jcf-select-opener:before {
    top: 22px;
  }
}

.table-checkout .jcf-select .jcf-select-text {
  text-align: center;
  margin: 0 30px 0 5px;
  padding: 12px 0;
  font: 700 18px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
}

@media (min-width: 768px) {
  .table-checkout .jcf-select .jcf-select-text {
    padding: 14px 0;
    font-size: 20px;
  }
}

.table-checkout .btn-del {
  font-weight: 500;
  font-size: 10px;
  margin-top: 0;
  padding-left: 18px;
  padding-top: 2px;
}

@media (min-width: 768px) {
  .table-checkout .btn-del {
    padding-left: 22px;
    margin-top: 14px;
  }
}

.table-checkout .btn-del i {
  margin-top: 0;
  font-weight: inherit;
}

.table-checkout .btn-del i:before {
  font-size: 11px;
}

@media (min-width: 768px) {
  .table-checkout .btn-del i:before {
    font-size: 13px;
  }
}

.info-table tr td:first-child, .info-table tr th:first-child {
  text-align: left;
  width: 70%;
  padding-left: 0;
  padding-top: 19px;
}

.info-table tr td:last-child, .info-table tr th:last-child {
  text-align: right;
  width: 30%;
  padding-right: 0;
  padding-top: 35px;
}

@media (min-width: 768px) {
  .info-table tr td:last-child, .info-table tr th:last-child {
    padding-top: 76px;
  }
}

.info-table .detail-list {
  width: 100%;
  margin-bottom: 7px;
}

.info-table .detail-list dt, .info-table .detail-list dd {
  line-height: 1.4285;
  float: left;
}

.info-table .detail-list dt {
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  clear: both;
  padding-right: 4px;
  padding-top: 2px;
  padding: 2px 4px 5px 0;
  width: 100%;
}

@media (min-width: 768px) {
  .info-table .detail-list dt {
    width: auto;
    padding: 2px 4px 10px 0;
    font-size: 12px;
  }
}

.info-table .detail-list dd {
  width: 100%;
}

@media (min-width: 768px) {
  .info-table .detail-list dd {
    width: auto;
  }
}

.info-table .detail-list dd p {
  margin: 0 0 9px;
}

@media (min-width: 768px) {
  .info-table .detail-list dd p {
    margin: 0 0 18px;
  }
}

.info-table .info-body {
  padding-top: 10px;
  padding-bottom: 2px;
  font-size: 12px;
  line-height: 1.4285;
  border-top: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  .info-table .info-body {
    font-size: 14px;
    padding-top: 22px;
  }
}

.info-table .category-title {
  padding-bottom: 5px;
}

.info-table h4 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 5px;
  letter-spacing: -0.7px;
}

@media (min-width: 768px) {
  .info-table h4 {
    font-size: 30px;
  }
}

.info-table .details {
  color: #8c8c8c;
  font-size: 9px;
  margin: 0 0 7px;
}

.info-table .details dt, .info-table .details dd {
  line-height: 2;
}

.info-table .price-item {
  color: #8c8c8c;
  display: block;
  text-transform: uppercase;
  font: 700 20px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  letter-spacing: 0.2px;
  padding: 0 0 12px;
}

@media (min-width: 768px) {
  .info-table .price-item {
    font-size: 23px;
  }
}

.info-table .price-item .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
}

@media (min-width: 768px) {
  .info-table .price-item .sup {
    font-size: 14px;
  }
}

.info-table .img-list {
  margin: 0;
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
}

.info-table .img-list li {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px 5px 0;
  max-width: 50%;
}

@media (min-width: 768px) {
  .info-table .img-list li {
    padding: 0 20px 20px 0;
    max-width: none;
  }
}

.info-table .img-list li img {
  max-width: 100%;
  height: auto;
}

.aside-box {
  margin-bottom: 24px;
}

.aside-box .head-box {
  border-bottom: 1px solid #e9e9e9;
  text-align: right;
  padding: 0 0 12px;
}

@media (min-width: 1024px) {
  .aside-box .head-box.item-head {
    padding-right: 15px;
  }
}

@media (min-width: 1024px) {
  .aside-box .head-box.item-head h4,
  .aside-box .head-box.item-head h3 {
    margin-right: -15px;
  }
}

.aside-box .head-box h4,
.aside-box .head-box h3 {
  text-align: left;
}

.aside-box .head-box .btn-link {
  margin-right: -5px;
}

.aside-box .head-box .category-title {
  letter-spacing: 0.6px;
  margin-bottom: -1px;
}

.aside-box h3 {
  line-height: 1;
  margin: 0 0 4px;
}

@media (min-width: 1024px) {
  .aside-box h3 {
    font-size: 42px;
    margin: 0 0 2px;
  }
}

.aside-box h4 {
  font-size: 28px;
  line-height: 1;
  margin: 0 0 2px;
}

.ads-post {
  font-size: 12px;
  width: 100%;
  font-weight: 500;
  padding: 12px 0 4px;
  color: #bfbfbf;
  border-bottom: 1px solid #e9e9e9;
}

@media (min-width: 1024px) {
  .ads-post {
    padding: 12px 0 6px;
  }
}

.ads-post.dots-1 h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ads-post.related h5 {
  margin: 1px 0 8px;
}

@media (min-width: 1024px) {
  .ads-post.related h5 {
    margin: 5px 0 7px;
  }
}

.ads-post .img-holder,
.ads-post .img-box {
  float: left;
  position: relative;
  margin: 0 10px 8px 0;
  width: 100px;
}

@media (min-width: 1024px) {
  .ads-post .img-holder,
  .ads-post .img-box {
    margin: 0 16px 6px 0;
    width: 135px;
  }
}

.ads-post .img-holder img,
.ads-post .img-box img {
  display: block;
  width: 100%;
  height: auto;
}

.ads-post .img-holder:hover .icon,
.ads-post .img-box:hover .icon {
  color: #ffcc00;
}

.ads-post .img-holder .icon,
.ads-post .img-box .icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: color .2s;
  transform: translate(-50%, -50%);
}

.ads-post .img-holder {
  width: 84px !important;
  margin: 0 16px 8px 0;
}

@media (min-width: 1024px) {
  .ads-post .img-holder {
    margin: 0 16px 6px 0;
  }
}

.ads-post .img-holder.bordered-box {
  border: 1px solid #bfbfbf;
}

.ads-post .category-title {
  padding: 2px 0 5px;
  letter-spacing: 1.2px;
}

@media (min-width: 1024px) {
  .ads-post .category-title {
    padding: 1px 0 2px;
  }
}

.ads-post .text-wrapp,
.ads-post .text-box {
  overflow: hidden;
  margin-top: -3px;
}

@media (min-width: 1024px) {
  .ads-post .text-wrapp,
  .ads-post .text-box {
    margin-top: -2px;
  }
}

.ads-post .text-wrapp p,
.ads-post .text-box p {
  margin: 0;
}

.ads-post .text-wrapp time,
.ads-post .text-box time {
  display: block;
  padding: 2px 0 1px;
}

@media (min-width: 1024px) {
  .ads-post .text-wrapp time,
  .ads-post .text-box time {
    padding: 2px 0 0;
  }
}

.ads-post .text-wrapp {
  padding-right: 15px;
}

@media (min-width: 1024px) {
  .ads-post .text-wrapp {
    padding-right: 50px;
  }
}

.ads-post h5 {
  font: 500 16px/1.25 "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 0 4px;
  color: #000;
}

@media (min-width: 1024px) {
  .ads-post h5 {
    margin: 5px 0 4px;
  }
}

.ads-post h5 a {
  color: inherit;
}

.ads-post h5 a:hover {
  text-decoration: none;
  color: #2a3768;
}

.ads-post .details {
  margin: 0 0 2px;
  font-size: 11px;
  padding-left: 1px;
}

.ads-post .details dt, .ads-post .details dd {
  line-height: 1.66;
}

.ads-post .price {
  font: 700 20px/1.2 "Kepler", Georgia, "Times New Roman", Times, serif;
  color: #2a3768;
  display: block;
}

.ads-post .price .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  padding-top: 2px;
}

.ads-post .disclosed {
  display: block;
  text-transform: uppercase;
  font-weight: 900;
  color: #2a3768;
  font-size: 10px;
  line-height: 1;
  padding: 4px 0;
}

.ads-post .number {
  font-size: 16px;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  color: #000;
  display: block;
  margin-top: -2px;
  padding: 0 0 16px;
}

.ads-post .date {
  display: block;
  color: #8c8c8c;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1;
  font-weight: 500;
}

@media (min-width: 1024px) {
  .ads-post .link-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.ads-post .link-box a {
  font-size: 10px;
  color: #000;
  display: none;
  margin-top: 7px;
}

.ads-post .link-box a:hover {
  text-decoration: none;
  color: #2eabe1;
}

@media (min-width: 1024px) {
  .ads-post .link-box a {
    display: inline-block;
  }
}

.ads-post .info-tag {
  color: #bfbfbf;
  font-size: 10px;
  padding-top: 2px;
  letter-spacing: 1px;
  overflow: hidden;
  margin: 0 0 3px;
  font-weight: 700;
}

@media (min-width: 1024px) {
  .ads-post .info-tag {
    margin: 0 0 5px;
  }
}

.ads-post .info-tag li {
  padding: 0 9px 0 0;
  position: relative;
}

.ads-post .info-tag li:before {
  content: '';
  width: 3px;
  height: 3px;
  background: #bfbfbf;
  border-radius: 50%;
  position: absolute;
  top: 42%;
  left: -8px;
  transform: translateY(-50%);
}

.icon-star:before {
  font-size: 14px;
}

.icon-lock-circle:before {
  font-size: 32px;
}

.icon-top-circle:before {
  font-size: 48px;
}

.icon-btn-top:before {
  font-size: 12px;
}

.icon-chevron-down:before {
  font-size: 16px;
}

.icon-chevron-l:before {
  font-size: 16px;
}

.icon-chevron-top:before {
  font-size: 16px;
}

.icon-chevron-r:before {
  font-size: 16px;
}

.icon-full:before {
  font-size: 32px;
}

.icon-reduce:before {
  font-size: 32px;
}

.icon-pause:before {
  font-size: 13px;
}

.icon-play:before {
  font-size: 10px;
}

.icon-stop:before {
  font-size: 10px;
}

.icon-amazon:before {
  font-size: 20px;
}

.icon-anchor:before {
  font-size: 12px;
}

.icon-android:before {
  font-size: 19px;
}

.icon-apple:before {
  font-size: 19px;
}

.icon-arrow-b:before {
  font-size: 12px;
}

.icon-arrow-l:before {
  font-size: 10px;
}

.icon-arrow-r:before {
  font-size: 10px;
}

.icon-arrow-t:before {
  font-size: 12px;
}

.icon-btn-play-lg:before {
  font-size: 82px;
}

.icon-btn-play-sm:before {
  font-size: 32px;
}

.icon-btn-plus:before {
  font-size: 24px;
}

.icon-btn-plus1:before {
  font-size: 48px;
}

.icon-cart:before {
  font-size: 12px;
}

.icon-chat:before {
  font-size: 40px;
}

.icon-chat-1:before {
  font-size: 30px;
}

.icon-check:before {
  font-size: 10px;
}

.icon-check-1:before {
  font-size: 17px;
}

.icon-check-lg:before {
  font-size: 30px;
}

.icon-chevron-b:before {
  font-size: 5px;
}

.icon-close:before {
  font-size: 10px;
}

.icon-close.icon-lg:before {
  font-size: 16px;
}

.icon-download:before {
  font-size: 17px;
}

.icon-email:before {
  font-size: 12px;
}

.icon-email-1:before {
  font-size: 33px;
}

.icon-email-2:before {
  font-size: 10px;
}

.icon-eye:before {
  font-size: 16px;
}

.icon-facebook:before {
  font-size: 16px;
}

.icon-twitter:before {
  font-size: 14px;
}

.icon-facebook-square:before {
  font-size: 16px;
}

.icon-facebook-square.icon-lg:before {
  font-size: 32px;
}

.icon-instagram-square:before {
  font-size: 16px;
}

.icon-instagram-square.icon-lg:before {
  font-size: 32px;
}

.icon-twitter-square:before {
  font-size: 16px;
}

.icon-twitter-square.icon-lg:before {
  font-size: 32px;
}

.icon-rss-square:before {
  font-size: 16px;
}

.icon-rss-square.icon-lg:before {
  font-size: 32px;
}

.icon-globe:before {
  font-size: 15px;
}

.icon-group:before {
  font-size: 46px;
}

.icon-group-1:before {
  font-size: 37px;
}

.icon-img:before {
  font-size: 20px;
}

.icon-info:before {
  font-size: 16px;
}

.icon-label:before {
  font-size: 43px;
}

.icon-like:before {
  font-size: 11px;
}

.icon-lock:before {
  font-size: 16px;
}

.icon-lock-1:before {
  font-size: 54px;
}

.icon-marker:before {
  font-size: 12px;
}

.icon-marker.icon-lg:before {
  font-size: 15px;
}

.icon-notebook:before {
  font-size: 61px;
}

.icon-notes:before {
  font-size: 46px;
}

.icon-notes-1:before {
  font-size: 72px;
}

.icon-oliver:before {
  font-size: 12px;
}

.icon-pencil:before {
  font-size: 11px;
}

.icon-pencil-circle:before {
  font-size: 36px;
}

.icon-phone:before {
  font-size: 15px;
}

.icon-photo:before {
  font-size: 12px;
}

.icon-pin:before {
  font-size: 16px;
}

.icon-plus:before {
  font-size: 28px;
}

.icon-print:before {
  font-size: 14px;
}

.icon-recend:before {
  font-size: 14px;
}

.icon-reload:before {
  font-size: 20px;
}

.icon-rope:before {
  font-size: 52px;
}

.icon-search:before {
  font-size: 12px;
}

.icon-settings:before {
  font-size: 12px;
}

.icon-truck:before {
  font-size: 69px;
}

.icon-user:before {
  font-size: 11px;
}

.icon-user-1:before {
  font-size: 16px;
}

body {
  font-weight: 300;
  min-width: 320px;
}

::selection {
  background: #2a3768;
  color: #fff;
}

address {
  font-size: 14px;
}

@media (min-width: 1024px) {
  address {
    font-size: 16px;
  }
}

address .title {
  font: 700 32px/1 "Kepler", Georgia, "Times New Roman", Times, serif;
  padding-bottom: 3px;
}

@media (min-width: 768px) {
  address .title {
    font-size: 42px;
  }
}

address .sub-title,
address .title {
  display: block;
}

address a {
  color: inherit;
}

address a:hover {
  text-decoration: none;
  color: #ffcc00;
}

address a:active {
  color: inherit;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 10px;
}

@media (min-width: 768px) {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 24px;
  }
}

h1 mark, h2 mark, h3 mark, h4 mark, h5 mark, h6 mark, .h1 mark, .h2 mark, .h3 mark, .h4 mark, .h5 mark, .h6 mark {
  background: none;
  padding: 0;
  font-weight: 300;
}

h1, .h1 {
  font-size: 44px;
  line-height: 1.1;
}

@media (min-width: 1024px) {
  h1, .h1 {
    font-size: 87px;
    line-height: 1.1;
  }
}

h2, .h2 {
  font-size: 36px;
  line-height: 1.05;
}

@media (min-width: 1024px) {
  h2, .h2 {
    font-size: 72px;
  }
}

h3, .h3 {
  font-size: 32px;
}

@media (min-width: 1024px) {
  h3, .h3 {
    font-size: 48px;
  }
}

h4, .h4 {
  font-size: 28px;
}

@media (min-width: 1024px) {
  h4, .h4 {
    font-size: 32px;
    line-height: 1.1;
  }
}

h5, .h5 {
  font-size: 24px;
}

h6, .h6 {
  font-size: 16px;
}

p {
  margin: 0 0 24px;
}

ol, ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  ol, ul {
    margin-bottom: 24px;
  }
}

ol:not([class]):not([id]) {
  counter-reset: newCounter;
}

ol:not([class]):not([id]) li:before {
  counter-increment: newCounter;
  content: counters(newCounter, ".") ".";
  display: inline-block;
  margin-right: 5px;
}

ul:not([class]):not([id]) li:before {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #000;
  display: inline-block;
  vertical-align: middle;
  margin: -1px 5px 0 1px;
}

a {
  transition: color .2s;
}

a:focus, a:active {
  text-decoration: none;
  color: #2eabe1;
}

a,
button {
  outline: none;
}

a:focus,
button:focus {
  outline: none;
}

mark {
  transition: color .2s;
  color: inherit;
}

.btn {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 1px;
  padding: 11px 10px;
  white-space: normal;
  transition: background .2s, border-color .2s;
}

@media (min-width: 768px) {
  .btn {
    font-size: 12px;
    padding: 12px;
  }
}

.btn.btn-default {
  border-color: #8c8c8c;
  background: #8c8c8c;
}

.btn.btn-default.disabled:active, .btn.btn-default[disabled]:active, .btn.btn-default.disabled, .btn.btn-default[disabled], .btn.btn-default:hover {
  background: #aeaeae;
  border-color: #aeaeae;
}

.btn.btn-default:focus, .btn.btn-default.active:focus, .btn.btn-default:active:focus, .btn.btn-default.active, .btn.btn-default:active {
  border-color: #8c8c8c;
  background: #8c8c8c;
}

.btn.btn-default.btn-border {
  color: #8c8c8c;
}

.btn.btn-warning {
  border-color: #ffcc00;
}

.btn.btn-warning.disabled:active, .btn.btn-warning[disabled]:active, .btn.btn-warning.disabled, .btn.btn-warning[disabled], .btn.btn-warning:hover {
  border-color: #ffdb4c;
  background: #ffdb4c;
}

.btn.btn-warning:focus, .btn.btn-warning.active:focus, .btn.btn-warning:active:focus, .btn.btn-warning.active, .btn.btn-warning:active {
  border-color: #ffcc00;
  background: #ffcc00;
}

.btn.btn-warning.btn-border {
  color: #ffcc00;
}

.btn.btn-primary {
  border-color: #2a3768;
}

.btn.btn-primary.disabled:active, .btn.btn-primary[disabled]:active, .btn.btn-primary.disabled, .btn.btn-primary[disabled], .btn.btn-primary:hover {
  border-color: #697395;
  background: #697395;
}

.btn.btn-primary:focus, .btn.btn-primary.active:focus, .btn.btn-primary:active:focus, .btn.btn-primary.active, .btn.btn-primary:active {
  border-color: #2a3768;
  background: #2a3768;
}

.btn.btn-primary.btn-border {
  color: #2a3768;
}

.btn.btn-info {
  border-color: #2eabe1;
}

.btn.btn-info.disabled:active, .btn.btn-info[disabled]:active, .btn.btn-info.disabled, .btn.btn-info[disabled], .btn.btn-info:hover {
  border-color: #69c3ea;
  background: #69c3ea;
}

.btn.btn-info:focus, .btn.btn-info.active:focus, .btn.btn-info:active:focus, .btn.btn-info.active, .btn.btn-info:active {
  border-color: #2eabe1;
  background: #2eabe1;
}

.btn.btn-info.btn-border {
  color: #2eabe1;
}

.btn.btn-success {
  border-color: #2ecc71;
}

.btn.btn-success.disabled:active, .btn.btn-success[disabled]:active, .btn.btn-success.disabled, .btn.btn-success[disabled], .btn.btn-success:hover {
  border-color: #6cdb9b;
  background: #6cdb9b;
}

.btn.btn-success:focus, .btn.btn-success.active:focus, .btn.btn-success:active:focus, .btn.btn-success.active, .btn.btn-success:active {
  border-color: #2ecc71;
  background: #2ecc71;
}

.btn.btn-success.btn-border {
  color: #2ecc71;
}

.btn.btn-danger {
  border-color: #e74c3c;
}

.btn.btn-danger.disabled:active, .btn.btn-danger[disabled]:active, .btn.btn-danger.disabled, .btn.btn-danger[disabled], .btn.btn-danger:hover {
  border-color: #ee8176;
  background: #ee8176;
}

.btn.btn-danger:focus, .btn.btn-danger.active:focus, .btn.btn-danger:active:focus, .btn.btn-danger.active, .btn.btn-danger:active {
  border-color: #e74c3c;
  background: #e74c3c;
}

.btn.btn-danger.btn-border {
  color: #e74c3c;
}

.btn.btn-link {
  font-weight: 700;
  color: #000;
  letter-spacing: 0.3px;
  transition: color .2s;
  padding: 5px;
  text-align: right;
}

.btn.btn-link.disabled:active, .btn.btn-link[disabled]:active, .btn.btn-link.disabled, .btn.btn-link[disabled], .btn.btn-link:hover {
  text-decoration: none;
  color: #4c4c4c;
}

.btn.btn-link:focus, .btn.btn-link.active:focus, .btn.btn-link:active:focus, .btn.btn-link.active, .btn.btn-link:active {
  text-decoration: none;
  color: #000;
}

.btn.btn-link.pull-right {
  padding-right: 0;
  margin-right: -1px;
}

.btn.btn-link [class^="icon-"], .btn.btn-link [class*=" icon-"] {
  margin-left: 2px;
}

@media (min-width: 768px) {
  .btn.btn-link [class^="icon-"], .btn.btn-link [class*=" icon-"] {
    margin-left: 6px;
  }
}

.btn.btn-border {
  background: none !important;
}

.btn.btn-lg {
  padding: 14px 12px;
  line-height: 1.5;
}

.btn.btn-sm {
  padding: 7px 12px;
}

.btn.btn-xs {
  font-size: 9px;
  padding: 8px 6px 7px;
}

@media (min-width: 768px) {
  .btn.btn-xs {
    font-size: 10px;
    padding: 8px 6px;
  }
}

.btn.btn-dark.btn-border, .btn.btn-dark {
  color: #000;
}

.btn.btn-dark.btn-border:focus, .btn.btn-dark.btn-border.active:focus, .btn.btn-dark.btn-border:active:focus, .btn.btn-dark.btn-border.active, .btn.btn-dark.btn-border:active, .btn.btn-dark:focus, .btn.btn-dark.active:focus, .btn.btn-dark:active:focus, .btn.btn-dark.active, .btn.btn-dark:active {
  color: #000;
}

.btn.disabled, .btn[disabled] {
  cursor: default;
}

.btn.active, .btn:active {
  background: inherit;
  box-shadow: none;
}

.btn-arrow {
  background: none;
  border: none;
  color: #000;
  font-size: 9px;
  line-height: 1;
  padding: 2px;
  transition: color .2s;
}

.btn-arrow.disabled:active, .btn-arrow[disabled]:active, .btn-arrow.disabled, .btn-arrow[disabled], .btn-arrow:hover {
  color: #ffcc00;
  background: none;
}

.btn-arrow:focus, .btn-arrow.active:focus, .btn-arrow:active:focus, .btn-arrow.active, .btn-arrow:active {
  color: #000;
}

.btn-top {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #2a3768;
  text-align: center;
  padding: 11px;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  transition: background .2s;
}

@media (min-width: 768px) {
  .btn-top {
    width: 48px;
    height: 48px;
    padding: 15px;
  }
}

.btn-top:hover {
  text-decoration: none;
  background: #ffcc00;
  color: #fff;
}

.btn-top:focus, .btn-top:active {
  text-decoration: none;
  color: #fff;
  background: #2a3768;
}

.btn-top i {
  line-height: 18px;
}

@media (min-width: 768px) {
  .btn-top i:before {
    font-size: 14px;
  }
}

.btn-slide {
  background: none;
  border: 2px solid #000;
  padding: 9px;
  width: 38px;
  height: 38px;
  line-height: 18px;
  transition: background .2s, border-color .2s, color .2s;
}

@media (min-width: 768px) {
  .btn-slide {
    width: 42px;
    height: 42px;
    padding: 10px;
  }
}

.btn-slide.disabled:active, .btn-slide[disabled]:active, .btn-slide.disabled, .btn-slide[disabled], .btn-slide:hover {
  background: #ffcc00;
  border-color: #ffcc00;
  color: #fff;
}

.btn-slide:focus, .btn-slide.active:focus, .btn-slide:active:focus, .btn-slide.active, .btn-slide:active {
  border-color: #000;
  background: none;
  color: #000;
}

.social-networks {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10px;
}

.social-networks li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 27px 10px 0;
}

.social-networks li.facebook a:hover {
  color: #3c5b9a;
}

.social-networks li.twitter a:hover {
  color: #2eabe1;
}

.social-networks a {
  color: #000;
  text-decoration: none;
  transition: color .2s;
}

.social-networks a:hover {
  color: #ffcc00;
}

.social-networks a:focus, .social-networks a:active {
  color: #000;
}

.social-networks a i {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  margin-right: 5px;
  margin-top: -4px;
}

.social-networks a i.icon-facebook {
  font-size: 16px;
}

.social-networks a i.icon-twitter {
  font-size: 14px;
}

.social-networks a i.icon-email {
  font-size: 12px;
}

.social-networks a i.icon-print {
  font-size: 14px;
}

.btn-group.btn-group-alt {
  margin: 0 -5px;
}

.btn-group.btn-group-alt .btn {
  margin: 0 5px 10px;
  border-radius: 2px !important;
}

.btn-social {
  border-color: #ffcc00;
  color: #ffcc00;
  background: transparent;
  font-size: 10px;
  padding: 13px 12px 10px;
  min-width: 123px;
  height: 38px;
  letter-spacing: 0.5px;
  transition: background .2s, color .2s, border-color .2s;
}

.btn-social.btn-facebook, .btn-social.btn-twitter {
  border-color: #2eabe1;
  color: #2eabe1;
}

.btn-social.btn-facebook:hover, .btn-social.btn-twitter:hover {
  background: #2eabe1;
  color: #fff;
}

.btn-social.btn-facebook:focus, .btn-social.btn-facebook.active:focus, .btn-social.btn-facebook:active:focus, .btn-social.btn-facebook.active, .btn-social.btn-facebook:active, .btn-social.btn-twitter:focus, .btn-social.btn-twitter.active:focus, .btn-social.btn-twitter:active:focus, .btn-social.btn-twitter.active, .btn-social.btn-twitter:active {
  border-color: #2eabe1;
  background: transparent;
  color: #2eabe1;
}

.btn-social.btn-facebook i, .btn-social.btn-twitter i {
  font-size: 13px;
}

.btn-social.btn-facebook {
  border-color: #3c5b9a;
  color: #3c5b9a;
}

.btn-social.btn-facebook:hover {
  background: #3c5b9a;
}

.btn-social.btn-facebook:focus, .btn-social.btn-facebook.active:focus, .btn-social.btn-facebook:active:focus, .btn-social.btn-facebook.active, .btn-social.btn-facebook:active {
  border-color: #3c5b9a;
  color: #3c5b9a;
}

.btn-social.btn-facebook i {
  font-size: 16px;
  width: 12px;
}

.btn-social:hover {
  background: #ffcc00;
}

.btn-social:focus, .btn-social.active:focus, .btn-social:active:focus, .btn-social.active, .btn-social:active {
  border-color: #ffcc00;
  background: transparent;
  color: #ffcc00;
}

.btn-social i {
  width: 16px;
  height: 16px;
  font-size: 11px;
  line-height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin: -3px 8px -1px 0;
}

.social-link {
  color: #000;
  text-decoration: none;
  transition: color .2s;
  display: inline-block;
  vertical-align: middle;
}

.social-link.social-sm {
  font-size: 16px;
}

.social-link.facebook-link:hover {
  color: #3c5b9a;
}

.social-link.facebook-link:active {
  color: #000;
}

.social-link.twitter-link:hover {
  color: #2eabe1;
}

.social-link.twitter-link:active {
  color: #000;
}

.social-link:hover {
  color: #8c8c8c;
  text-decoration: none;
}

.social-link:active {
  color: #000;
}

.social-link i {
  display: inline-block;
  vertical-align: top;
}

.social-links {
  margin: 0 0 20px;
}

.social-links li {
  line-height: 1;
  margin-bottom: 15px;
}

.form-control {
  box-shadow: none;
  font-weight: 900;
  font-size: 11px;
  transition: none;
  outline: none;
  height: 44px;
  padding: 14px 16px;
  -webkit-appearance: none;
  background: #fff url(../images/transparent.png);
}

@media (min-width: 768px) {
  .form-control {
    height: 48px;
    padding: 14px 18px;
    font-size: 12px;
  }
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

textarea.form-control {
  resize: none;
  height: 228px;
  min-height: 228px;
}

.error-message {
  font-size: 12px;
  display: none;
  color: #000;
  margin: 3px 0 -5px;
  font-weight: 500;
  letter-spacing: .3px;
}

.has-error .error-message {
  display: block;
}

.has-error .form-control {
  border-width: 2px;
  box-shadow: none;
  color: #ffcc00;
  border-color: #ffcc00;
}

.has-error .form-control::-moz-placeholder {
  color: #ffcc00;
  opacity: 1;
}

.has-error .form-control:-ms-input-placeholder {
  color: #ffcc00;
}

.has-error .form-control::-webkit-input-placeholder {
  color: #ffcc00;
}

.has-error .form-control:focus {
  border-color: #e9e9e9;
  box-shadow: none;
}

.has-error .form-control:focus::-moz-placeholder {
  color: #b0b0b0;
  opacity: 1;
}

.has-error .form-control:focus:-ms-input-placeholder {
  color: #b0b0b0;
}

.has-error .form-control:focus::-webkit-input-placeholder {
  color: #b0b0b0;
}

.success-message {
  display: none;
}

.validation-success .success-message {
  display: block;
}

.radio-inline,
.checkbox-inline {
  font-size: 10px;
  line-height: 13px;
  min-height: 20px;
  font-weight: 700;
  color: #b0b0b0;
  padding-left: 24px;
  padding-top: 4px;
  padding-bottom: 2px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .radio-inline,
  .checkbox-inline {
    font-size: 12px;
    line-height: 15px;
    padding-left: 31px;
    padding-top: 5px;
    padding-bottom: 0;
    min-height: 24px;
    letter-spacing: 0.3px;
  }
}

.radio-inline.jcf-label-active,
.checkbox-inline.jcf-label-active {
  color: #2a3768;
}

.radio-inline .jcf-checkbox,
.radio-inline .jcf-radio,
.checkbox-inline .jcf-checkbox,
.checkbox-inline .jcf-radio {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 0;
}

@media (min-width: 768px) {
  .radio-inline .jcf-checkbox,
  .radio-inline .jcf-radio,
  .checkbox-inline .jcf-checkbox,
  .checkbox-inline .jcf-radio {
    margin-top: -12px;
  }
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-left: 12px;
}

.validation-row {
  margin-bottom: 24px;
}

.validation-row.has-error label {
  color: #ffcc00;
}

.validation-row.has-error .jcf-checkbox,
.validation-row.has-error .jcf-radio {
  border-width: 2px;
  border-color: #ffcc00;
}

.validation-row.has-error .jcf-checkbox span,
.validation-row.has-error .jcf-radio span {
  background: #ffcc00;
}

.validation-row.has-error .jcf-checkbox.jcf-checked,
.validation-row.has-error .jcf-radio.jcf-checked {
  color: #ffcc00;
}

.validation-row.has-error .jcf-checkbox span {
  background: none;
  color: #ffcc00;
}

.validation-row.has-error .checkbox-inline,
.validation-row.has-error .radio-inline {
  font-weight: 900;
}

.validation-row.has-error .jcf-label-active {
  color: #ffcc00;
}

.recaptcha {
  margin-bottom: 24px;
}

.recaptcha .captcha-img {
  width: 164px;
  height: 48px;
  background: #2a3768;
}

.recaptcha .captcha-img img {
  display: block;
  width: 100%;
}

.recaptcha .captcha-message {
  font-size: 12px;
  display: block;
  color: #b0b0b0;
  letter-spacing: .3px;
  padding: 3px 0 10px;
  font-weight: 700;
}

.form-subscribe-1 {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .form-subscribe-1 {
    margin-bottom: 24px;
    max-width: 495px;
  }
}

.form-subscribe-1 .form-control {
  border: none;
  box-shadow: none;
  height: 42px;
  font-size: 11px;
  padding: 10px 18px;
}

.form-subscribe-1 .form-control::-moz-placeholder {
  color: #7f7f7f;
  opacity: 1;
}

.form-subscribe-1 .form-control:-ms-input-placeholder {
  color: #7f7f7f;
}

.form-subscribe-1 .form-control::-webkit-input-placeholder {
  color: #7f7f7f;
}

@media (min-width: 768px) {
  .form-subscribe-1 .form-control {
    height: 48px;
    font-size: 12px;
    padding: 14px 19px;
    letter-spacing: 0.3px;
    border-radius: 3px !important;
  }
}

@media (min-width: 768px) {
  .form-subscribe-1 .input-group-btn {
    padding-left: 12px;
  }
}

.form-subscribe-1 .input-group-btn .btn {
  margin-left: 0;
}

.form-subscribe-1 .btn.btn-lg {
  padding: 10px 15px;
  height: 42px;
  border-radius: 0 3px 3px 0;
}

@media (min-width: 768px) {
  .form-subscribe-1 .btn.btn-lg {
    height: 48px;
    padding: 14px 16px;
    border-radius: 2px !important;
    min-width: 123px;
  }
}

@media (max-width: 1023px) {
  .form-subscribe-2 .input-group {
    display: block;
  }
}

.form-subscribe-2 .form-control {
  border: none;
  box-shadow: none;
  height: 44px;
  font-size: 11px;
  padding: 11px 16px;
}

@media (max-width: 1023px) {
  .form-subscribe-2 .form-control {
    border-radius: 3px !important;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .form-subscribe-2 .form-control {
    height: 48px;
    font-size: 12px;
    letter-spacing: 0.3px;
    padding: 12px 19px;
  }
}

@media (min-width: 1024px) {
  .form-subscribe-2 .form-control {
    padding: 12px 20px;
  }
}

.form-subscribe-2 .btn.btn-lg {
  padding: 11px 15px;
  height: 44px;
}

@media (max-width: 1023px) {
  .form-subscribe-2 .btn.btn-lg {
    width: 100%;
    margin-left: 0;
    border-radius: 3px !important;
  }
}

@media (min-width: 768px) {
  .form-subscribe-2 .btn.btn-lg {
    height: 48px;
    padding: 14px 16px;
  }
}

@media (min-width: 1024px) {
  .form-subscribe-2 .btn.btn-lg {
    min-width: 124px;
  }
}

.contact-form .form-control {
  border-color: #fff;
  letter-spacing: 0.3px;
}

.contact-form .input-group-btn .btn {
  margin-left: 0;
}

.contact-form textarea {
  height: 148px;
  min-height: 148px;
  line-height: 1.833;
  padding: 11px 10px 11px 18px;
}

.contact-form .input-box {
  overflow: hidden;
  display: block;
  padding-bottom: 10px;
}

@media (min-width: 1024px) {
  .contact-form .input-box {
    padding: 0;
    float: left;
    width: 206px;
  }
}

@media (min-width: 1024px) {
  .contact-form .input-box .form-control {
    border-radius: 3px 0 0 3px;
  }
}

.contact-form .btn {
  width: 100%;
  display: block;
  letter-spacing: 1.7px;
}

@media (min-width: 1024px) {
  .contact-form .btn {
    width: auto;
    float: left;
    border-radius: 0 3px 3px 0;
    min-width: 123px;
    letter-spacing: 1.4px;
  }
}

.contact-form .recaptcha {
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .contact-form .recaptcha {
    float: left;
    width: 174px;
    padding-right: 10px;
    margin: 0 0 -1px;
  }
}

@media (min-width: 1024px) {
  .contact-form .recaptcha {
    margin: 0;
  }
}

.contact-form .captcha-message {
  display: inline-block;
  vertical-align: middle;
  padding: 3px 0;
  max-width: 56%;
}

@media (min-width: 480px) {
  .contact-form .captcha-message {
    padding: 3px 0 10px;
    max-width: none;
  }
}

@media (min-width: 768px) {
  .contact-form .captcha-message {
    display: block;
  }
}

@media (min-width: 1024px) {
  .contact-form .captcha-message {
    padding-bottom: 0;
  }
}

.contact-form .captcha-img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  background: #404b77;
}

@media (min-width: 768px) {
  .contact-form .captcha-img {
    display: block;
    margin: 0;
  }
}

.pagination {
  width: 100%;
  text-align: center;
  font-weight: 500;
  border: 1px solid #e9e9e9;
  padding: 6px;
  border-radius: 0;
  font-size: 16px;
}

.pagination > li {
  display: inline-block;
  vertical-align: top;
}

.pagination > li.active a {
  pointer-events: none;
  background: none;
  color: #2a3768;
  font-weight: bold;
}

.pagination > li.next, .pagination > li.previous {
  float: left;
  border-right: 1px solid #bfbfbf;
}

.pagination > li.next a, .pagination > li.previous a {
  width: 42px;
  font-size: 9px;
  padding: 15px 12px 11px 8px;
}

@media (min-width: 768px) {
  .pagination > li.next a, .pagination > li.previous a {
    width: 66px;
    padding: 20px 14px 16px 10px;
  }
}

.pagination > li.next {
  float: right;
  border-right: 0;
  border-left: 1px solid #bfbfbf;
}

.pagination > li.next a {
  padding: 15px 10px 11px 12px;
}

@media (min-width: 768px) {
  .pagination > li.next a {
    padding: 20px 8px 16px 12px;
  }
}

.pagination > li > a {
  margin-left: 0;
  background: none;
  border: none;
  line-height: 1.3;
  padding: 10px 8px 9px 9px;
  transition: color .2s;
}

@media (min-width: 768px) {
  .pagination > li > a {
    padding: 15px 12px 14px 13px;
  }
}

.pagination > li > a:hover {
  background: none;
}

.pagination > li > a:focus, .pagination > li > a:active {
  background: none;
  color: #8c8c8c;
}

.pagination > li > span {
  margin-left: 0;
  border: none;
  line-height: 1.3;
  padding: 10px 8px 9px;
}

@media (min-width: 768px) {
  .pagination > li > span {
    padding: 15px 104px 14px 10px;
  }
}

.pagination > li > span:hover {
  color: #8c8c8c;
  background: none;
}

.pager.pager-sm li > a {
  border: none;
  background: none;
  font-size: 9px;
  line-height: 1;
  padding: 2px 3px;
  width: auto;
  height: auto;
  margin: 0;
  transition: color .2s;
}

.pager.pager-sm li > a:hover {
  background: none;
  color: #ffcc00;
}

.pager li {
  display: inline-block;
  vertical-align: middle;
}

.pager li > a {
  border-width: 2px;
  color: #000;
  width: 42px;
  height: 42px;
  font-size: 10px;
  line-height: 20px;
  padding: 10px;
  margin: 0 10px;
  display: block;
  transition: color .2s, border-color .2s, background .2s;
}

.pager li > a:hover {
  background: #ffcc00;
  color: #fff;
  border-color: #ffcc00;
}

.pager li > a:active, .pager li > a:focus {
  background: none;
  color: #000;
  border-color: #000;
}

.gallery-wrapp {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .gallery-wrapp {
    margin-bottom: 30px;
  }
}

.gallery-wrapp .custom-pagination {
  height: 12px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10px;
  z-index: 10;
  text-align: center;
}

.gallery-wrapp .custom-pagination span {
  border-radius: 50%;
  margin: 0 3px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  background: #fff;
  opacity: 1;
  width: 12px;
  height: 12px;
  border: 1px solid #ffcc00;
  transition: background .2s;
}

.gallery-wrapp .custom-pagination span:hover, .gallery-wrapp .custom-pagination span.active {
  background: #ffcc00;
}

.carousel-holder {
  overflow: hidden;
  padding: 10px 0 6px;
}

.carousel-holder .gallery-wrapp {
  margin: 0;
}

@media (min-width: 768px) {
  .carousel-holder {
    margin: 0 -16px;
    padding: 40px 0 20px;
  }
}

@media (min-width: 1024px) {
  .carousel-holder {
    margin: 0;
    padding: 40px 5px 36px;
  }
}

.swiper-container .swiper-pagination-progress {
  height: 3px;
  background: none;
  position: absolute;
  top: auto;
  bottom: 0;
}

.swiper-container .swiper-pagination-progress .swiper-pagination-progressbar {
  background: #ffcc00;
}

.swiper-container .swiper-pagination-fraction {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  bottom: 100px;
  text-align: left;
  padding: 0 26px;
}

.swiper-container .swiper-pagination-bullets {
  height: 12px !important;
}

.swiper-container .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 3px;
  vertical-align: top;
  background: #fff;
  opacity: 1;
  width: 12px;
  height: 12px;
  border: 1px solid #ffcc00;
  transition: background .2s;
}

.swiper-container .swiper-pagination-bullets .swiper-pagination-bullet:hover, .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ffcc00;
}

.counter {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 100%;
  width: auto;
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  padding: 0 23px 7px;
  z-index: 10;
}

@media (min-width: 768px) {
  .counter {
    font-size: 12px;
    padding: 0 26px 8px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  z-index: 20;
  margin-top: -19px;
  left: 0;
  width: 38px;
  height: 38px;
}

@media (min-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    height: 44px;
    margin-top: -22px;
  }
}

.swiper-button-next {
  left: auto;
  right: 0;
}

.swiper-control-panel {
  position: absolute;
  bottom: 150px;
  left: 0;
  padding: 0 18px 22px;
  z-index: 7;
}

@media (min-width: 768px) {
  .swiper-control-panel {
    padding: 0 20px 53px;
  }
}

@media (min-width: 1024px) {
  .swiper-control-panel {
    padding: 0 20px 21px;
  }
}

.swiper-control-panel .swiper-control-btn {
  padding: 0;
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  text-decoration: none;
  transition: background .2s;
}

.swiper-control-panel .swiper-control-btn.active .icon-play {
  display: none;
}

.swiper-control-panel .swiper-control-btn.active .icon-pause {
  display: block;
}

@media (min-width: 768px) {
  .swiper-control-panel .swiper-control-btn {
    width: 44px;
    height: 44px;
  }
}

.swiper-control-panel .swiper-control-btn:only-child {
  margin: 0;
}

.swiper-control-panel .swiper-control-btn:hover {
  text-decoration: none;
  background: #ffcc00;
}

.swiper-control-panel .swiper-control-btn .icon-play {
  display: block;
}

.swiper-control-panel .swiper-control-btn .icon-pause {
  display: none;
}

.swiper-control-panel .swiper-control-btn i {
  line-height: 40px;
  display: inline-block;
}

@media (min-width: 768px) {
  .swiper-control-panel .swiper-control-btn i {
    line-height: 44px;
  }
}

.swiper-control-panel .swiper-control-btn i.icon-stop {
  font-size: 9px;
}

@media (min-width: 768px) {
  .swiper-control-panel .swiper-control-btn i.icon-stop {
    font-size: 10px;
  }
}

.swiper-control-panel .swiper-control-btn i.icon-play {
  margin-left: 3px;
  font-size: 9px;
}

@media (min-width: 768px) {
  .swiper-control-panel .swiper-control-btn i.icon-play {
    font-size: 10px;
  }
}

.swiper-control-panel .swiper-control-btn i.icon-pause {
  font-size: 9px;
}

@media (min-width: 768px) {
  .swiper-control-panel .swiper-control-btn i.icon-pause {
    font-size: 10px;
  }
}

.has-full-mode-gallery {
  overflow: hidden;
}

.visual-gallery {
  background: #fff;
}

.visual-gallery.visual-without-thumbs {
  padding-right: 0;
}

@media (min-width: 1024px) {
  .visual-gallery.visual-without-thumbs .swiper-wrapper {
    height: 556px;
  }
}

@media (min-width: 768px) {
  .visual-gallery.visual-without-thumbs .swiper-wrapper .caption p {
    max-width: 87%;
  }
}

.visual-gallery.has-video-active .swiper-pagination,
.visual-gallery.has-video-active .screen-controll-btn,
.visual-gallery.has-video-active .gallery-top .swiper-button-prev,
.visual-gallery.has-video-active .gallery-top .swiper-button-next,
.visual-gallery.has-video-active .caption,
.visual-gallery.has-video-active .swiper-control-panel,
.visual-gallery.has-video-active .btn-play {
  display: none;
}

.visual-gallery.full-mode-gallery {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.visual-gallery.full-mode-gallery .swiper-container {
  height: 100%;
}

.visual-gallery.full-mode-gallery .swiper-wrapper {
  height: 100%;
}

.visual-gallery.full-mode-gallery .screen-controll-btn .icon-reduce {
  opacity: 1;
  visibility: visible;
}

.visual-gallery.full-mode-gallery .screen-controll-btn .icon-full {
  opacity: 0;
  visibility: hidden;
}

.visual-gallery.full-mode-gallery.visual-without-thumbs .swiper-container {
  height: 100%;
}

.visual-gallery.full-mode-gallery.visual-without-thumbs .swiper-wrapper {
  height: 100%;
}

.visual-gallery.full-mode-gallery.visual-without-thumbs .caption p {
  max-width: none;
}

.visual-gallery.full-mode-gallery .caption p {
  max-width: none;
}

@media (min-width: 1024px) {
  .visual-gallery {
    padding-right: 240px;
  }
}

.visual-gallery .swiper-wrapper {
  height: 274px;
}

@media (min-width: 768px) {
  .visual-gallery .swiper-wrapper {
    height: 512px;
  }
}

@media (min-width: 1024px) {
  .visual-gallery .swiper-wrapper {
    height: 675px;
  }
}

.visual-gallery .swiper-slide {
  background-size: cover;
  background-position: center;
}

.visual-gallery .swiper-slide a {
  display: block;
  width: 100%;
  height: 100%;
}

.visual-gallery .swiper-slide a:hover .btn-play {
  color: #ffcc00;
}

.visual-gallery .swiper-slide.video-active {
  background-image: none !important;
  background: #000;
}

.visual-gallery .swiper-slide.video-active .video {
  border: none;
  width: 100%;
  height: 100%;
}

.visual-gallery .gallery-thumbs {
  height: 100%;
  width: 240px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: none;
  box-sizing: border-box;
  background: #fff;
}

@media (min-width: 1024px) {
  .visual-gallery .gallery-thumbs {
    display: block;
  }
}

.visual-gallery .gallery-thumbs .swiper-slide {
  width: 100%;
  height: 25%;
}

.visual-gallery .gallery-thumbs .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.visual-gallery .gallery-thumbs .swiper-slide.swiper-slide-active a:before {
  display: block;
}

.visual-gallery .gallery-thumbs .swiper-slide a {
  display: block;
  height: 100%;
}

.visual-gallery .gallery-thumbs .swiper-slide a:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  display: none;
}

.visual-gallery .swiper-button-prev,
.visual-gallery .swiper-button-next {
  text-align: center;
  width: 44px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  transition: background .2s;
  left: 20px;
  display: none;
  margin-top: -22px;
}

@media (min-width: 768px) {
  .visual-gallery .swiper-button-prev,
  .visual-gallery .swiper-button-next {
    display: block;
  }
}

.visual-gallery .swiper-button-prev i,
.visual-gallery .swiper-button-next i {
  color: #fff;
  line-height: 44px;
}

.visual-gallery .swiper-button-prev:hover,
.visual-gallery .swiper-button-next:hover {
  background: #ffcc00;
}

.visual-gallery .swiper-button-next {
  left: auto;
  right: 20px;
}

.visual-gallery .swiper-container-vertical .swiper-button-prev,
.visual-gallery .swiper-container-vertical .swiper-button-next {
  transform: rotate(90deg);
  bottom: 10px;
  left: 50%;
  top: auto;
  margin-top: 0;
  margin-left: -22px;
}

.visual-gallery .swiper-container-vertical .swiper-button-prev i,
.visual-gallery .swiper-container-vertical .swiper-button-next i {
  margin-top: -1px;
  margin-right: -2px;
  display: inline-block;
}

.visual-gallery .swiper-container-vertical .swiper-button-prev {
  top: 10px;
  bottom: auto;
}

.visual-gallery .swiper-container-vertical .swiper-button-prev i {
  margin-right: 0;
  margin-top: -1px;
  margin-left: -2px;
}

.visual-gallery .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  padding: 14px 17px 19px;
  color: #fff;
  font-size: 12px;
  line-height: 1.27;
  font-weight: 500;
}

@media (min-width: 768px) {
  .visual-gallery .caption {
    font-size: 14px;
    line-height: 1.285;
    padding: 14px 20px 19px;
  }
}

.visual-gallery .caption p {
  margin: 0;
}

@media (min-width: 768px) {
  .visual-gallery .caption p {
    max-width: 72%;
  }
}

.visual-gallery .screen-controll-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 8;
  width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  display: none;
}

.visual-gallery .screen-controll-btn:hover {
  color: #ffcc00;
  text-decoration: none;
}

@media (min-width: 768px) {
  .visual-gallery .screen-controll-btn {
    display: block;
  }
}

.visual-gallery .screen-controll-btn [class^="icon-"], .visual-gallery .screen-controll-btn [class*=" icon-"] {
  transition: opacity .2s, visibility .2s;
}

.visual-gallery .screen-controll-btn .icon-full {
  opacity: 1;
  visibility: visible;
}

.visual-gallery .screen-controll-btn .icon-reduce {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.visual-gallery .btn-play {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 82px;
  height: 82px;
  color: #fff;
  text-decoration: none;
  margin: -41px 0 0 -41px;
  display: none;
  transition: color .2s;
}

@media (min-width: 768px) {
  .visual-gallery .btn-play {
    display: block;
  }
}

.visual-gallery .close-video {
  z-index: 1000;
  position: absolute;
  top: 10px;
  left: 10px;
  color: #000;
  cursor: pointer;
  transition: background .2s;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
}

.visual-gallery .close-video:hover {
  background: #ffcc00;
}

.visual-gallery .close-video:before {
  content: "\e916";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 30px;
}

.carousel-with-nav {
  padding: 0 32px;
}

@media (min-width: 768px) {
  .carousel-with-nav {
    padding: 0 20px;
  }
}

@media (min-width: 1280px) {
  .carousel-with-nav {
    margin: 0 -32px 24px;
  }
}

.post-gallery {
  padding: 0 0 26px;
}

@media (min-width: 768px) {
  .post-gallery {
    padding: 0 32px;
  }
}

.post-gallery .swiper-wrapper {
  height: 368px;
}

@media (min-width: 768px) {
  .post-gallery .swiper-wrapper {
    height: 410px;
  }
}

@media (min-width: 1024px) {
  .post-gallery .swiper-wrapper {
    height: 676px;
  }
}

.post-gallery .swiper-slide {
  background-size: cover;
  background-position: center;
}

.post-gallery .swiper-button-prev,
.post-gallery .swiper-button-next {
  display: none;
}

@media (min-width: 768px) {
  .post-gallery .swiper-button-prev,
  .post-gallery .swiper-button-next {
    display: block;
  }
}

@media (min-width: 1024px) {
  .post-gallery .swiper-button-prev,
  .post-gallery .swiper-button-next {
    margin-top: -32px;
  }
}

.post-gallery .swiper-pagination {
  height: 4px;
}

.post-gallery .custom-pagination {
  bottom: 0;
}

@media (min-width: 768px) {
  .post-gallery .custom-pagination {
    display: none;
  }
}

@media (min-width: 1024px) {
  .gallery-top-post:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 392px;
  }
}

.gallery-top-post .text-block {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 450px;
  padding: 20px 15px 32px;
  text-align: center;
}

@media (min-width: 768px) {
  .gallery-top-post .text-block {
    justify-content: flex-end;
    text-align: left;
    padding: 50px 200px 50px 50px;
  }
}

@media (min-width: 1024px) {
  .gallery-top-post .text-block {
    padding: 50px 450px 50px 50px;
  }
}

.gallery-top-post .gallery-post {
  color: #fff;
  font-size: 13px;
  line-height: 1.27;
  font-weight: 500;
}

@media (min-width: 768px) {
  .gallery-top-post .gallery-post {
    max-width: 430px;
    font-size: 14px;
    line-height: 1.285;
  }
}

.gallery-top-post .gallery-post .title {
  display: inline-block;
  vertical-align: top;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  padding: 13px 0 12px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
}

@media (min-width: 768px) {
  .gallery-top-post .gallery-post .title {
    font-size: 12px;
    padding: 14px 0 13px;
  }
}

.gallery-top-post .gallery-post p {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .gallery-top-post .gallery-post p {
    max-width: 80%;
    margin-bottom: 24px;
  }
}

.gallery-top-post .gallery-post h2 {
  font-size: 36px;
  line-height: 1;
  max-width: 86%;
  margin: 17px auto 10px;
}

@media (min-width: 768px) {
  .gallery-top-post .gallery-post h2 {
    font-size: 56px;
    max-width: 91%;
    margin-top: 17px;
    margin: 17px 0 9px;
  }
}

.gallery-top-post .gallery-post .btn-link {
  position: relative;
  border: 1px solid #fff;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  min-width: 134px;
  padding: 15px 12px;
  text-align: center;
  transition: background .2s, border-color .2s;
}

@media (min-width: 768px) {
  .gallery-top-post .gallery-post .btn-link {
    min-width: 148px;
    font-size: 12px;
    padding: 17px 12px;
  }
}

.gallery-top-post .gallery-post .btn-link:hover {
  background: #ffcc00;
  border-color: #ffcc00;
}

.gallery-top-post .gallery-post .btn-link:focus, .gallery-top-post .gallery-post .btn-link.active:focus, .gallery-top-post .gallery-post .btn-link:active:focus, .gallery-top-post .gallery-post .btn-link.active, .gallery-top-post .gallery-post .btn-link:active {
  background: none;
  border-color: #fff;
}

.gallery-top-post .gallery-post .post-holder {
  width: 100%;
}

@media (min-width: 768px) {
  .gallery-top-post .gallery-post .post-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.gallery-top-post .gallery-post .info-box {
  padding: 0 13px 1px 20px;
  font-weight: 900;
  display: none;
}

@media (min-width: 768px) {
  .gallery-top-post .gallery-post .info-box {
    display: block;
  }
}

.gallery-top-post .gallery-post .info-box span {
  display: block;
  font-weight: 500;
}

.gallery-thumbs-post {
  padding: 0 20px 0 48px;
  position: absolute;
  right: 32px;
  top: 34px;
  bottom: 34px;
  width: 392px;
  z-index: 1;
  height: auto;
  display: none;
}

@media (min-width: 1024px) {
  .gallery-thumbs-post {
    display: block;
  }
}

.gallery-thumbs-post .thumbs-item {
  color: #fff;
  cursor: pointer;
  padding: 14px 0;
}

.gallery-thumbs-post .thumbs-item.thumbs-active .img-box:before, .gallery-thumbs-post .thumbs-item:hover .img-box:before {
  opacity: 1;
  visibility: visible;
}

.gallery-thumbs-post .thumbs-post {
  transform: translate3d(0, 0, 0);
}

.gallery-thumbs-post .thumbs-post .holder {
  overflow: hidden;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.7);
}

.gallery-thumbs-post .thumbs-post .sub-title {
  display: block;
  text-transform: uppercase;
  line-height: 1;
  padding: 0 0 11px;
  font-weight: 700;
  color: #fff;
}

.gallery-thumbs-post .thumbs-post .img-box {
  margin-right: 24px;
  float: left;
  width: 153px;
  position: relative;
}

.gallery-thumbs-post .thumbs-post .img-box:before {
  content: '';
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #ffcc00;
  transition: opacity .2s, visibility .2s;
}

.gallery-thumbs-post .thumbs-post .img-box img {
  width: 100%;
  height: auto;
  display: block;
}

.gallery-thumbs-post .thumbs-post h3 {
  color: #fff;
  margin-top: 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 1px;
}

.gallery-thumbs-post .thumbs-post p {
  margin: 0;
}

.carousel-wrapper {
  margin: 0 0 24px;
  padding: 0 2px;
}

@media (min-width: 768px) {
  .carousel-wrapper {
    padding: 0;
  }
}

.carousel-wrapper .swiper-container {
  padding-bottom: 14px;
}

@media (min-width: 768px) {
  .carousel-wrapper .swiper-container {
    padding-bottom: 0;
  }
}

.carousel-wrapper .swiper-pagination {
  position: relative;
  bottom: -13px;
}

@media (min-width: 768px) {
  .carousel-wrapper .swiper-pagination {
    display: none;
  }
}

.service-carousel {
  padding: 0;
}

.service-carousel:after, .service-carousel:before {
  content: '';
  position: absolute;
  width: 32px;
  top: 0;
  bottom: 0;
  left: 0;
  height: auto;
  background: #fff;
  z-index: 10;
}

.service-carousel:after {
  left: auto;
  right: 0;
}

@media (min-width: 640px) {
  .service-carousel {
    margin: 0 -12px;
  }
}

@media (min-width: 768px) {
  .service-carousel {
    padding: 0 20px;
    margin: 0 0 24px;
  }
}

@media (min-width: 1280px) {
  .service-carousel {
    margin: 0 -32px 24px;
  }
}

.service-carousel .swiper-container {
  padding-bottom: 16px;
}

@media (min-width: 768px) {
  .service-carousel .swiper-container {
    padding-bottom: 0;
  }
}

@media (min-width: 640px) {
  .service-carousel .swiper-slide {
    padding: 0 12px;
  }
}

.service-carousel .swiper-pagination {
  position: relative;
  bottom: -15px;
}

@media (min-width: 768px) {
  .service-carousel .swiper-pagination {
    display: none;
  }
}

.service-carousel .product-block {
  margin-bottom: 0;
  padding: 0 0 7px;
}

@media (min-width: 768px) {
  .service-carousel .product-block {
    padding: 0;
  }
}

.service-carousel .product-block .holder-box {
  padding-bottom: 13px;
}

@media (min-width: 768px) {
  .service-carousel .product-block .holder-box {
    padding-bottom: 25px;
  }
}

.service-carousel .swiper-button-prev,
.service-carousel .swiper-button-next {
  display: none;
}

@media (min-width: 768px) {
  .service-carousel .swiper-button-prev,
  .service-carousel .swiper-button-next {
    display: block;
  }
}

.product-carousel {
  padding: 0;
}

@media (min-width: 768px) {
  .product-carousel {
    padding: 0 20px;
  }
}

.product-carousel:after, .product-carousel:before {
  content: '';
  position: absolute;
  width: 32px;
  top: 0;
  bottom: 0;
  left: 0;
  height: auto;
  background: #fff;
  z-index: 10;
}

.product-carousel:after {
  left: auto;
  right: 0;
}

.product-carousel .product-block {
  margin-bottom: 0;
}

.product-carousel .product-block h3 {
  min-height: 0;
}

@media (min-width: 640px) {
  .product-carousel .product-block h3 {
    min-height: 60px;
  }
}

.product-carousel .swiper-pagination {
  display: none;
}

.product-carousel .swiper-slide {
  overflow: hidden;
}

@media (min-width: 640px) {
  .product-carousel .swiper-slide {
    padding: 0 12px;
  }
}

.partner-section .gallery-wrapp {
  padding: 0 25px;
  margin-bottom: 33px;
}

@media (min-width: 768px) {
  .partner-section .gallery-wrapp {
    padding: 0 80px;
    margin-bottom: 12px;
  }
}

.partner-section .swiper-container {
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .partner-section .swiper-container {
    padding-bottom: 0;
  }
}

.partner-section .swiper-pagination {
  bottom: 3px;
}

@media (min-width: 768px) {
  .partner-section .swiper-pagination {
    display: none;
  }
}

@media (min-width: 640px) {
  .partner-section .swiper-slide {
    padding: 0 15px;
  }
}

.partner-section .swiper-button-prev,
.partner-section .swiper-button-next {
  display: none;
}

@media (min-width: 768px) {
  .partner-section .swiper-button-prev,
  .partner-section .swiper-button-next {
    display: block;
  }
}

@media (min-width: 1024px) {
  .info-section.item {
    padding-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .info-section.item .swiper-button-prev,
  .info-section.item .swiper-button-next {
    display: none;
  }
}

.info-section.item .heading-title {
  padding: 0;
}

.info-section.item .swiper-container {
  padding: 0;
}

@media (min-width: 768px) {
  .info-section .swiper-container {
    padding-top: 58px;
  }
}

@media (min-width: 1024px) {
  .info-section .swiper-container {
    padding-top: 47px;
  }
}

.info-section .gallery-wrapp {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .info-section .gallery-wrapp {
    margin: 0 -14px;
  }
}

@media (min-width: 1024px) {
  .info-section .gallery-wrapp {
    margin: 0 -15px;
  }
}

.info-section .swiper-slide {
  padding: 0 28px;
}

@media (min-width: 768px) {
  .info-section .swiper-slide {
    padding: 0 14px;
  }
}

@media (min-width: 1024px) {
  .info-section .swiper-slide {
    padding: 0 15px;
  }
}

.info-section .swiper-button-prev,
.info-section .swiper-button-next {
  z-index: 20;
}

@media (min-width: 768px) {
  .info-section .swiper-button-prev,
  .info-section .swiper-button-next {
    left: auto;
    right: 11px;
    padding: 3px 2px;
    height: 24px;
    width: 20px;
    border: none;
    top: 0 !important;
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .info-section .swiper-button-prev:hover,
  .info-section .swiper-button-next:hover {
    color: #ffcc00;
    background: none;
  }
}

@media (min-width: 768px) {
  .info-section .swiper-button-prev {
    right: 37px;
  }
}

@media (min-width: 768px) {
  .holder-section .swiper-button-prev,
  .holder-section .swiper-button-next {
    display: none;
  }
}

.carousel-history .swiper-slide {
  padding: 0 8px 1px;
}

@media (min-width: 768px) {
  .carousel-history .swiper-slide {
    padding: 0 13px 1px;
  }
}

.gallery-box .swiper-slide {
  padding: 0 20px;
}

.default-carousel {
  margin: 0 -10px;
  border-bottom: 1px solid #e9e9e9;
}

.default-carousel.item-default {
  margin: 0 -6px;
}

@media (min-width: 768px) {
  .default-carousel.item-default {
    margin: 0 -10px;
  }
}

@media (min-width: 1024px) {
  .default-carousel.item-default {
    margin: 0 -12px;
  }
}

.default-carousel.item-default .swiper-slide {
  padding: 0 9px;
}

@media (min-width: 768px) {
  .default-carousel.item-default .swiper-slide {
    padding: 0 10px;
  }
}

@media (min-width: 1024px) {
  .default-carousel.item-default .swiper-slide {
    padding: 0 12px;
  }
}

@media (min-width: 768px) {
  .default-carousel {
    margin: 0 -12px;
  }
}

@media (min-width: 1024px) {
  .default-carousel {
    margin: 0 -15px;
  }
}

.default-carousel .swiper-slide {
  padding: 0 9px;
}

@media (min-width: 768px) {
  .default-carousel .swiper-slide {
    padding: 0 12px;
  }
}

@media (min-width: 1024px) {
  .default-carousel .swiper-slide {
    padding: 0 15px;
  }
}

.default-carousel .item-box h3 {
  font-size: 22px;
  line-height: 1;
  margin-top: 8px;
  margin-bottom: 6px;
}

@media (min-width: 768px) {
  .default-carousel .item-box h3 {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

@media (min-width: 1024px) {
  .default-carousel .item-box h3 {
    font-size: 28px;
    margin-bottom: 19px;
  }
}

.default-carousel .detail-post {
  margin-bottom: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .default-carousel .detail-post {
    padding-bottom: 8px;
  }
}

@media (min-width: 1024px) {
  .default-carousel .detail-post {
    padding-bottom: 16px;
  }
}

@media (min-width: 768px) {
  .default-carousel .detail-post .img-box {
    margin-bottom: 18px;
  }
}

@media (min-width: 1024px) {
  .default-carousel .detail-post .img-box {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .default-carousel .detail-post .img-box {
    margin-bottom: 16px;
  }
}

.default-carousel .detail-post h3 {
  font-weight: 500;
}

@media (max-width: 767px) {
  .default-carousel .detail-post h3 {
    font-size: 14px;
    line-height: 1;
    margin: 0 0 2px;
  }
}

@media (max-width: 767px) {
  .default-carousel .detail-post .details {
    font-size: 9px;
    margin-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .default-carousel .detail-post .price {
    border-top: 1px solid #e9e9e9;
    font-size: 21px;
    width: 100%;
    padding: 11px 0 0;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .default-carousel .detail-post .price .sup {
    font-size: 12px;
    padding-top: 2px;
  }
}

.default-carousel .detail-post .builder {
  margin: 0;
  padding: 0 0 1px;
  display: block;
}

@media (max-width: 767px) {
  .default-carousel .detail-post .builder {
    display: none;
  }
}

@media (min-width: 768px) {
  .default-carousel .detail-post .builder span {
    display: block;
  }
}

@media (min-width: 1024px) {
  .default-carousel .detail-post .builder span {
    display: inline;
  }
}

@media (max-width: 767px) {
  .default-carousel .detail-post .link-box {
    border: none;
    flex-direction: column-reverse;
    align-items: flex-start;
    font-size: 9px;
    padding-bottom: 0;
    margin-bottom: 2px;
  }
}

@media (min-width: 768px) {
  .default-carousel .detail-post .link-box {
    padding-bottom: 13px;
    margin-bottom: 8px;
  }
}

@media (min-width: 1024px) {
  .default-carousel .detail-post .link-box {
    margin-bottom: 15px;
  }
}

.default-carousel .detail-post .link-box a {
  color: rgba(0, 0, 0, 0.5);
}

.default-carousel .detail-post .link-box a:hover {
  color: #000;
}

@media (max-width: 767px) {
  .default-carousel .detail-post .link-box a:hover {
    color: #2eabe1;
  }
}

@media (max-width: 767px) {
  .default-carousel .detail-post .link-box a {
    color: #000;
  }
}

.customer-product {
  padding: 6px 0 0;
}

@media (min-width: 600px) {
  .customer-product {
    padding: 6px 30px 0;
  }
}

@media (min-width: 768px) {
  .customer-product {
    padding: 0 10px;
  }
}

@media (min-width: 1024px) {
  .customer-product {
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .customer-carousel {
    margin: 0 -24px;
  }
}

.customer-carousel .swiper-slide {
  padding: 0 20px;
}

@media (min-width: 768px) {
  .customer-carousel .swiper-slide {
    padding: 0 5px;
  }
}

@media (min-width: 1024px) {
  .customer-carousel .swiper-slide {
    padding: 0 25px;
  }
}

.customer-carousel .swiper-button-prev,
.customer-carousel .swiper-button-next {
  margin-top: -34px;
  top: 27%;
}

.customer-carousel .product-block {
  margin-bottom: 0;
}

.customer-carousel .product-block h3 {
  font-size: 24px;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .customer-carousel .product-block h3 {
    font-size: 26px;
    line-height: 1.076;
    margin-bottom: 0;
  }
}

.customer-carousel .product-block .note-box {
  padding: 0;
}

.social-networks {
  margin: 0;
  padding: 0;
  list-style: none;
}

.example-wrapper:after, .heading-box:after, div.lightbox .form:after, .product-box:after, .bio-block:after, .item-post:after, .item-post .holder-box:after, .product-post:after, .selling-post:after, .selling-post .text-box:after, .contact-block .holder:after, .detail-post:after, .document-post:after, .article-box:after, .info-table .detail-list:after, .info-table .img-list:after, .ads-post:after, .gallery-thumbs-post .thumbs-post:after, .clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.text-xs-left {
  text-align: left;
}

.text-xs-right {
  text-align: right;
}

.text-xs-center {
  text-align: center;
}

.text-xs-justify {
  text-align: justify;
}

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-justify {
    text-align: justify;
  }
}

@media (min-width: 1024px) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-justify {
    text-align: justify;
  }
}

@media (min-width: 1250px) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
  .text-lg-justify {
    text-align: justify;
  }
}

.ad-horizontal {
  padding: 10px;
}

@media (min-width: 769px) {
  .ad-horizontal {
    padding: 30px 0;
  }
}

.ad-horizontal img {
  height: auto;
  width: 100%;
}

@media (min-width: 769px) {
  .ad-horizontal img {
    height: auto;
    width: auto;
  }
}

@media (max-width: 1024px) {
  .container {
    padding: 0 20px;
  }
}

.navbar-toggle {
  background: transparent;
  border: none;
  margin: 0;
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: block;
  }
}

@media (min-width: 1025px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-toggle .icon-bar {
  background-color: #2a3768 !important;
  width: 22px;
  height: 4px;
  border-radius: 6px;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 3px;
}

.hot-news .text-block {
  position: relative;
  background-position: center;
  background-size: cover;
  text-align: center;
}

.hot-news .text-block::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 80.30303%;
}

.hot-news .text-block > div,
.hot-news .text-block > article {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .hot-news .text-block {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .hot-news .text-block {
    margin: 0 0 24px;
  }
}

.hot-news .gallery-post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 450px;
  padding: 20px 15px 32px;
  color: #fff;
  background-color: rgba(42, 55, 104, 0.3);
  font-size: 13px;
  line-height: 1.27;
  font-weight: 500;
}

@media (min-width: 768px) {
  .hot-news .gallery-post {
    justify-content: flex-end;
    padding: 50px 200px 50px 50px;
    font-size: 14px;
    line-height: 1.285;
  }
}

@media (min-width: 1024px) {
  .hot-news .gallery-post {
    padding: 50px 280px 50px 50px;
  }
}

.hot-news .gallery-post .icon-btn-play-sm {
  margin-bottom: 20px;
  align-self: flex-start;
}

.hot-news .gallery-post .title {
  display: inline-block;
  vertical-align: top;
  align-self: flex-start;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  padding: 13px 0 12px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
}

@media (min-width: 768px) {
  .hot-news .gallery-post .title {
    font-size: 12px;
    padding: 14px 0 13px;
  }
}

.hot-news .gallery-post p {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .hot-news .gallery-post p {
    max-width: 80%;
    margin-bottom: 24px;
  }
}

.hot-news .gallery-post h2 {
  font-size: 36px;
  line-height: 1;
  max-width: 86%;
  margin: 17px auto 10px;
}

@media (min-width: 768px) {
  .hot-news .gallery-post h2 {
    font-size: 38px;
    max-width: 100%;
    margin: 17px 0 9px;
  }
}

.hot-news .gallery-post .btn-link {
  position: relative;
  border: 1px solid #fff;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  min-width: 134px;
  padding: 15px 12px;
  transition: background .2s, border-color .2s;
}

@media (min-width: 768px) {
  .hot-news .gallery-post .btn-link {
    min-width: 148px;
    font-size: 12px;
    padding: 17px 12px;
  }
}

.hot-news .gallery-post .btn-link:hover {
  background: #ffcc00;
  border-color: #ffcc00;
}

.hot-news .gallery-post .btn-link:focus, .hot-news .gallery-post .btn-link.active:focus, .hot-news .gallery-post .btn-link:active:focus, .hot-news .gallery-post .btn-link.active, .hot-news .gallery-post .btn-link:active {
  background: none;
  border-color: #fff;
}

.hot-news .gallery-post .post-holder {
  width: 100%;
}

@media (min-width: 768px) {
  .hot-news .gallery-post .post-holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.hot-news .gallery-post .info-box {
  padding: 0 13px 1px 20px;
  font-weight: 900;
  display: none;
}

@media (min-width: 768px) {
  .hot-news .gallery-post .info-box {
    display: block;
  }
}

.hot-news .gallery-post .info-box span {
  display: block;
  font-weight: 500;
}

.home-page > .row:first-child {
  border-top: 1px solid #e9e9e9;
}

@media (min-width: 1024px) {
  .home-page .hot-news .text-block {
    margin: 0 12px 24px;
  }
}

.home-page .offset-title {
  display: inline-block;
  margin: 8px 0 15px -56px;
  line-height: 1;
}

.home-page .offset-title .btn {
  display: block;
  padding: 2px 0;
  color: #000;
  text-align: right;
}

.home-page .offset-title .btn:hover {
  color: #2a3768;
}

.home-page .card-block {
  margin-bottom: 8px;
  border-bottom: none;
}

.home-page .card-block ul {
  margin: 0;
}

.home-page .offset-col {
  width: auto;
  padding: 59px 48px 38px;
  margin: 0 -36px;
  background-color: #f2f2f2;
  float: none;
}

.home-page .offset-col .offset-title {
  position: absolute;
  z-index: 2;
  font-size: 107px;
  margin: 25px 0 15px -61px;
}

.home-page .offset-col .offset-title .btn {
  padding: 2px 42px 2px 0;
  margin: 8px 0 0;
}

.home-page .offset-col .carousel-with-nav {
  margin: 0 -32px;
}

.magazine-list {
  display: flex;
  flex-flow: row wrap;
}

@media (min-width: 768px) {
  .magazine-list {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .magazine-list {
    justify-content: center;
  }
}

.magazine-list .item-product-box {
  flex: 0 1 100%;
  margin: 0 0 20px;
}

@media (min-width: 480px) {
  .magazine-list .item-product-box {
    flex: 0 1 50%;
  }
}

@media (min-width: 768px) {
  .magazine-list .item-product-box {
    flex: 0 1 calc(100% / 3);
    margin: 0 0 50px;
  }
}

@media (min-width: 1024px) {
  .magazine-list .item-product-box {
    flex: 0 1 25%;
  }
}

.magazine-list .card-block {
  width: 100%;
  margin: 0 0 20px;
}

@media (min-width: 768px) {
  .magazine-list .card-block {
    margin: 0 0 50px;
  }
}

.magazine-list .subscribe-block {
  width: 100%;
  margin: 0 0 20px;
}

@media (min-width: 768px) {
  .magazine-list .subscribe-block {
    margin: 0 0 50px;
  }
}

.magazine-list nav {
  width: 100%;
}

.magazine-list .pagination {
  margin: 0 0 20px;
}

@media (min-width: 768px) {
  .magazine-list .pagination {
    margin: 0 0 40px;
  }
}

.form-inline .form-group {
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 768px) {
  .contact-block .form-col {
    width: 54%;
    float: left;
  }
}

@media (min-width: 768px) {
  .contact-block .form-col-input {
    width: 46%;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .contact-block .footer-holder {
    float: right;
  }
}

@media (min-width: 768px) {
  .contact-block .input-box {
    float: left;
    width: 206px;
  }
  .contact-block .input-box input {
    border-radius: 3px 0 0 3px;
  }
}

@media (min-width: 768px) {
  .contact-block .btn {
    float: left;
    width: auto;
    min-width: 123px;
    border-radius: 0 3px 3px 0;
  }
}

.search {
  background: #f2f2f2;
}

.search-default {
  display: none;
  margin-left: -10px;
  margin-right: -10px;
  padding: 20px 22px;
}

@media (min-width: 768px) {
  .search-default {
    display: flex;
    padding: 20px 10px 11px;
    align-items: flex-start;
  }
}

@media (min-width: 1025px) {
  .search-default {
    padding: 15px 41px 7px;
  }
}

.search-default .form-group {
  flex: 1;
  margin-right: 4px;
}

@media (min-width: 769px) {
  .search-default .form-group.model {
    flex: 0 0 220px;
  }
}

@media (min-width: 1025px) {
  .search-default .form-group.model {
    flex: 0 0 365px;
  }
}

.search-default .form-control {
  font-weight: 700;
  width: 100%;
}

.search-default .jcf-select {
  min-width: 10px;
}

.search-default .jcf-select .jcf-select-text {
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-left: 10px;
}

.search-default .btn {
  flex: 1;
  width: 122px;
}

@media (min-width: 1025px) {
  .search-default .btn {
    flex: 0 0 181px;
  }
}

.jcf-select-drop .jcf-select-drop-content .jcf-list {
  font-weight: 700;
}

.jcf-select-drop .jcf-select-drop-content .jcf-list .jcf-option {
  letter-spacing: .2px;
}

.search-home {
  position: absolute;
  right: 0;
  bottom: 18px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px 0 0;
  max-width: 1200px;
  height: 200px;
  margin: auto;
}

@media (min-width: 768px) {
  .search-home {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 112px;
    padding: 32px 0;
  }
}

@media (min-width: 1025px) {
  .search-home {
    padding: 32px;
  }
}

.search-home .search {
  background: transparent;
}

.search-home .jcf-select {
  min-width: 10px;
}

.search-home .jcf-select .jcf-select-text {
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .search-home .form-inline {
    display: flex;
    align-items: flex-start;
  }
}

.search-home .form-inline .form-group {
  width: 49%;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .search-home .form-inline .form-group {
    flex: 1;
    width: auto;
    margin-right: 4px;
  }
}

@media (min-width: 1024px) {
  .search-home .form-inline .form-group {
    width: 12.3%;
  }
}

.search-home .form-inline .form-group .jcf-select {
  min-width: 0;
  margin-bottom: 0;
}

.search-home .form-inline .form-group.model {
  width: 100%;
}

@media (min-width: 769px) {
  .search-home .form-inline .form-group.model {
    flex: 0 0 220px;
    width: auto;
  }
}

@media (min-width: 1025px) {
  .search-home .form-inline .form-group.model {
    flex: 0 0 365px;
  }
}

.search-home .form-inline .form-group .form-control {
  font-weight: 700;
  width: 100%;
}

.search-home .form-inline .btn {
  width: 100%;
}

@media (min-width: 768px) {
  .search-home .form-inline .btn {
    flex: 1;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .search-home .form-inline .btn {
    width: 15.9%;
  }
}

.header-annonces .container {
  position: relative;
}

.header-annonces .navbar-first {
  background-color: #e9e9e9;
  border-bottom: none;
}

@media (min-width: 1200px) {
  .header-annonces .navbar-first {
    margin-bottom: 0;
  }
}

.header-annonces .navbar-first .social-links,
.header-annonces .navbar-first .navbar-lang {
  margin-bottom: 0;
}

.header-annonces .navbar-first .social-links li,
.header-annonces .navbar-first .navbar-lang li {
  margin-bottom: 0;
}

.header-annonces .navbar-first .social-links li {
  margin-right: 20px;
}

.header-annonces .navbar-first .social-links li:first-of-type {
  margin-right: 15px;
}

.header-annonces .navbar-first .social-links li:first-of-type a {
  padding-top: 10px;
  padding-left: 0;
}

.header-annonces .navbar-first .social-links li:last-of-type {
  margin-right: 0;
}

.header-annonces .navbar-first .social-links li .social {
  display: block;
  padding-top: 15px;
}

.header-annonces .navbar-first .social-links li .social i {
  color: #2a3768;
}

.header-annonces .navbar-first .navbar-lang li a {
  padding: 10px 9px;
  font-size: 1.3rem;
  font-weight: 500;
}

.header-annonces .navbar-first .navbar-lang li a img {
  margin-right: 7px;
}

.header-annonces .navbar-first .navbar-lang li.is-active a {
  text-decoration: underline;
}

.header-annonces .navbar-second {
  background: transparent;
  border: none;
  border-bottom: solid 1px #e9e9e9;
  margin-bottom: 0;
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .header-annonces .navbar-second {
    border-bottom: none;
    margin-bottom: 12px;
  }
}

@media (min-width: 1200px) {
  .header-annonces .navbar-second {
    margin-bottom: 6px;
  }
}

.header-annonces .navbar-second .navbar-toggle {
  position: absolute;
  top: 13px;
  right: 12px;
}

@media (min-width: 768px) {
  .header-annonces .navbar-second .navbar-toggle {
    position: absolute;
    top: 21px;
    right: 24px;
  }
}

.header-annonces .navbar-second .navbar-brand {
  padding-top: 14px;
  padding-left: 0;
}

@media (min-width: 768px) {
  .header-annonces .navbar-second .navbar-brand {
    margin-left: -6px;
  }
}

@media (min-width: 768px) {
  .header-annonces .navbar-second .navbar-brand img {
    width: 250px;
    height: 53px;
  }
}

.header-annonces .navbar-second .navbar-menu {
  margin-top: 15px;
}

.header-annonces .navbar-second .navbar-menu li {
  margin-right: 11px;
}

.header-annonces .navbar-second .navbar-menu li a {
  font-size: 13px;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.header-annonces .navbar-second .navbar-account {
  float: right;
  margin: 23px 61px 0 0;
}

@media (min-width: 1200px) {
  .header-annonces .navbar-second .navbar-account {
    margin: 23px 0 0 0;
  }
}

.header-annonces .navbar-second .navbar-account li:last-of-type a {
  border-right: none;
}

@media (min-width: 1200px) {
  .header-annonces .navbar-second .navbar-account li:last-of-type a {
    padding: 3px 0 3px 12px;
  }
}

.header-annonces .navbar-second .navbar-account li a {
  padding: 7px 7px 6px 15px;
  border-right: solid 1px #ececec;
}

@media (min-width: 1200px) {
  .header-annonces .navbar-second .navbar-account li a {
    padding: 3px 4px 3px 12px;
  }
}

.header-annonces .navbar-second .navbar-account li i {
  color: #2a3768;
}

.header-annonces .navbar-second .navbar-account li i.icon-cart:before, .header-annonces .navbar-second .navbar-account li i.icon-pin:before {
  font-size: 1.6rem;
}

@media (min-width: 1200px) {
  .header-annonces .navbar-second .navbar-account li i.icon-cart:before, .header-annonces .navbar-second .navbar-account li i.icon-pin:before {
    font-size: 1.3rem;
  }
}

.header-annonces .navbar-second .navbar-account li i.icon-user:before {
  font-size: 1.4rem;
}

@media (min-width: 1200px) {
  .header-annonces .navbar-second .navbar-account li i.icon-user:before {
    font-size: 1.1rem;
  }
}

.header-annonces .navbar-second .navbar-account li sup {
  color: #8c8c8c;
  top: -14px;
  font-size: 10px;
  left: -6px;
}

@media (min-width: 1200px) {
  .header-annonces .navbar-second .navbar-account li sup {
    top: -12px;
  }
}

.header-annonces .navbar-second .search {
  float: right;
  padding: 9px 10px;
  margin-top: 11px;
  margin-right: 47px;
}

.header-annonces .navbar-second .search i:before {
  font-size: 1.6rem;
}

.header-annonces .navbar-second .baseline {
  float: left;
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 26px;
  margin-left: 8px;
  width: 251px;
}

.header-annonces .breadcrumb-annonces {
  background-color: #2a3768;
  color: white;
  padding: 6px 0;
}

.header-annonces .breadcrumb-annonces .breadcrumb {
  background-color: transparent;
  font-size: 1.2rem;
  margin-bottom: 0;
  padding: 4px 0;
}

.header-annonces .breadcrumb-annonces .breadcrumb > li.active + li:before {
  content: '';
}

.header-annonces .breadcrumb-annonces .breadcrumb > li + li:before {
  content: '\0203A';
}

.header-annonces .breadcrumb-annonces .pull-right span {
  font-size: 1.4rem;
  font-weight: 700;
  padding-right: 8px;
}

.header-annonces .breadcrumb-annonces .pull-right .btn {
  width: 133px;
}

.annonces-subfooter {
  margin: 0 12px;
  padding: 20px 20px 0;
  border-bottom: solid 1px #e9e9e9;
}

@media (min-width: 1024px) {
  .annonces-subfooter {
    padding-left: 0;
    padding-right: 0;
  }
}

.annonces-subfooter .item {
  padding-top: 30px;
}

@media (min-width: 1024px) {
  .annonces-subfooter .item {
    padding-top: 50px;
  }
}

.annonces-subfooter .picto {
  text-align: center;
}

@media (min-width: 1024px) {
  .annonces-subfooter .picto {
    text-align: left;
    padding-left: 14px;
  }
}

@media (min-width: 1200px) {
  .annonces-subfooter .picto {
    padding-left: 0;
  }
}

.annonces-subfooter .details {
  text-align: center;
}

@media (min-width: 1024px) {
  .annonces-subfooter .details {
    text-align: left;
    padding-left: 14px;
  }
}

.annonces-subfooter .details .h4 {
  font-size: 2.2rem;
  margin-top: 10px;
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  .annonces-subfooter .details .h4 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.annonces-subfooter .details p {
  font-size: 1.4rem;
  line-height: 1.42857em;
  color: #8c8c8c;
  padding: 0 5px;
}

@media (min-width: 1024px) {
  .annonces-subfooter .details p {
    padding: 0 10px 0 0;
  }
}

.banner-box {
  margin: 20px 0;
}

@media (min-width: 768px) {
  .banner-box {
    margin: 28px auto 50px !important;
  }
}

@media (min-width: 1024px) {
  .banner-box {
    margin: 28px auto 45px !important;
  }
}

.carousel-home {
  position: relative;
  background-size: cover;
  height: 354px;
}

@media (min-width: 768px) {
  .carousel-home {
    min-height: 730px;
    margin-bottom: 11px;
    background-position: center center;
  }
}

@media (min-width: 1024px) {
  .carousel-home {
    min-height: 589px;
    margin-bottom: 35px;
  }
}

.carousel-home > .container {
  position: relative;
  min-height: 589px;
  z-index: 999;
}

.carousel-home > .container .bloc-left,
.carousel-home > .container .bloc-right {
  color: white;
}

.carousel-home > .container .bloc-left p {
  font-size: 1.5rem;
  padding: 16px 16px 0;
  line-height: 1.2em;
  margin-bottom: 9px;
}

.carousel-home > .container .bloc-right .btn {
  color: white;
  border-color: white;
}

.home-main-content {
  margin-top: 6px;
}

@media (min-width: 768px) {
  .home-main-content {
    margin-top: 1px;
  }
}

.annonce-details .form-inline {
  display: none;
  background: #f2f2f2;
  margin-left: -10px;
  margin-right: -10px;
  padding: 20px 22px;
  text-align: center;
}

@media (min-width: 768px) {
  .annonce-details .form-inline {
    display: block;
  }
}

@media (min-width: 1024px) {
  .annonce-details .form-inline {
    padding: 15px 22px 7px;
  }
}

.annonce-details .form-inline .form-group {
  margin-right: 6px;
}

.annonce-details .form-inline .btn {
  width: 122px;
}

.annonce-details-header {
  position: relative;
  top: 36px;
}

@media (min-width: 768px) {
  .annonce-details-header {
    top: 36px;
  }
}

@media (min-width: 1024px) {
  .annonce-details-header {
    top: 36px;
  }
}

.annonce-details-header .btn-return {
  position: absolute;
  top: -43px;
  left: 12px;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .annonce-details-header .btn-return {
    position: absolute;
    top: 26px;
    left: 12px;
    width: 93px;
  }
}

@media (min-width: 1024px) {
  .annonce-details-header .btn-return {
    position: absolute;
    top: 35px;
    left: 12px;
  }
}

.annonce-details-header .annonce-title {
  position: relative;
  font-size: 3.2rem;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .annonce-details-header .annonce-title {
    font-size: 4.8rem;
    margin-top: 60px;
    margin-bottom: 31px;
  }
}

.annonce-details-header .annonce-title:before {
  position: absolute;
  top: -45px;
  right: 0;
  left: 0;
  content: 'catamaran';
  font-size: 1rem;
  font-family: "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #c3c3c3;
  border-top: solid 1px #c3c3c3;
  border-bottom: solid 1px #c3c3c3;
  padding: 11px 0;
  width: 69px;
  margin: auto;
  letter-spacing: 0.1em;
}

@media (min-width: 768px) {
  .annonce-details-header .annonce-title:before {
    position: absolute;
    top: -73px;
    right: 0;
    left: 0;
    font-size: 1.2rem;
    width: 80px;
    letter-spacing: 0.08em;
    padding: 13px 0;
  }
}

.annonce-details-header .annonce-title:after {
  content: '';
}

@media (min-width: 768px) {
  .annonce-details-header .annonce-title:after {
    position: absolute;
    top: -25px;
    right: 0;
    left: 0;
    content: 'occasion';
    font-size: 9.2rem;
    font-family: "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #f1f1f1;
    text-transform: uppercase;
    margin: auto;
    z-index: -1;
  }
}

@media (min-width: 1024px) {
  .annonce-details-header .annonce-title:after {
    font-size: 14rem;
  }
}

.annonce-details-header .gallery-wrapp {
  margin-bottom: 11px;
}

.annonce-details-header .contact-block {
  min-height: 389px;
}

@media (min-width: 768px) {
  .annonce-details-header .contact-block {
    min-height: 556px;
    margin-bottom: 29px;
  }
}

.annonce-details-header .contact-block .title {
  display: block;
  font-family: "Kepler", Georgia, "Times New Roman", Times, serif;
  font-size: 1.7rem;
  color: #abaebd;
}

@media (min-width: 768px) {
  .annonce-details-header .contact-block .title {
    font-size: 2rem;
  }
}

.annonce-details-header .contact-block .advertiser {
  font-size: 1.8rem;
}

@media (min-width: 768px) {
  .annonce-details-header .contact-block .advertiser {
    font-size: 2.2rem;
  }
}

.annonce-details-header .contact-block .price-block {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  padding-top: 10px;
  margin-bottom: 17px;
}

.annonce-details-header .contact-block .price-block .price-title {
  display: block;
  font-family: "Kepler", Georgia, "Times New Roman", Times, serif;
  font-size: 1.7rem;
  color: #abaebd;
}

@media (min-width: 768px) {
  .annonce-details-header .contact-block .price-block .price-title {
    font-size: 2.4rem;
  }
}

.annonce-details-header .contact-block .price-block .price-item {
  font-family: "Kepler", Georgia, "Times New Roman", Times, serif;
  font-size: 3.4rem;
}

@media (min-width: 768px) {
  .annonce-details-header .contact-block .price-block .price-item {
    font-size: 4rem;
  }
}

.annonce-details-header .contact-block .price-block .price-item .sup {
  display: inline-block;
  vertical-align: top;
  font-size: 1.8rem;
}

.annonce-details-header .contact-block .links .more {
  display: block;
  font-size: 1rem;
  font-weight: 900;
  color: white;
  text-align: center;
  margin-top: 10px;
  padding-bottom: 15px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

@media (min-width: 768px) {
  .annonce-details-header .contact-block .links .more {
    font-size: 1.2rem;
  }
}

.annonce-details-header .contact-block .links .btn-primary {
  font-size: 0.7rem;
  background-color: #232d56;
  margin-top: 14px;
}

@media (min-width: 768px) {
  .annonce-details-header .contact-block .links .btn-primary {
    font-size: 1rem;
  }
}

.annonce-details-header .date {
  font-size: 1rem;
  font-weight: 500;
}

@media (min-width: 768px) {
  .annonce-details-header .date {
    font-size: 1.2rem;
  }
}

.annonce-details-header .social-networks {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .annonce-details-header .social-networks {
    display: block;
  }
}

.annonce-details-header .social-networks li {
  margin: 0 0 10px 0;
  transition: color .2s;
}

.annonce-details-header .social-networks li:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .annonce-details-header .social-networks li {
    margin: 0 24px 10px 0;
  }
  .annonce-details-header .social-networks li:last-of-type {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .annonce-details-header .social-networks li {
    margin: 0 27px 10px 0;
  }
}

.annonce-details-header .social-networks li .social i {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  margin-right: 5px;
  margin-top: -4px;
}

.annonce-details-header .social-networks li .social i:before {
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  .annonce-details-header .social-networks li .social i:before {
    font-size: 1.6rem;
  }
}

.annonce-details-header .social-networks li.facebook:hover {
  color: #3C5B9A;
}

.annonce-details-header .social-networks li.twitter:hover {
  color: #2EABE1;
}

.annonce-details-header .social-networks li.mail:hover {
  color: #ffcc00;
}

.annonce-details-header .social-networks li.print:hover {
  color: #ffcc00;
}

.main-content {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .main-content {
    margin-top: 39px;
  }
}

.annonce-details-content hr {
  margin-top: 9px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .annonce-details-content hr {
    margin-bottom: 24px;
  }
}

.annonce-details-content .panel .panel-title {
  font-weight: 700;
}

.annonce-details-content .description .title {
  font-size: 2.5rem;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .annonce-details-content .description .title {
    margin-top: 0;
    font-size: 3rem;
  }
}

.annonce-details-content .description .text {
  font-size: 1.4rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .annonce-details-content .description .text {
    font-size: 1.6rem;
  }
}

.annonce-details-content .btn-group.btn-group-alt {
  display: flex;
  justify-content: space-around;
}

@media (min-width: 768px) {
  .annonce-details-content .btn-group.btn-group-alt {
    justify-content: flex-start;
  }
}

.annonce-details-content .btn-group.btn-group-alt .btn {
  flex: 1;
  margin: 0 4px 10px;
  padding: 10px 9px 10px;
  min-width: 0;
  font-size: 0.8rem;
  height: 32px;
}

.annonce-details-content .btn-group.btn-group-alt .btn i:before {
  font-size: 1.3rem;
}

.annonce-details-content .btn-group.btn-group-alt .btn.btn-facebook i {
  margin-top: -4px;
}

@media (min-width: 768px) {
  .annonce-details-content .btn-group.btn-group-alt .btn.btn-facebook i {
    margin-top: -3px;
  }
}

.annonce-details-content .btn-group.btn-group-alt .btn.btn-mail i:before {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .annonce-details-content .btn-group.btn-group-alt .btn.btn-mail i:before {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .annonce-details-content .btn-group.btn-group-alt .btn {
    flex: 0;
    font-size: 1.2rem;
    height: 38px;
    margin: 0 5px 10px;
    padding: 13px 12px 10px;
    min-width: 123px;
  }
  .annonce-details-content .btn-group.btn-group-alt .btn i:before {
    font-size: 1.6rem;
  }
}

.specifications-section {
  margin-top: 21px;
  margin-bottom: 48px;
}

.specifications-section:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .specifications-section {
    margin-bottom: 58px;
  }
  .specifications-section:last-of-type {
    margin-bottom: 40px;
  }
}

.specifications-section .title {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 23px;
}

@media (min-width: 768px) {
  .specifications-section .title {
    font-size: 3rem;
    margin-bottom: 27px;
  }
}

@media (min-width: 1024px) {
  .specifications-section .title {
    margin-bottom: 34px;
  }
}

.specifications-section .line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 26px;
}

.specifications-section .line .spec-item {
  font-size: 1rem;
  font-family: "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  color: #bfbfbf;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding-right: 5px;
}

@media (min-width: 768px) {
  .specifications-section .line .spec-item {
    font-size: 1.2rem;
  }
}

.specifications-section .line .spec-info {
  font-size: 1rem;
  font-family: "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.03em;
  padding-left: 5px;
}

@media (min-width: 768px) {
  .specifications-section .line .spec-info {
    font-size: 1.4rem;
  }
}

.specifications-section .line .dots {
  border-bottom: dotted 1px rgba(0, 0, 0, 0.5);
  flex: 1;
}

.specifications-section .professional-annonces,
.specifications-section .similar-annonces {
  margin-bottom: 0;
}

.specifications-section .professional-annonces .detail-post,
.specifications-section .similar-annonces .detail-post {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .specifications-section .share-annonces,
  .specifications-section .professional-annonces,
  .specifications-section .similar-annonces {
    padding-top: 36px;
  }
}

.last-published-annonces-title {
  font-size: 3.2rem;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .last-published-annonces-title {
    font-size: 4.2rem;
    margin-top: 14px;
    margin-bottom: 34px;
  }
}

.last-published-annonces {
  margin-left: -10px;
  margin-right: -10px;
}

@media (min-width: 768px) {
  .last-published-annonces {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .last-published-annonces .last-published-block-title {
    border-bottom: solid 1px #e9e9e9;
    margin-bottom: 24px;
  }
}

@media (min-width: 1024px) {
  .last-published-annonces .last-published-annonces-title {
    font-size: 4.2rem;
    margin-bottom: 40px;
    padding-left: 3px;
  }
}

.last-published-annonces .annonces-list {
  margin-bottom: 25px;
  border-bottom: solid 1px #e9e9e9;
}

.last-published-annonces .annonces-list .detail-post {
  margin-bottom: 11px;
}

@media (min-width: 1024px) {
  .last-published-annonces .annonces-list .detail-post {
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) {
  .last-published-annonces .last-published-annonce:last-child .detail-post, .last-published-annonces .last-published-annonce:nth-last-child(2) .detail-post, .last-published-annonces .last-published-annonce:nth-last-child(3) .detail-post {
    margin-bottom: 0;
  }
}

.last-published-annonces .newsletter-register {
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .last-published-annonces .newsletter-register {
    border-top: solid 1px #e9e9e9;
    border-bottom: solid 1px #e9e9e9;
  }
}

@media (min-width: 1024px) {
  .last-published-annonces .newsletter-register {
    margin-top: -30px;
  }
}

.last-published-annonces .newsletter-register .subscribe-block-md {
  margin-bottom: 0;
}

.last-published-annonces .btn-more {
  background: #e9e9e9;
  color: #9d9d9d;
  width: 100%;
}

.last-published-annonces .btn-more:hover {
  background: #eeeeee;
}

.annonces-une .annonces-une-block-title {
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .annonces-une .annonces-une-block-title {
    border-bottom: solid 1px #e9e9e9;
  }
}

@media (min-width: 1024px) {
  .annonces-une .annonces-une-block-title {
    padding-bottom: 17px;
    margin-top: 20px;
  }
}

@media (min-width: 1200px) {
  .annonces-une .annonces-une-block-title {
    padding-bottom: 9px;
  }
}

.annonces-une .annonces-une-block-title .h2 {
  font-size: 3rem;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .annonces-une .annonces-une-block-title .h2 {
    font-size: 3rem;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .annonces-une .annonces-une-block-title .h2 {
    font-size: 3.4rem;
  }
}

@media (min-width: 1200px) {
  .annonces-une .annonces-une-block-title .h2 {
    font-size: 4.2rem;
  }
}

.annonces-une .annonces-une-block-title .all-annonces {
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 8px;
  letter-spacing: 0.03em;
}

.annonces-une .annonces-une-block-title .all-annonces span {
  padding-right: 9px;
}

.annonces-une .annonces-list .annonce {
  display: flex;
}

.annonces-une .annonces-list .photo-annonce {
  max-width: 100px;
}

@media (min-width: 1024px) {
  .annonces-une .annonces-list .photo-annonce {
    max-width: 135px;
  }
}

.annonces-une .annonces-list .text-annonce {
  flex: 1;
  padding-left: 17px;
}

.annonces-une .annonces-list .text-annonce .date-annonce {
  display: none;
}

@media (min-width: 1024px) {
  .annonces-une .annonces-list .text-annonce .date-annonce {
    display: block;
  }
}
