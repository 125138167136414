body {
	font-weight: 300;
	min-width: 320px;
}

::selection {
	background: $brand-primary;
	color: $white;
}

address {
	font-size: 14px;
	@include media ('>=desktop') {
		font-size: $font-size-base;
	}
	.title {
		font: 700 32px/1 $font-family-serif;
		padding-bottom: 3px;
		@include media ('>=tablet') {
			font-size: 42px;
		}
	}
	.sub-title,
	.title {
		display: block;
	}

	a {
		color: inherit;
		&:hover {
			text-decoration: none;
			color: $brand-warning;
		}
		&:active {
			color: inherit;
		}
	}
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	margin-top: 10px;
	@include media ('>=tablet') {
		margin-top: 24px;
	}

	mark {
		background: none;
		padding: 0;
		font-weight: 300;
	}
}

h1, .h1 {
	font-size: 44px;
	line-height: 1.1;

	@include media ('>=desktop') {
		font-size: 87px;
		line-height: 1.1;
	}
}

h2, .h2 {
	font-size: 36px;
	line-height: 1.05;
	@include media ('>=desktop') {
		font-size: 72px;
	}
}

h3, .h3 {
	font-size: 32px;
	@include media ('>=desktop') {
		font-size: 48px;
	}
}

h4, .h4 {
	font-size: 28px;
	@include media ('>=desktop') {
		font-size: 32px;
		line-height: 1.1;
	}
}

h5, .h5 {
	font-size: 24px;
}

h6, .h6 {
	font-size: 16px;
}


p {
	margin: 0 0 24px;
}

ol, ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 20px;

	@include media ('>=tablet') {
		margin-bottom: 24px;
	}
}

ol:not([class]):not([id]) {
	counter-reset: newCounter;
	li {
		&:before {
			counter-increment: newCounter;
			content: counters(newCounter,".") ".";
			display: inline-block;
			margin-right: 5px;
		}
	}
}

ul:not([class]):not([id]) {
	li {
		&:before {
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background: $gray-base;
			display: inline-block;
			vertical-align: middle;
			margin: -1px 5px 0 1px;
		}
	}
}

a {
	transition: color .2s;

	&:focus,
	&:active {
		text-decoration: none;
		color: $brand-info;
	}
}

a,
button {
	outline: none;
	&:focus {
		outline: none;
	}
}

mark {
	transition: color .2s;
	color: inherit;
}