//AR--> Bouton Hamburger
.navbar-toggle {
	background: transparent;
	border: none;
	margin: 0;
	@include media('>=tablet') {
		display: block; }
	@include media('>desktop') {
		display: none; }
	.icon-bar {
		background-color: $brand-primary !important;
		width: 22px;
		height: 4px;
		border-radius: 6px;
		+ .icon-bar {
			margin-top: 3px; } } }
