.magazine-list {
	display: flex;
	flex-flow: row wrap;
	@include media (">=tablet") {
		justify-content: space-between; }
	@include media (">=desktop") {
		justify-content: center; }
	.item-product-box {
		flex: 0 1 100%;
		margin: 0 0 20px;
		@include media (">=phone") {
			flex: 0 1 50%; }
		@include media (">=tablet") {
			flex: 0 1 calc(100% / 3);
			margin: 0 0 50px; }
		@include media (">=desktop") {
			flex: 0 1 25%; } }
	.card-block {
		width: 100%;
		margin: 0 0 20px;
		@include media (">=tablet") {
			margin: 0 0 50px; } }
	.subscribe-block {
		width: 100%;
		margin: 0 0 20px;
		@include media (">=tablet") {
			margin: 0 0 50px; } }
	nav {
		width: 100%; }
	.pagination {
		margin: 0 0 20px;
		@include media (">=tablet") {
			margin: 0 0 40px; } } }
