/* custom checkbox styles */
.jcf-checkbox {
	vertical-align: middle;
	display: inline-block;
	position: relative;
	overflow: hidden;
	cursor: default;
	background: #fff;
	border-radius: 2px;
	border: 1px solid lighten($gray-base, 91.37%);
	margin: 0 3px 0 0;
	height: 20px;
	width: 20px;

	@include media ('>=tablet') {
		height: 24px;
		width: 24px;
	}
}
.jcf-checkbox span{
	position:absolute;
	display:none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.jcf-checkbox span:before {
	position:absolute;
	top:50%;
	left:50%;
	width: 10px;
	height: 10px;
	margin: -3px 0 0 -3px;
	content: "\e911";
	font-family: 'icomoon' !important;
	font-size: 8px;
	line-height: 1.5;
	@include media ('>=tablet') {
		font-size: 10px;
		line-height: 1.5;
		margin: -5px 0 0 -5px;
	}
}
:root .jcf-checkbox span {margin:-5px 0 0 -5px;}
.jcf-checkbox input[type="checkbox"] {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 0;
	margin: 0;
	left: 0;
	top: 0;
}
.jcf-checkbox.jcf-checked span{display:block;}
/* custom radio styles */
.jcf-radio {
	vertical-align: middle;
	display: inline-block;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	background: #fff;
	border: 1px solid lighten($gray-base, 91.37%);
	border-radius: 50%;
	margin: 0 3px 0 0;
	height: 20px;
	width: 20px;
	@include media ('>=tablet') {
		width: 24px;
		height: 24px;
	}
}
.jcf-radio span{
	display: none;
	position: absolute;
	top: 4px;
	left: 4px;
	right: 4px;
	bottom: 4px;
	background: $brand-primary;
	border-radius: 100%;
}
.jcf-radio input[type="radio"] {
	position: absolute;
	height: 100%;
	width: 100%;
	border: 0;
	margin: 0;
	left: 0;
	top: 0;
	cursor: pointer;
}
.jcf-radio.jcf-checked span {display:block;}
/* custom select styles */
.jcf-select {
	display: inline-block;
	vertical-align: top;
	position: relative;
	border: 1px solid #e9e9e9;
	border-radius: 3px;
	background: #fff;
	margin: 0 0 10px;
	min-width: 150px;
	height: 44px;
	width: 100%;

	@include media ('>=tablet') {
		height: 48px;
	}
}
.jcf-select select {
	z-index: 1;
	left: 0;
	top: 0;
}
.jcf-select .jcf-select-text {
	text-overflow:ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	display: block;
	font: 900 11px/1.5 $font-family-sans-serif;
	margin: 0 50px 0 16px;
	padding: 13px 0;

	@include media ('>=tablet') {
		font-size: 12px;
		margin: 0 50px 0 18px;
		padding: 14px 0;
	}
}
.jcf-select .jcf-select-text .jcf-option-hideme {
	color: #b0b0b0;
}
.jcf-select .jcf-select-opener {
	position: absolute;
	text-align: center;
	cursor: pointer;
	background: #fff;
	width: 44px;
	bottom: 0;
	right: 0;
	top: 0;
	border-radius: 0 4px 4px 0;
	color: #000;
}
.jcf-select .jcf-select-opener {
	&:before {
		content: "\e914";
		font-family: 'icomoon' !important;
		line-height: 1;
		font-size: 5px;
		line-height: 1;
		width: 10px;
		height: 10px;
		position: absolute;
		top: 19px;
		left: 16px;

		@include media ('>=tablet') {
			top: 21px;
		}
	}
}
body > .jcf-select-drop {
	position: absolute;
	margin: -1px 0 0;
	z-index: 9999;
}
body > .jcf-select-drop.jcf-drop-flipped {
	margin: 1px 0 0;
}
.jcf-select .jcf-select-drop {
	position: absolute;
	margin-top: 0px;
	z-index: 9999;
	top: 100%;
	left: -1px;
	right: -1px;
}
.jcf-select .jcf-drop-flipped {
	bottom: 100%;
	top: auto;
}

.jcf-select-drop .jcf-scrollbar-vertical {
	width: 0;
	z-index: 10;
}
.jcf-select-drop .jcf-scrollbar-vertical .jcf-scrollbar-handle {
	right: 9px;
}
.jcf-select-drop .jcf-select-drop-content {
	border: 1px solid #e9e9e9;
	box-shadow: -4px 4px 0 rgba($gray-base, .1);
}
.jcf-select-drop.jcf-drop-flipped .jcf-select-drop-content {
	box-shadow: -4px -4px 0 rgba($gray-base, .1);
}
.jcf-select-drop .jcf-option-hideme {
	display: none !important;
}
/* multiple select styles */
.jcf-list-box {
	overflow: hidden;
	display: inline-block;
	border: 1px solid #b8c3c9;
	min-width: 200px;
	margin: 0 15px;
}
/* select options styles */
.jcf-list {
	display: inline-block;
	vertical-align: top;
	position: relative;
	background: #fff;
	font: 900 11px/1.5 $font-family-sans-serif;
	width: 100%;

	@include media ('>=tablet') {
		font-size: 12px;
	}
}
.jcf-list .jcf-list-content {
	vertical-align: top;
	display: inline-block;
	overflow: auto;
	width: 100%;
}
.jcf-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.jcf-list ul li {
	display: block;
	&:hover + li,
	&:hover {
		.jcf-option {
			border-color: $brand-warning;
		}
	}
	&:before {
		display: none !important;
	}
	.jcf-option.jcf-selected {
		position: relative;
		z-index: 2;
		margin-bottom: -1px;
		padding-bottom: 16px;
	}
	&:first-child + li,
	&:first-child {
		.jcf-option {
			border: none;
		}
	}
}
.jcf-list .jcf-overflow {
	overflow: auto;
}
.jcf-list .jcf-option {
	overflow: hidden;
	cursor: pointer;
	display: block;
	padding: 12px 16px 13px;
	color: #000;
	height: 1%;
	letter-spacing: .5px;
	border-top: 1px solid #e9e9e9;
	transition: background .1s, color .1s, border-color .1s;

	@include media ('>=tablet') {
		padding: 14px 13px 15px;
	}
}
.jcf-list .jcf-disabled {
	background: #fff !important;
	color: #aaa !important;
}
.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
	background: $brand-warning;
	color: #fff;
	border-color: $brand-warning;
}

.jcf-list .jcf-optgroup-caption {
	white-space: nowrap;
	font-weight: bold;
	display: block;
	padding: 5px 9px;
	cursor: default;
	color: #000;
}
.jcf-list .jcf-optgroup .jcf-option {
	padding-left: 30px;
}



/* custom scrollbars styles */
.jcf-scrollable-wrapper {
	box-sizing: content-box;
	position: relative;
}
.jcf-scrollbar-vertical {
	position: absolute;
	cursor: pointer;
	background: none;
	width: 13px;
	bottom: 0;
	right: 0;
	top: 0;
}
.jcf-scrollbar-vertical .jcf-scrollbar-dec,
.jcf-scrollbar-vertical .jcf-scrollbar-inc {
	background: none;
	height: 10px;
	width: 10px;
	left: 0;
	top: 0;
}
.jcf-scrollbar-vertical .jcf-scrollbar-inc {
	top: auto;
	bottom: 0;
	height: 10px;
	width: 0;
}
.jcf-scrollbar-vertical .jcf-scrollbar-handle {
	background: rgba($gray-base, .3);
	height: 1px;
	width: 5px;
	border-radius: 2px;
}
.jcf-scrollbar-horizontal {
	position: absolute;
	right: auto;
	top: auto;
	left: 0;
	bottom: 0;
	width: 1px;
	height: 10px;
}
.jcf-scrollbar-horizontal .jcf-scrollbar-dec,
.jcf-scrollbar-horizontal .jcf-scrollbar-inc {
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
	background: #bbb;
	height: 0;
	width: 0;
}
.jcf-scrollbar-horizontal .jcf-scrollbar-inc {
	left: auto;
	right: 0;
}
.jcf-scrollbar-horizontal .jcf-scrollbar-slider {
	display: inline-block;
	position: relative;
	height: 14px;
}
.jcf-scrollbar-horizontal .jcf-scrollbar-handle {
	position: absolute;
	height: 4px;
	background: rgba($gray-base, .3);
	border-radius: 2px;
}
.jcf-scrollbar.jcf-inactive .jcf-scrollbar-handle {
	visibility: hidden;
}
.jcf-scrollbar.jcf-inactive .jcf-scrollbar-dec, 
.jcf-scrollbar.jcf-inactive .jcf-scrollbar-inc {
	background: #e3e3e3;
}
/* common custom form elements styles */
.jcf-disabled {background: #ddd !important;}