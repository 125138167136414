// HOMPEAGE

.carousel-home {
	position: relative;
	// background: url('../img/annonce-carousel-accueil.jpg') no-repeat
	background-size: cover;
	height: 354px;
	@include media('>=tablet') {
		min-height: 730px;
		margin-bottom: 11px;
		background-position: center center; }
	@include media('>=desktop') {
		min-height: 589px;
		// margin-bottom: -4px
		margin-bottom: 35px; }

	> .container {
		position: relative;
		min-height: 589px;
		z-index: 999;

		.bloc-left,
		.bloc-right {
			color: white; }

		.bloc-left {
			p {
				font-size: rem(15);
				padding: 16px 16px 0;
				line-height: em(18, 15);
				margin-bottom: 9px; } }

		.bloc-right {
			.btn {
				color: white;
				border-color: white; } } } }

.home-main-content {
	margin-top: 6px;
	@include media('>=tablet') {
		margin-top: 1px; } }
