.hot-news {
	.text-block {
		@include aspect-ratio(792, 636);
		background-position: center;
		background-size: cover;
		text-align: center;
		@include media ('>=tablet') {
			text-align: left; }
		@include media ('>=desktop') {
			margin: 0 0 $grid-gutter-width; } }
	.gallery-post {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-right: 450px;
		padding: 20px 15px 32px;
		color: $white;
		background-color: rgba($brand-primary, 0.3);
		font-size: 13px;
		line-height: 1.27;
		font-weight: 500;
		@include media ('>=tablet') {
			justify-content: flex-end;
			padding: 50px 200px 50px 50px;
			font-size: 14px;
			line-height: 1.285; }
		@include media ('>=desktop') {
			padding: 50px 280px 50px 50px; }
		.icon-btn-play-sm {
			margin-bottom: 20px;
			align-self: flex-start; }
		.title {
			display: inline-block;
			vertical-align: top;
			align-self: flex-start;
			border-bottom: 1px solid $white;
			border-top: 1px solid $white;
			padding: 13px 0 12px;
			letter-spacing: 1.2px;
			text-transform: uppercase;
			font-size: 11px;
			line-height: 1;
			@include media ('>=tablet') {
				font-size: 12px;
				padding: 14px 0 13px; } }
		p {
			margin-bottom: 20px;
			@include media ('>=tablet') {
				max-width: 80%;
				margin-bottom: 24px; } }
		h2 {
			font-size: 36px;
			line-height: 1;
			max-width: 86%;
			margin: 17px auto 10px;
			@include media ('>=tablet') {
				font-size: 38px;
				max-width: 100%;
				margin: 17px 0 9px; } }
		.btn-link {
			position: relative;
			border: 1px solid $white;
			color: $white;
			font-size: 11px;
			line-height: 1;
			min-width: 134px;
			padding: 15px 12px;
			transition: background .2s, border-color .2s;
			@include media ('>=tablet') {
				min-width: 148px;
				font-size: 12px;
				padding: 17px 12px; }
			&:hover {
				background: $brand-warning;
				border-color: $brand-warning; }
			&:focus,
			&.active:focus,
			&:active:focus,
			&.active,
			&:active {
				background: none;
				border-color: $white; } }
		.post-holder {
			width: 100%;
			@include media ('>=tablet') {
				display: flex;
				justify-content: flex-start;
				align-items: center; } }
		.info-box {
			padding: 0 13px 1px 20px;
			font-weight: 900;
			display: none;
			@include media ('>=tablet') {
				display: block; }
			span {
				display: block;
				font-weight: 500; } } } }

.home-page {
	> .row:first-child {
		border-top: 1px solid $panel-default-border; } // ?

	.hot-news {
		.text-block {
			@include media ('>=desktop') {
				margin: 0 ($grid-gutter-width / 2) $grid-gutter-width; } } }

	.offset-title {
		display: inline-block;
		margin: 8px 0 15px -56px;
		line-height: 1;
		.btn {
			display: block;
			padding: 2px 0;
			color: $gray-base;
			text-align: right;
			&:hover {
				color: $brand-primary; } } }

	.card-block {
		margin-bottom: 8px;
		border-bottom: none;
		ul {
			margin: 0; } }

	.offset-col {
		width: auto;
		padding: 59px 48px 38px;
		margin: 0 -36px;
		background-color: lighten($gray-base, 95%);
		float: none;
		.offset-title {
			@include position(absolute);
			z-index: 2;
			font-size: 107px;
			margin: 25px 0 15px -61px;
			.btn {
				padding: 2px 42px 2px 0;
				margin: 8px 0 0; } }

		.carousel-with-nav {
			margin: 0 -32px; } } }
