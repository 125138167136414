@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?4gyhg2');
  src:  url('../fonts/icomoon.eot?4gyhg2#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?4gyhg2') format('truetype'),
    url('../fonts/icomoon.woff?4gyhg2') format('woff'),
    url('../fonts/icomoon.svg?4gyhg2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
  content: "\e948";
}
.icon-chat-1:before {
  content: "\e910";
}
.icon-check-1:before {
  content: "\e912";
}
.icon-lock-circle:before {
  content: "\e947";
}
.icon-top-circle:before {
  content: "\e90d";
}
.icon-btn-top:before {
  content: "\e908";
}
.icon-chevron-down:before {
  content: "\e93f";
}
.icon-chevron-l:before {
  content: "\e940";
}
.icon-chevron-top:before {
  content: "\e941";
}
.icon-chevron-r:before {
  content: "\e915";
}
.icon-full:before {
  content: "\e942";
}
.icon-reduce:before {
  content: "\e945";
}
.icon-pause:before {
  content: "\e943";
}
.icon-play:before {
  content: "\e944";
}
.icon-stop:before {
  content: "\e946";
}
.icon-amazon:before {
  content: "\e900";
}
.icon-anchor:before {
  content: "\e901";
}
.icon-android:before {
  content: "\e902";
}
.icon-apple:before {
  content: "\e903";
}
.icon-arrow-b:before {
  content: "\e904";
}
.icon-arrow-l:before {
  content: "\e905";
}
.icon-arrow-r:before {
  content: "\e906";
}
.icon-arrow-t:before {
  content: "\e907";
}
.icon-btn-play-lg:before {
  content: "\e909";
}
.icon-btn-play-sm:before {
  content: "\e90a";
}
.icon-btn-plus:before {
  content: "\e90b";
}
.icon-btn-plus1:before {
  content: "\e90c";
}
.icon-cart:before {
  content: "\e90e";
}
.icon-chat:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e911";
}
.icon-check-lg:before {
  content: "\e913";
}
.icon-chevron-b:before {
  content: "\e914";
}
.icon-close:before {
  content: "\e916";
}
.icon-download:before {
  content: "\e917";
}
.icon-email:before {
  content: "\e918";
}
.icon-email-1:before {
  content: "\e919";
}
.icon-email-2:before {
  content: "\e91a";
}
.icon-eye:before {
  content: "\e91b";
}
.icon-facebook:before {
  content: "\e91c";
}
.icon-twitter:before {
  content: "\e93b";
}
.icon-facebook-square:before {
  content: "\e91d";
}
.icon-instagram-square:before {
  content: "\e923";
}
.icon-twitter-square:before {
  content: "\e93c";
}
.icon-rss-square:before {
  content: "\e937";
}
.icon-globe:before {
  content: "\e91e";
}
.icon-group:before {
  content: "\e91f";
}
.icon-group-1:before {
  content: "\e920";
}
.icon-img:before {
  content: "\e921";
}
.icon-info:before {
  content: "\e922";
}
.icon-label:before {
  content: "\e924";
}
.icon-like:before {
  content: "\e925";
}
.icon-lock:before {
  content: "\e926";
}
.icon-lock-1:before {
  content: "\e927";
}
.icon-marker:before {
  content: "\e928";
}
.icon-notebook:before {
  content: "\e929";
}
.icon-notes:before {
  content: "\e92a";
}
.icon-notes-1:before {
  content: "\e92b";
}
.icon-oliver:before {
  content: "\e92c";
}
.icon-pencil:before {
  content: "\e92d";
}
.icon-pencil-circle:before {
  content: "\e92e";
}
.icon-phone:before {
  content: "\e92f";
}
.icon-photo:before {
  content: "\e930";
}
.icon-pin:before {
  content: "\e931";
}
.icon-plus:before {
  content: "\e932";
}
.icon-print:before {
  content: "\e933";
}
.icon-recend:before {
  content: "\e934";
}
.icon-reload:before {
  content: "\e935";
}
.icon-rope:before {
  content: "\e936";
}
.icon-search:before {
  content: "\e938";
}
.icon-settings:before {
  content: "\e939";
}
.icon-truck:before {
  content: "\e93a";
}
.icon-user:before {
  content: "\e93d";
}
.icon-user-1:before {
  content: "\e93e";
}

