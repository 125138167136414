.icon-star:before {
  font-size: 14px;
}
.icon-lock-circle:before {
  font-size: 32px;
}
.icon-top-circle:before {
  font-size: 48px;
}
.icon-btn-top:before {
  font-size: 12px;
}
.icon-chevron-down:before {
  font-size: 16px;
}
.icon-chevron-l:before {
  font-size: 16px;
}
.icon-chevron-top:before {
  font-size: 16px;
}
.icon-chevron-r:before {
  font-size: 16px;
}
.icon-full:before {
  font-size: 32px;
}
.icon-reduce:before {
  font-size: 32px;
}
.icon-pause:before {
  font-size: 13px;
}
.icon-play:before {
  font-size: 10px;
}
.icon-stop:before {
  font-size: 10px;
}
.icon-amazon:before {
  font-size: 20px;
}
.icon-anchor:before {
  font-size: 12px;
}
.icon-android:before {
  font-size: 19px;
}
.icon-apple:before {
  font-size: 19px;
}
.icon-arrow-b:before {
  font-size: 12px;
}
.icon-arrow-l:before {
  font-size: 10px;
}
.icon-arrow-r:before {
  font-size: 10px;
}
.icon-arrow-t:before {
  font-size: 12px;
}
.icon-btn-play-lg:before {
  font-size: 82px;
}
.icon-btn-play-sm:before {
  font-size: 32px;
}
.icon-btn-plus:before {
  font-size: 24px;
}
.icon-btn-plus1:before {
  font-size: 48px;
}
.icon-cart:before {
  font-size: 12px;
}
.icon-chat:before {
  font-size: 40px;
}
.icon-chat-1:before {
  font-size: 30px;
}
.icon-check:before {
  font-size: 10px;
}
.icon-check-1:before {
  font-size: 17px;
}
.icon-check-lg:before {
  font-size: 30px;
}
.icon-chevron-b:before {
  font-size: 5px;
}
.icon-close:before {
  font-size: 10px;
}
.icon-close.icon-lg:before {
  font-size: 16px;
}
.icon-download:before {
  font-size: 17px;
}
.icon-email:before {
  font-size: 12px;
}
.icon-email-1:before {
  font-size: 33px;
}
.icon-email-2:before {
  font-size: 10px;
}
.icon-eye:before {
  font-size: 16px;
}
.icon-facebook:before {
  font-size: 16px;
}
.icon-twitter:before {
  font-size: 14px;
}
.icon-facebook-square:before {
  font-size: 16px;
}
.icon-facebook-square.icon-lg:before {
  font-size: 32px;
}
.icon-instagram-square:before {
  font-size: 16px;
}
.icon-instagram-square.icon-lg:before {
  font-size: 32px;
}
.icon-twitter-square:before {
  font-size: 16px;
}
.icon-twitter-square.icon-lg:before {
  font-size: 32px;
}
.icon-rss-square:before {
  font-size: 16px;
}
.icon-rss-square.icon-lg:before {
  font-size: 32px;
}
.icon-globe:before {
  font-size: 15px;
}
.icon-group:before {
  font-size: 46px;
}
.icon-group-1:before {
  font-size: 37px;
}
.icon-img:before {
  font-size: 20px;
}
.icon-info:before {
  font-size: 16px;
}
.icon-label:before {
  font-size: 43px;
}
.icon-like:before {
  font-size: 11px;
}
.icon-lock:before {
  font-size: 16px;
}
.icon-lock-1:before {
  font-size: 54px;
}
.icon-marker:before {
  font-size: 12px;
}
.icon-marker.icon-lg:before {
  font-size: 15px;
}
.icon-notebook:before {
  font-size: 61px;
}
.icon-notes:before {
  font-size: 46px;
}
.icon-notes-1:before {
  font-size: 72px;
}
.icon-oliver:before {
  font-size: 12px;
}
.icon-pencil:before {
  font-size: 11px;
}
.icon-pencil-circle:before {
  font-size: 36px;
}
.icon-phone:before {
  font-size: 15px;
}
.icon-photo:before {
  font-size: 12px;
}
.icon-pin:before {
  font-size: 16px;
}
.icon-plus:before {
  font-size: 28px;
}
.icon-print:before {
  font-size: 14px;
}
.icon-recend:before {
  font-size: 14px;
}
.icon-reload:before {
  font-size: 20px;
}
.icon-rope:before {
  font-size: 52px;
}
.icon-search:before {
  font-size: 12px;
}
.icon-settings:before {
  font-size: 12px;
}
.icon-truck:before {
  font-size: 69px;
}
.icon-user:before {
  font-size: 11px;
}
.icon-user-1:before {
  font-size: 16px;
}