//AR--> WIDGET ANNONCES A LA UNE
.annonces-une {


	.annonces-une-block-title {
		@include media('>=tablet') {
			border-bottom: solid 1px $grey; }
		@include media('>=desktop') {
			padding-bottom: 17px;
			margin-top: 20px; }
		@include media('>=widescreen') {
			padding-bottom: 9px; }
		margin-bottom: 12px;

		.h2 {
			font-size: rem(30);
			@include media('>=tablet') {
				font-size: rem(30);
				margin-top: 0; }
			@include media('>=desktop') {
				font-size: rem(34); }
			@include media('>=widescreen') {
				font-size: rem(42); }
			margin-bottom: 5px; }

		.all-annonces {
			font-size: rem(12);
			font-weight: 700;
			text-transform: uppercase;
			margin-right: 8px;
			letter-spacing: 0.03em;
			span {
				padding-right: 9px; } } }


	//AR--> Liste des annonces visible à partir de la tablette
	.annonces-list {

		.annonce {
			display: flex; }

		.photo-annonce {
			max-width: 100px;
			@include media('>=desktop') {
				max-width: 135px; } }

		.text-annonce {
			flex: 1;
			padding-left: 17px;

			.title-annonce {}

			.price-annonce {}

			.date-annonce {
				display: none;
				@include media('>=desktop') {
					display: block; } } } } }
