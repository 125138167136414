.form-control {
	box-shadow: none;
	font-weight: 900;
	font-size: 11px;
	transition: none;
	outline: none;
	height: 44px;
	padding: 14px 16px;
	-webkit-appearance: none;
	background: $white url(../images/transparent.png);

	@include media ('>=tablet') {
		height: 48px;
		padding: 14px 18px;
		font-size: 12px;
	}

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

textarea.form-control {
	resize: none;
	height: 228px;
	min-height: 228px;
}

.error-message {
	font-size: 12px;
	display: none;
	color: $gray-base;
	margin: 3px 0 -5px;
	font-weight: 500;
	letter-spacing: .3px;
}


.has-error {
	.error-message {
		display: block;
	}
	.form-control {
		border-width: 2px;
		box-shadow: none;
		color: $brand-warning;
		border-color: $brand-warning;
		@include placeholder($color: $brand-warning);
		&:focus {
			border-color: lighten($gray-base, 91.3%);
			box-shadow: none;
			@include placeholder($color: lighten($gray-base, 69%));
		}
	}
}

.success-message {
	display: none;
}

.validation-success {
	.success-message {
		display: block;
	}
}

.radio-inline,
.checkbox-inline {
	font-size: 10px;
	line-height: 13px;
	min-height: 20px;
	font-weight: 700;
	color: lighten($gray-base, 69%);
	padding-left: 24px;
	padding-top: 4px;
	padding-bottom: 2px;
	margin-bottom: 12px;

	@include media ('>=tablet') {
		font-size: 12px;
		line-height: 15px;
		padding-left: 31px;
		padding-top: 5px;
		padding-bottom: 0;
		min-height: 24px;
		letter-spacing: 0.3px;
	}

	&.jcf-label-active {
		color: $brand-primary;
	}

	.jcf-checkbox,
	.jcf-radio {
		position: absolute;
		top: 50%;
		margin-top: -10px;
		left: 0;

		@include media ('>=tablet') {
			margin-top: -12px;
		}
	}
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
	margin-left: 12px;
}


.validation-row {
	margin-bottom: 24px;
	&.has-error {
		label {
			color: $brand-warning;
		}
		.jcf-checkbox,
		.jcf-radio {
			border-width: 2px;
			border-color: $brand-warning;

			span {
				background: $brand-warning;
			}

			&.jcf-checked {
				color: $brand-warning;
			}
		}
		.jcf-checkbox {
			span {
				background: none;
				color: $brand-warning;
			}
		}
		.checkbox-inline,
		.radio-inline {
			font-weight: 900;
		}
		.jcf-label-active {
			color: $brand-warning;
		}
	}
}

.recaptcha {
	margin-bottom: 24px;

	.captcha-img {
		width: 164px;
		height: 48px;
		background: $brand-primary;

		img {
			display: block;
			width: 100%;
		}
	}

	.captcha-message {
		font-size: 12px;
		display: block;
		color: lighten($gray-base, 69%);
		letter-spacing: .3px;
		padding: 3px 0 10px;
		font-weight: 700;
	}
}

.form-subscribe-1 {
	margin-bottom: 10px;
	@include media ('>=tablet') {
		margin-bottom: 24px;
		max-width: 495px;
	}

	.form-control {
		border: none;
		box-shadow: none;
		height: 42px;
		font-size: 11px;
		padding: 10px 18px;
		@include placeholder($color: #7f7f7f);

		@include media ('>=tablet') {
			height: 48px;
			font-size: 12px;
			padding: 14px 19px;
			letter-spacing: 0.3px;
			border-radius: 3px !important;
		}
	}

	.input-group-btn {
		@include media ('>=tablet') {
			padding-left: 12px;
		}

		.btn {
			margin-left: 0;
		}
	}

	.btn {
		&.btn-lg {
			padding: 10px 15px;
			height: 42px;
			border-radius: 0 3px 3px 0;

			@include media ('>=tablet') {
				height: 48px;
				padding: 14px 16px;
				border-radius: 2px !important;
				min-width: 123px;
			}
		}
	}
}

.form-subscribe-2 {
	.input-group {
		@include media ('<desktop') {
			display: block;
		}
	}

	.form-control {
		border: none;
		box-shadow: none;
		height: 44px;
		font-size: 11px;
		padding: 11px 16px;

		@include media ('<desktop') {
			border-radius: 3px !important;
			margin-bottom: 10px;
		}

		@include media ('>=tablet') {
			height: 48px;
			font-size: 12px;
			letter-spacing: 0.3px;
			padding: 12px 19px;
		}

		@include media ('>=desktop') {
			padding: 12px 20px;
		}
	}

	.btn {
		&.btn-lg {
			padding: 11px 15px;
			height: 44px;

			@include media ('<desktop') {
				width: 100%;
				margin-left: 0;
				border-radius: 3px !important;
			}

			@include media ('>=tablet') {
				height: 48px;
				padding: 14px 16px;
			}

			@include media ('>=desktop') {
				min-width: 124px;
			}
		}
	}
}

.contact-form {
	.form-control {
		border-color: $white;
		letter-spacing: 0.3px;
	}

	.input-group-btn {
		.btn {
			margin-left: 0;
		}
	}

	textarea {
		height: 148px;
		min-height: 148px;
		line-height: 1.833;
		padding: 11px 10px 11px 18px;
	}

	.input-box {
		overflow: hidden;
		display: block;
		padding-bottom: 10px;

		@include media ('>=desktop') {
			padding: 0;
			float: left;
			width: 206px;
		}

		.form-control {
			@include media ('>=desktop') {
				border-radius: 3px 0 0 3px;
			}
		}
	}

	.btn {
		width: 100%;
		display: block;
		letter-spacing: 1.7px;

		@include media ('>=desktop') {
			width: auto;
			float: left;
			border-radius: 0 3px 3px 0;
			min-width: 123px;
			letter-spacing: 1.4px;
		}
	}

	.recaptcha {
		overflow: hidden;
		width: 100%;
		margin-bottom: 10px;

		@include media ('>=phone') {
			float: left;
			width: 174px;
			padding-right: 10px;
			margin: 0 0 -1px;
		}
		@include media ('>=desktop') {
			margin: 0;
		}
	}

	.captcha-message {
		display: inline-block;
		vertical-align: middle;
		padding: 3px 0;
		max-width: 56%;
		@include media ('>=phone') {
			padding: 3px 0 10px;
			max-width: none;
		}
		@include media ('>=tablet') {
			display: block;
		}
		@include media ('>=desktop') {
			padding-bottom: 0;
		}
	}

	.captcha-img {
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
		background: lighten(desaturate(adjust-hue($brand-primary, 0.5806), 12.4111), 7.2549);
		@include media ('>=tablet') {
			display: block;
			margin: 0;
		}
	}
}