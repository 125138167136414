.header-annonces {

	.container {
		position: relative; }


	.navbar-first {
		background-color: #e9e9e9;
		border-bottom: none;
		@include media('>=widescreen') {
			margin-bottom: 0; }

		.social-links,
		.navbar-lang {
			margin-bottom: 0;
			li {
				margin-bottom: 0; } }

		.social-links {

			li {
				margin-right: 20px;
				&:first-of-type {
					margin-right: 15px;
					a {
						padding-top: 10px;
						padding-left: 0; } }
				&:last-of-type {
					margin-right: 0; }

				.social {
					display: block;
					padding-top: 15px;

					i {
						color: $brand-primary; } } } }

		.navbar-lang {
			li {
				a {
					padding: 10px 9px;
					font-size: rem(13);
					font-weight: 500;

					img {
						margin-right: 7px; } }

				&.is-active {
					a {
						text-decoration: underline; } } } } }

	.navbar-second {
		background: transparent;
		border: none;
		border-bottom: solid 1px $grey;
		margin-bottom: 0;
		padding-bottom: 8px;

		@include media('>=tablet') {
			border-bottom: none;
			margin-bottom: 12px; }
		@include media('>=widescreen') {
			// margin-bottom: 15px
			margin-bottom: 6px; }

		.navbar-header {
			// float: left
			// +media('>=desktop')
 }			// 	float: none
		//AR--> Hamburger menu mobile
		.navbar-toggle {
			@include position(absolute, 13px 12px null null);
			@include media('>=tablet') {
				@include position(absolute, 21px 24px null null); } }

		.navbar-brand {
			padding-top: 14px;
			padding-left: 0;
			@include media('>=tablet') {
				margin-left: -6px; }

			img {
				@include media('>=tablet') {
					width: 250px;
					height: 53px; } } }

		.navbar-menu {
			margin-top: 15px;
			li {
				margin-right: 11px;
				a {
					font-size: 13px;
					font-weight: 700;
					color: black;
					text-transform: uppercase;
					letter-spacing: 0.03em; } } }

		.navbar-account {
			float: right;
			margin: 23px 61px 0 0;
			@include media('>=widescreen') {
				margin: 23px 0 0 0; }


			li {
				&:last-of-type {
					a {
						border-right: none;
						@include media('>=widescreen') {
							padding: 3px 0 3px 12px; } } }
				a {
					padding: 7px 7px 6px 15px;
					border-right: solid 1px $gray-lighter;
					@include media('>=widescreen') {
						padding: 3px 4px 3px 12px; } }
				i {
					color: $brand-primary;

					&.icon-cart,
					&.icon-pin {
						&:before {
							font-size: rem(16);
							@include media('>=widescreen') {
								font-size: rem(13); } } }
					&.icon-user {
						&:before {
							font-size: rem(14);
							@include media('>=widescreen') {
								font-size: rem(11); } } } }

				sup {
					color: $gray-light;
					top: -14px;
					font-size: 10px;
					left: -6px;
					@include media('>=widescreen') {
						top: -12px; } } } }

		.search {
			float: right;
			padding: 9px 10px;
			margin-top: 11px;
			margin-right: 47px;

			i {
				&:before {
					font-size: rem(16); } } }

		.baseline {
			float: left;
			font-size: rem(11);
			font-weight: 700;
			margin-top: 26px;
			margin-left: 8px;
			width: 251px; } }


	.breadcrumb-annonces {
		background-color: $brand-primary;
		color: white;
		padding: 6px 0;

		.breadcrumb {
			background-color: transparent;
			font-size: rem(12);
			margin-bottom: 0;
			padding: 4px 0;
			// +media('>desktop')
			> li {
				&.active {
					+ li:before {
						content: ''; } }
				+ li:before {
					content: '\0203A'; } } }

		//AR--> Visible à partir de la tablette
		.pull-right {
			span {
				font-size: rem(14);
				font-weight: 700;
				padding-right: 8px; }
			.btn {
				width: 133px; } } } }
