.annonce-details {
	.form-inline {
		display: none;
		background: lighten($gray-base, 94.9%);
		margin-left: -10px;
		margin-right: -10px;
		padding: 20px 22px;
		text-align: center;
		@include media('>=tablet') {
			display: block; }
		@include media('>=desktop') {
			padding: 15px 22px 7px; }

		.form-group {
			margin-right: 6px; }

		.btn {
			width: 122px; } } }

.annonce-details-header {
	position: relative;
	top: 36px;
	@include media('>=tablet') {
		top: 36px; }
	@include media('>=desktop') {
		top: 36px; }


	.btn-return {
		@include position(absolute, -43px null null 12px);
		font-size: rem(9);
		@include media('>=tablet') {
			@include position(absolute, 26px null null 12px);
			width: 93px; }
		@include media('>=desktop') {
			@include position(absolute, 35px null null 12px); } }

	.annonce-title {
		position: relative;
		font-size: rem(32);
		margin-top: 30px;
		@include media('>=tablet') {
			font-size: rem(48);
			margin-top: 60px;
			margin-bottom: 31px; }
		@include media('>=desktop');

		&:before {
			@include position(absolute, -45px 0 null 0);
			content: 'catamaran';
			font-size: rem(10);
			font-family: $font-family-sans-serif;
			font-weight: 700;
			text-transform: uppercase;
			color: darken($grey,15%);
			border-top: solid 1px darken($grey,15%);
			border-bottom: solid 1px darken($grey,15%);
			padding: 11px 0;
			width: 69px;
			margin: auto;
			letter-spacing: 0.1em;
			@include media('>=tablet') {
				@include position(absolute, -73px 0 null 0);
				font-size: rem(12);
				width: 80px;
				letter-spacing: 0.08em;
				padding: 13px 0; } }
		&:after {
			content: '';
			@include media('>=tablet') {
				@include position(absolute, -25px 0 null 0);
				content: 'occasion';
				font-size: rem(92);
				font-family: $font-family-sans-serif;
				color: #f1f1f1;
				text-transform: uppercase;
				margin: auto;
				z-index: -1; }
			@include media('>=desktop') {
				font-size: rem(140); } } }

	.gallery-wrapp {
		margin-bottom: 11px; }

	.contact-block {
		min-height: 389px;
		@include media('>=tablet') {
			min-height: 556px;
			margin-bottom: 29px; }

		.title {
			display: block;
			font-family: $font-family-serif;
			font-size: rem(17);
			color: #abaebd;
			@include media('>=tablet') {
				font-size: rem(20); } }

		.advertiser {
			font-size: rem(18);
			@include media('>=tablet') {
				font-size: rem(22); } }

		.price-block {
			border-top: solid 1px rgba(255,255,255,0.2);
			border-bottom: solid 1px rgba(255,255,255,0.2);
			padding-top: 10px;
			margin-bottom: 17px;
			.price-title {
				display: block;
				font-family: $font-family-serif;
				font-size: rem(17);
				color: #abaebd;
				@include media('>=tablet') {
					font-size: rem(24); } }
			.price-item {
				font-family: $font-family-serif;
				font-size: rem(34);
				@include media('>=tablet') {
					font-size: rem(40); }
				.sup {
					display: inline-block;
					vertical-align: top;
					font-size: rem(18); } } }
		.links {
			.more {
				display: block;
				font-size: rem(10);
				font-weight: 900;
				color: white;
				text-align: center;
				margin-top: 10px;
				padding-bottom: 15px;
				border-bottom: solid 1px rgba(255,255,255,0.2);
				@include media('>=tablet') {
					font-size: rem(12); } }
			.btn-primary {
				font-size: rem(7);
				background-color: darken($brand-primary, 5%);
				margin-top: 14px;
				@include media('>=tablet') {
					font-size: rem(10); } } } }

	.date {
		font-size: rem(10);
		font-weight: 500;
		@include media('>=tablet') {
			font-size: rem(12); } }

	.social-networks {
		display: flex;
		justify-content: space-between;
		@include media('>=tablet') {
			display: block; }
		li {
			margin: 0 0 10px 0;
			transition: color .2s;
			&:hover {
				cursor: pointer; }

			@include media('>=tablet') {
				margin: 0 24px 10px 0;
				&:last-of-type {
					margin-right: 0; } }
			@include media('>=tablet') {
				margin: 0 27px 10px 0; }

			.social {
				i {
					display: inline-block;
					vertical-align: middle;
					font-size: 16px;
					margin-right: 5px;
					margin-top: -4px;
					&:before {
						font-size: rem(12);
						@include media('>=tablet') {
							font-size: rem(16); } } } }

			&.facebook {
				&:hover {
					color: $facebook; } }
			&.twitter {
				&:hover {
					color: $twitter; } }
			&.mail {
				&:hover {
					color: $brand-warning; } }
			&.print {
				&:hover {
					color: $brand-warning; } } } } }

.main-content {
	margin-top: 30px;
	@include media('>=tablet') {
		margin-top: 39px; } }

.annonce-details-content {

	hr {
		margin-top: 9px;
		margin-bottom: 0;
		@include media('>=tablet') {
			margin-bottom: 24px; } }

	.panel {
		.panel-title {
			font-weight: 700; } }

	.description {
		.title {
			font-size: rem(25);
			margin-top: 20px;
			@include media('>=tablet') {
				margin-top: 0;
				font-size: rem(30); } }
		.text {
			font-size: rem(14);
			line-height: 1.5;
			@include media('>=tablet') {
				font-size: rem(16); } } }


	.btn-group.btn-group-alt {
		display: flex;
		justify-content: space-around;
		@include media('>=tablet') {
			justify-content: flex-start; }
		.btn {
			flex: 1;
			margin: 0 4px 10px;
			padding: 10px 9px 10px;
			min-width: 0;
			font-size: rem(8);
			height: 32px;
			i:before {
				font-size: rem(13); }
			&.btn-facebook {
				i {
					margin-top: -4px;
					@include media('>=tablet') {
						margin-top: -3px; } } }
			&.btn-mail {
				i:before {
					font-size: rem(10);
					@include media('>=tablet') {
						font-size: rem(12); } } }
			@include media('>=tablet') {
				flex: 0;
				font-size: rem(12);
				height: 38px;
				margin: 0 5px 10px;
				padding: 13px 12px 10px;
				min-width: 123px;
				i:before {
					font-size: rem(16); } } } } }


.specifications-section {
	margin-top: 21px;
	margin-bottom: 48px;
	&:last-of-type {
		margin-bottom: 0; }
	@include media('>=tablet') {
		margin-bottom: 58px;
		&:last-of-type {
			margin-bottom: 40px; } }
	.title {
		font-size: rem(25);
		margin-top: 0;
		margin-bottom: 23px;
		@include media('>=tablet') {
			font-size: rem(30);
			margin-bottom: 27px; }
		@include media('>=desktop') {
			margin-bottom: 34px; } }
	.line {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 26px;

		.spec-item {
			font-size: rem(10);
			font-family: $font-family-sans-serif;
			font-weight: 900;
			color: #bfbfbf;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			padding-right: 5px;
			@include media('>=tablet') {
				font-size: rem(12); } }

		.spec-info {
			font-size: rem(10);
			font-family: $font-family-sans-serif;
			font-weight: 700;
			letter-spacing: 0.03em;
			padding-left: 5px;
			@include media('>=tablet') {
				font-size: rem(14); } }


		.dots {
			border-bottom: dotted 1px rgba(0,0,0,0.5);
			flex: 1; } }

	.professional-annonces,
	.similar-annonces {
		margin-bottom: 0;

		.detail-post {
			margin-bottom: 0; } }

	.share-annonces,
	.professional-annonces,
	.similar-annonces {
		@include media('>=tablet') {
			padding-top: 36px; } } }
