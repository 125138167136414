.form-inline {
	.form-group {
		display: inline-block;
		vertical-align: top; } }

//surcharge de psd2html
.contact-block {
	.form-col {
		@include media('>=tablet') {
			width: 54%;
			float: left; } }
	.form-col-input {
		@include media('>=tablet') {
			width: 46%;
			padding-right: 10px; } }
	.footer-holder {
		@include media('>=tablet') {
			float: right; } }
	.input-box {
		@include media('>=tablet') {
			float: left;
			width: 206px;
			input {
				border-radius: 3px 0 0 3px; } } }
	.btn {
		@include media('>=tablet') {
			float: left;
			width: auto;
			min-width: 123px;
			border-radius: 0 3px 3px 0; } } }

//FORMULAIRE DE RECHERCHE PAR DEFAUT
.search {
	background: lighten($gray-base, 94.9%); }

.search-default {
	display: none;
	margin-left: -10px;
	margin-right: -10px;
	padding: 20px 22px;
	// text-align: center
	@include media('>=tablet') {
		// display: block
		display: flex;
		padding: 20px 10px 11px;
		align-items: flex-start; }
	@include media('>desktop') {
		padding: 15px 41px 7px; }

	.form-group {
		flex: 1;
		margin-right: 4px;

		&.model {
			@include media('>tablet') {
				flex: 0 0 220px; }
			@include media('>desktop') {
				flex: 0 0 365px; } } }

	.form-control {
		font-weight: 700;
		width: 100%; }

	.jcf-select {
		min-width: 10px;
		.jcf-select-text {
			font-weight: 700;
			text-align: left;
			margin: 0;
			padding-left: 10px; } }

	.btn {
		flex: 1;
		width: 122px;
		@include media('>desktop') {
			flex: 0 0 181px; } } }

.jcf-select-drop {
	.jcf-select-drop-content {
		.jcf-list {
			font-weight: 700;
			.jcf-option {
				letter-spacing: .2px; } } } }

//FORMULAIRE DE RECHERCHE DE LA PAGE D'ACCUEIL
.search-home {
	@include position(absolute, null 0 18px 0);
	// margin-left: -20px
	// margin-right: -20px
	background-color: rgba(0,0,0,0.7);
	padding: 20px 0 0;
	max-width: 1200px;
	height: 200px;
	margin: auto;
	@include media('>=tablet') {
		@include position(absolute, null 0 0 0);
		height: 112px;
		padding: 32px 0; }
	@include media('>desktop') {
		padding: 32px; }

	.search {
		background: transparent; }

	.jcf-select {
		min-width: 10px;
		.jcf-select-text {
			font-weight: 700;
			text-align: left;
			margin: 0;
			padding-left: 10px; } }

	.form-inline {
		@include media('>=tablet') {
			display: flex;
			align-items: flex-start; }

		.form-group {
			width: 49%;
			margin-bottom: 4px;
			@include media('>=tablet') {
				flex: 1;
				width: auto;
				margin-right: 4px; }
			@include media('>=desktop') {
				width: 12.3%; }
			.jcf-select {
				min-width: 0;
				margin-bottom: 0; }

			&.model {
				width: 100%;
				@include media('>tablet') {
					flex: 0 0 220px;
					width: auto; }
				@include media('>desktop') {
					flex: 0 0 365px; } }

			.form-control {
				font-weight: 700;
				width: 100%; } }
		.btn {
			width: 100%;
			@include media('>=tablet') {
				flex: 1;
				width: auto;
				// display: inline-block
 }				// width: 19%
			@include media('>=desktop') {
				width: 15.9%; } } } }
