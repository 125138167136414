//em mode mobile et tablette le titre "Les dernières annonces" ne se trouve pas dans la div.last-published-annonces
.last-published-annonces-title {
	font-size: rem(32);
	margin-bottom: 20px;
	@include media('>=tablet') {
		font-size: rem(42);
		margin-top: 14px;
		margin-bottom: 34px; } }

.last-published-annonces {
	margin-left: -10px;
	margin-right: -10px;
	@include media('>=tablet') {
		margin-left: 0;
		margin-right: 0; }


	.last-published-block-title {
		@include media('>=desktop') {
			border-bottom: solid 1px $grey;
			margin-bottom: 24px; } }

	.last-published-annonces-title {
		@include media('>=desktop') {
			font-size: rem(42);
			margin-bottom: 40px;
			padding-left: 3px; } }


	.annonces-list {
		margin-bottom: 25px;
		border-bottom: solid 1px $grey;
		.detail-post {
			margin-bottom: 11px;
			@include media('>=desktop') {
				margin-bottom: 30px; } } }

	.last-published-annonce {
		@include media('>=desktop') {
			&:last-child,//dernier annonce
			&:nth-last-child(2),// avant dernier annonce
			&:nth-last-child(3), {// antépénultième annonce
				.detail-post {
					margin-bottom: 0; } } } }

	.newsletter-register {
		padding-top: 24px;
		padding-bottom: 24px;
		margin-bottom: 24px;
		@include media('>=tablet') {
			border-top: solid 1px $grey;
			border-bottom: solid 1px $grey; }
		@include media('>=desktop') {
			margin-top: -30px; }

		.subscribe-block-md {
			margin-bottom: 0; } }

	.btn-more {
		background: $grey;
		color: darken($grey,30%);
		width: 100%;
		&:hover {
			background: lighten($grey, 2%); } } }
